<template>
    <div class="container-fluid">
        <div class="row">
        <transition name="fade">
          <div class="col-6">
            <div class="card-header bg-gradient-bleu text-white flex-center-space-between" style="height:60px">
              <h4 class="card-title">IMPORT DES ALLERGÈNES</h4>
            </div>
            <div class="card">
              <div class="filtres-container">
                <!--<div v-tooltip="{ content: 'Exporter les allergènes', placement: 'top' }" class="push-left-2rem">
                    <i class="fas fa-file-export cursor-pointer" style="font-size: 1.6rem;margin-right: 1rem;" @click="exporterAllergenes()"></i>
                </div>-->
                <div class="flex-center-space-between">
                    <v-select v-model="fournisseurId" :options="fournisseurs" label='nom' placeholder="Fournisseurs..." :reduce="f => f.id" style="margin-top:0!important;padding-top:0!important" />
                    <input type="file" id="selectedFile" ref="file" style="visibility: hidden;" @change="uploadFile()" />
                    <button class="btn btn-primary cursor-pointer" v-tooltip="{ content: 'Importer le fichier des allergènes', placement: 'top' }" onclick="document.getElementById('selectedFile').click();">
                        <i class="fas fa-file-import push-right-1rem"></i>Choisir un fichier
                    </button>
                </div>
                <div class="flex-center-space-between">
                    <button class="btn btn-success cursor-pointer push-left-2rem" :disabled="!fournisseurId || !fichierAllergenes" v-tooltip="{ content: 'Importer les allergènes', placement: 'top' }" @click="importerAllergenes()">
                        <i class="fas fa-file-import push-right-1rem" :class="{ 'fa-spin ' : importEnCours }"></i>Importer
                    </button>
                    {{fichierAllergenes.name}}
                </div>
              </div>
            </div>
          </div>
        </transition>
        <transition name="fade" mode="out-in">
            <div class="col-6">
                <div v-if="succes.length !== 0" class="card-header alert-success flex-center-space-between" style="height:60px">
                    <h4 class="card-title">SUCCES</h4>
                </div>
                <div v-if="succes.length !== 0" class="card">
                    <div class="push-left-1rem push-right-1rem push-top-1rem push-bottom-1rem">
                        <div v-for="(s, i) in succes" :key="i">
                            <li style="color:green">{{s}}</li>
                        </div>
                    </div>
                </div>
                <div v-if="erreurs.length !== 0" class="card-header alert-danger flex-center-space-between" style="height:60px">
                    <h4 class="card-title">ERREURS</h4>
                </div>
                <div v-if="erreurs.length !== 0" class="card">
                    <div class="push-left-1rem push-right-1rem push-top-1rem push-bottom-1rem">
                        <div v-for="(erreur, i) in erreurs" :key="i">
                            <li style="color:red">{{erreur}}</li>
                        </div>
                    </div>
                </div>
                <div v-if="avertissements.length !== 0" class="card-header alert-warning flex-center-space-between" style="height:60px">
                    <h4 class="card-title">AVERTISSEMENTS</h4>
                </div>
                <div v-if="avertissements.length !== 0" class="card">
                    <div class="push-left-1rem push-right-1rem push-top-1rem push-bottom-1rem">
                        <div v-for="(avertissement, i) in avertissements" :key="i">
                            <li style="color:orange">{{avertissement}}</li>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        </div>
    </div>
</template>

<script>
import { importerAllergenes } from '@/api/gpao/fournisseurs'
import { showAlerts } from "@/mixins/alerts"

export default {
    mixins: [
        showAlerts
    ],
    data() {
        return {
            fournisseurs: [],
            fournisseurId: null,
            fichierAllergenes: '',
            erreurs: [],
            avertissements: [],
            succes: [],
            importEnCours: false
        }
    },
    methods: {
        uploadFile() {
            this.fichierAllergenes = this.$refs.file.files[0]
        },
        async importerAllergenes() {
            if (!this.fichierAllergenes) return
            this.importEnCours = true
            this.erreurs = []
            this.avertissements = []
            this.succes = []
            let formData = new FormData()
            formData.append('file', this.fichierAllergenes)
            try {
                let response = await importerAllergenes(this.fournisseurId, formData)
                this.erreurs = response.data.erreurs
                this.avertissements = response.data.avertissements
                if (response.data.ajoutsAllergenes !== 0)
                    this.succes.push(response.data.ajoutsAllergenes + " codes EAN avec leurs allergènes ont été ajoutés en base de données avec succès")
                if (response.data.modificationsAllergenes !== 0)
                    this.succes.push(response.data.modificationsAllergenes + " codes EAN avec leurs allergènes ont été modifiés en base de données avec succès")
                if (response.data.suppressionsAllergenes !== 0)
                    this.succes.push(response.data.suppressionsAllergenes + " codes EAN avec leurs allergènes ont été supprimés de la base de données avec succès")
                if (response.data.modificationsTarifs !== 0)
                    this.succes.push(response.data.modificationsTarifs + " tarifs ont eu leur code EAN modifié en base de données avec succès")
                if (this.erreurs.length === 0 && this.succes.length === 0)
                    this.succes.push("Les allergènes présents dans le fichier sont déjà à jour en base de données")
                /*if (!this.erreurs) {
                    this.$toast('Allergènes importés !', {
                    horizontalPosition: 'center',
                    verticalPosition: 'top',
                    className: ['toast-success'],
                    duration: 1500
                    })
                }*/
            } finally {
                this.importEnCours = false
            }
        },
        exporterAllergenes() {
            
        }
    },
    mounted() {
        this.fournisseurs = this.$store.state.fournisseurs.fournisseursCourants
    }
}
</script>