<template >
<div class="container-fluid">
  <BarreFiltres :filtres="filtres" @appliquer-filtre="appliquerFiltre($event)" />
  <div class="flex-center-center">
    <div class="card col-12 col-xl-10 col-xxl-8 height-responsive">
      <div class="card-header" >
        Menus - Documentation 
      </div>
    
      <div class="card-container height-responsive-body height-responsive-1b">
        <section id="sectionSuivi"  class=" docSection">
          <div class="card-body">
            <p>Après avoir cliqué sur l'option "Menus", vous serez automatiquement redirigé vers la fonctionnalité de "Suivi".</p>  
            <p>Les menus dans Mercudyn sont basés sur des structures sur lesquelles vous définissez vos services et composantes.</p>
            <div class="h6 important">
              <p>Une structure de menu définit un convive type, le ou les services, les composantes ainsi que les effectifs théoriques.  </p>
              <p>Un menu est définit pour une période et contient des recettes ainsi que des effectifs.</p>         
            </div>
          </div>
        </section>

        <section id="creationStucture" class=" docSection">
          <div class="header header-tertiary">Créer une structure de menu</div>
          <div class="card-body">
            <p>Une structure de menu permet de donner un cadre, un patron à vos menus.</p>
            <p>Pour créer une structure de menu, cliquez sur le bouton "créer une stucture de menu"</p>
            <p>Sélectionner le type de votre structure</p>
            <div class="flex-center-center mb-3">
              <div class="col-6">
                <img class="border-black border-rounded w-100" src="../../assets/images/documentation/menus/suivi/structure.png"  alt="Illustration type de structure"  >
              </div>
             </div>
            <p>Pour la création d'une nouvelle structure :</p>
            <div class="container mb-3">
              <img class="border-black border-rounded w-100" src="../../assets/images/documentation/menus/suivi/nouvelleStructure.png"  alt="Illustration nouvelle structure" >
            </div>
            <p>Nommez votre structure et sélectionnez le convive type rattaché* puis définissez le type de gestion. soit par composantes soit par stucture</p>
            <ul class="puces">
              <li>Gestion par Structure : choisissez un régime alimentaire ainsi qu'une texture qui seront utilisés pour l'ensemble de la structure.</li>
              <li>Gestion par Composantes : Chaque composante peut être dupliquée et déclinée pour différents régimes alimentaires et textures.</li>
            </ul>
            <p>Pour chaque service vous pouvez définir les composantes souhaitées ainsi que les effectifs théoriques.</p>
            <div class="container mb-3">
              <img class="border-black border-rounded w-100" src="../../assets/images/documentation/menus/suivi/structureComposantes.png"  alt="Illustration nouvelle structure" >
            </div>
            <p>L'exemple ci-dessus montre une duplication de l'entrée principale, au déjeuner avec un effectif de 12 convives diabétiques.</p>
            <p>Lorsque vous avez terminé de créer votre structure, vous pouvez l'enregistrer via le bouton en bas de la fenêtre.</p>
            <div class="h6 important">
              <p><strong>Sécurités autour des structures de menu</strong></p>
              <p>La modification d'une structure ne peux pas impacter et ne modifiera pas une semaine comportant un menu, même vide (sans recette).</p>
              <p>Cela permet de conserver les menus passés tout en permettant de faire évoluer la structure (ajout de composante, de service...).</p>
            </div>
            <div class="note">
              <p>*Une structure dite Neutre peut être créée sans convive rattaché. Cela permet une gestion globale d'un menu par duplication utilisée par certains de nos adhérents.<br>
                N'hésitez pas à vous rapprocher de votre Conseiller.ère de Gestion pour plus d'informations.
              </p>
            </div>
          </div>
        </section>
     

        <section id="creationStuctureAgap" class="docSection">
          <div class="header header-tertiary">Créer une structure de menu - Copie d'une structure Agap'pro</div>
          <div class="card-body">
            <p>Vous avez la possibilité de copier une structure Agap pro.</p>
            <div class="flex-center-center mb-3">
              <div class="col-6">
                <img class="border-black border-rounded w-100" src="../../assets/images/documentation/menus/suivi/structureCopieAgap.png" alt="Illustration de création de structure de menu copie Agap pro" >
              </div>
            </div>  
            <p>Cela vous permet d'importer les menus Agap'pro.</p>
          </div>
        </section>

        <section id="creationMenu" class="docSection">
          <div class="header header-tertiary">Créer un menu</div>
          <div class="card-body">
            <div class="h6 important">
              <p><strong>Info</strong></p>
              <p>Lorsque vous validez la création d'une structure, Mercudyn vous propose de créer une semaine de menu sur la période en cours.</p>
            </div>
            <p>Pour créer un menu, sélectionnez la structure concernée dans la liste, choisissez la période de consommation puis cliquez sur le bouton de création de menu.
            Vous retrouvez votre structure de menu vide, sur la période sélectionnée et prête à accueillir vos recettes !</p>
            <p>Si vous employez une structure Agap'pro, vous avez la possibilité d'importer les menus préparés par notre équipe de diététiciens.</p>
            <p>Dans l'exemple ci-dessous, nous avons pour le lundi une journée contenant des recettes et le mardi le menu vide, prêt à recevoir des recettes.</p>
          <div class="flex-center-center mb-3">
           <div class="col-6">
              <img class="border-black border-rounded w-100" src="../../assets/images/documentation/menus/suivi/menuDetails.png"  alt="Illustration d'un menu avec des recettes">
            </div>
          </div>  
            <p>Sur votre menu vous pouvez :</p>
            <ul class="puces">
              <li>Ajouter des recettes, les remplacer, modifier les effectifs (voir tableau ci-dessous).</li>
              <li><a href="#duplicationMenu">Dupliquer le menu</a> vers une autre structure (qui ne doit pas comporter de menu sur la période souhaitée).</li>
              <li>Exporter le menu (l'export utilise les titres publiques des recettes).</li>
              <li>Supprimer le menu de la structure (permet l'édition de la structure sur cette période ou de recevoir une duplication depuis une autre structure).</li>
            </ul>
            <p>L'ajustement des effectifs peut se faire à plusieurs endroits dans MercuDyn 5.</p>
            <p>Pour chaque composante vous pouvez définir l'effectif théorique (à commander et produire) ainsi que sur les jours passés, renseigner l'effectif réel pour calculer la perte produite.</p>
          
            <div class="container mb-3">
              <table class="table table-bordered text-center">
                <thead class="table-header">
                  <tr>
                    <th>Boutons</th>
                    <th class="aligner-gauche">Signification</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><button class="btn btn-primary"><i class="fas fa-users cursor-pointer has-tooltip" ></i></button></td>
                    <td class="aligner-gauche">Permet de modifier l'effectif de la journée ou du service en impactant les composantes de la catégorie</td>
                  </tr>
                  <tr>
                    <td><button type="button" class="btn btn-primary has-tooltip" style="border-radius: 8px;"><i class="fas fa-plus"></i></button></td>
                    <td class="aligner-gauche">Permet de sélectionner une recette à ajouter au menu</td>
                  </tr>
                  <tr>
                    <td><button type="button" class="btn btn-primary"><i class="fas fa-exchange-alt"></i></button></td>
                    <td class="aligner-gauche">Permet de changer la recette de la composante</td>
                  </tr>
                  <tr>
                    <td><button type="button" class="btn btn-danger has-tooltip" style=" border-radius: 8px" ><i class="fas fa-times"></i></button></td>
                    <td class="aligner-gauche">Permet d'enlever la recette de la composante</td>
                  </tr>
                </tbody>
                <thead class="table-header">
                  <tr>
                    <th>Icônes</th>
                    <th class="aligner-gauche">Signification</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><i class="fas fa-exclamation-triangle text-warning has-tooltip v-tooltip-open"></i></td>
                    <td class="aligner-gauche">Permet de signaler qu'une recette n'a pas été consultée</td>
                  </tr>
                </tbody>
                <thead class="table-header">
                  <tr>
                    <th>Légende</th>
                    <th class="aligner-gauche">Signification</th>             
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><p style="color: orange; font-weight: 600"> Cake aux fruits </p></td>
                    <td class="aligner-gauche">Si la police d'une recette est orange, cela indique qu'il y a un ingrédient manquant dans la recette.</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p>Lorsque vous survolez le nom de la recette, une fenêtre s'ouvre, affichant diverses informations telles que le nom de la recette, le prix par personne, les ingrédients et leur quantité.</p>
            <div class="flex-center-center mb-3">
              <div class="col-6">
                <img class="border-black border-rounded w-100" src="../../assets/images/documentation/menus/suivi/fenetrePopup.png"  alt="Illustration fenêtre au survol">
              </div>
            </div>
            <p>Si vous cliquez sur le nom de la recette, cela vous amène directement à l'onglet des recettes.</p>
            <div class="h6 important">
              <p>Pensez à enregistrer vos modifications via le bouton **ENREGISTRER** en haut de la page.</p>        
            </div>
          </div>
        </section>

        <section id="duplicationMenu" class="docSection">
          <div class="header header-tertiary">Dupliquer un menu</div>
          <div class="card-body">
            <p>Vous avez la possibilité de dupliquer un menu vers un autre établissement. Pour cela, selectionnez le bouton  : <button  type="button" class="btn btn-primary">Dupliquer le menu</button></p>
            <p>Une fenêtre s'ouvre avec plusieurs champs à compléter.</p>
            <p>Une fois le menu d'origine renseigné, vous avez la possibilité de dupliquer votre menu sur un ou plusieurs établissements. L'établissement de destination doit déjà contenir une structure de menu pour effectuer une duplication.</p>
            <p>Si vous souhaitez également dupliquer le menu avec des recettes modifiées, vous devez cochez "Prendre en compte les modifications de recettes".</p>
            <p>Si un menu est déjà existant, pour l'établissement de destination, vous avez la possibilité d'écraser les données en cochant la case "Ecraser le(s) menu(s) de destination présent(s)"</p>
            <div class="flex-center-center mb-3">
              <div class="col-6">
                <img class="border-black border-rounded w-100" src="../../assets/images/documentation/menus/recettes/duplicationMenu.png"  alt="Illustration d'une duplication de menu">
              </div>
            </div>
          </div>
        </section>

        <section id="sectionRecettes" class=" docSection">
          <div class="header header-tertiary">Recettes</div>
          <div class="card-body">
            <p>L'écran de gestion des recettes permet une visualisation rapide de l'ensemble de vos recettes ainsi que celles créées par l'équipe de cuisiniers et diététiciennes d'Agap'pro.<br> 
              Des fonctions de filtres et de prévisualisation offrent un classement et un affichage rapide des informations importantes.
            </p>
            <div class="container mb-3">
              <img class="border-black border-rounded w-100" src="../../assets/images/documentation/menus/recettes/recetteGestion.png"  alt="Image gestion des recettes" >
            </div>
            <p>1. Filtre par catégories. Filtre la liste des recettes suivant les catégories sélectionnées pour les retrouver facilement.</p>
            <p>2. Filtre par composantes. Filtre la liste des recettes suivant les composantes sélectionnées dans celles-ci. Sert de filtre lors de l'ajout de recette dans un menu.</p>
            <p>3. Filtre des recettes par appartenance.</p>
            <div class="container mb-3">
              <table class="table table-bordered text-center">
                <thead class="table-header">
                  <tr>
                    <th>Boutons</th>
                    <th class="aligner-gauche">Signification</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><img  src="../../assets/images/documentation/menus/recettes/recette-filtre-1utilisateur.png"  alt="Bouton Mes recettes"></td>
                    <td class="aligner-gauche">Mes recettes, visible uniquement par mon profil. (Suppression autorisée)</td>
                  </tr>
                  <tr>
                    <td><img  src="../../assets/images/documentation/menus/recettes/recette-filtre-2etablissement.png"  alt="Bouton Recettes de mon établissement"></td>
                    <td class="aligner-gauche">Les recettes disponibles sur mon établissement et mes collègues. (Pas de suppression par sécurité)</td>
                  </tr>
                  <tr>
                    <td><img  src="../../assets/images/documentation/menus/recettes/recette-filtre-3universelles.png"  alt="Bouton Recettes universelles"></td>
                    <td class="aligner-gauche">Les recettes universelles Agap'pro. (Dupliquation uniquement)</td>
                  </tr>
                  <tr>
                    <td><img  src="../../assets/images/documentation/menus/recettes/recette-filtre-4toutes.png"  alt="Bouton Toutes les recettes"></td>
                    <td class="aligner-gauche">Toutes les recettes de toutes les catégories.</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p>4. Recherche dans la liste des recettes. La recherche est effectuée en tenant compte des différents filtres appliqués.</p>
            <p>5. Liste des recettes. Le premier clic sur une recette affiche point n°7, tandis que le deuxième clic permet d'éditer la recette.</p>
            <p>6. Actions sur la recette.</p>
            <div class="container mb-3">
              <table class="table table-bordered text-center">
                <thead class="table-header">
                  <tr>
                    <th>Boutons</th>
                    <th class="aligner-gauche">Signification</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><button alt="Nouvelle" type="button" class="btn btn btn-success btn-secondary">Nouvelle</button></td>
                    <td class="aligner-gauche">Créer une nouvelle recette.</td>
                  </tr>
                  <tr>
                    <td><button  type="button" class="btn btn btn-success btn-secondary">Dupliquer</button></td>
                    <td class="aligner-gauche">Dupliquer la recette sélectionnée.</td>
                  </tr>
                  <tr>
                    <td><button  type="button" class="btn btn btn-danger btn-secondary">Supprimer</button></td>
                    <td class="aligner-gauche">Supprimer la recette sélectionnée.</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p>7. Aperçu de la recette au clic dans la liste.</p>
          </div>
        </section>

      
        <section id="sectionRecettesEdition">
          <div class="header header-tertiary">Création / Edition de recette</div>
          <div class="card-body">
            <p>Que se soit pour la création ou l'édition, l'écran reste très similaire.</p>
            <div class="h6 important">
              <p>Les recettes Agap'pro sont proposées à l'ensemble de nos adhérents. </p>
              <p>Nos recettes initiales (hors sauces) sont calculées pour 100 portions. Les grammages sont établis pour des enfants en primaire. </p>
              <p>Vous trouverez en bas de cette page<a href="#sectionExemples"> des exemples</a> concrets afin de vous aider à démarrer.</p>         
            </div>
            <div class="container mb-3">
              <img class="border-black border-rounded w-100" src="../../assets/images/documentation/menus/recettesEdition/recette-edit-1general.png"  alt="Image recette édition généralité" >
            </div>
            <ul class="puces">
              <li>
                <span class="bold600">Titre interne</span>, ex: Purée flocons <br>
                Titre de la recette dans le logiciel (Recettes, Menus, fiches de production...).
              </li>
              <li>
                <span class="bold600">Titre public</span>, ex: Purée du Chef<br>
                Titre de la recette affiché sur les exports à destination du public..
              </li>
              <li>
                <span class="bold600">Pour</span> et <span class="bold600">Unité de production</span> <br>
                Pour combien de l'unité choisie la recette est-elle réalisée.<br>
                Ex: Pour 100 personnes ; Pour 3 L ; Pour 5 KG...
              </li>
              <li>
                <span class="bold600">Type de convive</span>, ex: Sco Primaire <br>
                Sur quel convive la recette est-elle basée. <br>
                Ce champ n'apparait que si Personne est sélectionné en Unité de recette.
              </li>
              <li>
                <span class="bold600">Catégorie</span>, ex: Dessert fruit cru <br>
                Dans quelle catégorie classer la recette pour les filtres.
              </li>
              <li>
                <span class="bold600">Zone de préparation</span>, ex: Chaud, Froid...<br>
                Vous pouvez choisir la zone de préparation de votre choix
              </li>
            </ul>
          </div>
        </section>

        <section id="sectionIngredients">
          <div class="header header-tertiary">Ingrédients</div>
          <div class="card-body">
            <div class="container mb-3">
              <img class="border-black border-rounded w-100" src="../../assets/images/documentation/menus/recettesEdition/recette-edit-2ingredient.png"  alt="Image recette édition ingrédients">
            </div>
            <p>Cette section de la recette vous permet de gérer les ingrédients qui composent votre recette et vous permet de préciser les grammages pour les ingrédients à la pièce.</p>
            <p>Le champ de recherche permet de selectionner des ingrédients à ajouter à la recette. Vous avez également la possibilité de choisir une sous-recette.</p>
            <p>Ex: Avec des andouillettes, si vous souhaitez ajouter une sauce, effectuez une recherche (par exemple, "sauce moutarde"). Une fois sélectionnée, en survolant le nom de la recette, vous pourrez obtenir un aperçu de son contenu</p> 
            <p>Si celle-ci n'a pas été consulté, un point d'exclamation:<i class="fas fa-exclamation-triangle text-warning has-tooltip"></i> sera visible. Vous pouvez la visualiser et la modifier en cliquant dessus.</p>
            <div class="flex-center-center mb-3">
              <div class="col-6">
                <img class="border-black border-rounded w-100" src="../../assets/images/documentation/menus/recettesEdition/rechercheSousRecette.png"  alt="Image recette">
              </div>
            </div>
            <div class="h6 important">
              <p>Les ingrédients sont associés aux produits de la mercuriale ainsi qu'à vos produits en gré à gré.  </p>
              <p>Un ingrédient peut être lié à des produits de différentes familles (ex: Haricot vert fin va être proposé en Surgelés et en Epicerie).</p>
            </div>
            <p>Les éléments qui composent chaque ligne ingrédient, de gauche à droite :</p>
            <ul class="puces">
              <li>
                Famille produit de l'ingrédient. Si plusieurs existent, peut-être changée à la volée.
              </li>
              <li>
                Le nom de l'ingrédient.
              </li>
              <li>
                La quantité <span class="bold600"> brut </span> nécessaire selon l'unité de production.
              </li>
              <li>
                <span class="bold600"> L'Unité de Production </span> peut être en PCE, KG et L. <br>
                Si l'unité est en PCE des précisions par convive peuvent être indiquées.
              </li>
              <li>
                Le convive type pour l'ingrédient à la pièce. <br>
                Permet en dupliquant l'ingrédient de préciser une quantité différente de l'ingrédient selon le convive sélectionné. <br>
                Si aucun convive type supplémentaire n'est définit, la quantité du convive type de la recette sera sélectionné.
              </li>
              <li>
                Si l'ingrédient est à la fois à la pièce et en portion, le logiciel peut proposer de sélectionner un grammage spécifique par convive type (cf. figure ci-dessous).
              </li>
              <div class="container mb-3">
                <img class="border-black border-rounded w-100" src="../../assets/images/documentation/menus/recettesEdition/recette-edit-3pcegramme.png"  alt="Image d'ingrédient à la pièce et en portion">
              </div>
              <li>
                Un ingrédient noté en orange n'a pas de produit rattaché ex: F&L de saison lorsque non référencés
              </li>
            </ul>
            <div class="container mb-3">
              <table class="table table-bordered text-center">
                <thead class="table-header">
                  <tr>
                    <th>Boutons</th>
                    <th class="aligner-gauche">Signification</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class=top:10px>
                    <td><ToggleButton :labels="{checked: 'Commander', unchecked: 'Ne pas commander'}" :color="{checked: '#025DBF', unchecked: '#CCCCCC'}" :height="25" :width="150" /></td>
                    <td class="aligner-gauche">Commander: Le produit de cette ligne va être commandé.<br>Ne pas commander: Le produit de cette ligne ne sera pas commandé, mais il sera quand même pris en compte dans le prix de la recette.</td>
                  </tr>
                  <tr>
                    <td><i class="fas fa-copy"></i></td>
                    <td class="aligner-gauche"> L'icône de double feuille permet la duplication de l'ingrédient (uniquement PCE).</td>
                  </tr>
                  <tr>
                    <td><button type="button" class="btn btn-primary has-tooltip"><i class="fas fa-exchange-alt"></i></button></td>
                    <td class="aligner-gauche">Visualisation de la liste des produits. Permet d'accéder aux fiches techniques et d'ajouter des favoris.</td>
                  </tr>
                  <tr>
                    <td><button type="button" aria-label="Supprimer cet ingrédient" class="btn btn-danger"><span aria-hidden="true"><i class="fas fa-trash"></i></span></button></td>
                    <td class="aligner-gauche">Le produit de cette ligne sera supprimé, son prix ne sera pas inclus dans le calcul de la recette.</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>

        <section id="sectionParAssiette">
          <div class="header header-tertiary">Quantités par assiette</div>
          <div class="card-body">
          <div class="container mb-3">
            <img class="border-black border-rounded w-100" src="../../assets/images/documentation/menus/recettesEdition/recette-edit-4qteassiette.png"  alt="Image des quantités par assiette">
          </div>
          <p>Cette zone permet de définir pour chaque convive type la portion par service.  </p>
          <p>La valeur de référence est encadrée en vert</p>
          </div>
        </section>

        <section>
          <div class="header header-tertiary">Marche à suivre</div>
          <div class="card-body">
          <p>Texte libre pour détailler le déroulement de la recette ou autres précisions à apporter (ex: Utiliser le programme 4 sur le four 1).</p>
          </div>
        </section>

        <section id="sectionCompatibilites">
          <div class="header header-tertiary">Compatibilités</div>
          <div class="card-body">
          <p>Cette zone permet de définir comment la recette doit être gérée par Mercudyn que ce soit en classement, calculs ou propositions.</p>
          <ul class="puces">
            <li>
              <span class="bold600">Textures incompatibles</span> et <span class="bold600">Régimes alimentaires</span> incompatibles permettent de définir les compatibilités avec les convives définis dans les structures de menus. 
            </li>
            <li>
            <span class="bold600"> Composantes de repas</span> permet d'indiquer dans quelles composantes du menu la recette doit être proposée par défaut.
              </li>
            <li>
              <span class="bold600">Catégories GEMRCN</span> permet d'indiquer la ou les catégories compatibles du GEMRCN.
            </li>
          </ul>
          </div>
        </section>

        <section id="sectionExemples">
          <div class="header header-tertiary">Exemples: Création / Edition de recette</div>
          <div class="card-body">
            <h6>Exemple 1, les vinaigrettes, sauces et fonds</h6>
            <p>J'ai une recette "Vinaigrette balsamique", au litre.</p>
            <p>J'insère ma recette "Vinaigrette balsamique" en tant que sous recette dans mes recettes "Salade de laitue balsamique", "Salade de betteraves balsamique" [...] dans toutes mes recettes où je souhaite ajouter une sauce vinaigrette balsamique.</p>
            <p>Ma recette de "Vinaigrette balsamique" n'existant qu'une seule fois est étant simplement utilisée dans mes autres recettes, si un jour je souhaite modifier ma vinaigrette je n'ai qu'à modifier ma recette de "Vinaigrette balsamique" et chaque recette où ma vinaigrette est utilisée en profitera directement, sans avoir à passer sur chaque recette utilisant ma vinaigrette.</p>
          </div>

          <div class="card-body">
            <h6>Exemple 2, les produits à la pièce</h6>
            <p>Prenons l'exemple du steak haché</p>
            <p>Si sur mon établissement je prépare mes steaks pour trois convives type, Mercudyn vous permet de gérer tous vos cas en une seule recette.</p>
            <p>En dupliquant la ligne de produit à la pièce, je peux paramétrer pour chaque convive type mes quantités.</p>
            <div class="container mb-3">
              <img class="border-black border-rounded w-100" src="../../assets/images/documentation/menus/recettesEdition/exemple2.png"  alt="Image recette édition généralité" >
            </div>            
            <p>Dans cet exemple de recette de steak haché, j'ai trois fois ma ligne de steak.</p>
            <p>La première est "ma base", pour mes 100 convives en Sco Primaire, comme définit en haut de ma recette.</p>
            <p>La deuxième dit que si je sers des Sco Maternelles, je donne 1/2 steak de 100g par convive.</p>
            <p>La troisième dit que si je sers des Personnes Agées en Institution, je donne 1 steak de 80g par personne.</p>
            <p>Si je sers un convive type non géré par la duplication de ma ligne, comme par exemple Entreprise Adulte, c'est la ligne de base qui servira de référence. Ici, 1 steak de 100g par convive.</p>
          </div>
        </section>
      </div>
    </div> 
  </div> 
</div>

</template>

<script>

import BarreFiltres from '@/components/Divers/BarreFiltres'

export default {
  name: "DocMenus",
  components: {
    BarreFiltres
  },
  data() {
    return {
      filtres: {
        sections: {
          label: "Choix de votre recherche...",
          champ: "select",
          options: [
            { valeur: 'sectionSuivi', texte: 'Suivi'},
            { valeur: 'creationStucture', texte: 'Suivi: Créer une structure de menu' },
            { valeur: 'creationStuctureAgap', texte:`Suivi: Créer une structure de menu - Copie d'une structure Agap'pro` },
            { valeur: 'creationMenu', texte: 'Suivi: Créer un menu' },
            { valeur: 'duplicationMenu', texte: 'Suivi: Dupliquer un menu' },
            { valeur: 'sectionRecettes',texte:'Recettes'},
            { valeur: 'sectionRecettesEdition',texte:'Recettes: Création / Edition de recette'},
            { valeur: 'sectionIngredients', texte:'Recettes: Ingrédients'},
            { valeur: 'sectionParAssiette', texte:'Recettes: Quantités par assiette'},
            { valeur: 'sectionCompatibilites', texte:'Recettes: Compatibilités'},
            { valeur: 'sectionExemples',texte:'Exemples: Création / Edition de recette'}
          ],
          selection: [],
          filtrer(contexte) {
            let filtre = contexte.filtres.sections
            if (filtre.selection) {
              let sectionSelectionnee = document.getElementById(filtre.selection.valeur);
              if (sectionSelectionnee) {
                sectionSelectionnee.scrollIntoView();
              }  
            }
            //contexte.selectChoixRechercheDoc(contexte.filtres.sections.selection?.valeur)
          }
        },
        // Appeler "this.filtres.initialiser(this)" dans mounted() => génère le tableau de filtres utilisés pour afficher les filtres dans la barre de filtres
        barreGauche: [],
        barreDroite: [],
        initialiser(contexte) {
          contexte.filtres.barreGauche = [ contexte.filtres.sections ]
        }
      }
    }
  },
  methods: {
    appliquerFiltre(filtrer) {
      // Nécessaire pour faire fonctionner les filtres de la barre de filtres
      filtrer(this)
    },
    //   selectChoixRechercheDoc() {
    //   if (this.docRecherche) {
    //     const sectionSelectionnee = document.getElementById(this.docRecherche);
    //     if (sectionSelectionnee) {
    //       sectionSelectionnee.scrollIntoView();
    //     }  
    //   }
    // }
  },
  mounted() {
    this.filtres.initialiser(this)
  }
}
</script>
