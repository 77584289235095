<template>
  <div class="text-center">
    <h3>En Construction</h3>
    <h3><a href="/">Retour à l'accueil</a></h3>
    <img src="../../public/assets/images/icon-en-construction.png" alt="">
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
