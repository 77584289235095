import Vue from 'vue'

import { chargerInfosApp, chargerDefinitions, chargerRaisonsIncidents, chargerTypesReclamations, chargerFamillesEtGammes, chargerIngredient, chargerTypesComposantes, chargerTypesConvives, chargerServices, chargerPeriodesPlansAlimentaires } from '@/api/gpao/definitions'
import { chargerCategories, chargerPlans } from '@/api/gpao/plansAlimentaires'
import { connecteData } from "@/mixins/connecteData"
import { constantesId } from "@/helpers/constantes"

export default {
  namespaced: true,
  mixins: [ connecteData ],
  state: {
    informationsAccueil: {},
    ingredients: [],
    etatsCommandes: [],
    etatsReception: [],
    services: []
  },
  getters: {
    environnement: state => {
      return state.infosApp?.environnement || process.env.NODE_ENV
    },
    environnementProduction: state => {
      return (state.infosApp?.environnement || process.env.NODE_ENV) === "prod"
    },
    environnementApiDateBuild: state => {
      return state.infosApp?.dateBuild?.substring(2) || ""
    },
    obtenirIngredient: state => id => {
      return state.ingredients.find(item => item.id === id)
    },
    trouverIngredient: state => produitId => {
      var result
      state.ingredients.forEach(ingredient =>
        ingredient.produits && ingredient.produits.forEach(produit => {
          var prod = produit.prod && produit.prod.find(prod => prod.pId === produitId)
          if (prod) {
            result = {
              id: ingredient.id,
              nom: ingredient.nom,
              up: ingredient.up,
              grammage: prod.gram
            }
          }
        })
      )
      return result
    },
    obtenirFamille: state => familleId => {
      return state.familles.find(item => item.id === familleId)
    },
    trouverFamille: state => familleCode => {
      return state.familles.find(item => item.code === familleCode)
    },
    categorieRecettes: state => {
      return state.categorieRecettes
    },
    composantesRepas: state => {
      return state.composantesRepas
    },
    convivesTypesNonNeutre: state => {
      return state.convivesTypes.filter(x => x.value !== constantesId.typeConviveIdNeutre)
    },
    convivesTypes: state => {
      return state.convivesTypes
    },
    familleParId: state => id => {
      if (id) {
        const f = state.familles && state.familles.find(el => el.id === id);
        if (!f)
          return  {code: "404", nom: "Non trouvé" }
        return f
      }
    },
    familles: state => {
      return state.familles
    },
    gemrcn: state => {
      return state.gemrcn
    },
    informationsAccueil: state => {
      return state.informationsAccueil
    },
    ingredients: state => {
      return state.ingredients
    },
    famillesActives: state => {
      return state.famillesActives
    },
    raisonsIncidents: state => {
      return state.raisonsIncidents
    },
    regimes: state => {
      return state.regimes
    },
    obtenirRegime: state => id => {
      return state.regimes && state.regimes.find(item => item.value === id) || {}
    },
    services: state => {
      return state.services
    },
    obtenirService: state => id => {
      return state.services && state.services.find(item => item.value === id) || {}
    },
    textures: state => {
      return state.textures
    },
    obtenirTexture: state => id => {
      return state.textures && state.textures.find(item => item.value === id) || {}
    },
    zonesPreparations: state => {
      return state.zonesPreparations
    },
    typesComposantes: state => {
      return state.typesComposantes
    },
    typesConvives: state => {
      return state.typesConvives
    },
    typesServices: state => {
      return state.typesServices
    },
    limitesGEMRCN: state => {
      return state.limitesGEMRCN
    },
    configurationsGEMRCN: state => {
      return state.configurationsGEMRCN
    },
    configurationGEMRCN: state => id => {
      return state?.configurationsGEMRCN?.find(c => c.id === id)
    },
    plansAlimentaires: state => {
      return state.plansAlimentaires
    }
  },
  actions: {
    clear({ commit }) {
      commit('clearState')
    },
    async rechargerIngredient({ commit }, ingredientId) {
      var response = await chargerIngredient(ingredientId)
      var misAjour = response.data
      commit('remplacerIngredient', misAjour)
    },
    async chargerInfosApp({ commit }) {
      return chargerInfosApp()
        .then((response) => {
          commit('setItems', { cle: "infosApp", data: response.data })
          return response
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    async chargerDefinitions({ commit }, opt) {

      /////// A supprimer lorsque la table definitions sera découpée en tables de références distinctes et l'API sera supprimée ///////
      /////// ==> DEBUT ///////

      var response = await chargerDefinitions(opt.key, opt.etablissementId)
      if (!opt.key) {
        Object.entries(response.data).map(el => {
          commit('setItems', { cle: el[0], data: el[1] })
        })
        commit('setItems', {
          cle: "etatsCommandes", data: [
            { value: "EC1", text: "En création", groupement: null },
            { value: "EC2", text: "Envoyée", groupement: null },
            { value: "EC3", text: "Reçue", groupement: null }
          ]
        })
        commit('setItems', {
          cle: "etatsReception", data: [
            { value: "ER1", text: "OK", groupement: null },
            { value: "ER2", text: "Refusée", groupement: null },
            { value: "ER3", text: "Substituée", groupement: null },
            { value: "ER4", text: "Ajoutée", groupement: null },
          ]
        })
      } else if (opt.key === "familles") {
        return chargerFamillesEtGammes(opt.etablissementId)
          .then(response2 => {
            var famillesActives = []
            var gammes = []
            response2.data.forEach(f => {
              if (f.estGamme) {
                gammes.push(f)
              } else {
                famillesActives.push(f)
              }
            })
            commit('setItems', { cle: "familles", data: response.data })
            commit('setItems', { cle: "famillesActives", data: famillesActives })
            commit('setItems', { cle: "gammes", data: gammes })
            return 
          })
      } else {
        commit('setItems', { cle: opt.key, data: response.data })
      }

      /////// Bloc à supprimer lorsque la table definitions sera découpée en tables de références distinctes et l'API sera supprimée ///////
      /////// ==> FIN ///////

      await chargerTypesComposantes()
        .then((response) => {
          commit('setItems', { cle: "typesComposantes", data: response.data })
          return response
        })
        .catch(function (error) {
          console.log(error)
        })

      await chargerTypesConvives()
        .then((response) => {
          commit('setItems', { cle: "typesConvives", data: response.data })
          return response
        })
        .catch(function (error) {
          console.log(error)
        })

      await chargerServices()
        .then((response) => {
          commit('setItems', { cle: "typesServices", data: response.data })
          return response
        })
        .catch(function (error) {
          console.log(error)
        })

      await chargerPeriodesPlansAlimentaires()
        .then((response) => {
          commit('setItems', { cle: "periodesPlansAlimentaires", data: response.data })
          return response
        })
        .catch(function (error) {
          console.log(error)
        })

      return Promise.resolve()
    },
    async chargerConfigurationsGEMRCN({ commit }, etabCourantId) {
      return chargerCategories(etabCourantId)
        .then((response) => {
          commit('setItems', { cle: "configurationsGEMRCN", data: response.data })
          return response
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    async chargerPlansAlimentaires({ commit }, etabCourantId) {
      return chargerPlans(etabCourantId)
        .then((response) => {
          commit('setItems', { cle: "plansAlimentaires", data: response.data })
          return response
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    async chargerRaisonsIncidents({ commit }) {
      return chargerRaisonsIncidents()
        .then((response) => {
          commit('setItems', { cle: "raisonsIncidents", data: response.data })
          return response
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    async chargerTypesReclamations({ commit }) {
      return chargerTypesReclamations()
        .then((response) => {
          commit('setItems', { cle: "typesReclamations", data: response.data })
          return response
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  },
  mutations: {
    clearState(state) {
      state.informationsAccueil = {}
      state.ingredients = []
      state.etatsCommandes = []
      state.etatsReception = []
      state.services = []
      state.familles = []
      state.categorieRecettes = []
      state.composantesRepas = []
      state.convivesTypes = []
      state.gemrcn = []
      state.famillesActives = []
      state.raisonsIncidents = []
      state.regimes = []
      state.textures = []
      state.zonesPreparations = []
      state.typesComposantes = []
      state.typesConvives = []
      state.typesServices = []
      state.limitesGEMRCN = []
      state.configurationsGEMRCN = []
      state.plansAlimentaires = []
    },
    setItems(state, items) {
      Vue.set(state, items.cle, items.data)
    },
    remplacerIngredient(state, misAjour) {
      Vue.set(state,"ingredients", state.ingredients.map(ing => (ing.id === misAjour.id) ? misAjour : ing))
    },
    setMemo(state, payload) {
      var infos = state.informationsAccueil
      infos.memoUser = payload
      Vue.set(state, 'informationsAccueil', infos)
    },
  }
}
