import { USER_INFO, USER_ERROR, USER_SUCCESS, USER_LOGOUT } from "../actions/user"
import Vue from "vue"
import { AUTH_LOGOUT } from "../actions/auth"
import { currentUserInfo } from "@/api/gpao/user"
import userInfo from "../../models/userInfo"

export default {
  namespaced: true,

  state: {
    status: "",
    userInfo: {}
  },

  getters: {
    utilisateurCourant: state => state.userInfo,
    [USER_INFO]: state => state
  },

  actions: {
    [USER_INFO]: async function ({ commit, dispatch }) {
      try {
        await commit(USER_INFO)
        const resp = await currentUserInfo()
        commit(USER_SUCCESS, resp)
      } catch(err) {
        await commit(USER_ERROR)
        await dispatch("auth/" + AUTH_LOGOUT)
        console.error(err)
      }
    }
  },

  mutations: {
    [USER_INFO]: state => {
      state.status = "loading"
    },
    [USER_SUCCESS]: (state, resp) => {
      state.status = "success"
      Vue.set(state, "userInfo", new userInfo(resp.data))
    },
    [USER_ERROR]: state => {
      state.status = "error"
    },
    [USER_LOGOUT]: state => {
      console.log("LOGOUT")
      state.userInfo = {}
      window.location = "/"//recharge la page pour éviter "l'écran blanc" après un logout
    },
    setUserInfo: function (state, infoFournisseur) {
      var site = state.userInfo.etablissements.find(e => e.eId === infoFournisseur.eId)
      site.infoFournisseurs.splice(site.infoFournisseurs.findIndex(f => f.foId === infoFournisseur.foId && f.eId === infoFournisseur.eId), 1, infoFournisseur)
      Vue.set(state, "userInfo", state.userInfo)
    },
    setPrefFournisseur: function (state, opt) {
      var site = state.userInfo.etablissements.find(e => e.eId === opt.etablissementId)
      site.choixFournisseurs.find(f => f.foId === opt.fournisseurId && f.fId === opt.familleId && f.eId === opt.etablissementId).actif = opt.actif
      Vue.set(state, "userInfo", state.userInfo)
    },
    setALieuPortage: function (state, payload)    {
      var site = state.userInfo.etablissements.find(e => e.eId === payload.eId)  
      site.aLieuPortageActif = payload.aLieuPortage
      Vue.set(state, "userInfo", state.userInfo)
    },
    setInfosEtablissement: function (state, opt) {
      var site = state.userInfo.etablissements.find(e => e.eId === opt.eId)
      site.eRS = opt.eRS
      site.adresse1 = opt.adresse1
      site.adresse2 = opt.adresse2
      site.adresse3 = opt.adresse3
      site.codePostal = opt.codePostal
      site.ville = opt.ville
      site.codeAF = opt.codeAF
      site.contact = opt.contact
      site.tel = opt.tel
      site.email = opt.email
      site.donneesSupplementaires=opt.donneesSupplementaires
      site.commentairePourToutFournisseur = opt.commentairePourToutFournisseur
      Vue.set(state, "userInfo", state.userInfo)
    },
    setAfficherPopupInfo: function (state, opt) {
      Vue.set(state.userInfo, "afficherPopupInfo", opt)
    }
  }
}
