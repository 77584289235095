<template>
  <div style="display:inline">
    <i class="fas fa-info-circle cursor-pointer" @click="afficherMouvements()"></i>
    <b-modal :id="'modal' + id" size="xxl" :title="titre()"
      ok-title="Fermer" ok-only
      no-close-on-backdrop
      @ok="hide()">
      <template slot="default">
        <div v-if="lot">
          <MvtsStockListe :lot="lot" :debut="debut" :fin="fin" :quantiteInitiale="quantiteInitiale" :avecDetails="avecDetails" />
        </div>
        <div v-else-if="ingredient">
          <div v-for="(lot, index) in ingredient.lots" :key="index">
            <div style="margin:15px 0 0 15px;">
              <span style="display:inline-block;">N° Lot : {{lot.NumLot}}</span>
              <span style="display:inline-block;margin-left:50px;">Désignation : {{lot.designationFournisseur}}</span>
              <span style="display:inline-block;margin-left:50px;" title="Date de Durabilité Minimale (ex. DLUO)">DLC / DDM : {{formatHumanDate(lot.dlcDluo)}}</span>
            </div>
            <MvtsStockListe :lot="lot" :debut="debut" :fin="fin" :quantiteInitiale="quantiteInitiale" :avecDetails="avecDetails" />
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import MvtsStockListe from './MvtsStockListe.vue'
import { formatageDate } from '@/mixins/dateFormats'

export default {
  name: "BtnAffichageMvtsStock",
  props: ['id', 'lot', 'ingredient', 'debut', 'fin', "quantiteInitiale", "avecDetails"],
  mixins: [formatageDate],
  components: {
    MvtsStockListe
  },
  methods: {
    async afficherMouvements() {
      this.show()
    },
    titre() {
      return this.lot ? this.lot.designationFournisseur + (this.lot.reference ? ' (réf. ' + this.lot.reference + ')' : '') + (this.avecDetails ? ((this.lot.NumLot ? ' - Lot : ' + this.lot.NumLot : '') + (this.lot.dlcDluo ? ' - DLC : ' + this.formatHumanDate(this.lot.dlcDluo) : '')) : '') : this.ingredient ? this.ingredient.nom : ''
    },
    show() {
      this.$bvModal.show('modal' + this.id)
    },
    hide() {
      this.$bvModal.hide('modal' + this.id)
    }
  }
}
</script>
