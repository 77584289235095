<template>
  <b-container>
    <b-row>
      <b-col class="text-center">
        <h1>404</h1>
        <h3>Cette page n'existe pas...</h3>
        <h3><a href="/">Retour à l'accueil</a></h3>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
}
</script>

<style lang='css' scoped>
h1 {
  font-size: 3rem;
  color: black;
}
</style>
