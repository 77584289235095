import Vue from 'vue'

const etablissements = {
  namespaced: true,
  state: {
    etablissement: {
      etablissementId: null,
      userId: null
    }
  },
  getters: {
    etabCourant: state => { return state.etablissement},
    etabCourantId: state => {
      if ( state.etablissement) {
        return state.etablissement.etablissementId
      }
      return undefined
    },
    etablissementId(state) {
      return state.etablissement.etablissementId
    },
    etablissementUserId(state) {
      return state.etablissement.userId
    }
  },
  mutations: {
    setEtablissement(state, payload) {
      // state.etablissement = payload
      Vue.set(state, 'etablissement', payload)
    },

    emptyEtablissementId(state) {
      Vue.set(state.etablissement, 'etablissementId', null)
    }
  }
}

export default etablissements
