import { mapGetters } from 'vuex'
import vm from "@/helpers/vm-toolkit-vue"
export const fournisseurs = {
    computed: {
        ...mapGetters("fournisseurs", ["fournisseursCourants"]),
    },
    methods: {
        affecterJourLivraisonEtDateDeLivraison(fournisseur, infoFournisseurs, dateMaxExclue) {
            let jours = infoFournisseurs.find(x => x.foId === fournisseur.foId)
            if (jours)
                jours = jours.Jours
            this.affecterJourLivraison(fournisseur, jours)
            const today = new Date()
            
            /*if (dateMaxExclue < today) {
                dateMaxExclue = new Date()
                dateMaxExclue.setDate(today.getDate() + 7)
            }*/
            fournisseur.peutLivrerLe = []
            let enCours = new Date()
            enCours.setDate(today.getDate() + 1)
            var joursParIndex = ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"]
            while (enCours < dateMaxExclue) {
                var jour = enCours.getDay()
                if (fournisseur.joursLivraison[joursParIndex[jour]]) {
                    fournisseur.peutLivrerLe.push(new Date(enCours))
                }
                enCours.setDate(enCours.getDate() + 1)
            }
        },
        affecterJourLivraison(f, jours) {
            f.joursLivraison = {
                Lu: false,
                Ma: false,
                Me: false,
                Je: false,
                Ve: false,
                Sa: false,
                Di: false
            }
            if (jours) {
                const livreLes = jours.split(',')
                livreLes.forEach(l => f.joursLivraison[l] = true)
            }
        },
        fournisseurNom(id) { // TODO Obsolete, dans le store
            if (id) {
                const f = this.fournisseursCourants.find(el => el.id === id);
                if (!f)
                    return 'Non trouvé ' + id
                return f.nom
            }
        },
        fournisseurFP(id) {
            if (id) {
                const f = this.fournisseursCourants.find(el => el.id === id);
                if (!f)
                    return 'Non trouvé ' + id
                return f.francoPort
            }
        },
        parametresFournisseur(fournisseurId, etablissementId) {
            var fournisseur = {
                dfId: "",
                depots: []
            }

            if (!fournisseurId) {
                return fournisseur
            }

            if (!etablissementId) {
                etablissementId = this.$store.state.etablissements.etablissement.etablissementId
            }

            const fourn = this.fournisseursCourants.find(f => f.id === fournisseurId)
            if (fourn) {
                fournisseur.nom = fourn.nom
                fournisseur.nomCourt = fourn.nomCourt
                fournisseur.francoPort = fourn.francoPort
                fournisseur.estLocal = fourn.estLocal
                fournisseur.depots = fourn.depots
                fournisseur.familles = fourn.familles
                fournisseur.etablissements = fourn.etablissements
            }

            const infoF = this.$store.state.user.userInfo.etablissements.find(e => e.eId === etablissementId).infoFournisseurs.find(f => f.foId === fournisseurId)
            vm.add(infoF, fournisseur)

            if (fournisseur.depots.length === 1 && !fournisseur.dfId) {
                fournisseur.dfId = fournisseur.depots[0].dfId
            }
            if (fournisseur.dfId) {
                fournisseur.dfId = String(fournisseur.dfId)
            } else {
                fournisseur.dfId = ""
            }

            return fournisseur
        }
    }
}