import Vue from "vue"
import Vuex from "vuex"
import user from "./modules/user"
import auth from "./modules/auth"
import definitions from "./modules/definitions"
import etablissements from "./modules/etablissements"
import profils from "./modules/profils"
import fournisseurs from "./modules/fournisseurs"
import recettes from "./modules/recettes"
import menus from "./modules/menus"
import produits from "./modules/produits"
import stock from "./modules/stock"
import commandes from "./modules/commandes"
import fichesTechniques from "./modules/fichesTechniques"
import uxHelpers from "./modules/uxHelpers"
import createPersistedState from 'vuex-persistedstate'
//import {setUserBugsnag} from "@/helpers/bugsnagM5"
Vue.use(Vuex)

const debug = process.env.NODE_ENV !== "production"

export default new Vuex.Store({
  modules: {
    user,
    auth,
    definitions,
    etablissements,
    profils,
    fournisseurs,
    recettes,
    menus,
    produits,
    stock,
    commandes,
    fichesTechniques,
    uxHelpers
  },
  plugins: [createPersistedState({
    key: /*process.env.NODE_ENV +*/ "persisted-vuex",
   // storage: window.sessionStorage,
    paths: [
      'user',
      'auth',
      'definitions',
      'etablissements',
      'profils',
      'fournisseurs',
      'recettes',
      'menus',
      'produits',
      'stock',
      'commandes',
      'mercuriales'
    ],
    rehydrated: store=>{ 
      if (store.state.user)
    {
   //   setUserBugsnag(store.state.user.userInfo)
    }}
  })],
  strict: debug
})

