import store from '../store'

export function viderStore() {
  var toGet = [
    store.dispatch('definitions/clear'),
    store.dispatch('fournisseurs/clear'),
    store.dispatch('menus/clear'),
    store.dispatch('produits/clear')
  ]
  return Promise.all(toGet)
}

export function chargerInfosApp() {
  var toGet = [
    store.dispatch('definitions/chargerInfosApp')
  ]
  return Promise.all(toGet)
}

export function chargerDefinitions() {
  return store.dispatch('definitions/chargerDefinitions', { key: "" })
    .then(() => {
      var toGet = [
        store.dispatch('definitions/chargerDefinitions', { key: "ingredients" }),

        store.dispatch('definitions/chargerRaisonsIncidents'),
        store.dispatch('definitions/chargerTypesReclamations')
      ]
      return Promise.all(toGet)
    })
}

export function chargerDonneesEtablissement(eId) {
  var toGet = [
    store.dispatch('produits/produitsUtilisateurCourant', eId),
    store.dispatch('fournisseurs/listeFournisseurs', eId ),
    store.dispatch('definitions/chargerDefinitions', { key: "familles", etablissementId: eId }),
    store.dispatch('definitions/chargerDefinitions', { key: "informationsAccueil", etablissementId: eId }),
    store.dispatch('definitions/chargerConfigurationsGEMRCN', eId),
    store.dispatch('definitions/chargerPlansAlimentaires', eId)
  ]
  return Promise.all(toGet)
}
