
import Vue from "vue"
import store from "../store"
import { AUTH_SUCCESS, AUTH_LOGOUT } from "../store/actions/auth"

export default function setup(axiosService) {

  axiosService.interceptors.response.use(
    function (response) {
      return response
    },
    async function (error) {
      const requeteInitiale = error.config;
      store.commit("uxHelpers/hideSpinner")

      if (error.request.status === 401) {
        if (error.request.responseURL.endsWith("api/auth")) {
          return Promise.reject(error)
        }
        if (!(error.config.data && error.config.data.includes("refreshToken")) && store.state.auth.token) {
          console.log("Gestion Token : Demande Refresh")
          const payload = { refreshToken: store.state.auth.token.refreshToken }
          // eslint-disable-next-line promise/no-promise-in-callback
          return axiosService
            .post("auth/refresh", payload)
            .then(response => {
              const token = response.data
              console.log("Gestion Token : Refresh OK !")
              store.commit("auth/" + AUTH_SUCCESS, token)
              return axiosService(requeteInitiale)
            })
            .catch(error => {
              console.error("Gestion Token : Erreur sur Refresh" + error)
              console.dir(error)
              store.commit("auth/" + AUTH_LOGOUT)
              // eslint-disable-next-line promise/no-return-wrap
              return Promise.reject(error)
            })
        }
        return Promise.reject(error)
      }

      let titre = "Erreur interne"
      let details = "Erreur de communication avec le serveur"
      if (error.response && error.response.data) {
        if (error.response.data.detail) {
          titre = error.response.data.title || titre
          details = error.response.data.detail
        } else if (error.response.data.errors) {
          titre = error.response.data.title
          details = Object.keys(error.response.data.errors)
            .map(function (key) {
              return /*key + ":" + */ error.response.data.errors[key]
            }).join("<br>")
        } else if (error.response.data instanceof Blob) {
             titre = error.response.status
             details = await error.response.data.text()
        }
      } else if (error.message) {
        if (error.message === "Network Error") {
          titre = "Erreur réseau"
          details = "Connexion internet ou serveur manquant."
        } else if (error.message.startsWith("timeout") && error.message.endsWith("exceeded")) {
          titre = "Délai d'attente dépassé"
          details = "MercuDyn met du temps à répondre, merci de vérifier votre connexion internet."
        } else if (error.message !== "Request failed with status code 500") {
          details = error.message
        }
      }
    
      Vue.swal({
        title: titre,
        html: details,
        icon: "error",
        confirmButtonText: "Ok !"
      })
      error.erreurDejaAffichee = true
      return Promise.reject(error)
    }
  )
}
