<template>
  <div style="margin-right:5px" v-if="isLoading">
   <i class="fas fa-euro-sign fa-spin"></i>
  </div>
  <div style="text-align:right" v-else>
    {{prix}} €
  </div>
</template>

<script>
import { connecteData } from "@/mixins/connecteData"
import { prixUnitairePourComposante } from '@/api/gpao/recettes'
export default {
  name: "ComposantePrix",
  mixins: [connecteData],
  props: ['composante'],
  data() {
    return {
      prix: null,
      isLoading:false,
    }
  },
  mounted() {
    this.isLoading=true
    return prixUnitairePourComposante(this.etabCourantId , this.composante)
      .then((response) => {
        this.prix = response.data.prixUnitaire
        this.$emit('prix-par-convive', { prix: this.prix, composante: this.composante })
        this.isLoading = false
        return
      })
      .catch((error) => {
        console.error(error)
      })
  }
}
</script>

<style>
</style>
