
export const constantesId={
UPPiece: "PCE",
UPKG: "KG",
UPL:"L",
//*** pour l'instant considéré constant */
servicePetitDejeuner: 'SE1',
serviceDejeuner: 'SE2',
serviceGouter: 'SE3',
serviceDiner: 'SE4',
serviceCollationMatinale: 'SE5',
serviceCollationApresMidi: 'SE6',
serviceCollationSoir: 'SE7',
//*** */
textureNormale: 'TE1',
regimeNormal: 'RE3',
typeConviveIdNeutre: 'TCN',
//*** */
etatCmdEnCours: 'EC1',
etatCmdEnvoyee: 'EC2',
etatCmdRecue: 'EC3',
//*** */
filtreToutesDlc: 'toutes',
filtreDlcProches: 'proches',
filtreDlcDepassees: 'depassees',
//*** */
tMvtStockEntreeMarchandise: 0,
tMvtStockSortieJournee: 1,
tMvtStockSortieAutre: 2,
tMvtStockRegularisation: 3,
//*** */
allergenes: [
    { id: 'Arachide', valeur: 'Arachide' },
    { id: 'Celeri', valeur: 'Céleri' },
    { id: 'Crustaces', valeur: 'Crustacés' },
    { id: 'FruitsACoques', valeur: 'Fruits à coques' },
    { id: 'Gluten', valeur: 'Gluten' },
    { id: 'Lait', valeur: 'Lait' },
    { id: 'Lupin', valeur: 'Lupin' },
    { id: 'Mollusques', valeur: 'Mollusques' },
    { id: 'Moutarde', valeur: 'Moutarde' },
    { id: 'Oeuf', valeur: 'Oeuf' },
    { id: 'Poisson', valeur: 'Poisson' },
    { id: 'Sesame', valeur: 'Sésame' },
    { id: 'Soja', valeur: 'Soja' },
    { id: 'Sulfites', valeur: 'Sulfites' }
],
//*** */
messageDefautEnvoiCommande: "Bonjour,\n\nPouvez-vous accuser réception de la commande ci-jointe, je vous prie ?\nMerci de nous contacter en cas de rupture ou de substitution de produits.\n\nCordialement,\n"
}

