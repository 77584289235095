import service from '@/helpers/serviceGPAO'

export function obtenirStock(etablissementId, dateHeure, dateLimite) {
  return service.get('stockinventaire/stock/' + etablissementId + '/' + dateHeure + '/' + dateLimite)
    .then(reponse => {
      reponse.data.zonesStockage.forEach(zone => {
        zone.ingredients.forEach(ingredient => ingredient.nomRecherche = ingredient.nom.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase())
      })
      return reponse;
    })
}

export function ajouterEnStock(date, article) {
  if (!article.dlc)
    article.dlc=false
  if (!article.dlcDluo)
    article.dlcDluo=null
  return service.put('stockinventaire/stock/entree/' + date, article)
}

export function sortirDuStock(date, article) {
  return service.put('stockinventaire/stock/sortie/' + date, article)
}

export function viderZoneStockage(date, zoneStockage) {
  return service.post('stockinventaire/stock/vider/' + date, zoneStockage)
}

export function listeInventaires(etablissementId) {
  return service.get('stockinventaire/listeinventaires/' + etablissementId)
}

export function detailInventaire(inventaireId) {
  return service.get('stockinventaire/inventaire/' + inventaireId)
    .then(reponse => {
      reponse.data.zonesStockage.forEach(zone => {
        zone.ingredients.forEach(ingredient => ingredient.nomRecherche = ingredient.nom.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase())
      })
      return reponse;
    })
}

export function creerInventaire(etablissementId, dateHeure) {
  return service.post('stockinventaire/inventaire/creer/' + etablissementId + '/' + dateHeure)
}

export function modifierInventaire(idInventaire, lignes) {
  return service.post('stockinventaire/inventaire/modifier/' + idInventaire , lignes )
}

export function validerInventaire(idInventaire) {
  return service.patch('stockinventaire/inventaire/valider/' + idInventaire )
}

export function annulerInventaire(idInventaire) {
  return service.delete('stockinventaire/inventaire/annuler/' + idInventaire )
}

export function obtenirMouvementsStockLot(idLot, etablissementId, avecDetails) {
  return service.get('stockinventaire/stock/mouvements/' + idLot + '/' + etablissementId + '/' + avecDetails)
}

export function obtenirMouvementsStockLotADate(idLot, date, etablissementId, avecDetails) {
  return service.get('stockinventaire/stock/mouvements/' + idLot + '/' + date + '/' + etablissementId + '/' + avecDetails)
}

export function obtenirMouvementsStockLotPeriode(idLot, dateDebut, dateFin, etablissementId, avecDetails) {
  return service.get('stockinventaire/stock/mouvements/' + idLot + '/' + dateDebut + '/' + dateFin + '/' + etablissementId + '/' + avecDetails)
}

export function obtenirMouvementsStockPeriode(dateDebut, dateFin, etablissementId) {
  return service.get('stockinventaire/stock/mouvements/' + dateDebut + '/' + dateFin + '/' + etablissementId)
}
