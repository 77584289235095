import afficherPrix  from '@/helpers/filters/afficherPrix.js'
import afficherPrix2  from '@/helpers/filters/afficherPrix2.js'
import afficherPrix6  from '@/helpers/filters/afficherPrix6.js'
import mettreVirgule  from '@/helpers/filters/mettreVirgule.js'
import afficherArrondi  from '@/helpers/filters/afficherArrondi.js'
// https://stackoverflow.com/a/47005707/130420
export default {
  install(Vue) {
    Vue.filter('mettreVirgule', mettreVirgule);
    Vue.filter('afficherPrix', afficherPrix);
    Vue.filter('afficherPrix2', afficherPrix2);
    Vue.filter('afficherPrix6', afficherPrix6);
    Vue.filter('afficherArrondi', afficherArrondi);
  }
}