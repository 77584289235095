<template>
  <span>
    <button :class="'btn ' + (erreurBarquettes ? 'btn-danger' : (montrerBarquettes ? 'btn-primary' : 'btn-secondary'))" :disabled="erreurBarquettes" @click="toggleBarquettes">Barquettage</button>
    <div v-if="montrerBarquettes" id="barquettes" :key="keyBarquettes" :style="dropLeft ? 'right:15px;' : ''">
      <table style="table-layout:fixed;width:300px;">
        <colgroup>
          <col span="1" width="52">
          <col span="1" width="202">
          <col span="1" width="42">
        </colgroup>
        <thead>
          <tr>
            <th>Taille</th>
            <th>Désignation</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(barquette, iBarquette) in barquettes" :key="iBarquette">
            <td :class="{erreur: !barquette.tailleValide}">
              <InputNumber v-model="barquette.Taille" typeFiltre="quantiteEntiere" style="width:48px;" @input="inputBarquette" />
            </td>
            <td :class="{erreur: !barquette.designationValide}">
              <input type="text" v-model="barquette.Designation" style="width:200px;" @input="inputBarquette">
            </td>
            <td>
              <i class="far fa-trash-alt" style="transform:scale(0.8);" v-tooltip="{content: 'Supprimer cette barquette', placement: 'right'}" @click="supprimerBarquette(iBarquette)"></i>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="flex-center-space-between" style="padding-top:5px;">
        <b-button v-tooltip="{content: 'Nouveau type de barquette', placement: 'right'}" @click="creerBarquette"><i class="fas fa-plus cursor-pointer"></i></b-button>
        <b-button :disabled="erreurBarquettes || !modifBarquettes" class="btn-primary" @click="enregistrerBarquettes">Enregistrer</b-button>
      </div>
    </div>
  </span>
</template>

<script>
import InputNumber from "@/components/Inputs/InputNumber"
import _ from "lodash"
export default {
  name: "boutonGestionBarquettes",
  components: {InputNumber},
  props: {
    donnees: {type: String, default: ""},
    dropLeft: {type: Boolean, default: false}
  },
  data() {
    return {
      barquettes: [],
      barquettesAvant: "",
      erreurBarquettes: false,
      modifBarquettes: false,
      montrerBarquettes: false,
      keyBarquettes: 1,
    }
  },
  watch: {
    montrerBarquettes(visible) {
      setTimeout(() => {
        if (visible) {
          window.addEventListener("click", this.clickOutBarquettes)
        } else {
          window.removeEventListener("click", this.clickOutBarquettes)
        }
      }, 10)
    }
  },
  methods: {
    async toggleBarquettes() {
      if (!this.montrerBarquettes) {//préparation des données
        this.barquettes = this.donnees && JSON.parse(this.donnees.replaceAll("'", "\""))
        this.barquettes = this.barquettes && this.barquettes.Barquettage || []
        this.barquettesAvant = JSON.stringify(this.barquettes.map(b => {
          return {
            Taille: b.Taille,
            Designation: b.Designation
          }
        }))
        this.checkBarquettes()
        this.keyBarquettes += 1
        this.montrerBarquettes = true
      } else {
        if (!this.erreurBarquettes) {//pas de fermeture en cas d'erreur
          if (this.modifBarquettes) {
            let result = await this.alerteConfirmation('Modifications non enregistrées', "Les modifications effectuées sur le barquettage n'ont pas été appliquées.<br/>Voulez-vous les enregistrer ?")
            if (result) {
              return this.enregistrerBarquettes()
            }
          }
          this.montrerBarquettes = false
        }
      }
    },
    inputBarquette() {
      this.checkBarquettes()
    },
    creerBarquette() {
      this.barquettes.push({Taille: "", Designation: ""})
      this.checkBarquettes()
      this.keyBarquettes += 1
    },
    supprimerBarquette(index) {
      _.remove(this.barquettes, (b, i) => i === index)
      this.checkBarquettes()
      this.keyBarquettes += 1
    },
    checkBarquette(barquette) {
      if (barquette.Designation.length > 20) {
        barquette.Designation = barquette.Designation.substr(0, 20)
      }
      barquette.tailleValide = Boolean(barquette.Taille)
      barquette.designationValide = Boolean(barquette.Designation)
    },
    checkBarquettes() {
      this.barquettes.forEach(this.checkBarquette)
      this.erreurBarquettes = this.barquettes.filter(b => !b.tailleValide || !b.designationValide).length > 0
      this.modifBarquettes = this.barquettesAvant !== JSON.stringify(this.barquettes.map(b => {
        return {
          Taille: b.Taille,
          Designation: b.Designation
        }
      }))
    },
    enregistrerBarquettes() {
      this.$emit("enregistrer", this.barquettes.map(b => {
        return {
          Taille: b.Taille,
          Designation: b.Designation
        }
      }))
      this.montrerBarquettes = false
    },
    clickOutBarquettes(e) {//fermeture du combo par clic en dehors
      if (this.montrerBarquettes && !e.target.closest("#barquettes") && !e.target.closest(".swal2-container")) {
        this.toggleBarquettes()
      }
    }
  },
  destroyed() {
    window.removeEventListener("click", this.clickOutBarquettes)
  }
}
</script>

<style>
#barquettes {
  position: absolute;
  z-index: 2;
  background: white;
  border: 1px solid black;
  border-radius: 3.5px;
  padding: 8px;
}
#barquettes td.erreur {
  border: 1px solid red;
}
</style>
