import service from '@/helpers/serviceGPAO'

export function chargerCommandes(etablissementId, params) {
  return service.post('/commandes/liste/' + etablissementId, params);
}

export function detailsCommande(id) {
  return service.get('commandes/' + id)
}

export function ajouterCommande(etablissementId, params) {
  return service.post('commandes/ajouter/' + etablissementId, params)
}

export function modifierCommande(idCommande, data) {
  return service.put('commandes/modifier/' + idCommande, data)
}

export function demanderValidationCommande(idCommande) {
  return service.get('commandes/DemanderValidationCommande/' + idCommande)
}

export function recalculerCommande(commande) {
  return service.post('commandes/recalculer', commande)
}

export function optimiserpcbCommande(commande) {
  return service.post('commandes/optimisation/mieuxdisant', commande)
}

export function optimiserfournisseurCommande(commande) {
  return service.post('commandes/optimisation/fournisseurs', commande)
}

export function optimiserfrancoCommande(commande) {
  return service.post('commandes/optimisation/franco', commande)
}

export function choisirFournisseur(fId, commande) {
  return service.post('commandes/optimisation/changefournisseur/' + fId, commande)
}

export function listerFournisseursPossibles(commande) {
  return service.post('commandes/optimisation/listerfournisseur', commande)
}

export function supprimerUnFournisseur(idFournisseur, commande) {
  return service.post('commandes/optimisation/supprimerfournisseur/' + idFournisseur, commande)
}

export function receptionnerCommande(idCommande, data) {
  return service.put('commandes/receptionner/' + idCommande, data)
}

export function envoyerCommandeGlobale(idCommande) {
  return service.patch('commandes/envoyer/' + idCommande)
}

export function envoyerCommandeFournisseurs(idCommande, data) {
  return service.post('commandes/envoyer/' + idCommande, data)
}

export function articleFavori(produitId, fournisseurId) {
  let parametres = produitId
  if (fournisseurId)
    parametres += "/" + fournisseurId
  return service.patch('produits/modifierfavori/' + parametres)
}

export function articleFavoriRecette(produitId, recetteId, fournisseurId) {
  let parametres = produitId
  parametres += "/" + recetteId
  if (fournisseurId)
    parametres += "/" + fournisseurId
  return service.patch('produits/modifierfavorirecette/' + parametres)
}

export function renommerCommande(idCommande, params) {
  return service.patch('commandes/modifier/' + idCommande, params)
}

export function supprimerCommande(idCommande) {
  return service.delete('commandes/supprimer/' + idCommande)
}

export function dupliquerCommande(idCommande, optionsDuplication) {
  return service.put('commandes/dupliquer/' + idCommande, optionsDuplication)
}

export function envoyerBC(idCommande, data) {
  return service.post('commandes/envoyerBDC/' + idCommande, data)
}