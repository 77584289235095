/* eslint-disable promise/param-names */
import { AUTH_REQUEST, AUTH_ERROR, AUTH_SUCCESS, AUTH_LOGOUT } from "../actions/auth"
import { USER_INFO, USER_LOGOUT } from "../actions/user"
import { login } from '@/api/gpao/user'
import Vue from "vue"
import authentified from "../../models/authentified"
const tokenAuthName=/*process.env.NODE_ENV +*/ "auth-user-token"
const token = localStorage.getItem(tokenAuthName);
const initialState = token
  ? { token: token, status: "success" }
  : { token: "", status: null }

const auth = {
  namespaced: true,
  state: initialState,

  getters: {
    estAuthentifie: state => !!state.token && state.status == "success",
    // estAuthentifie: false,
    authStatus: state => state.status,
    token: state => state.token,
    isAdmin: state => state.authentified && state.authentified.isAdmin,
    roles: state => state.authentified && state.authentified.roles || [],
    isAdminGPAO: state => state.authentified && state.authentified.roles && state.authentified.roles.indexOf("AdminGPAO") !== -1,
    isLecteur: state => state.authentified && state.authentified.roles && state.authentified.roles.indexOf("LecteurGPAO") !== -1,
    isUtilisateur: state => state.authentified && state.authentified.roles && state.authentified.roles.indexOf("UtilisateurGPAO") !== -1,
    isMercuA: state => state.authentified && state.authentified.roles && state.authentified.roles.indexOf("AccesMercuA") !== -1,
    isMercuC: state => state.authentified && state.authentified.roles && state.authentified.roles.indexOf("AccesMercuC") !== -1,
    isMercuD: state => state.authentified && state.authentified.roles && state.authentified.roles.indexOf("AccesMercuD") !== -1,
    isMercuE: state => state.authentified && state.authentified.roles && state.authentified.roles.indexOf("AccesMercuE") !== -1,
    isMercuM: state => state.authentified && state.authentified.roles && state.authentified.roles.indexOf("AccesMercuM") !== -1,
    isMercuP: state => state.authentified && state.authentified.roles && state.authentified.roles.indexOf("AccesMercuP") !== -1,
    isMercu1: state => state.authentified && state.authentified.roles && state.authentified.roles.indexOf("AccesBase100") !== -1,
    isDifferenteMercu: state => state.authentified && state.authentified.roles && (state.authentified.roles.indexOf("AccesMercu1") !== -1 || state.authentified.roles.indexOf("AccesMercuP") !== -1 || state.authentified.roles.indexOf("AccesMercuM") !== -1 || state.authentified.roles.indexOf("AccesMercuE") !== -1 || state.authentified.roles.indexOf("AccesMercuD") !== -1 || state.authentified.roles.indexOf("AccesMercuC") !== -1 || state.authentified.roles.indexOf("AccesMercuA") !== -1)
  },

  actions: {
    [AUTH_REQUEST]: async function ({ commit, dispatch }, user) {
      commit(AUTH_REQUEST)
      try {
        const resp = await login(user.userName.trim(), user.password)
        commit(AUTH_SUCCESS, resp.data)
        await dispatch("user/" + USER_INFO, null, { root: true })
        return;
      }
      catch (err) {
        commit(AUTH_ERROR)
        return err;
      }

    },
    [AUTH_LOGOUT]:  function ({ commit }) {
       commit("user/" + USER_LOGOUT, undefined, { root: true })
      // eslint-disable-next-line promise/avoid-new
      return new Promise(resolve => {
        commit(AUTH_LOGOUT)
        resolve()
      })
    }
  },
  mutations: {
    [AUTH_REQUEST]: state => {
      state.status = "loading"
    },
    [AUTH_SUCCESS]: (state, token) => {
      state.token = token
      const base64 = state.token.accessToken.split(".")[1].replace("-", "+").replace("_", "/")
      state.status = "success"
      const clearToken = JSON.parse(window.atob(base64))
      localStorage.setItem(tokenAuthName, token)
      Vue.set(state, "authentified", new authentified(clearToken))
    },

    [AUTH_ERROR]: state => {
      state.status = "error"
      state.token = ""
      state.authentified = null
    },
    [AUTH_LOGOUT]: state => {
      state.token = ""
      state.authentified = null
      state.status = null
      localStorage.removeItem(tokenAuthName)
    }
  }
}

export default auth;