<template>
  <b-modal ref="modalEnvoiCommande" size="lg" title="Passer la commande au statut 'Envoyé'"
    ok-title="Modifier le statut" :ok-disabled="!fournisseursSelectionnesPourEnvoi.length"
    cancel-title="Annuler"
    no-close-on-backdrop
    @show="initialiserModale()"
    @ok="send()">
    <template slot="default">
      <p>Les commandes sélectionnées seront considérées comme envoyées au fournisseur.</p>
      <p>Cette fonctionnalité est à utiliser lorsque vous avez passé votre commande par téléphone ou que vous avez envoyé le bon de commande manuellement.</p>
      <p><b>Cette action est irréversible.</b></p>

      <div class="card">
        <div class="card-header flex-start">
          <label class="label-checkbox mb-2">
            <input type="checkbox" v-model="envoyerTout" @change="checkAllEnvoi()">
            <span class="ml-2">Fournisseurs</span>
          </label>
        </div>
        <div class="card-body">
          <div class="row" v-for="(fournisseur, index) in fournisseurs" :key="index">
            <div class="col-6">
              <label class="label-checkbox mb-1">
                <input type="checkbox" :disabled="!fournisseur.dateLivraisonSouhaitee" v-model="fournisseur.envoi">
                {{ fournisseur.nom }}
              </label>
            </div>
            <div class="col-6 flex-end">
              <span v-if="fournisseur.dateLivraisonSouhaitee">Livraison demandée le {{fournisseur.dateLivraisonSouhaitee}}</span>
              <em v-else class="error ml-2">
                <i class="fas fa-exclamation-triangle ligne-modifiee"></i> Aucune date de livraison renseignée
              </em>
            </div>
          </div>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { envoyerCommandeFournisseurs } from '@/api/gpao/commandes'
import { connecteData } from "@/mixins/connecteData"
import { formatageDate } from '@/mixins/dateFormats'
import { constantesId } from "@/helpers/constantes"
import { showAlerts } from '@/mixins/alerts'
import { mapGetters } from "vuex"

export default {
  name: "ModalEnvoiCommande",
  mixins: [ connecteData, formatageDate, showAlerts ],
  props: [ 'commande' ],
  data() {
    return {
      envoyerTout: true,
      fournisseurs: []
    }
  },
  computed: {
    ...mapGetters("fournisseurs", ["nomFournisseur"]),
    fournisseursSelectionnesPourEnvoi() {
      return this.fournisseurs?.filter(f => f.envoi).map(f => f.fournisseurId) || []
    }
  },
  watch: {
    fournisseursSelectionnesPourEnvoi() {
      if (this.fournisseurs.length === this.fournisseursSelectionnesPourEnvoi.length) {
        this.envoyerTout = true
      } else {
        this.envoyerTout = false
      }
    }
  },
  methods: {
    initialiserModale() {
      let fournisseurs = []
      this.commande?.detailFournisseurs?.filter(f => f.totalHDT && (f.etatCommande === constantesId.etatCmdEnCours || (f.etatDuMail && f.etatDuMail.estErreur)))?.forEach(f => {
        fournisseurs.push({
          fournisseurId: f.fournisseurId,
          nom: this.nomFournisseur(f.fournisseurId),
          dateLivraisonSouhaitee: this.formatHumanDate(f.dateLivraisonSouhaitee),
          envoi: f.dateLivraisonSouhaitee ? true : false 
        })
      })
      this.fournisseurs = fournisseurs
    },
    checkAllEnvoi() {
      this.fournisseurs.forEach(f => {
        if (f.dateLivraisonSouhaitee)
          f.envoi = this.envoyerTout
      })
    },
    send() {
      if (this.fournisseursSelectionnesPourEnvoi.length) {
        return envoyerCommandeFournisseurs(this.commande.id, this.fournisseursSelectionnesPourEnvoi)
          .then(() => {
            this.hide()
            this.$emit("commande-envoyee")
            this.alerteSucces("Commande envoyée !")
            return
          })
      }
    },
    show() {
      this.$refs.modalEnvoiCommande.show()
    },
    hide() {
      this.$refs.modalEnvoiCommande.hide()
    }
  }
}
</script>
