<template>
  <b-modal ref="modalImpressionCommande" size="xl" :title="'Configuration des bons de commande de ' + commande.nom"
    :ok-title="nomBoutonOk" :ok-disabled="!peutGenererBdC"
    cancel-title="Annuler"
    no-close-on-backdrop
    @show="initialiserModale()"
    @ok="imprimerCommande()">
    <template slot="default">
      <div class="form-group">
        <label for="dpDateCommande">Date de commande :</label>
        <v-date-picker class="date-picker" id="dpDateCommande" disabled v-model="options.DateImpression" :popover="{ visibility: 'click' }">
          <template v-slot="{ inputValue, inputEvents }">
            <input :value="inputValue ? inputValue : 'Choisir la date...'" v-on="inputEvents" />
          </template>
        </v-date-picker>
      </div>

      <div class="row form-group">
        <div class="col-6">
          <label class="label-checkbox">
            <input type="checkbox" v-model="options.ImprimerTitreCommande">
            Intégrer le nom de la commande
          </label>
          <label class="label-checkbox">
            <input type="checkbox" v-model="options.ImprimerControleAReception">
            Intégrer le contrôle à réception
          </label>
          <label class="label-checkbox">
            <input type="checkbox" v-model="options.A3">
            Générer au format A3
          </label>
        </div>
        <div class="col-6">
          <label class="label-checkbox">
            <input type="checkbox" v-model="options.SortieXlsx">
            Sortie en fichier Excel
          </label>
          <label class="label-checkbox">
            <input type="checkbox" v-model="options.TrierAlpha">
            Trier les lignes par ordre alphabétique
          </label>
          <label class="label-checkbox">
            <input type="checkbox" v-model="options.ImprimerTVA">
            Intégrer le total TTC
          </label>
          <label class="label-checkbox">
            <input type="checkbox" v-model="options.ImprimerDatesConso">
            Intégrer les dates de consommation
          </label>
        </div>
      </div>

      <div class="row">
        <div :class="pasDEnvoi ? 'col-12' : 'col-6'">
          <div class="card">
            <div class="card-header flex-start">
              <label class="label-checkbox mb-2">
                <input type="checkbox" v-model="options.ImprimeTout" @change="checkAllImprime()">
                <span class="ml-2">IMPRESSION</span>
              </label>
            </div>
            <div class="card-body">
              <label class="label-checkbox" v-for="(fournisseur, index) in fournisseurs" :key="index">
                <input type="checkbox" v-model="fournisseur.impression" >
                {{ fournisseur.nom }} 
              </label>
            </div>
          </div>
        </div>

        <div v-if="!pasDEnvoi" class="col-6">
          <div class="card">
            <div class="card-header flex-start">
              <label class="label-checkbox mb-2">
                <input type="checkbox" v-model="options.EnvoiTout" @change="checkAllEnvoi()">
                <span class="ml-2">ENVOI</span>
              </label>
            </div>
            <div class="card-body">
                <label class="label-checkbox" v-for="(fournisseur, index) in fournisseursPourEnvoi" :key="index">
                  <input type="checkbox" v-model="fournisseur.envoi" :disabled="!fournisseur.dateLivraisonSouhaitee">
                  {{ abregerTexte(fournisseur.nom, !fournisseur.dateLivraisonSouhaitee ? 30 : 50) }} <em v-if="!fournisseur.dateLivraisonSouhaitee" class="error ml-2"><i class="fas fa-exclamation-triangle mr-1"></i>Date de livraison manquante</em>
                </label>
              <div class="form-group">
                <label for="txtEmailEnvoiBC">Votre Email :</label>
                <input type="text" class="form-control" id="txtEmailEnvoiBC" v-model="emailEnvoiBC" @focus="$event.target.select()">
                <div class="error" v-if="fournisseursSelectionnesPourEnvoi.length && !$v.emailEnvoiBC.required">Ce champ est requis</div>
                <div class="error" v-if="fournisseursSelectionnesPourEnvoi.length && !$v.emailEnvoiBC.email">Email invalide</div>
              </div>
              <div class="form-group">
                <label for="txtMailBC">Votre Message :</label>
                <textarea class="form-control" id="txtMailBC" v-model="txtMailBC" @focus="$event.target.select()" />
              </div>
              <div class="flex-end"><a class="btn btn-primary" @click="resetTxtMailBC">Réinitialiser le message</a></div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex"
import { obtenirBC } from '@/api/gpao/documents'
import { envoyerBC } from '@/api/gpao/commandes'
import { connecteData } from "@/mixins/connecteData"
import { showAlerts } from '@/mixins/alerts'
import { constantesId } from "@/helpers/constantes"
import { abregerTexte } from "@/helpers/utils"
import { required, email } from "vuelidate/lib/validators"
import { saveAs } from 'file-saver'

export default {
  name: "ModalImpressionCommande",
  mixins: [ connecteData, showAlerts ],
  props: {
    commande: {type: Object, required: true},
    fournisseurId: {type: Number},
    pasDEnvoi: {type: Boolean, default: () => false},
    disabled: {type: Boolean}
  },
  data() {
    return {
      fournisseurs: [],
      txtMailBC: "",
      emailEnvoiBC: "",
      options: {
        CommandeId: null,
        DateImpression: new Date(),
        SortieXlsx: true,
        A3: false,
        ImprimerControleAReception: true,
        ImprimerTitreCommande: true,
        TrierAlpha: false,
        ImprimerTVA: false,
        ImprimerDatesConso: false,
        EnvoiTout: false,
        ImprimeTout: true
      }
    }
  },
  validations: {
    emailEnvoiBC: { required, email }
  },
  computed: {
    ...mapGetters("definitions", ["environnementProduction"]),
    ...mapGetters("fournisseurs", ["nomFournisseur"]),
    nomBoutonOk() {
      if (this.fournisseurs?.filter(f => f.envoi).length) {
        if (this.fournisseurs?.filter(f => f.impression).length) {
          return "Imp./Env."
        }
        return "Envoyer"
      }
      return "Imprimer"
    },
    fournisseursPourEnvoi() {
      return this.fournisseurs?.filter(f => f.etatCommande === constantesId.etatCmdEnCours || (f.etatDuMail && f.etatDuMail.estErreur)) || []
    },
    fournisseursSelectionnesPourEnvoi() {
      return this.fournisseurs?.filter(f => f.envoi) || []
    },
    fournisseursSelectionnesPourImpression() {
      return this.fournisseurs?.filter(f => f.impression) || []
    },
    peutGenererBdC() {
      if (!this.fournisseursSelectionnesPourImpression.length && !this.fournisseursSelectionnesPourEnvoi.length) {
        return false
      }
      return !this.fournisseursSelectionnesPourEnvoi.length || (this.$v.emailEnvoiBC.required && this.$v.emailEnvoiBC.email)
    }
  },
  watch: {
    fournisseurId() {
      this.initialiserModale()
    },
    txtMailBC(newVal) {
      localStorage.txtMailBC = newVal
    },
    emailEnvoiBC(newVal) {
      localStorage.emailEnvoiBC = newVal || ""
    },
    fournisseursSelectionnesPourEnvoi() {
      if (this.fournisseursPourEnvoi.length === this.fournisseursSelectionnesPourEnvoi.length) {
        this.options.EnvoiTout = true
      } else {
        this.options.EnvoiTout = false
      }
    },
    fournisseursSelectionnesPourImpression() {
      if (this.fournisseurs.length === this.fournisseursSelectionnesPourImpression.length) {
        this.options.ImprimeTout = true
      } else {
        this.options.ImprimeTout = false
      }
    }
  },
  mounted() {
    let options = this.$store.state.commandes.optionsImpression || this.options
    this.options.SortieXlsx = options.SortieXlsx
    this.options.A3 = options.A3
    this.options.ImprimerControleAReception = options.ImprimerControleAReception
    this.options.ImprimerTitreCommande = options.ImprimerTitreCommande
    this.options.TrierAlpha = options.TrierAlpha
    this.options.ImprimerTVA = options.ImprimerTVA
    this.options.ImprimerDatesConso = options.ImprimerDatesConso
    this.options.EnvoiTout = options.EnvoiTout
    this.options.ImprimeTout = options.ImprimeTout
  },
  methods: {
    abregerTexte(texte, long) {
      return abregerTexte(texte, long)
    },
    initialiserModale() {
      this.options.CommandeId = this.commande.id
      this.fournisseurs = []
      this.commande.detailFournisseurs.filter(f => f.totalHDT).forEach(fournisseur => {
        this.fournisseurs.push({
          fournisseurId: fournisseur.fournisseurId,
          nom: this.nomFournisseur(fournisseur.fournisseurId),
          impression: this.fournisseurId ? fournisseur.fournisseurId === this.fournisseurId : true,
          envoi: false,
          etatCommande: fournisseur.etatCommande,
          etatDuMail:  fournisseur.etatDuMail,
          dateLivraisonSouhaitee: fournisseur.dateLivraisonSouhaitee
        })
      })

      this.options.EnvoiTout = (this.fournisseursSelectionnesPourEnvoi.length == this.fournisseurs.length)
      this.options.ImprimeTout = (this.fournisseursSelectionnesPourImpression.length == this.fournisseurs.length)

      this.emailEnvoiBC = localStorage.emailEnvoiBC || ""
      if (!this.emailEnvoiBC) {
        let etab = this.utilisateurCourant.etablissements.find(x => x.eId === this.etabCourantId)
        if (etab) {
          this.emailEnvoiBC = etab.email
        }
      }
      this.txtMailBC = localStorage.txtMailBC

      if (!this.txtMailBC) {
        this.resetTxtMailBC()
      }
    },
    nettoyer() {
      this.fournisseurs.length = 0;

      this.$store.commit('commandes/setOptionsImpression', {
        SortieXlsx: this.options.SortieXlsx,
        A3: this.options.A3,
        ImprimerControleAReception: this.options.ImprimerControleAReception,
        ImprimerTitreCommande: this.options.ImprimerTitreCommande,
        TrierAlpha: this.options.TrierAlpha,
        ImprimerTVA: this.options.ImprimerTVA,
        ImprimerDatesConso: this.options.ImprimerDatesConso
      })
    },
    checkAllEnvoi() {
      this.fournisseurs.forEach(f => {
        if (f.dateLivraisonSouhaitee)
          f.envoi = this.options.EnvoiTout
      })
    },
    checkAllImprime() {
      this.fournisseurs.forEach(f => {
          f.impression = this.options.ImprimeTout
      })
    },
    resetTxtMailBC() {
      localStorage.txtMailBC = constantesId.messageDefautEnvoiCommande
      this.txtMailBC = localStorage.txtMailBC
    },
    imprimerCommande() {
      this.options.SortieEnPDF = !this.options.SortieXlsx
      if (this.fournisseursSelectionnesPourImpression.length !== 0) {
        this.options.FournisseurIds = this.fournisseursSelectionnesPourImpression.map(f => f.fournisseurId)
        // eslint-disable-next-line promise/catch-or-return
        obtenirBC(this.options.CommandeId, this.options)
          .then(response => saveAs(new File([response.data], this.commande.nom + (this.options.SortieXlsx ? ".xlsx" : ".pdf"), { type: "text/plain;charset=utf-8" })))
      }
      if (this.fournisseursSelectionnesPourEnvoi.length !== 0) {
        if (!this.environnementProduction) {
          // Les envois de commandes par mail aux fournisseurs sont désactivés sur les environnements de test
          this.alerteEnvironnementTest()
          return
        }
        this.options.FournisseurIds = this.fournisseursSelectionnesPourEnvoi.map(f => f.fournisseurId)
        this.options.emetteur = this.emailEnvoiBC
        this.options.message = this.txtMailBC.replace(/\n/g, "<br />")
        return this.alerteConfirmation("Envoyer les commandes par email", "Les bons de commande vont être envoyés par email au" + (this.fournisseursSelectionnesPourEnvoi.length > 1 ? "x" : "") + " fournisseur" + (this.fournisseursSelectionnesPourEnvoi.length > 1 ? "s" : "") + " sélectionné" + (this.fournisseursSelectionnesPourEnvoi.length > 1 ? "s" : "") + ".", "Envoyer", "Annuler")
          .then(result => {
            if (!result) {
              return
            }
            // eslint-disable-next-line promise/no-nesting
            return envoyerBC(this.options.CommandeId, this.options)
              .then(() => {
                this.$emit("commande-envoyee")
                this.alerteSucces("Bon de commande envoyé !")
                return this.hide()
              })
          })
      }
      this.hide()
    },
    show(fournisseurId) {
      if (fournisseurId) {
        this.fournisseurId = fournisseurId
      }
      this.$refs.modalImpressionCommande.show()
    },
    hide() {
      this.nettoyer()
      this.$refs.modalImpressionCommande.hide()
    }
  }
}
</script>
