<template>
  <div>
    <b-modal v-model="montrerModalCreation" size="md" title="Type de structure"
      ok-title="Suivant" :ok-disabled="!creationOK"
      cancel-title="Annuler"
      no-close-on-backdrop
      @ok="creerStructure">
      <template slot="default">
        <div>Sélectionnez un type de structure</div>
        <fieldset>
          <div class="custom-control custom-radio" style="margin-top:5px;">
            <input type="radio" name="typeStructure" value="nouvelle" class="custom-control-input" id="typeStructure-nouvelle" v-model="typeStructure">
            <label class="custom-control-label" for="typeStructure-nouvelle">Nouvelle</label>
          </div>
          <div class="custom-control custom-radio" style="margin-top:5px;">
            <input type="radio" name="typeStructure" value="perso" class="custom-control-input" id="typeStructure-perso" v-model="typeStructure">
            <label class="custom-control-label" for="typeStructure-perso">Copie d'une structure personnelle <i v-if="typeStructure === 'perso'" class="fas fa-chevron-down" style="color:gray;vertical-align:text-bottom;"></i></label>
          </div>
          <div v-if="typeStructure === 'perso'" style="margin-left:20px;">
            <img v-if="structures=== null" alt="en attente" src="/assets/images/spinners/cooking-blue-court.gif" style='height:100px;margin-left:-8px;' />
            <b-form-select v-else v-model="structureACopier" :options="structures.filter(s => s.estPerso)" value-field="id" text-field="nom">
              <template slot="first">
                <option :value="null" disabled>Structure à copier...</option>
              </template>
            </b-form-select>
          </div>
          <div class="custom-control custom-radio" style="margin-top:5px;">
            <input type="radio" name="typeStructure" value="agap" class="custom-control-input" id="typeStructure-agap" v-model="typeStructure">
            <label class="custom-control-label" for="typeStructure-agap">Copie d'une structure Agap'pro <i v-if="typeStructure === 'agap'" class="fas fa-chevron-down" style="color:gray;vertical-align:text-bottom;"></i></label>
          </div>
          <div v-if="typeStructure === 'agap'" style="margin-left:20px;">
            <img v-if="structures=== null" alt="en attente" src="/assets/images/spinners/cooking-blue-court.gif" style='height:100px;margin-left:-8px;' />
            <b-form-select v-else v-model="structureACopier" :options="structures.filter(s => !s.estPerso)" value-field="id" text-field="nom">
              <template slot="first">
                <option :value="null" disabled>Structure à copier...</option>
              </template>
            </b-form-select>
          </div>
          <div v-if="profilIsAdmin" class="custom-control custom-radio" style="margin-top:5px;">
            <input type="radio" name="typeStructure" value="universelle" class="custom-control-input" id="typeStructure-universelle" v-model="typeStructure">
            <label class="custom-control-label" for="typeStructure-universelle">Universelle <i v-if="typeStructure === 'universelle'" class="fas fa-chevron-down" style="color:gray;vertical-align:text-bottom;"></i></label>
          </div>
          <div v-if="profilIsAdmin && typeStructure === 'universelle'" style="margin-left:20px;">
            <img v-if="structures=== null" alt="en attente" src="/assets/images/spinners/cooking-blue-court.gif" style='height:100px;margin-left:-8px;' />
            <b-form-select v-else v-model="structureACopier" :options="structures" value-field="id" text-field="nom">
              <template slot="first">
                <option :value="null">Structure vierge</option>
              </template>
            </b-form-select>
          </div>
        </fieldset>
      </template>
    </b-modal>

    <b-modal v-model="montrerModalEdition" size="xl"
      ok-title="Enregistrer" :ok-disabled="peutEnregistrer || enEnregistrement"
      cancel-title="Annuler"
      no-close-on-backdrop
      @ok="sauverStructure(plan)">
      <template slot="modal-title">
        <div class="flex-center-space-between">
          <template v-if="this.planId">Structure de menu : {{ plan.nom }}</template>
          <template v-else>Création d'une nouvelle structure</template>

          <div class="flex-end pl-5 ml-5">
            <div v-if="this.planId && profilIsAdmin" v-tooltip="{ content: 'Exporter cette structure', placement: 'top' }" class="push-left-1rem">
              <i class="fas fa-file-export cursor-pointer" @click="exporter(planId)"></i>
            </div>
            <div v-if="this.planId" v-tooltip="{ content: 'Masquer cette structure', placement: 'top' }" class="btn btn-warning btn-sm push-left-1rem">
              <i class="fas fa-eye-slash cursor-pointer" @click="desactiver(planId)"></i>
            </div>
            <div v-if="this.planId" v-tooltip="{ content: 'Supprimer cette structure', placement: 'top' }" class="btn btn-danger btn-sm push-left-1rem">
              <i class="fas fa-trash cursor-pointer" @click="supprimer(planId)"></i>
            </div>
          </div>
        </div>
      </template>
      <template slot="default">
        <div class="container-fluid">
          <div class="row">
            <div class="col-5">
              <input type="text" v-model.trim="plan.nom" placeholder="Nom" style="width:100%" maxlength="120" />
              <div class="error" v-if="!$v.plan.nom.required">
                Ce champ est requis
              </div>
            </div>
            <div class="col-3">
              <b-form-select v-model="plan.typeConviveId" :options="optionsConvives" class="selectpicker" :disabled="Boolean(this.planId)">
                <template slot="first">
                  <option :value="null" disabled>Convive type...</option>
                </template>
              </b-form-select>
              <div class="error" v-if="!$v.plan.typeConviveId.required">
                Ce champ est requis
              </div>
              <div class="error" v-if="estNeutre">
                Cette structure sera Neutre
              </div>
            </div>
            <div class="col-4" v-if="!estNeutre">
              <!-- <ToggleButton v-model="plan.excluEffectifPortage" :labels="{checked: 'Effectif par menu uniquement', unchecked:''}" :width=250 v-tooltip="{content: 'Cacher cette structure du module de gestion d\'effectif par portage'}" :key="plan.id"></ToggleButton> -->
              <div>Saisie des effectifs uniquement sur le menu</div>
              <b-form-radio-group v-model="plan.excluEffectifPortage" :options="[{text: 'Oui', value: true}, {text: 'Non', value: false}]" style="margin-left:5px;" />
            </div>
          </div>
          <div class="row">
            <div class="col-2 push-top-1rem">
              Type de gestion par
            </div>
            <div class="col-3 push-top-1rem">
              <b-form-radio-group id="radio-group-1" v-model="plan.gestionParPlan" name="radio-group-1" :options="optionsGestionPar" @input="setRegimesEtTexturesDesComposantes()">
              </b-form-radio-group>
            </div>
            <div v-show="plan.gestionParPlan" class="col-5" style="display:flex;  margin-top: 0.7rem;">
              <div>
                <b-form-select v-model="plan.regimeId" :options="optionsRegimes" class="selectpicker">
                  <template slot="first">
                    <option :value="null" disabled>Régime...</option>
                  </template>
                </b-form-select>
              </div>
              <div>
                <b-form-select v-model="plan.textureId" :options="optionsTextures" class="selectpicker">
                  <template slot="first">
                    <option :value="null" disabled>Texture...</option>
                  </template>
                </b-form-select>
              </div>
            </div>
            <div class="col-3">
              <BoutonGestionBarquettes :donnees="plan.donneesSupplementaires" @enregistrer="enregistrerBarquettes" />
            </div>
            <div class="col-3">
              <button :class="'btn ' + (erreurFacturables ? 'btn-danger' : (montrerFacturables ? 'btn-primary' : 'btn-secondary'))" :disabled="erreurFacturables" @click="toggleFacturables">Facturables</button>
              <div v-if="montrerFacturables" id="facturables" :key="keyFacturables">
                <table style="table-layout:fixed;width:320px;">
                  <colgroup>
                    <col span="1" width="72">
                    <col span="1" width="202">
                    <col span="1" width="42">
                  </colgroup>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Libellé</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(facturable, iFacturable) in facturables" :key="iFacturable">
                      <td :class="{erreur: !facturable.idValide}">
                        <input type="text" v-model="facturable.Id" style="width:68px;" :disabled="facturablesEnLectureSeule" @input="inputFacturable">
                      </td>
                      <td :class="{erreur: !facturable.libelleValide}">
                        <input type="text" v-model="facturable.Libelle" style="width:200px;" :disabled="facturablesEnLectureSeule" @input="inputFacturable">
                      </td>
                      <td>
                        <i v-if="!facturablesEnLectureSeule" class="far fa-trash-alt" style="transform:scale(0.8);" v-tooltip="{content: 'Supprimer ce facturable', placement: 'right'}" @click="supprimerFacturable(iFacturable)"></i>
                      </td>
                    </tr>
                    <tr v-if="!facturablesEnLectureSeule">
                      <td style="padding-top:5px;">
                        <b-button v-tooltip="{content: 'Nouveau type de facturable', placement: 'right'}" @click="creerFacturable"><i class="fas fa-plus cursor-pointer"></i></b-button>
                      </td>
                      <td colspan="2" style="padding-top:5px;text-align:right;">
                        <b-button :disabled="erreurFacturables || !modifFacturables" class="btn-primary" @click="enregistrerFacturables">Enregistrer</b-button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid push-top-1rem">
          <div class="row">
            <div class="col-12" ref="effectifs">
              <div v-for="(typeService, iTypeService) in optionsServices" :key="iTypeService" :id="'accordion' + iTypeService">
                <div class="card no-margin-bottom">
                  <div class="card-header cursor-pointer" :id="'headingOne' + iTypeService" data-toggle="collapse" :data-target="'#collapseOne' + iTypeService" aria-expanded="true" :aria-controls="'collapseOne' + iTypeService">
                    <h5>
                      {{ typeService.text }}
                    </h5>
                  </div>
                  <div :id="'collapseOne' + iTypeService" :class="toogleService(typeService.value) ? 'collapse.show' : 'collapse'" :aria-labelledby="'headingOne' + iTypeService" :data-parent="'#accordion' + iTypeService">
                    <div class="card-body">
                      <div class="container-fluid">
                        <div class="row">
                          <div class="col-5 flex-center-space-between">
                            <div style="align-self:start;margin-top:10px;">
                              <b-button class="btn-sm btn-secondary" @click="alertModal(typeService.value)"><i class="fas fa-plus"></i> composante</b-button>
                            </div>
                            <div style="align-self:flex-end;margin-bottom:30px;">Eff.</div>
                          </div>
                          <div class="col-7">
                            <div class="container-fluid">
                              <div class="row">
                                <div class="col-11">
                                  <table class="table" style="table-layout:fixed;word-wrap:break-word;">
                                    <thead>
                                      <tr>
                                        <th v-for="jour in optionsJours" style="font-size:.7rem;padding-left:12px;" :key="jour.nom">
                                          <button type="button" class="btn btn-secondary btn-changer-eff" v-b-modal="'changerEffectif-' + typeService.value + '-' + jour.id">{{ jour.nomCourt }}&nbsp;<i class="fas fa-users cursor-pointer"></i></button>
                                          <b-modal :id="'changerEffectif-' + typeService.value + '-' + jour.id" hide-footer header-bg-variant="success" header-text-variant="light" @show="initEffectifsJour(typeService.value, jour.id)">
                                            <template slot="modal-title">
                                              <h3><i class="fas fa-users" style="margin-right:1rem"></i>Effectifs {{ typeService.text.toLocaleLowerCase() }}, {{ jour.nom.toLocaleLowerCase() }}</h3>
                                            </template>
                                            <template slot="default">
                                              <div class="container-fluid" :key="keyEffectifsJour">
                                                <div class="row">
                                                  <div class="col-6">Régime / Texture</div>
                                                  <div class="col-3">Effectif</div>
                                                </div>
                                                <div v-for="(type, iType) in effectifsJour" :key="iType" class="row" style="margin-top:10px;">
                                                  <div class="col-6">{{ type.titre }}</div>
                                                  <div class="col-3"><InputNumber v-model="type.effectif" typeFiltre="quantiteEntiere" /></div>
                                                </div>
                                              </div>
                                              <div style="margin: 2rem 0; text-align:right">
                                                <button class="btn btn-primary" style="background: #d33 !important;border-color: #d33 !important;" @click="setEffectifsJour(typeService.value, jour.id)">
                                                  Valider</button>
                                                <button class="btn btn-danger" style="margin-left:3rem; background: #3085d6 !important;border-color: #3085d6 !important;" @click="$bvModal.hide('changerEffectif-' + typeService.value + '-' + jour.id)">Annuler</button>
                                              </div>
                                            </template>
                                          </b-modal>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td class="td-input-container" v-for="(service, iService) in planServices(typeService.value)" :key="iService">
                                          <InputNumber v-if="service" v-model="service.effectif" :key="service.serviceId + service.jour" typeFiltre="quantiteEntiere" :data-key="service.serviceId + '-' + service.jour" @move="moveEffectif($event, planServices(typeService.value), iService)" />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="container-fluid">
                        <div class="row">
                          <div class="col-5">
                            <table class="table" style="table-layout:fixed;word-wrap:break-word;">
                              <tbody>
                                <tr v-for="(service, iService) in planServices(typeService.value).slice(0, 1)" :key="iService">
                                  <div v-if="service">
                                    <td v-for="(composante, iComposante) in service.planComposantes" :key="iComposante" class="ligne-composante-titre">
                                      <div>
                                        {{ titreComposante(composante) }}
                                      </div>
                                      <div class="flex-center-space-between" v-if="!plan.gestionParPlan" style=";display: flex;align-items: flex-start;flex-direction: row">
                                        <div class="inline-dropdown" style="position:relative">
                                          <b-form-select v-if="composante.index !== 0" v-model="composante.regimeId" :options="optionsRegimes" @change="
                                            composanteRT($event, service.serviceId, composante.composanteId, composante.index,'regime' ) ">
                                            <template slot="first">
                                              <option :value="null" disabled>Régime...</option>
                                            </template>
                                          </b-form-select>
                                          <div class="error" style="position:absolute;top:3px" v-if="composante.regimeId === ''">
                                            * requis
                                          </div>
                                        </div>
                                        <div class="inline-dropdown">
                                          <b-form-select v-if="composante.index !== 0" v-model="composante.textureId" :options="optionsTextures" @change="composanteRT($event, service.serviceId, composante.composanteId, composante.index, 'texture')" style="margin-left:15px;margin-right:10px">
                                            <template slot="first">
                                              <option :value="null" disabled>Texture...</option>
                                            </template>
                                          </b-form-select>
                                        </div>
                                      </div>
                                      <div>Eff.</div>
                                    </td>
                                  </div>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="col-7">
                            <div class="container-fluid">
                              <div class="row">
                                <div class="col-11">
                                  <table class="table" style="table-layout:fixed;word-wrap:break-word;">
                                    <tbody>
                                      <tr>
                                        <td class="td-input-container" v-for="(service, iService) in planServices(typeService.value)" :key="iService">
                                          <div v-if="service" style="width:inherit">
                                            <div v-for="(composante, iComposante) in service.planComposantes" :key="iComposante" style="width:inherit" class="td-height">
                                              <InputNumber v-model="composante.effectif" :key="composante.composanteId + service.serviceId + service.jour" typeFiltre="quantiteEntiere" :data-key="service.serviceId + '-' + service.jour + '-' + composante.cleComposee" @move="moveEffectifComposante($event, planServices(typeService.value), iService, iComposante)" />
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                      <!-- <tr>
                                        <td class="td-input-container" v-for="(service, iService) in planServices(typeService.value)" :key="iService">
                                          <div v-if="service && service.cleComposee" @click="dupliquerEffectifs(planServices(typeService.value), service)" v-tooltip="{content: 'Dupliquer les effectifs', placement: 'top'}" style="transform:translate(15px);">
                                            <i class="far fa-copy"></i>
                                          </div>
                                        </td>
                                      </tr> -->
                                    </tbody>
                                  </table>
                                </div>
                                <div class="col-1">
                                  <table class="table">
                                    <tbody>
                                      <tr v-for="(service, iService) in planServices(typeService.value).slice(0, 1)" :key="iService">
                                        <div v-if="service">
                                          <td class="flex-center-space-between cursor-pointer container-dupliquer-effacer" v-for="(composante, iComposante) in service.planComposantes" :key="iComposante">
                                            <div v-if="composante.index === 0" @click="dupliquerComposante(typeService.value, composante.composanteId)" v-tooltip="{content: 'Dupliquer la composante', placement: 'top'}">
                                              <i class="far fa-copy"></i>
                                            </div>
                                            <div v-else style="width:15px"></div>
                                            <div @click="effacerComposante(typeService.value, composante.composanteId, composante.index)" v-tooltip="{content: 'Supprimer la composante', placement: 'top'}">
                                              <i class="fas fa-times"></i>
                                            </div>
                                          </td>
                                        </div>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="error" v-if="toutEstNan" style="display:inline-block;">Veuillez saisir au moins un effectif pour cette structure de menu</div>
        <!-- <b-button v-if="this.planId" @click.prevent="supprimer(plan.id)"><i class="fas fa-times"></i></b-button> -->
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import { constantesId } from "@/helpers/constantes"
import { required } from "vuelidate/lib/validators"
import { arrayEmpty } from "@/mixins/index"
import { ajouterMenuPlan, modifierMenuPlan, supprimerMenuPlan, desactiverMenuPlan, obtenirMenuPlan, chargerStructures } from "@/api/gpao/menus"
import { showAlerts } from "@/mixins/alerts"
import { optionsJours } from "@/mixins/menus"
import { connecteData } from "@/mixins/connecteData"
import { lancerTelechargement } from "@/helpers/utils"
import InputNumber from "@/components/Inputs/InputNumber"
import BoutonGestionBarquettes from "@/components/Divers/BoutonGestionBarquettes"
import _ from "lodash"

export default {
  mixins: [arrayEmpty, showAlerts, optionsJours, connecteData],
  components: { InputNumber, BoutonGestionBarquettes },
  data() {
    return {
      planId: undefined,
      montrerModalCreation: false,
      montrerModalEdition: false,
      plan: {
        nom: "",
        typeConviveId: null,
        utilisateurId: null,
        services: [],
        regimeId: "RE3", // 'normal' à vérifier avec la base en prod
        textureId: "TE1", // 'normal' idem
        gestionParPlan: true,
        etablissementId: null,
        excluEffectifPortage: false,
      },
      planRedacteurOriginal: null,
      optionsServices: this.$store.state.definitions.services,
      optionsComposantesRepas: this.$store.state.definitions.composantesRepas,
      optionsRegimes: this.$store.state.definitions.regimes,
      optionsTextures: this.$store.state.definitions.textures,
      composantesSelectionnees: [],
      optionsGestionPar: [{ text: "Composantes", value: false }, { text: "Structure", value: true }],
      enEnregistrement: false,
      typeStructure: null,
      structures: null,
      structureACopier: null,
      facturablesEnLectureSeule: true,
      facturables: [],
      facturablesAvant: "",
      erreurFacturables: false,
      montrerFacturables: false,
      keyFacturables: 1,
      keyEffectifsJour: 1,
      effectifsJour: []
    }
  },
  validations: {
    plan: {
      nom: { required },
      typeConviveId: { required }
    }
  },
  watch: {
    typeStructure() {
      this.structureACopier = null
    },
    montrerFacturables(visible) {
      setTimeout(() => {
        if (visible) {
          window.addEventListener("click", this.clickOutFacturables)
        } else {
          window.removeEventListener("click", this.clickOutFacturables)
        }
      }, 10)
    }
  },
  computed: {
    optionsConvives() {
      const convivesTypes = this.$store.state.definitions.convivesTypes && this.$store.state.definitions.convivesTypes.slice() || []
      return convivesTypes
    },
    peutEnregistrer() {
      return !this.$v.plan.nom.required || !this.$v.plan.typeConviveId.required || this.toutEstNan
    },
    estNeutre() {
      return this.plan.typeConviveId === constantesId.typeConviveIdNeutre
    },
    structureUniverselle() {
      return !this.plan.etablissementId;
    },
    toutEstNan() {
      for (let s = 0; s < this.plan.services.length; s++) {
        let service = this.plan.services[s]

        if (!Number.isNaN(service.effectif) && service.effectif != null) {
          return false
        }
        for (let c = 0; c < service.planComposantes.length; c++) {
          let composante = service.planComposantes[c]

          if (composante && !Number.isNaN(composante.effectif) && composante.effectif != null) {
            return false
          }
        }
      }
      return true;
    },
    creationOK() {
      return this.typeStructure === "nouvelle" || this.typeStructure === "universelle" || this.structureACopier
    },
    dataFacturables() {
      return this.facturables.map(f => {
        return {
          Id: f.Id,
          Libelle: f.Libelle
        }
      })
    },
    modifFacturables() {
      return this.facturablesAvant !== JSON.stringify(this.dataFacturables)
    }
  },
  methods: {
    ...mapActions("uxHelpers", ["afficherAttente", "cacherAttente"]),
    afficherModal(planId) {
      this.enEnregistrement = false
      this.montrerFacturables = false
      if (!planId) {//création
        this.typeStructure = null
        this.structureACopier = null
        this.structures = null
        this.planId = null
        this.montrerModalCreation = true
        return chargerStructures(this.etabCourantId)
          .then(r => this.structures = r.data || [])
      }
      this.planId = planId//édition
      this.montrerModalEdition = true
      this.initialiserFenetre()
    },
    creerStructure() {
      this.montrerModalCreation = false
      this.montrerModalEdition = true
      this.initialiserFenetre(this.structureACopier, this.typeStructure === "universelle")
    },
    async initialiserFenetre(aCopier, universelle) {
      this.plan.nom = undefined
      this.plan.typeConviveId = undefined
      this.plan.textureId = undefined
      this.plan.regimeId = undefined
      this.plan.menuStructureOrigineId = undefined

      if (aCopier) {// copie
        await this.getPlanPourEdition(aCopier, universelle)
      } else if (this.planId) {// édition
        await this.getPlanPourEdition(this.planId)
      } else {// création
        this.plan.id = undefined
        this.plan.etablissementId = this.etabCourantId
        this.plan.services = []
        this.plan.gestionParPlan = false
        this.plan.estUniversel = universelle
        this.creerServiceJours()
      }
    },
    setRegimesEtTexturesDesComposantes() {
      if (this.plan.gestionParPlan) {
        this.plan.regimeId = "RE3" // 'normal' à vérifier avec la base en prod
        this.plan.textureId = constantesId.textureNormale
        this.plan.services.map(service => {
          service.planComposantes.map(planCp => {
            planCp.regimeId = "RE3" // 'normal' à vérifier avec la base en prod
            planCp.textureId = constantesId.textureNormale
          })
        })
      }
    },
    setRegimeDesComposantes(event) {
      if (this.plan.gestionParPlan) {
        this.plan.services.map(service => {
          service.planComposantes.map(planCp => {
            planCp.regimeId = event.sender.value()
          })
        })
      }
    },
    setTextureDesComposantes(event) {
      if (this.plan.gestionParPlan) {
        this.plan.services.map(service => {
          service.planComposantes.map(planCp => {
            planCp.textureId = event.sender.value()
          })
        })
      }
    },
    async alertModal(serviceId) {
      let htmlComposante = ""
      let optionsComposantesRepas = null
      if (
        this.plan.services.find(service => service.serviceId === serviceId)
          .planComposantes.length === 0
      ) {
        optionsComposantesRepas = this.optionsComposantesRepas
      } else {
        let optionsDejaPresentes = this.plan.services
          .find(service => service.serviceId === serviceId)
          .planComposantes.map(planCp => {
            return this.optionsComposantesRepas.find(
              comp => comp.value === planCp.composanteId
            )
          })
        optionsComposantesRepas = _.difference(this.optionsComposantesRepas, optionsDejaPresentes)
      }
      optionsComposantesRepas.forEach(element => {
        htmlComposante += `<div style="text-align:left;margin-left:30%"><div class="custom-control custom-checkbox"><input type="checkbox" class="custom-control-input" name="${element.value}" value="${element.value}" id="${element.value}"><label for="${element.value}" class="custom-control-label">${element.text}</label></input></div></div>`
      })
      let checkboxIds = []
      optionsComposantesRepas.forEach(element => {
        checkboxIds.push(element.value)
      })
      const service = serviceId
      const { value: formValues } = await this.$swal.fire({
        title: "Ajouter des composantes",
        html: htmlComposante,
        focusConfirm: false,
        preConfirm: () => {
          return [
            checkboxIds.forEach(id => {
              if (document.getElementById(id).checked) {
                this.composantesSelectionnees.push(
                  document.getElementById(id).value
                )
              }
            })
          ]
        }
      })
      if (formValues) {
        this.ajouterComposante(service)
      }
    },
    composanteRT(event, serviceId, composanteId, index, categorie) {
      let id = event
      // eslint-disable-next-line promise/avoid-new
      return new Promise(resolve => {
        this.plan.services
          .filter(jourDeService => jourDeService.serviceId === serviceId)
          .map(service => {
            service.planComposantes.map(composante => {
              if (
                composante.index === index &&
                composante.composanteId === composanteId
              ) {
                if (categorie === "regime") {
                  composante["regimeId"] = id
                }
                if (categorie === "texture") {
                  composante["textureId"] = id
                }
              }
            })
          })
        resolve(this.plan.services)
      }).then(result => {
        this.plan.services = []
        this.$set(this.plan, "services", result)
        return
      })
    },
    titreService(serviceId) {
      return this.optionsServices.find(opt => opt.value === serviceId).text
    },
    titreComposante(composante) {
      let titre = this.optionsComposantesRepas.find(
        opt => opt.value === composante.composanteId
      ).text
      if (composante.index && composante.index !== 0) {
        titre += " " + (composante.index + 1)
      }
      //titre += "-" + composante.regimeId + "-" + composante.textureId
      return titre
    },
    dupliquerComposante(serviceId, composanteTypeId) {
      let services = this.plan.services.filter(
        service => service.serviceId === serviceId
      )
      services.forEach(service => {
        let indexGeneral = service.planComposantes.length - 1 - service.planComposantes.reverse().findIndex(
          composante => composante.composanteId === composanteTypeId
        )
        let planComposantes = service.planComposantes.reverse().filter(
          composante => composante.composanteId === composanteTypeId
        )
        let newCompo = { ...planComposantes[planComposantes.length - 1] }
        newCompo.id = -1
        newCompo.index++
        newCompo.cleComposee = newCompo.composanteId + "-" + newCompo.index
        if (planComposantes[planComposantes.length - 1].effectif || planComposantes[planComposantes.length - 1].effectif === 0) {
          newCompo.effectif = 0
        }
        service.planComposantes.splice(indexGeneral + 1, 0, newCompo)
        planComposantes.push(newCompo)
      })
    },
    /* dupliquerEffectifs(services, service) {
      services.forEach(s => {
        if (s.cleComposee && s.cleComposee !== service.cleComposee) {
          service.planComposantes.forEach((c, i) => {
            s.planComposantes[i].effectif = c.effectif
          })
        }
      })
    }, */
    planServices(serviceId) {
      let planServices = _.orderBy(this.plan.services.filter(el => el.serviceId === serviceId), ["jour"], ["asc"])
      _.move = function (array, fromIndex, toIndex) {
        array.splice(toIndex, 0, array.splice(fromIndex, 1)[0])
      }
      _.move(planServices, 0, 6)
      return planServices
    },
    toogleService(serviceId) {
      if (
        this.plan.services.filter(el => el.serviceId === serviceId).length !== 0
      ) {
        return !!this.planServices(serviceId).filter(
          s => s.planComposantes.length !== 0
        ).length
      }
    },
    effacerComposante(serviceId, composanteId, index) {
      // eslint-disable-next-line promise/avoid-new
      return new Promise(resolve => {
        this.plan.services.map(service => {
          if (service.serviceId === serviceId) {
            _.remove(service.planComposantes, function (el) {
              return el.composanteId === composanteId && el.index === index
            })
          }
        })
        resolve(this.plan.services)
      }).then(result => {
        this.plan.services = []
        this.$set(this.plan, "services", result)
        return
      })
    },
    serviceComposantesTitres(service) {
      if (!this.arrayEmpty(service)) {
        let ids = service.map(el =>
          el.planComposantes.map(el => el.composanteId)
        )[0] // todo : flat dans l'édition
        return ids.map(
          el =>
            this.$store.state.definitions.composantesRepas.find(
              item => item.value === el
            ).text
        )
      }
    },
    serviceComposantesIds(service) {
      if (!this.arrayEmpty(service)) {
        let ids = service.map(el =>
          el.planComposantes.map(el => el.composanteId)
        )[0] // todo : flat dans l'édition
        return ids.map(el =>
          this.$store.state.definitions.composantesRepas.find(
            item => item.value === el
          )
        )
      }
    },
    ajouterComposante(serviceId) {
      this.plan.services
        .filter(jourDeService => jourDeService.serviceId === serviceId)
        .map(service => {
          this.composantesSelectionnees.map(planComposante => {
            let effectif = (service.effectif || service.effectif === null) ? service.effectif : 0
            let sr = {}
            sr["effectif"] = effectif
            sr["index"] = 0
            sr["composanteId"] = planComposante
            sr["jourServiceId"] = service.jour
            if (this.plan.gestionParPlan) {
              sr["regimeId"] = this.plan.regimeId
              sr["textureId"] = this.plan.textureId
            } else {
              sr["regimeId"] = constantesId.regimeNormal
              sr["textureId"] = constantesId.textureNormale
            }
            service.planComposantes.push(sr)
          })
        })
      this.composantesSelectionnees = []
    },
    status(validation) {
      return {
        error: validation.$error,
        dirty: validation.$dirty
      }
    },
    sauverStructure(plan) {
      this.enEnregistrement = true
      plan.services.forEach(s => {
        if (!s.effectif) {
          s.effectif = s.planComposantes.reduce((t, c) => t += c.effectif || 0, 0)
        }
      })
      _.remove(plan.services, function (el) {
        return !el.effectif
      })
      plan.services.map(service => {
        _.remove(service.planComposantes, function (el) {
          return !el.effectif
        })
      })
      plan.etablissementId = plan.estUniversel ? null : this.etabCourantId
      let requete = plan.id ? modifierMenuPlan(plan) : ajouterMenuPlan(plan)
      // 1. ajouter une structure de menu
      // 2. modifier cette structure de menu à voir si cette fonctionnalité doit exister
      // 3. utiliser cette structure de menu modifié attaché au menu actuel pour en créer un attaché à un nouveau menu la semaine suivante
      // 4. utiliser cette structure de menu modifié attaché au menu actuel pour modifier le menu actuel
      this.afficherAttente()
      requete
        .then(response => {
          this.cacherAttente()
          if (this.$route.name === "Menus") {
            this.alerteSucces("Structure de menu Sauvegardée !")
            this.montrerModalEdition = false
            
            let retour = { planId: plan.id || response.data, nom: this.plan.nom, estPerso: plan.etablissementId }
            this.$emit("retour-ajout-structure", retour)
          } else if (this.$route.name === "MenusPlans") {
            this.alerteSucces("Structure de menu Sauvegardée !")
            this.montrerModalEdition = false
          }
          return
        })
        .catch(error => {
          this.cacherAttente()
          console.log(error)
        })
    },
    exporter(structureId) {
      // this.$store.commit("uxHelpers/showSpinner")
      this.afficherAttente()
      return obtenirMenuPlan(structureId, true).then(reponse => {
        this.cacherAttente()
        lancerTelechargement(new Blob([reponse.data]), this.plan.nom + ".json")
        return
      })
    },
    async getPlanPourEdition(structureId, universelle) {
      const reponse = await obtenirMenuPlan(structureId)
      this.plan = reponse.data
      if (universelle !== undefined) {// nettoyage de la copie
        if (!this.plan.etablissementId) {
          // Seules les structures de menus universelles sont déclinables
          this.plan.menuStructureOrigineId = structureId
        }
        this.plan.nom += " (copie)"
        this.plan.estUniversel = universelle
        delete this.plan.id
        delete this.plan.dateCreation
        delete this.plan.dateModification
        this.plan.services.forEach(service => {
          delete service.id
          delete service.menuPlanId
          delete service.cleComposee
          delete service.decalageJour
          service.planComposantes.forEach(composante => {
            delete composante.id
            delete composante.cleComposee
          })
        })
      }
      this.optionsServices.map(service => {
        let servicesPresents = this.plan.services.filter(
          el => el.serviceId === service.value
        )
        let composantePresentes = []
        servicesPresents.forEach(service => {
          service.planComposantes.forEach(composante => {
            var trouve = composantePresentes.find(
              x => x.cleComposee == composante.cleComposee
            )
            if (!trouve) {
              composantePresentes.push(composante)
            }
          })
        })
        this.optionsJours.map(jour => {
          let serviceActuel = this.plan.services.find(el => el.serviceId === service.value && el.jour === jour.id)

          //let serviceContenantToutesLesComposantes = longestArray(servicesPresents, 'planComposantes')
          if (serviceActuel) {
            // serviceContenantToutesLesComposantes.planComposantes.map(el => {
            composantePresentes.map(compo => {
              if (
                !serviceActuel.planComposantes.find(
                  t => t.cleComposee === compo.cleComposee
                )
              ) {
                // rajouter une composante vierge
                serviceActuel.planComposantes.push({
                  cleComposee: compo.cleComposee,
                  composanteId: compo.composanteId,
                  effectif: null,
                  id: -1,
                  index: compo.index,
                  jourServiceId: compo.jourServiceId,
                  regimeId: compo.regimeId,
                  textureId: compo.textureId
                })
              }
            })
          } else {
            let servicePresent = this.plan.services.find(
              el => el.serviceId === service.value &&
                el.planComposantes &&
                el.planComposantes.length
            )
            if (servicePresent) {
              let planComposantes = _.cloneDeep(servicePresent.planComposantes)
              if (servicePresent.serviceId === service.value)
                planComposantes.forEach(el => {
                  el.effectif = null
                  el.id = -1
                })
              this.plan.services.push({ effectif: null, serviceId: service.value, jour: jour.id, planComposantes: planComposantes })
            } else {
              this.plan.services.push({ effectif: null, serviceId: service.value, jour: jour.id, planComposantes: [] })
            }
          }
        })
      })
    },
    async desactiver(id) {
      let resultat = await this.alerteConfirmation("Désactiver la structure de menu", "Souhaitez-vous masquer définitivement cette structure de menu ?<br />Les menus saisis avec cette structure seront conservés.")
      if (resultat) {
        return desactiverMenuPlan(id)
          .then(() => {
            if (this.$route.name === "MenusPlansEdition") {
              this.$emit("finished")
            } else if (this.$route.name === "Menus") {
              this.montrerModalEdition = false
              this.$emit("structure-supprimee")
            }
            return
          })
      }
    },
    async supprimer(id) {
      let resultat = await this.alerteConfirmation("Supprimer la structure de menu", "Souhaitez-vous supprimer cette structure de menu ?<br />Vous perdrez tous les menus saisis avec cette structure.")
      if (resultat) {
        return supprimerMenuPlan(id)
          .then(() => {
            if (this.$route.name === "MenusPlansEdition") {
              this.$emit("finished")
            } else if (this.$route.name === "Menus") {
              this.montrerModalEdition = false
              this.$emit("structure-supprimee")
            }
            return
          })
      }
    },
    creerServiceJours() {
      // remplit dans this.plan la structure pour tout les services et tout les jours possibles. (si pas déjà présent)
      this.optionsServices.map(service => {
        this.optionsJours.map(jour => {
          const dejaPresent = this.plan.services.find(
            x => x.serviceId == service.value && x.jour == jour.id
          )
          if (!dejaPresent) {
            this.plan.services.push({
              effectif: null,
              serviceId: service.value,
              jour: jour.id,
              planComposantes: []
            })
          }
        })
      })
    },
    initEffectifsJour(serviceId, jourId) {
      var effectifsJour = []
      this.planServices(serviceId).find(service => service.jour === jourId).planComposantes.forEach(composante => {
        var type = effectifsJour.find(e => e.regimeId === composante.regimeId && e.textureId === composante.textureId)
        if (!type) {
          effectifsJour.push({
            regimeId: composante.regimeId,
            textureId: composante.textureId,
            titre: (composante.regimeId === "RE3" &&  composante.textureId === "TE1") ? "Normal" : (this.optionsRegimes.find(r => r.value === composante.regimeId).text + " / " + this.optionsTextures.find(t => t.value === composante.textureId).text),
            effectif: 0
          })
          type = effectifsJour.find(e => e.regimeId === composante.regimeId && e.textureId === composante.textureId)
        }
        if (type.effectif < composante.effectif) {
          type.effectif = composante.effectif
        }
      })
      this.effectifsJour = effectifsJour
      this.keyEffectifsJour += 1
    },
    setEffectifsJour(serviceId, jourId) {
      this.planServices(serviceId).find(service => service.jour === jourId).planComposantes.forEach(composante => {
        composante.effectif = this.effectifsJour.find(e => e.regimeId === composante.regimeId && e.textureId === composante.textureId).effectif || 0
      })
     this.plan.services.find(s => s.serviceId === serviceId && s.jour === jourId).effectif = this.effectifsJour.reduce((total, e) => total + e.effectif, 0)
     this.$bvModal.hide('changerEffectif-' + serviceId + '-' + jourId)
    },
    enregistrerBarquettes(dataBarquettes) {
      var data = this.plan.donneesSupplementaires && JSON.parse(this.plan.donneesSupplementaires.replaceAll("'", "\"")) || {}
      data.Barquettage = dataBarquettes
      this.plan.donneesSupplementaires = JSON.stringify(data).replaceAll("\"", "'")
    },
    async toggleFacturables() {
      if (!this.montrerFacturables) {//préparation des données
        this.facturables = this.plan.donneesSupplementaires && JSON.parse(this.plan.donneesSupplementaires.replaceAll("'", "\""))
        this.facturables = this.facturables && this.facturables.Facturables || []
        this.facturablesAvant = JSON.stringify(this.dataFacturables)
        this.checkFacturables()
        this.keyFacturables += 1
        this.montrerFacturables = true
      } else {
        if (!this.erreurFacturables) {//pas de fermeture en cas d'erreur
          if (this.modifFacturables) {
            let result = await this.alerteConfirmation('Modifications non enregistrées', "Les modifications effectuées sur les effectifs de facturation n'ont pas été appliquées.<br/>Voulez-vous les enregistrer ?")
            if (result) {
              return this.enregistrerFacturables()
            }
          }
          this.montrerFacturables = false
        }
      }
    },
    inputFacturable() {
      this.checkFacturables()
    },
    creerFacturable() {
      this.facturables.push({Id: "", Libelle: ""})
      this.checkFacturables()
      this.keyFacturables += 1
    },
    supprimerFacturable(index) {
      _.remove(this.facturables, (b, i) => i === index)
      this.checkFacturables()
      this.keyFacturables += 1
    },
    checkFacturable(facturable) {
      if (facturable.Libelle.length > 20) {
        facturable.Libelle = facturable.Libelle.substr(0, 20)
      }
      facturable.idValide = Boolean(facturable.Id)
      facturable.libelleValide = Boolean(facturable.Libelle)
    },
    checkFacturables() {
      this.facturables.forEach(this.checkFacturable)
      this.erreurFacturables = this.facturables.filter(b => !b.idValide || !b.libelleValide).length > 0
    },
    enregistrerFacturables() {
      var data = this.plan.donneesSupplementaires && JSON.parse(this.plan.donneesSupplementaires.replaceAll("'", "\"")) || {}
      data.Facturables = this.dataFacturables
      this.plan.donneesSupplementaires = JSON.stringify(data).replaceAll("\"", "'")
      this.montrerFacturables = false
    },
    clickOutFacturables(e) {//fermeture du combo par clic en dehors
      if (this.montrerFacturables && !e.target.closest("#facturables") && !e.target.closest(".swal2-container")) {
        this.toggleFacturables()
      }
    },
    moveEffectif(e, services, iService) {
      if (e.direction === "left" && iService > 0) {
        iService -= 1
      } else if ((e.direction === "right" || e.direction === "enter") && iService < services.length - 1) {
        iService += 1
      }
      let cible = services[iService].serviceId + "-" + services[iService].jour
      cible = this.$refs.effectifs.querySelector("[data-key='" + cible + "']")
      if (cible) {
        cible.focus()
      }
    },
    moveEffectifComposante(e, services, iService, iComposante) {
      if (e.direction === "left" && iService > 0) {
        iService -= 1
      } else if ((e.direction === "right" || e.direction === "enter") && iService < services.length - 1) {
        iService += 1
      } else if (e.direction === "up" && iComposante > 0) {
        iComposante -= 1
      } else if (e.direction === "down" && iComposante < services[iService].planComposantes.length - 1) {
        iComposante += 1
      }
      let cible = services[iService].serviceId + "-" + services[iService].jour + '-' + services[iService].planComposantes[iComposante].cleComposee
      cible = this.$refs.effectifs.querySelector("[data-key='" + cible + "']")
      if (cible) {
        cible.focus()
      }
    },
    onClose() {
      window.removeEventListener("click", this.clickOutFacturables)
      this.$emit("reset-select-plan")
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/css/couleurs.scss";
/* input, button, select, optgroup, textarea {
  width: 100% !important;
} */
.input-plan-composante {
  padding: 0.6rem 0;
}

.container-dupliquer-effacer {
  padding: 0;
  height: 3.21rem;
}
.inline-dropdown {
  width: 70px !important;
}
.ligne-composante-titre {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.21rem;
}
.td-input-container {
  width: 60px;
}
.td-height {
  height: 3.21rem;
}
.table th,
.table td {
  border-bottom: none !important;
}
#facturables {
  position: absolute;
  z-index: 2;
  background: white;
  border: 1px solid black;
  border-radius: 3.5px;
  padding: 8px;
}
#facturables td.erreur {
  border: 1px solid red;
}
.btn-changer-eff {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.21rem;
}
</style>
