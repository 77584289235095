import Vue from 'vue'

const fichesTechniques = {
  namespaced: true,
  state: {
    familleId: '',
    statuses: '',
    termeDeRecherche: ''
  },
  mutations: {
    setRouteEnMemoire(state, payload) {
      Vue.set(state, 'familleId', payload.familleId)
      Vue.set(state, 'statuses', payload.statuses)
      Vue.set(state, 'termeDeRecherche', payload.termeDeRecherche)
    }
  }
}

export default fichesTechniques
