<template>
  <div>
    <div v-if="aPlusieursProfils && !etablissementId" class="form-group required mb-0 pb-0">
      <label>Choisissez votre profil de connexion</label>
    </div>
    <v-select v-if="aPlusieursProfils && !etablissementId" v-model="profilChoisi" :options="optionsProfils" value="profilId" label="libelle"
      placeholder="Profils..." :clearable="false" :disabled="disabled" @input="setProfil($event.profilId)">
    </v-select>
    <div v-if="!etablissementId && (profilChoisi || profilIsUtilisateur)" class="form-group required mb-0 pb-0">
        <label>Choisissez l'établissement sur lequel vous allez travailler...</label>
    </div>
    <v-select v-if="etablissementsCourants && (profilChoisi || profilIsUtilisateur)" v-model="etablissementChoisi" :options="optionsEtablissements" value="eId" label="eRS"
      placeholder="Établissements..." :clearable="false" :disabled="disabled" @input="setEtablissement($event.eId)">
    </v-select>
  </div>
</template>

<script>
import {objetVide} from '@/mixins/index'
import { connecteData } from "@/mixins/connecteData"
export default {
  props: ['etablissementId', 'profilId', 'disabled'],
  mixins: [objetVide, connecteData],
  data() {
    return {
      etablissementChoisi: null,
      profilChoisi: null
    }
  },
  computed: {
    optionsEtablissements() {
      let result = []
      this.etablissementsCourants.map(el => {
        result.push({eId: el.eId, eRS: el.eRS})
      })
      result.sort(function(a,b){  return a.eRS.localeCompare(b.eRS)})
      return result;
    },
    optionsProfils() {
      let result = []
      this.listeProfils.map(el => {
        result.push({profilId: el.id, libelle: el.libelle})
      })
      result.sort(function(a,b){  return a.libelle.localeCompare(b.libelle)})
      return result;
    }
  },
  methods: {
    setEtablissement(event) {
      if (event) {
        this.$emit('etablissement-selectionne', event)
      }
    },
    setProfil(event) {
      if (event) {
        this.$emit('profil-selectionne', event)
        if (event != 'UtilisateurGPAO')
          this.setEtablissement(this.etablissementsCourants[0].eId)
      }
    }
  },
  mounted() {
    if (this.etablissementId) {
      this.etablissementChoisi = this.optionsEtablissements.find(e => e.eId === this.etablissementId)
    }
    if (this.profilId) {
      this.profilChoisi = this.optionsProfils.find(e => e.profilId === this.profilId)
    }
  }
}
</script>

<style>

</style>
