<template>
  <div>

    <ModalAjoutProduitHM ref="modalAjoutProduit" :listeFamillesFournisseurs="produitsHMtab" :produit="produitSelectionne" :fournisseur="tarifSelectionne" :paramModale="paramModale" @produit-modifie="chargerProduits(produitSelectionne.produit.produitId, produitSelectionne.famille.familleId)" />

    <!-- Contenu de la page -->
    <div class="container-fluid">
      <BarreActions ref="barreActions" :actions="actions" @appliquer-action="executerAction($event)" />

      <div class="card table-scroll height-responsive-100">
        <div class="card-header flex-center-space-between">
          <div class="flex-start">
            <span>{{ titreProduit() }}</span>
          </div>
        </div>
        <table class="table table-bordered table-sm text-center table-header-secondary">
          <colgroup>
            <col width="165">
            <col>
            <col width="100">
            <col width="100">
            <col width="100">
            <col width="100">
            <col width="255">
          </colgroup>
          <thead>
            <th class="aligner-gauche">Fournisseur</th>
            <th class="aligner-gauche">Désignation</th>
            <th>Marque</th>
            <th>Référence</th>
            <th>P.U.</th>
            <th>PCB</th>
            <th>Allergènes</th>
          </thead>
        </table>
        <div class="table-body">
          <table class="table table-bordered table-sm text-center">
            <colgroup>
              <col width="165">
              <col>
              <col width="100">
              <col width="100">
              <col width="100">
              <col width="100">
              <col width="250">
            </colgroup>
            <tbody>
              <tr :class="[{'ligne-selectionnee':tarif.tarifId === idTarifSelectionne}]" v-for="(tarif, iTarif) in listeTarifs" :key="iTarif" @click="selectionnerTarif(tarif)">
                <template>
                <td class="aligner-gauche">{{ nomFournisseur(tarif.fournisseurId) }}</td>
                <td class="aligner-gauche">{{ tarif.designation }}</td>
                <td>{{ tarif.marque }}</td>
                <td>{{ tarif.reference }}</td>
                <td>{{ tarif.pu }} €</td>
                <td>{{ tarif.pcb }} {{ produitsHM.produits[0].uc }}</td>
                <td>
                  {{ formatAllergene(tarif) }}
                  <em v-if="sansAllergene(tarif)" class="error ml-2">
                    <i class="fas fa-exclamation-triangle ligne-modifiee"></i> Produit sans allergene
                  </em>
                </td>
              </template>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarreActions from '@/components/Divers/BarreActions'
import ModalAjoutProduitHM from '@/components/Divers/ModalAjoutProduitHM'
import { connecteData } from "@/mixins/connecteData"
import { showAlerts } from "@/mixins/alerts"
import { obtenirDonneesUnProduitHM, desactiverTarifHM } from '@/api/gpao/fournisseurs'
import { mapGetters } from "vuex"

export default {
  name: "DetailProduitsHM",
  components: {
    BarreActions,
    ModalAjoutProduitHM,
  },
  mixins: [connecteData, showAlerts],
  data() {
    return {
      produitsHM: {},
      produitsHMtab: [],
      listeTarifs : [],
      isLoading: false,
      afficheNonAlimentaire: false,
      idTarifSelectionne: "",
      paramModale: {},
      actions: {
        // Boutons à afficher dans la barre d'action
        // Exemple :
        /*
        nomAction: {
          label: Label affiché sur le bouton (string),
          disabled: Etat actif/inactif par défaut du bouton (bool),
          class: Classe CSS (btn-primary, btn-success, btn-warning ou btn-danger) à appliquer sur le bouton (par défaut : "btn-primary" est appliqué => il s'agit du bouton bleu Agap'pro) (string)
          methode(contexte) {
            // => contexte équivaut à this (mais this ne fonctionne pas car l'initialisation de data n'est pas encore terminée, donc data n'est pas encore rattaché à this)
            traitements à exécuter lors du clic sur le bouton
            les traitements définis dans cette page doivent être appelés sous la forme : contexte.traitement()

            Remarque : pour que le système fonctionne, il faut déclarer la méthode ci-dessous dans la section "methods" de cette page
            executerAction(methode) {
              methode(this)
            }

          }
        }
        */
        creer: {
          label: "Ajouter un fournisseur",
          methode(contexte) {
            contexte.creerTarif()
          }
        },
        ouvrir: {
          label: "Modifier",
          disabled: true,
          methode(contexte) {
            let cmd = contexte.tarifSelectionne
            if (cmd) {
              contexte.modifierDetailTarif()
            }
          }
        },
        supprimer: {
          label: "Supprimer",
          disabled: true,
          class: "btn-danger",
          methode(contexte) {
            let cmd = contexte.tarifSelectionne
            if (cmd) {
              contexte.supprimerTarif()
            }
          }
        },
        retour: {
          label: "Retour",
          methode(contexte) {
            contexte.$router.push({ name: "ProduitsHorsMarche", params: { etablissementId: contexte.etabCourantId } })
          }
        },
        // Appeler "this.actions.initialiser(this)"" dans mounted() => génère les tableaux d'actions utilisés pour afficher les boutons à gauche et à droite de la barre d'actions
        barreGauche: [],
        barreDroite: [],
        initialiser(contexte) {
          contexte.actions.barreGauche = [ contexte.actions.creer, contexte.actions.ouvrir ]
          contexte.actions.barreDroite = [ contexte.actions.retour, contexte.actions.supprimer ]
        },
        // A gérer en cas de boutons à activer/désactiver (créer une méthode par groupe de boutons à activer/désactiver)
        disable(actions, disabled) {
          let actionsATraiter = [ actions.ouvrir, actions.supprimer ]
          actionsATraiter.forEach(a => a.disabled = disabled)
        }
      }
    }
  },
  computed: {
    ...mapGetters("fournisseurs", ["nomFournisseur", "francoFournisseur"]),
    produitSelectionne() {
      let prod = {}
      if (this.produitsHM && this.produitsHM.produits && this.produitsHM.produits.length > 0) {
        prod.produit = this.produitsHM.produits[0] ?? {}
        prod.famille = this.produitsHM.famille ?? {}
      }
      return prod
    },
    tarifSelectionne() {
      return this.listeTarifs?.find(c => c.tarifId === this.idTarifSelectionne) ?? {}
    },
    
  },
  methods: {
    reinitialiser() {

    },
    titreProduit() {
      if (this.produitsHM && this.produitsHM.famille && this.produitsHM.produits) {
        return this.produitsHM.famille.code + " - " + this.produitsHM.produits[0].designation
      }
    },
    sansAllergene(tarif) {
      return !tarif.aucunAllergene && tarif.allergenesMajeurs.length === 0
    },
    formatAllergene(tarif) {
      if (this.sansAllergene(tarif))
        return ""
      else
        return tarif.aucunAllergene && tarif.allergenesMajeurs.length === 0 ? "Aucun" : tarif.allergenesMajeurs.join(", ")
    },
    selectionnerTarif(tarif) {
      if (tarif.tarifId === this.idTarifSelectionne) {
        // La ligne est déjà sélectionnée, on l'ouvre
        this.modifierDetailTarif()
        return
      }
      this.idTarifSelectionne = tarif.tarifId
      this.actions.disable(this.actions, !this.tarifSelectionne)
    },
    modifierDetailTarif() {
      this.paramModale.modeAffichage = "modifierTarif"
      //let tmp = this
      this.$refs.modalAjoutProduit.show()
    },
    creerTarif() {
      this.paramModale.modeAffichage = "ajoutTarif"
      //let tmp = this
      this.$refs.modalAjoutProduit.show()
    },
    async supprimerTarif() {
      let result = await this.alerteConfirmation("Supprimer le tarif", "Souhaitez-vous supprimer ce tarif fournisseur pour le produit " + this.produitsHM.produits[0].designation + " ? La suppression est définitive.")
      if (result) {
        await desactiverTarifHM(this.idTarifSelectionne)
        await this.chargerProduits(this.produitSelectionne.produit.produitId, this.produitSelectionne.famille.familleId)
      }
    },
    async chargerProduits(produitId, familleId) {
      this.isLoading = true
      this.$store.commit('uxHelpers/showSpinner')
      this.produitsHM = {}
      this.produitsHMtab = []
      
      
      let response = await obtenirDonneesUnProduitHM(familleId, produitId, this.etabCourantId)
      if (!response) {
        this.$store.commit('uxHelpers/hideSpinner')
        this.isLoading = false
        return
      }
      this.produitsHM = response.data
      this.produitsHMtab.push(this.produitsHM)
      this.listeTarifs = response.data.produits[0].tarifs
      this.$store.commit('uxHelpers/hideSpinner')
      this.isLoading = false
    },
    executerAction(methode) {
      // Nécessaire pour faire fonctionner les actions de la barre d'action
      methode(this)
    }
  },
  async mounted() {
    this.actions.initialiser(this)
    await this.chargerProduits(this.$route.params.produitId, this.$route.params.familleId)
  }
}
</script>


