<template>
  <b-modal ref="modalNouvelleCommande" size="xl" title="Créer une commande"
    ok-title="Créer" :ok-disabled="!$v.optionsCreation.nom.required || !$v.optionsCreation.nom.minLength || ajoutKO"
    cancel-title="Annuler"
    no-close-on-backdrop
    @show="initialiserModale()"
    @shown="$refs.nomCommandeCreation.focus()"
    @ok="creerCommande()">
    <template slot="default">
      <!--<form @submit="creerCommande()">-->
      <div class="form-group">
        <label for="nomCommande">Nom de la commande</label>
        <input class="form-control" type="text" id="nomCommande" ref="nomCommandeCreation" v-model.trim="optionsCreation.nom" maxlength="80" @focus="$event.target.select()">
        <div class="error" v-if="!$v.optionsCreation.nom.required">Ce champ est requis</div>
        <div class="error" v-if="!$v.optionsCreation.nom.minLength">Ce champ doit contenir au moins 3 caractères</div>
      </div>
      <div class="form-group">
        <label for="datesMenus">Calculer à partir des menus de la période :</label>
        <SelectDatesMenusPlansServices id="datesMenus" :multiLigne="true" :dateDebut="optionsCreation.dateDebut" :dateFin="optionsCreation.dateFin" :menuPlanIds="optionsCreation.menusPlansIds" :serviceIds="optionsCreation.servicesIds" viderMenusPlans="Vider" @changer-dms="changerParametresMenus($event)" />
      </div>
      <!--</form>-->
    </template>
  </b-modal>
</template>

<script>
import { ajouterCommande } from '@/api/gpao/commandes'
import { connecteData } from "@/mixins/connecteData"
import { showAlerts } from '@/mixins/alerts'
import { required, minLength, maxLength } from "vuelidate/lib/validators"
import SelectDatesMenusPlansServices from '@/components/Divers/SelectDatesMenusPlansServices'

export default {
  name: "ModalCreationCommande",
  mixins: [ connecteData, showAlerts ],
  props: [ "commande" ],
  components: {
    SelectDatesMenusPlansServices
  },
  data() {
    return {
      key: 1,
      optionsCreation: {}
    }
  },
  validations: {
    optionsCreation: {
      nom: {
        required,
        minLength: minLength(3),
        maxlength: maxLength(80)
      }
    }
  },
  computed: {
    ajoutKO() {
      this.key
      return this.optionsCreation.dateDebut && (this.optionsCreation.menusPlansIds.length === 0 || this.optionsCreation.servicesIds.length ===0)
    }
  },
  methods: {
    initialiserModale() {
      this.optionsCreation = {
        nom: ""
      }
    },
    changerParametresMenus(parametres) {
      this.optionsCreation.dateDebut = parametres.dateDebut != "" ? parametres.dateDebut : null
      this.optionsCreation.dateFin = parametres.dateFin != "" ? parametres.dateFin : null
      this.optionsCreation.menusPlansIds = parametres.menuPlanIds
      this.optionsCreation.servicesIds = parametres.serviceIds
      this.optionsCreation.depuisBesoins = this.optionsCreation.dateDebut && this.optionsCreation.dateFin && this.optionsCreation.menusPlansIds.length
      this.key++
    },
    async creerCommande() {
      this.$store.commit('uxHelpers/showSpinner')
      let response = await ajouterCommande(this.etabCourantId, this.optionsCreation)
      this.hide()
      this.alerteSucces("Commande crée !")
      this.$emit("commande-creee", response.data)
      this.$store.commit('uxHelpers/hideSpinner')
    },
    show() {
      this.$refs.modalNouvelleCommande.show()
    },
    hide() {
      this.$refs.modalNouvelleCommande.hide()
    }
  }
}
</script>
