<template>
  <div>
    <ModalCreationCommande ref="modalCreationCommande" :commande="commandeSelectionnee" @commande-creee="commandeAjoutee($event)" />
    <ModalRenommageCommande ref="modalRenommageCommande" :commande="commandeSelectionnee" @commande-renommee="commandeRenommee()" />
    <ModalDuplicationCommande ref="modalDuplicationCommande" :commande="commandeSelectionnee" @commande-dupliquee="commandeDupliquee($event)" />

    <!-- Contenu de la page -->
    <div class="container-fluid">
      <BarreFiltres :filtres="filtres" @appliquer-filtre="appliquerFiltre($event)" />
      <BarreActions :actions="actions" @appliquer-action="executerAction($event)" />

      <div class="card table-scroll height-responsive-100">
        <table class="table table-bordered table-sm text-center table-header"> <!-- style="display:block;max-height:800px;overflow:auto" -->
        <!--<table class="table table-bordered text-center">-->
          <colgroup>
            <col>
            <col width="130">
            <col width="170">
            <col width="170">
            <col width="80">
            <col width="120">
            <col width="105">
          </colgroup>
          <thead>
            <th class="aligner-gauche">Nom de la commande</th>
            <th>Date de création</th>
            <th>Période de livraisons</th>
            <th>Période de menus</th>
            <th>Lignes</th>
            <th v-tooltip="{ content: 'Nombre de commandes fournisseurs par statut :<br/>à envoyer / envoyés / réceptionnés' }">Fournisseurs</th>
            <th>Total</th>
          </thead>
        </table>
        <div class="table-body">
          <table class="table table-bordered table-sm text-center"> <!-- style="display:block;max-height:800px;overflow:auto" -->
          <!--<table class="table table-bordered text-center">-->
            <colgroup>
              <col>
              <col width="130">
              <col width="170">
              <col width="170">
              <col width="80">
              <col width="120">
              <col width="100">
            </colgroup>
            <tbody>
              <tr :class="[{'ligne-selectionnee':commande.id === idCommandeSelectionnee}, {'ligne-desactivee':!commande.nbFournisseursEnCreation && !commande.nbFournisseursEnvoyes && commande.nbFournisseursReceptionnes}]" v-for="(commande, iCommande) in commandesFiltrees" :key="iCommande" @click="selectionnerCommande(commande)">
                <td :class="['aligner-gauche', {'text-success':!commande.nbFournisseursEnCreation && commande.nbFournisseursEnvoyes}]">
                  <i v-if="commande.nbFournisseursEnCreation || !commande.nbLignes" class="fas fa-shopping-cart" v-tooltip="{ content: 'Commande en cours d\'élaboration'}"></i>
                  <i v-else-if="commande.nbFournisseursEnvoyes" class="fas fa-shipping-fast ligne-modifiee" v-tooltip="{ content: 'Commande envoyée au fournisseur, en attente de livraison'}"></i>
                  <i v-else class="fas fa-boxes ligne-modifiee" v-tooltip="{ content: 'Commande réceptionnée'}"></i>
                  {{ commande.nom }}
                  <span v-if="commande.contientErreurMail" class="error ml-2"> <i class="fas fa-exclamation-triangle"></i> Erreur de mail</span>
                  <span v-if="commandeEnAttenteDeValidation(commande)" class="text-warning ml-2"> <i class="fas fa-exclamation-triangle"></i> En attente de validation par le gestionnaire</span>
                  <span v-if="commandeValidee(commande)" class="text-success ml-2"> <i class="fas fa-info-circle"></i> Validée par le gestionnaire</span>
                </td>
                <td>{{ formatHumanDateAvecSlashes(new Date(commande.dateCreation)) }}</td>
                <td>{{ datesLivraisons(commande.dateLivraisonDebut, commande.dateLivraisonFin) }}</td>
                <td>{{ datesMenus(commande.dateMenuDebut, commande.dateMenuFin) }}</td>
                <td>{{ commande.nbLignes }} / {{ commande.nbLignesTotal }}</td>
                <td v-tooltip="{ content: 'A envoyer / Envoyés / Réceptionnés' }">
                  <span v-if="commande.nbFournisseursEnCreation || !commande.nbLignes"><i class="fas fa-shopping-cart"></i> {{ commande.nbFournisseursEnCreation }} / </span>
                  <span v-if="commande.nbFournisseursEnCreation || !commande.nbLignes || commande.nbFournisseursEnvoyes"><span class="text-success"><i class="fas fa-shipping-fast ligne-modifiee"></i> {{ commande.nbFournisseursEnvoyes }}</span> / </span>
                  <span class="ligne-desactivee"><i class="fas fa-boxes ligne-modifiee"></i> {{ commande.nbFournisseursReceptionnes }}</span>
                </td>
                <td class="aligner-droite">{{ commande.totalHT | afficherPrix2 }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarreFiltres from '@/components/Divers/BarreFiltres'
import BarreActions from '@/components/Divers/BarreActions'
import ModalCreationCommande from '@/components/Commandes/ModalCreationCommande'
import ModalRenommageCommande from '@/components/Commandes/ModalRenommageCommande'
import ModalDuplicationCommande from '@/components/Commandes/ModalDuplicationCommande'
import { chargerCommandes, supprimerCommande } from '@/api/gpao/commandes'
import { showAlerts } from '@/mixins/alerts'
import { connecteData } from "@/mixins/connecteData"
import { formatageDate, livreLe, formatHumanDateAvecSlashes } from '@/mixins/dateFormats'
import { constantesId } from "@/helpers/constantes"

export default {
  name: "ListeDesCommandes",
  mixins: [ connecteData, showAlerts, formatageDate, formatHumanDateAvecSlashes, livreLe ],
  components: {
    BarreFiltres,
    BarreActions,
    ModalCreationCommande,
    ModalRenommageCommande,
    ModalDuplicationCommande
  },
  data() {
    return {
      commandes: [],
      commandesFiltrees: [],
      idCommandeSelectionnee: "",
      filtres: {
        statuts: {
          label: "Statut(s) de commandes",
          champ: "select",
          type: "multi",
          options: [
            {
              valeur: constantesId.etatCmdEnCours,
              texte: "A envoyer"
            },
            {
              valeur: constantesId.etatCmdEnvoyee,
              texte: "Envoyée"
            },
            {
              valeur: constantesId.etatCmdRecue,
              texte: "Reçue"
            }],
          selection: [],
          initialiser(contexte) {
            contexte.filtres.statuts.selection = contexte.$store.state.commandes.filtresEnMemoire.statutCommandeEnMemoire || [ constantesId.etatCmdEnCours, constantesId.etatCmdEnvoyee ]
          },
          filtrer(contexte, commande) {
            if (commande) {
              // Appelé par "AppliquerFiltres" ci-dessous pour déterminer si chaque commande est conforme au filtre ou non
              let filtre = contexte.filtres.statuts
              if (!filtre.selection?.length) {
                return true
              }
              if (filtre.selection?.find(f => f === constantesId.etatCmdEnCours) && (commande.nbFournisseursEnCreation || commande.nbFournisseursEnCreation + commande.nbFournisseursEnvoyes + commande.nbFournisseursReceptionnes === 0)) {
                return true
              }
              if (filtre.selection?.find(f => f === constantesId.etatCmdEnvoyee) && commande.nbFournisseursEnvoyes) {
                return true
              }
              if (filtre.selection?.find(f => f === constantesId.etatCmdRecue) && commande.nbFournisseursReceptionnes) {
                return true
              }
              return false
            } else {
              // Filtre de la liste des commande
              contexte.appliquerFiltres()
            }
          }
        },
        dateCreation: {
          label: "Date de création",
          champ: "select",
          width: "200px",
          options: [
            {
              valeur: 0,
              texte: "Toutes"
            },
            {
              valeur: 30,
              texte: "30 derniers jours"
            },
            {
              valeur: 90,
              texte: "3 derniers mois"
            },
            {
              valeur: 365,
              texte: "12 derniers mois"
            }],
          selection: null,
          initialiser(contexte) {
            contexte.filtres.dateCreation.selection = contexte.$store.state.commandes.filtresEnMemoire.dateCreationEnMemoire
          },
          filtrer(contexte, commande) {
            if (commande) {
              // Appelé par "AppliquerFiltres" ci-dessous pour déterminer si chaque commande est conforme au filtre ou non
              let selection = contexte.filtres.dateCreation.selection?.valeur
              if (!selection) {
                return true
              }
              let periodeSelectionnee = new Date(commande.dateCreation)
              periodeSelectionnee.setDate(periodeSelectionnee.getDate() + selection)
              return contexte.periodesCompatibles(commande.dateCreation, periodeSelectionnee, new Date(), new Date())
            } else {
              // Filtre de la liste des commande
              contexte.appliquerFiltres()
            }
          }
        },
        periodeLivraisons: {
          label: "Période de livraisons",
          champ: "datepicker",
          type: "multi",
          selection: null,
          initialiser(contexte) {
            contexte.filtres.periodeLivraisons.selection = contexte.$store.state.commandes.filtresEnMemoire.periodeLivraisonEnMemoire
          },
          filtrer(contexte, commande) {
            if (commande) {
              // Appelé par "AppliquerFiltres" ci-dessous pour déterminer si chaque commande est conforme au filtre ou non
              return contexte.filtrerPeriode(contexte.filtres.periodeLivraisons, commande.dateLivraisonDebut, commande.dateLivraisonFin)
            } else {
              // Filtre de la liste des commande
              contexte.appliquerFiltres()
            }
          }
        },
        periodeMenus: {
          label: "Période de menus",
          champ: "datepicker",
          type: "multi",
          selection: null,
          initialiser(contexte) {
            contexte.filtres.periodeMenus.selection = contexte.$store.state.commandes.filtresEnMemoire.periodeMenuEnMemoire
          },
          filtrer(contexte, commande) {
            if (commande) {
              // Appelé par "AppliquerFiltres" ci-dessous pour déterminer si chaque commande est conforme au filtre ou non
              return contexte.filtrerPeriode(contexte.filtres.periodeMenus, commande.dateMenuDebut, commande.dateMenuFin)
            } else {
              // Filtre de la liste des commande
              contexte.appliquerFiltres()
            }
          }
        },
        reinitialiser: {
          label: "Réinitialiser les filtres",
          champ: "btn",
          filtrer(contexte) {
            contexte.$store.commit('commandes/setFiltresEnMemoire', {key: 'statutCommandeEnMemoire', data: null})
            contexte.$store.commit('commandes/setFiltresEnMemoire', {key: 'dateCreationEnMemoire', data: null})
            contexte.$store.commit('commandes/setFiltresEnMemoire', {key: 'periodeLivraisonEnMemoire', data: null})
            contexte.$store.commit('commandes/setFiltresEnMemoire', {key: 'periodeMenuEnMemoire', data: null})
            contexte.filtres.barreGauche.forEach(f => {
              if (f.initialiser) {
                f.initialiser(contexte)
              }
            })
            contexte.appliquerFiltres()
          }
        },
        // Appeler "this.filtres.initialiser(this)" dans mounted() => génère le tableau de filtres utilisés pour afficher les filtres dans la barre de filtres
        barreGauche: [],
        barreDroite: [],
        initialiser(contexte) {
          contexte.filtres.barreGauche = [ contexte.filtres.statuts, contexte.filtres.dateCreation, contexte.filtres.periodeLivraisons, contexte.filtres.periodeMenus ]
          contexte.filtres.barreDroite = [ contexte.filtres.reinitialiser ]
          contexte.filtres.barreGauche.forEach(f => {
            if (f.initialiser) {
              f.initialiser(contexte)
            }
          })
          //contexte.filtres.reinitialiser.filtrer(contexte)
        }
      },
      actions: {
        // Boutons à afficher dans la barre d'action
        // Exemple :
        /*
        nomAction: {
          label: Label affiché sur le bouton (string),
          disabled: Etat actif/inactif par défaut du bouton (bool),
          class: Classe CSS (btn-primary, btn-success, btn-warning ou btn-danger) à appliquer sur le bouton (par défaut : "btn-primary" est appliqué => il s'agit du bouton bleu Agap'pro) (string)
          methode(contexte) {
            // => contexte équivaut à this (mais this ne fonctionne pas car l'initialisation de data n'est pas encore terminée, donc data n'est pas encore rattaché à this)
            traitements à exécuter lors du clic sur le bouton
            les traitements définis dans cette page doivent être appelés sous la forme : contexte.traitement()

            Remarque : pour que le système fonctionne, il faut déclarer la méthode ci-dessous dans la section "methods" de cette page
            executerAction(methode) {
              methode(this)
            }

          }
        }
        */
        creer: {
          label: "Créer une nouvelle commande",
          methode(contexte) {
            contexte.$refs.modalCreationCommande.show()
          }
        },
        ouvrir: {
          label: "Ouvrir",
          disabled: true,
          methode(contexte) {
            let cmd = contexte.commandeSelectionnee
            if (cmd) {
              contexte.ouvrirDetailCommande(cmd)
            }
          }
        },
        renommer: {
          label: "Renommer",
          disabled: true,
          methode(contexte) {
            let cmd = contexte.commandeSelectionnee
            if (cmd) {
              contexte.$refs.modalRenommageCommande.show()
            }
          }
        },
        dupliquer: {
          label: "Dupliquer",
          disabled: true,
          methode(contexte) {
            let cmd = contexte.commandeSelectionnee
            if (cmd) {
              contexte.$refs.modalDuplicationCommande.show()
            }
          }
        },
        supprimer: {
          label: "Supprimer",
          disabled: true,
          class: "btn-danger",
          methode(contexte) {
            let cmd = contexte.commandeSelectionnee
            if (cmd) {
              contexte.suppressionCommande(cmd)
            }
          }
        },
        // Appeler "this.actions.initialiser(this)"" dans mounted() => génère les tableaux d'actions utilisés pour afficher les boutons à gauche et à droite de la barre d'actions
        barreGauche: [],
        barreDroite: [],
        initialiser(contexte) {
          contexte.actions.barreGauche = [ contexte.actions.creer ]
          contexte.actions.barreDroite = [ contexte.actions.ouvrir, contexte.actions.renommer, contexte.actions.dupliquer, contexte.actions.supprimer ]
        },
        // A gérer en cas de boutons à activer/désactiver (créer une méthode par groupe de boutons à activer/désactiver)
        disable(actions, disabled) {
          let actionsATraiter = [ actions.ouvrir, actions.renommer, actions.dupliquer, actions.supprimer ]
          actionsATraiter.forEach(a => a.disabled = disabled)
        }
      }
    }
  },
  computed: {
    commandeSelectionnee() {
      return this.commandesFiltrees?.find(c => c.id === this.idCommandeSelectionnee)
    }
  },
  methods: {
    appliquerFiltres() {
      this.commandesFiltrees = this.commandes.filter(c => !this.filtres.barreGauche.find(f => !f.filtrer(this, c)))
      if (this.idCommandeSelectionnee && !this.commandesFiltrees.find(c => c.id === this.idCommandeSelectionnee)) {
        this.idCommandeSelectionnee = ""
        this.actions.disable(this.actions, !this.commandeSelectionnee)
      }
    },
    appliquerFiltre(filtrer) {
      // Nécessaire pour faire fonctionner les filtres de la barre de filtres
      filtrer(this)
    },
    executerAction(methode) {
      // Nécessaire pour faire fonctionner les actions de la barre d'actions'
      methode(this)
    },
    commandeEnAttenteDeValidation(cmd) {
    //Par défaut, si l'utilisateur n'a pas l'option attenteValidation, une commande n'est jamais en attente de validation
      if (this.estAttenteValidationCommande)
        return cmd?.commandeEnAttenteValidation
      else
        return false
    },
    commandeValidee(cmd) {
    //Par défaut, si l'utilisateur n'a pas l'option attenteValidation, une commande n'est jamais validée
    if (this.estAttenteValidationCommande)
        return cmd?.commandeValidee
      else
        return false
    },
    datesLivraisons(dateLivraisonMin, dateLivraisonMax) {
      let dateMin = this.formatHumanDateAvecSlashes(dateLivraisonMin)
      if (!dateMin) {
        // La commande ne contient pas de date de livraison
        return ""
      }
      let dateMax = this.formatHumanDateAvecSlashes(dateLivraisonMax)
      if (dateMin !== dateMax) {
        // On affiche la période si la date de début et la date de fin sont différentes
        return dateMin + " - " + dateMax
      }
      // Ici la date de début et la date de fin sont identiques, on n'affiche qu'une date
      return dateMin
    },
    datesMenus(dateMenuDebut, dateMenuFin) {
      let dateDebut = this.formatHumanDateAvecSlashes(dateMenuDebut)
      let dateFin = this.formatHumanDateAvecSlashes(dateMenuFin)

      let dates = dateDebut || ""
      if (dates && dateFin && dateFin !== dateDebut) {
        dates = dates + " - " + dateFin
      }
      return dates
    },
    async chargerCommandes() {
      this.$store.commit('uxHelpers/showSpinner')

      this.commandes = []
      let response = await chargerCommandes(this.etabCourantId, {
        Statuts: [ constantesId.etatCmdEnCours, constantesId.etatCmdEnvoyee, constantesId.etatCmdRecue ]
      })
      if (!response) {
        this.$store.commit('uxHelpers/hideSpinner')
        return
      }
      this.commandes = response.data
      this.appliquerFiltres()

      this.$store.commit('uxHelpers/hideSpinner')
    },
    selectionnerCommande(commande) {
      if (commande.id === this.idCommandeSelectionnee) {
        // La commande est déjà sélectionnée, on l'ouvre
        this.ouvrirDetailCommande(commande)
        return
      }
      this.idCommandeSelectionnee = commande.id
      this.actions.disable(this.actions, !this.commandeSelectionnee)
    },
    filtrerPeriode(filtre, dateDebut, dateFin) {
      if (!filtre?.selection?.start && !filtre?.selection?.end) {
        return true
      }
      return this.periodesCompatibles(filtre?.selection?.start, filtre?.selection?.end, dateDebut, dateFin)
    },
    commandeAjoutee(commande) {
      this.ouvrirDetailCommande(commande)
    },
    ouvrirDetailCommande(commande) {
      let destination = {
        name: "DetailCommande",
        params: { commandeId: commande.id }
      }
      this.$router.push(destination)
    },
    commandeRenommee() {
      this.chargerCommandes()
    },
    commandeDupliquee(resultat) {
      if (resultat.etablissementId === this.etabCourantId) {
        this.ouvrirDetailCommande(resultat.commande)
      }
    },
    async suppressionCommande(commande) {
      let result = await this.alerteConfirmationSuppression("Supprimer la commande ?", "Souhaitez-vous supprimer définitivement la commande '" + commande.nom + "' ?")
      if (result) {
        // eslint-disable-next-line promise/no-nesting
        await supprimerCommande(commande.id)
        this.chargerCommandes()
        this.alerteSucces('Commande supprimée !')
      }
    },
  },
  mounted() {
    this.filtres.initialiser(this)
    this.actions.initialiser(this)
    this.chargerCommandes()
  },
  beforeRouteLeave (to, from, next) {
    this.$store.commit('commandes/setFiltresEnMemoire', {key: 'statutCommandeEnMemoire', data: this.filtres.statuts.selection})
    this.$store.commit('commandes/setFiltresEnMemoire', {key: 'dateCreationEnMemoire', data: this.filtres.dateCreation.selection})
    this.$store.commit('commandes/setFiltresEnMemoire', {key: 'periodeLivraisonEnMemoire', data: this.filtres.periodeLivraisons.selection})
    this.$store.commit('commandes/setFiltresEnMemoire', {key: 'periodeMenuEnMemoire', data: this.filtres.periodeMenus.selection})
    next();
  }
}
</script>
