<template>
  <div>
    <nav class="header-navbar navbar-expand-md navbar navbar-semi-dark fixed-top">
      <div class="navbar-wrapper">
        <div class="navbar-container content">
          <div class="collapse navbar-collapse" id="navbar-mobile">
            <ul class="nav navbar-nav mr-auto float-left">
              <li @click="goHome()">
                <img class="logo" alt="mercudyn" src="../assets/images/logo/logo-mercudyn-RVB-72pp.png" />
              </li>

              <li :class="['nav-item',{'nav-item-btn':estMenuPrincipalSelectionne(entree)}]" v-for="entree in entrees" :key="entree.nom" :title="entree.aide">
                <div class="nav-item-principal">
                  <template v-if="entree.url">
                    <router-link :to="entree.url">
                      <span :class="[{'nav-item-selectionne':estMenuPrincipalSelectionne(entree)}]" aria-hidden="true">
                        {{ entree.titre }}
                        <i v-if="estMenuPrincipalSelectionne(entree) && entreesSecondairesFiltrees(menuPrincipalSelectionne).length" class="fas fa-chevron-down m-0 p-0"></i>
                      </span>
                    </router-link>
                  </template>
                </div>
                <div v-if="estMenuPrincipalSelectionne(entree) && entreesSecondairesFiltrees(menuPrincipalSelectionne).length" role="navigation" data-menu="menu-wrapper" data-nav="brand-center" class="navbar-expand-sm navbar navbar-horizontal navbar-light navbar-fixed navbar-secondaire">
                  <div data-menu="menu-container" class="main-menu-content center-layout">
                    <ul class="nav navbar-nav" data-menu="menu-navigation">
                      <li :class="['nav-item',{'nav-item-btn':estSelectionne(entree)}]" v-for="entree in entreesSecondairesFiltrees(menuPrincipalSelectionne)" :key="entree.nom" :title="entree.aide">
                        <template v-if="entree.url">
                          <!-- le menu Mercuriales est désactivé tant que les mercuriales ne sont pas chargées dans le store -->
                          <router-link :to="entree.url" :class="entree.url.name === 'Mercuriales' && !mercurialesChargees ? 'disabled' : ''" :event="entree.url.name === 'Mercuriales' && !mercurialesChargees ? '' : 'click'">
                            <span :class="[{'nav-item-selectionne':estSelectionne(entree)}]" aria-hidden="true">
                              {{ entree.titre }}
                            </span>
                          </router-link>
                        </template>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
            
            <ul class="nav navbar-nav float-right">
              <li v-if="environnement !== 'prod'" class="nav-item mt-2 mr-3" :title="buildToolTip">
                <span class="text-danger">Environnement {{environnement}}</span>
              </li>
              <li v-if="utilisateurCourant && etablissementsCourants && etablissementsCourants.length > 1 && this.profilIsUtilisateur" class="mr-2">
                <SelectEtablissement :disabled="!peutChangerEtablissement()" :etablissementId="etabCourantId" @etablissement-selectionne="changerEtablissement($event)" :key="userId" title="Etablissement en cours" style="min-width:180px;max-width:450px" />
              </li>
              <li v-else-if="this.profilIsUtilisateur && utilisateurCourant && $route.name !== 'Profil' &&  (etablissementsCourants && etablissementsCourants.length == 1)" class="nav-item mt-2 mr-3">
                <span>{{userEtablissementUniqueERS}}</span>
              </li>
              <li v-else-if="!this.profilIsUtilisateur" class="nav-item mt-2 mr-3">
                <span class="text-danger">{{this.profilIsAdmin ? "Profil Administrateur" : ("Profil Lecteur" + (this.profilLecteurMercu ? ' [' + this.profilLecteurMercu + ']' : ''))}}</span>
              </li>
              <li class="nav-item" style="position:relative;">
                <span id="headwayapp" class="nav-link" href=""><i class="ft-bell"></i></span>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{name: 'Profil'}" :title="'Mon profil ' + userName"><i class="ft-user"></i></router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{name: 'Configuration'}"><i class="ft-settings" title="Configuration"></i></router-link>
              </li>
              <li class="nav-item">
                <a class="nav-link" @click="logout" title="Déconnexion"><i class="ft-log-out"></i></a>
              </li>
            </ul>
          </div>
          <div v-if="entreesSecondairesFiltrees(menuPrincipalSelectionne).length" class="navbar-expand-sm navbar navbar-horizontal navbar-light navbar-fixed navbar-secondaire">
          </div>
        </div>
      </div>
    </nav>
    <div v-if="entreesSecondairesFiltrees(menuPrincipalSelectionne).length" class="navbar-double"></div>
  </div>
</template>

<script>
import SelectEtablissement from '@/components/Etablissements/SelectEtablissement'
import { AUTH_LOGOUT } from "../store/actions/auth"
import { connecteData } from "@/mixins/connecteData"
import { chargerMercuriales } from '@/helpers/mercuriales'
import { chargerDonneesEtablissement } from '@/helpers/definitions'
import { mapGetters } from "vuex"
import lscache from 'lscache'

export default {
  name: "topbar",
  components: {
    SelectEtablissement
  },
  mixins: [ connecteData],
  data() {
    return {
      buildToolTip: `API: ${this.environnementApiDateBuild} \n` + this.versionFront()
    }
  },
  computed: {
    ...mapGetters("uxHelpers", ["mercurialesChargees"]),
    ...mapGetters("definitions", ["environnement", "environnementProduction", "environnementApiDateBuild"]),
    entrees() {
      let entrees = []
      if (!this.profilIsLecteur) {
        entrees = [
          {
            nom: "Menus",
            titre: "Menus",
            aide: "Menus",
            url: { name: 'Menus', params: { etablissementId: this.etabCourantId } }
          }
        ]
        if (!this.profilIsAdmin) {
          entrees.push(
            {
              nom: "Commandes",
              titre: "Commandes",
              aide: "Commandes",
              url: { name: 'ListeDesCommandes', params: { etablissementId: this.etabCourantId } }
            },
            {
              nom: "Stock",
              titre: "Stock",
              aide: "Stock",
              url: { name: 'Stock', params: { etablissementId: this.etabCourantId } }
            },
            {
              nom: "Productions",
              titre: "Productions",
              aide: "Productions",
              url: { name: 'SuiviProduction', params: { etablissementId: this.etabCourantId } }
            },
            {
              nom: "Fournisseurs",
              titre: "Fournisseurs",
              aide: "Fournisseurs",
              url: { name: 'ActivationFournisseurs', params: { etablissementId: this.etabCourantId } }
            }
          )
        }
        if (this.profilIsAdmin) {
          entrees.unshift(
            {
              nom: "Nutrition",
              titre: "Nutrition",
              aide: "Nutrition",
              url: { name: 'PlansAlimentaires', params: { etablissementId: this.etabCourantId } }
            }
          )
          entrees.push(
            {
              nom: "DonneesProduitsAgap",
              titre: "Produits Agap'pro",
              aide: "Produits Agap'pro",
              url: { name: 'FichesTechniques' }
            },
            {
              nom: "PageTechnique",
              titre: "Page technique",
              aide: "Page technique",
              url: { name: 'Configurations' }
            }
          )
        }
      } else {
        entrees.push(
            {
              nom: "Mercuriale",
              titre: "Mercuriale",
              aide: "Mercuriale",
              url: { name: 'Mercuriales', params: { etablissementId: this.etabCourantId } }
            })
      }
      return entrees
    },
    entreesSecondaires() {
      let entreesSecondaires = [
        {
          nom: "SuiviMenus",
          nomParent: "Menus",
          titre: "Suivi",
          aide: "Suivi des menus",
          url: { name: 'Menus', params: { etablissementId: this.etabCourantId } }
        },
        {
          nom: "SaisieEffectifs",
          nomParent: "Menus",
          titre: "Saisie des effectifs",
          aide: "Saisie des effectifs",
          url: { name: 'EffectifsPortage', params: { etablissementId: this.etabCourantId } }
        },
        {
          nom: "Recettes",
          nomParent: "Menus",
          titre: "Recettes",
          aide: "Recettes",
          url: { name: 'Recettes', params: { etablissementId: this.etabCourantId } },
          urlsCompatibles: [ "RecetteEdition", "RecetteDupliquer" ]
        },
        {
          nom: "Aide",
          nomParent: "Menus",
          titre: "Aide",
          aide: "Aide",
          url: { name: 'DocMenus' },
        },
        {
          nom: "SuiviCommandes",
          nomParent: "Commandes",
          titre: "Suivi",
          aide: "Suivi des commandes",
          url: { name: 'ListeDesCommandes', params: { etablissementId: this.etabCourantId } },
          urlsCompatibles: [ "DetailCommande", "DetailCommandeFournisseur" ]
        },
        {
          nom: "Mercuriale",
          nomParent: "Commandes",
          titre: "Mercuriale",
          aide: "Mercuriale",
          url: { name: 'Mercuriales', params: { etablissementId: this.etabCourantId } }
        },
        {
          nom: "Speciaux",
          nomParent: "Commandes",
          titre: "Spéciaux",
          aide: "Speciaux",
          url: { name: 'Speciaux', params: { etablissementId: this.etabCourantId } }
        }
      ]

      if (this.estAutoriseProduitsHM) {
        entreesSecondaires.push(
          {
            nom: "HorsMarche",
            nomParent: "Commandes",
            titre: "Produits hors marché",
            aide: "Produits hors marché",
            url: { name: 'ProduitsHorsMarche' },
            urlsCompatibles: [ "DetailProduitHM" ]
          }
        )
      }
      
      entreesSecondaires.push(
        {
          nom: "Aide",
          nomParent: "Commandes",
          titre: "Aide",
          aide: "Aide",
          url: { name: 'DocCommandes'},
        },
        /*{
          nom: "ReclamationsAvoirs",
          nomParent: "Commandes",
          titre: "Réclamations & avoirs",
          aide: "Réclamations & avoirs",
          url: { name: 'Reclamations', params: { etablissementId: this.etabCourantId } }
        },*/
        {
          nom: "Stock",
          nomParent: "Stock",
          titre: "Stock",
          aide: "Stock",
          url: { name: 'Stock', params: { etablissementId: this.etabCourantId } }
        },
        {
          nom: "Inventaire",
          nomParent: "Stock",
          titre: "Inventaire",
          aide: "Inventaire",
          url: { name: 'Inventaire', params: { etablissementId: this.etabCourantId } }
        },
        {
          nom: "MouvementsStock",
          nomParent: "Stock",
          titre: "Mouvements",
          aide: "Mouvements de stock",
          url: { name: 'MouvementsStock', params: { etablissementId: this.etabCourantId } }
        },
        {
          nom: "Aide",
          nomParent: "Stock",
          titre: "Aide",
          aide: "Aide",
          url: { name: 'DocStock' },
        },
        {
          nom: "SuiviProductions",
          nomParent: "Productions",
          titre: "Suivi",
          aide: "Suivi des productions",
          url: { name: 'SuiviProduction', params: { etablissementId: this.etabCourantId } }
        },
        {
          nom: "ArchiveProductions",
          nomParent: "Productions",
          titre: "Archive",
          aide: "Productions archivées",
          url: { name: 'SuiviProductionArchive', params: { etablissementId: this.etabCourantId } }
        },
        {
          nom: "Aide",
          nomParent: "Productions",
          titre: "Aide",
          aide: "Aide",
          url: { name: 'DocProductions' },
        },
        {
          nom: "Activation",
          nomParent: "Fournisseurs",
          titre: "Activation",
          aide: "Activation",
          url: { name: 'ActivationFournisseurs', params: { etablissementId: this.etabCourantId } }
        },
        {
          nom: "Configuration",
          nomParent: "Fournisseurs",
          titre: "Configuration",
          aide: "Configuration",
          url: { name: 'Fournisseurs', params: { etablissementId: this.etabCourantId } }
        },        
        {
          nom: "Aide",
          nomParent: "Fournisseurs",
          titre: "Aide",
          aide: "Aide",
          url: { name: 'DocFournisseurs' }
        },
        /*,
        {
          nom: "Fournisseurs",
          nomParent: "Fournisseurs",
          titre: "Fournisseurs",
          aide: "Fournisseurs",
          url: { name: 'Fournisseurs', params: { etablissementId: this.etabCourantId } }
        }*/
      )
      
      if (this.profilIsAdmin) {
        entreesSecondaires.push(
          {
            nom: "PlansAlimentaires",
            nomParent: "Nutrition",
            titre: "Plans alimentaires",
            aide: "Plans alimentaires",
            url: { name: 'PlansAlimentaires', params: { etablissementId: this.etabCourantId } }
          },
          {
            nom: "CategoriesGEMRCN",
            nomParent: "Nutrition",
            titre: "Catégories GEMRCN",
            aide: "Catégories GEMRCN",
            url: { name: 'CategoriesGEMRCN', params: { etablissementId: this.etabCourantId } }
          }/*,
          {
            nom: "icone-allergenes iconePanneau",
            titre: "Imports des allergènes",
            url: { name: 'Allergenes' }
          }*/
        )
      }
      return entreesSecondaires
    },
    userName() {
      return this.utilisateurCourant && this.utilisateurCourant.userName
    },
    userEtablissementUniqueERS() {
      return this.etablissementsCourants[0].eRS
    },
    menuPrincipalSelectionne() {
      let entreeSelectionnee = this.entrees.find(e => this.estSelectionne(e))
      if (entreeSelectionnee)
        return entreeSelectionnee
      let entreeSecondaireSelectionnee = this.entreesSecondaires.find(e => this.estSelectionne(e))
      if (entreeSecondaireSelectionnee)
        return this.entrees.find(e => e.nom === entreeSecondaireSelectionnee.nomParent)
      return null
    }
  },
  methods: {
    goHome() {
      this.$router.push("/")
    },
    async logout() {
      await this.$store.dispatch("auth/" + AUTH_LOGOUT)
      this.$router.push({ name: "Home" })
    },
    versionFront() {
      return `FRONT: ${document.documentElement.dataset.buildTimestamp} (ici => +${new Date().getTimezoneOffset() / (-60)}H)`
    },
    changerEtablissement(etablissementId) {
      if (this.etabCourantId != etablissementId) {
        this.$store.commit('etablissements/setEtablissement', { etablissementId: etablissementId, userId: this.$store.state.user.userInfo.id })
        if (this.$route.params["etablissementId"]) {
          this.$route.params["etablissementId"] = etablissementId
          this.$router.replace({ name: this.$route.name, params: this.$route.params })
          lscache.flush()
        }
        return chargerDonneesEtablissement(this.etabCourantId)
          .then(function () {
            chargerMercuriales(this.etabCourantId, true, null, this.profilLecteurMercu)
          }.bind(this))
      }
    },
    estMenuPrincipalSelectionne(entree) {
      return entree?.nom === this.menuPrincipalSelectionne?.nom
    },
    estSelectionne(entree) {
      if (entree.url.name) {
        return this.$route.name === entree.url.name || entree.urlsCompatibles?.find(u => u === this.$route.name)
      }
      return this.$route.path === entree.url
    },
    entreesSecondairesFiltrees(entree) {
      if (!entree)
        return []
      let entreesFiltrees = this.entreesSecondaires.filter(e => e.nomParent === entree.nom)
      if (!this.etabCourant || !this.etabCourant.aLieuPortageActif) {
        entreesFiltrees = entreesFiltrees.filter(e => {
          if (e.url) {
            return e.url.name != "EffectifsPortage";
          }
          else {
            return e;
          }
        })
      }
      return entreesFiltrees
    },
    peutChangerEtablissement() {
      return this.$route.name != "DetailCommande" && this.$route.name != "DetailCommandeFournisseur"
        && this.$route.name != "RecetteEdition" && this.$route.name != "RecetteDupliquer"
    }
  },
  mounted() {
  }
}
</script>
