<template>
  <div>
    <RecetteListe :recetteList="liste" :allowMultiAction="profilIsAdmin"
      @set-terme-de-recherche="keepTermeDeRecherche($event)"
      @set-categories="keepCategorieIds($event)"
      @set-composantes="keepComposanteIds($event)"
      @set-type-de-recherche="keepTypeDeRecherche($event)"
      />
  </div>
</template>

<script>
import RecetteListe from '@/components/Recettes/RecetteListe'
import { chargerToutes } from '@/api/gpao/recettes'
import { connecteData } from "@/mixins/connecteData"
export default {
  components: { RecetteListe },
  mixins: [connecteData],
  data() {
    return {
      liste: [],
      categorieIds: [],
      composanteIds: [],
      termeDeRecherche: '',
      typeDeRecherche: '',
    }
  },
  methods: {
    keepTermeDeRecherche(terme) {
      this.termeDeRecherche = terme
    },
    keepTypeDeRecherche(type) {
      this.typeDeRecherche = type
    },
    keepCategorieIds(ids) {
      this.categorieIds = ids
    },
    keepComposanteIds(ids) {
      this.composanteIds = ids
    },
   
  },
  created() {
    return chargerToutes(this.etabCourantId)
      .then((response) => {
        this.liste = response.data
        return response
      })
      .catch((error) => {
        console.log(error)
      })
  },
  mounted() {
    this.categorieIds = this.$store.state.recettes.filtresEnMemoire.categorieIdsEnMemoire
    this.composanteIds = this.$store.state.recettes.filtresEnMemoire.composanteIdsEnMemoire
    this.termeDeRecherche = this.$store.state.recettes.filtresEnMemoire.termeDeRechercheEnMemoire
    this.typeDeRecherche = this.$store.state.recettes.filtresEnMemoire.typeDeRechercheEnMemoire
  },
  beforeRouteLeave (to, from, next) {
    this.$store.commit('recettes/setFiltresEnMemoire', {key: 'categorieIdsEnMemoire', data: this.categorieIds})
    this.$store.commit('recettes/setFiltresEnMemoire', {key: 'composanteIdsEnMemoire', data: this.composanteIds})
    this.$store.commit('recettes/setFiltresEnMemoire', {key: 'termeDeRechercheEnMemoire', data: this.termeDeRecherche})
    this.$store.commit('recettes/setFiltresEnMemoire', {key: 'typeDeRechercheEnMemoire', data: this.typeDeRecherche})
    next();
  }
}
</script>

<style>
</style>
