<template>
  <span>
    <b-button class="btn btn-primary" :size="size" @click="show" title='Fiche technique'>
      <i class="fas fa-book-open"></i>
    </b-button>
    <b-modal  v-model="afficherModale" :size="sizeModal" title="Fiche technique" hide-footer
      :content-class="contentClass">
      <template slot="default">
        <div v-if="https" class="container-fluid">
          <object :data="url" type="application/pdf">
            <div>
              <p>Un problème a été rencontré lors de l'ouverture de la fiche technique.</p>
              <p>Vous pouvez cliquer sur le lien suivant pour la télécharger :</p>
              <a :href="url" target="_blank" rel="noopener noreferrer" @click="hide" @click.right="hide" @click.middle="hide">{{url}}</a>
            </div>
          </object>
        </div>
        <div v-else class="container-fluid">
          <p>Pour des raisons dépendantes du site du fournisseur, nous ne pouvons afficher directement la fiche technique.</p>
          <p>Vous pouvez cliquer sur le lien suivant pour la télécharger :</p>
          <a :href="url" target="_blank" rel="noopener noreferrer" @click="hide" @click.right="hide" @click.middle="hide">{{url}}</a>
          <p></p>
        </div>
      </template>
    </b-modal>
  </span>
</template>

<script>
import { obtenirUrlFt } from "@/api/gpao/fournisseurs"
export default {
  name: "boutonFicheTechnique",
  props: ["fournisseurId", "reference", "urlFT", "size"],
  data() {
    return {
      afficherModale: false,
      https: false,
      sizeModal: "lg",
      contentClass:"",
      id: "",
      url: ""
    }
  },
  methods: {
    show() {
      var me = this
      !(function() {
        if (me.urlFT && me.urlFT.startsWith("http")) {
          return Promise.resolve(me.urlFT)
        }
        if (me.urlFT) {
          return Promise.resolve("https://admin.mercudyn.com" + me.urlFT)
        }
        return obtenirUrlFt(me.fournisseurId, me.reference).then(result => {
          return result.data
        })
      })()
        .then(url => {
          me.url = url
          this.afficherModale = true
          this.https = url.startsWith("https")
          this.sizeModal = this.https ? "xl" : "lg"
          this.contentClass = this.https ? "md-ft" : ""
          return
        })
    },
    hide() {
      this.afficherModale = false
    }  
  },
  mounted() {
    this.id = this.fournisseurId + "_" + this.reference
  }
}
</script>

<style>
.md-ft {
  height: 94vh;
}
.md-ft .container-fluid,
.md-ft object {
  height: 100%;
  width: 100%;
}
</style>
