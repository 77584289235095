<template>
  <b-modal v-if="tarif" v-model="afficherModal" size="lg" :title="title" hide-footer>
    <template slot="default">
      <div class="container-fluid" :key="key">
        <div class="row">
          <div class="col-12 flex-center-space-between">
            <div class="form-group">
              <label for="codeProduit">Code produit</label>
              <input id="codeProduit" class="form-control" type="text" v-model="tarif.Reference" readonly>
            </div>
            <BoutonFicheTechnique v-if="tarif.idFourn && tarif.Reference" class="flex-end" :fournisseurId="tarif.idFourn" :reference="tarif.Reference" />
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="designation">Désignation produit du fournisseur</label>
              <input id="designation" class="form-control" type="text" v-model="tarif.Designation" readonly>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="marque">Marque</label>
              <input id="marque" class="form-control" type="text" v-model="tarif.Marque" readonly>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="ingredient">Ingrédient</label>
              <input id="ingredient" class="form-control" type="text" v-model="ingredient.nom" readonly>
            </div>
          </div>

          <div class="col-12">
            <div class="flex-start">
              <div class="label-input">PCB :</div>
              <InputNumber v-model="tarif.PCB" typeFiltre="quantite" style="width:50px" readonly />
              <div class="input-group-append">
                <span class="input-group-text">{{plurielUC(tarif.PCB, prod.UC)}}</span>
              </div>
              <div class="label-input" style="margin-left:15px;">PU HT :</div>
              <InputNumber v-model="tarif.PU" typeFiltre="montant" style="width:70px" readonly />
              <div class="input-group-append">
                <span class="input-group-text">€{{ this.unitePrix(1) }}</span>
              </div>
              
              <div class="label-input" style="margin-left:15px;">Montant HT :</div>
              <InputNumber v-model="tarif.PHT" typeFiltre="montant" style="width:90px" readonly />
              <div class="input-group-append">
                <span class="input-group-text">€{{ this.unitePrix(tarif.PCB) }}</span>
              </div>
            </div>
          </div>




          <div class="col-12"><!-- v-if="tarif && (tarif.parPCE || tarif.parKg || tarif.parL)" -->
            <div class="flex-end" style="margin-top:15px;">
              <div class="input-group-prepend">
                <span class="input-group-text ">Dans {{tarif.PCB}} {{plurielUC(tarif.PCB, prod.UC)}} je vais avoir </span>
              </div>
              <InputNumber :value="arrondiNombre(tarif.parPCE * tarif.PCB, 3)" typeFiltre="quantite" class="saisieRepartition" style="width:60px;" readonly />
              <div class="input-group-append">
                <span class="input-group-text">{{ plurielUC(tarif.parPCE * tarif.PCB, 'Pièce') + ', '}}</span>
              </div>
              <InputNumber :value="arrondiNombre(tarif.parKg * tarif.PCB, 3)" typeFiltre="quantite" class="saisieRepartition" style="width:60px;" readonly />
              <div class="input-group-append">
                <span class="input-group-text"><i v-if="estInvalidePour('KG',tarif.parKg)" class="error fas fa-exclamation-circle" v-b-tooltip.hover title="Cette valeur devrait être 1"></i>Kg, </span>
              </div>
              <InputNumber :value="arrondiNombre(tarif.parL * tarif.PCB, 3)" typeFiltre="quantite" class="saisieRepartition" style="width:60px;" readonly />
              <div class="input-group-append">
                <span class="input-group-text"><i v-if="estInvalidePour('L',tarif.parL)" class="error fas fa-exclamation-circle" v-b-tooltip.hover title="Cette valeur devrait être 1"></i>{{ plurielUC(tarif.parL * tarif.PCB, 'Litre') + '.'}}</span>
              </div>
            </div>
            <div class="error" v-if="!tarif.parPCE && !tarif.parKg && !tarif.parL">Attention, les répartitions doivent être renseignées pour être prises en compte dans les calculs automatiques</div>
            <div class="flex-end" style="margin-top:3px;">
              <p>{{phraseConversion(tarif, prod, tarif.PCB)}}</p>
            </div>
            <div class="flex-end">
              <p>{{phraseConversion(tarif, prod, 1.5 * tarif.PCB)}}</p>
            </div>
          </div>

        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import InputNumber from '@/components/Inputs/InputNumber'
import BoutonFicheTechnique from '@/components/Divers/BoutonFicheTechnique'
import { arrondiMercuriale, arrondiNombre, plurielUC, phraseConversion } from '@/helpers/utils'
import { obtenirTarif } from "@/api/gpao/fournisseurs"
import { connecteData } from '@/mixins/connecteData'
import { mapGetters } from "vuex"

export default {
  name: "DetailsTarif",
  mixins: [ connecteData ],
  components: {InputNumber, BoutonFicheTechnique},
  data() {
    return {
      afficherModal: false,
      title: "",
      idProd: "",
      key: 1,
      prod: {},
      tarif: {},
      ingredient: {}
    }
  },
  computed: {
    ...mapGetters("definitions", ["trouverIngredient"]),
    ...mapGetters("fournisseurs", ["getFournisseur"])
  },
  methods: {
    plurielUC(val, uc) {
      return plurielUC(val, uc)
    },
    arrondiNombre(value, dec) {
      return arrondiNombre(value, dec)
    },
    unitePrix(pcb) {
      if (pcb) {
        return " / " + pcb + " " + this.plurielUC(pcb, this.prod.UC)
      } else {
        return " €"
      }
    },
    afficher(data) {//appel externe
      this.prod = data.prod
      this.tarif = data.tarif
      if (!this.prod || !this.tarif) {
        return
      }
      this.tarif.PU = arrondiMercuriale(this.tarif.PrixAchat)
      this.tarif.PHT = arrondiMercuriale(this.tarif.PrixAchat * this.tarif.PCB)
      this.title = this.getFournisseur(data.idFourn).nomCourt + " : " + this.prod.Designation
      this.ingredient = this.trouverIngredient(data.idProd) || {}
      this.key += 1
      this.afficherModal = true
      return obtenirTarif(this.tarif.idTarif)
        .then(r => {
          this.tarif.parKg = r.data.item2.parKg
          this.tarif.parPCE = r.data.item2.parPCE
          this.tarif.parL = r.data.item2.parL
          this.key += 1
          return
        })
      
    },
    phraseConversion(tarif, produit, qteCmd) {
      //pcb, uc, nbPCE, nbKg, nbL, qteCmd
      return phraseConversion(tarif.PCB, produit.UC, arrondiNombre(tarif.parPCE * tarif.PCB, 3), arrondiNombre(tarif.parKg * tarif.PCB, 3), arrondiNombre(tarif.parL * tarif.PCB, 3), qteCmd)
    },
    estInvalidePour(up, repartition) {
      if (!this.prod.UC)
        return false
      if (up !== this.prod.UC.toUpperCase()) {
        return false;
      }
      return repartition != 1;
    }
  }
}
</script>

<style scoped>
.form-control {
  box-shadow: 0 2px 2px 1px rgb(0 0 0 / 6%);
  border: none !important;
  border-radius: 0 !important;
  height: 31px;
  padding-left: 1rem;
  color: #4f565c;
  background-color: white;
}
.form-control:focus-visible {
  outline-offset: 0px;
  outline: -webkit-focus-ring-color auto 1px;
}
</style>
