<template>
  <div style="display:inline">
    <div class="card-body">
      <div class="table-responsive mb-0">
        <table class="table table-sm table-bordered">
          <thead>  
            <tr>
              <th>Type</th>
              <th>Date</th>
              <th>Quantité</th>
              <th>Stock</th>
              <th>Date conso.</th>
              <th>Service</th>
              <th>Commentaire</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(ligne, index) in listeMouvements" :key="index" :style="ligne.inventaire ? 'background-color:#eefbff' : ''">
              <td style="vertical-align:middle;width:200px">
                {{ligne.type}}
              </td>
              <td style="vertical-align:middle;width:150px">
                {{formatHumanDate(ligne.date)}}
              </td>
              <td style="vertical-align:middle;width:100px">
                <div v-if="!ligne.inventaire" class="flex-end"><span v-if="ligne.entree">+</span>{{ligne.quantite | afficherArrondi}} {{ligne.uc}}</div>
              </td>
              <td>
                {{ ligne.stock | afficherArrondi }} {{ligne.uc}}
              </td>
              <td>
                <span>{{ formatHumanDate(ligne.dateMenu) }}</span>
              </td>
              <td>
                <span>{{ ligne.service }}</span>
              </td>
              <td style="vertical-align:middle">
                {{ligne.commentaire}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { formatageDate } from '@/mixins/dateFormats'
import { connecteData } from "@/mixins/connecteData"
import { obtenirMouvementsStockLot, obtenirMouvementsStockLotADate, obtenirMouvementsStockLotPeriode } from '@/api/gpao/stock'
import { arrondiNombre } from '@/helpers/utils'
import { showAlerts } from '@/mixins/alerts'

export default {
  name: "MvtsStockListe",
  props: ["lot", "quantiteInitiale", "debut", "fin", "avecDetails"],
  mixins: [formatageDate, connecteData, showAlerts],
  data() {
    return {
      listeMouvements: [],
      qteStockAvant: 0
    }
  },
  methods: {
    async afficherMouvements() {
      if (!this.lot)
        return
      let mouvements = []
      let response = null
      if (this.debut && this.fin) {
        response = await obtenirMouvementsStockLotPeriode(this.lot.id, this.formatDateAvecTirets(this.debut), this.formatDateAvecTirets(this.fin), this.etabCourantId, this.avecDetails ?? false)
        response.data.unshift({ type: "Stock initial", date: this.debut, quantite: this.quantiteInitiale ?? 0, uc: this.lot.uc, inventaire: true, lot: this.lot })
      } else if (this.debut) {
        response = await obtenirMouvementsStockLotADate(this.lot.id, this.formatDateAvecTirets(this.debut), this.etabCourantId, this.avecDetails ?? false)
      } else {
        response = await obtenirMouvementsStockLot(this.lot.id, this.etabCourantId, this.avecDetails ?? false)
      }
      mouvements = response.data
      if (this.avecDetails) {
        mouvements = mouvements.filter(m => ((m.lot?.NumLot ?? '') === (this.lot?.NumLot ?? '')) && ((m.lot?.dlcDluo ?? '') === (this.lot?.dlcDluo ?? '')))
      }
      this.listeMouvements = mouvements

      let qteStock = this.quantiteInitiale ?? 0
      this.listeMouvements.sort((a, b) => this.dateSeule(a.date) < this.dateSeule(b.date) ? -1 : 1)
      this.listeMouvements.forEach(m => {
        if (m.inventaire) {
          qteStock = 0
        }
        qteStock = arrondiNombre(qteStock + m.quantite)
        m.stock = qteStock
      })
    }
  },
  mounted() {
    this.afficherMouvements()
  }
}
</script>
