import Vue from "vue"
import Router from "vue-router"
import Home from "../views/Home"
//import Login from "../views/Login"
import SuiviProduction from "../views/Production/PageProduction"
import Recettes from "../views/Recettes/PageRecettesListe"
import RecetteEdition from "../views/Recettes/PageRecetteEdition"
import Menus from "../views/Menus/PageMenusListe"
import MenusPlans from "../views/Menus/Plans/PagePlansListe"
import MenusPlansEdition from "../components/Menus/PlanEdition"
import Mercuriales from "../views/Mercuriales/PageMercuriales"
import Fournisseurs from "../views/Mercuriales/PageFournisseurs"
import FichesTechniques from "../views/Administration/PageFichesTechniques"
import Allergenes from "../views/Administration/PageImportAllergenes"
import Configurations from "../views/Configurations"
import Configuration from "../views/Configuration"
import Profil from "../views/Profil"
import EnConstruction from "../views/EnConstruction"
import NotFound from "../views/NotFound"
import Spinner from "../components/Spinner"
import ListeDesCommandes from '../views/Commandes/ListeDesCommandes'
import DetailCommande from '../views/Commandes/DetailCommande'
import DetailCommandeFournisseur from '../views/Commandes/DetailCommandeFournisseur'
import DocCommandes from '../views/Commandes/PageDocCommandesAide'
import Reclamations from '../views/Commandes/PageReclamations'
import Stock from '../views/Stocks/PageStock'
import Inventaire from '../views/Stocks/PageInventaire'
import DocStock from '../views/Stocks/PageDocStockAide'
import store from "../store"
import effectifPortage from "../views/Menus/PageEffectifPortage"
import MouvementsStock from '../views/Stocks/PageMouvementsStock'
import CategoriesGEMRCN from '../views/GEMRCN/PageCategoriesGEMRCN'
import PlansAlimentaires from '../views/Menus/PagePlansAlimentaires'
import PlanAlimentaire from '../views/Menus/PagePlanAlimentaireEdition'
import Speciaux from "../views/Administration/PageSpeciaux"
import ProduitsHorsMarche from "../views/Commandes/PageProduitsHorsMarche"
import DetailProduitHM from '../views/Commandes/DetailProduitsHorsMarche'

import DocMenus from '../views/Menus/PageDocMenusAide'
import DocProductions from '../views/Production/PageDocProductionsAide'
import ActivationFournisseurs from '../views/Mercuriales/PageFournisseursActivation'
import DocFournisseurs from '../views/Mercuriales/PageDocFournisseursAide'

import lscache from 'lscache'

Vue.use(Router)

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Home",
      meta: {
        title: "Accueil",
        message: "Bienvenue"
      },
      component: Home
    },
    {
      path: "/Home", redirect: { name: "Home" }
    },
    {
      path: "/spinner",
      name: "Spinner",
      meta: { title: "Chargement..." },
      component: Spinner
    },
    {
      path: "/en-construction",
      name: "EnConstruction",
      meta: { title: "En construction" },
      component: EnConstruction
    },
    {
      path: "/profil",
      name: "Profil",
      meta: { title: "Mon profil" },
      component: Profil
    },
    {
      path: "/configuration",
      name: "Configuration",
      meta: { title: "Configuration" },
      component: Configuration
    },
    {
      path: "/:etablissementId/liste-des-commandes/:dateDebut?/:dateFin?/:fournisseurs?/:etat?",
      name: "ListeDesCommandes",
      meta: { title: "Liste des commandes" },
      component: ListeDesCommandes,
      props: true
    },
    {
      path: "/:etablissementId/detail-commande/:commandeId",
      name: "DetailCommande",
      meta: { title: "Détail de la commande" },
      component: DetailCommande,
      props: true
    },
    {
      path: "/:etablissementId/detail-commande-fournisseur/:commandeId/:fournisseurId",
      name: "DetailCommandeFournisseur",
      meta: { title: "Détail de la commande fournisseur" },
      component: DetailCommandeFournisseur,
      props: true
    },
    {
      path: "/doc-commandes",
      name: "DocCommandes",
      meta: { title: "Documentation page commandes" },
      component: DocCommandes  
    },
    {
      path: "/:etablissementId/reclamations",
      name: "Reclamations",
      meta: { title: "Réclamations et avoirs" },
      component: Reclamations,
      props: true
    },
    {
      path: "/:etablissementId/stock",
      name: "Stock",
      meta: { title: "Stock" },
      component: Stock
    },
    {
      path: "/:etablissementId/mouvementsstock",
      name: "MouvementsStock",
      meta: { title: "Mouvements de stock" },
      component: MouvementsStock
    },
    {
      path: "/doc-stock",
      name: "DocStock",
      meta: { title: "Documentation page stock" },
      component: DocStock
    },
    {
      path: "/:etablissementId/inventaire",
      name: "Inventaire",
      meta: { title: "Inventaire" },
      component: Inventaire
    },
    {
      path: "/:etablissementId/speciaux",
      name: "Speciaux",
      meta: { title: "Liste des documents spéciaux" },
      component: Speciaux
    },
    {
      path: "/fiches-techniques/:familleId?/:statuses?/:termeDeRecherche?",
      name: "FichesTechniques",
      meta: { title: "Données produits" },
      component: FichesTechniques
    },
    {
      path: "/produitsHorsMarche",
      name: "ProduitsHorsMarche",
      meta: { title: "Données produits" },
      component: ProduitsHorsMarche,
      props: true
    },
    {
      path: "/detail-produit/:familleId/:produitId",
      name: "DetailProduitHM",
      meta: { title: "Détail du produit Hors Marché" },
      component: DetailProduitHM,
      props: true
    },
    {
      path: "/allergenes",
      name: "Allergenes",
      meta: { title: "Allergènes" },
      component: Allergenes
    },
    {
      path: "/admin-configurations",
      name: "Configurations",
      meta: { title: "Configurations Admin" },
      component: Configurations
    },
    {
      path: "/:etablissementId/recettes",
      name: "Recettes",
      component: Recettes,
      meta: { title: 'Recettes' }
    },
    {
      path: "/:etablissementId/effectifs/:pour?",
      name: "EffectifsPortage",
      component: effectifPortage,
      props:true,
      meta: { title: 'Portage : saisie des effectifs' }
    },
    {
      path: "/:etablissementId/recettes/edition/:recetteId?",
      name: "RecetteEdition",
      meta: { title: "Edition d'une recette" },
      component: RecetteEdition
    },
    {
      path: "/:etablissementId/recettes/dupliquer/:recetteId",
      name: "RecetteDupliquer",
      meta: { title: "Edition d'une recette dupliquée" },
      component: RecetteEdition
    },
    {
      path: "/:etablissementId/menus/:date?/:menuId?",
      name: "Menus",
      meta: { title: "Menus & Structures de menus" },
      component: Menus
    },
    {
      path: "/menus/plans",
      name: "MenusPlans",
      meta: { title: "Structures de menu" },
      component: MenusPlans
    },
    {
      path: "/menus/plans/edition/:planId?",
      name: "MenusPlansEdition",
      meta: { title: "Edition d'une structure de menus" },
      component: MenusPlansEdition
    },
    {
      path: "/doc-menus",
      name: "DocMenus",
      meta: { title: "Documentation page menus" },
      component: DocMenus  
    },
    {
      path: "/:etablissementId/mercuriales/:familleCode?/:selectionId?",
      name: "Mercuriales",
      meta: { title: "Mercuriales" },
      component: Mercuriales
    },
    {
      path: "/:etablissementId/SuiviProduction",
      name: "SuiviProduction",
      meta: { title: "Production" },
      component: SuiviProduction
    },
    {
      path: "/:etablissementId/SuiviProduction/Archive",
      name: "SuiviProductionArchive",
      meta: { title: "Productions archivées" },
      component: SuiviProduction,
      props: {
        archive: true
      }
    },
    {
      path: "/doc-productions",
      name: "DocProductions",
      meta: { title: "Documentation page productions" },
      component: DocProductions  
    },
    {
      path: "/:etablissementId/fournisseurs",
      name: "Fournisseurs",
      meta: { title: "Mes fournisseurs" },
      component: Fournisseurs
    },
    {
      path: "/:etablissementId/activation-fournisseurs",
      name: "ActivationFournisseurs",
      meta: { title: "Activation fournisseurs" },
      component: ActivationFournisseurs
    },
    {
      path: "/:etablissementId/gemrcn",
      name: "CategoriesGEMRCN",
      meta: { title: "Catégories GEMRCN" },
      component: CategoriesGEMRCN
    },
    {
      path: "/:etablissementId/plansalimentaires",
      name: "PlansAlimentaires",
      meta: { title: "Plans Alimentaires" },
      component: PlansAlimentaires
    },
    {
      path: "/:planAlimentaireId/planalimentaire",
      name: "PlanAlimentaire",
      meta: { title: "Plan Alimentaire" },
      component: PlanAlimentaire
    },
    {
      path: "/doc-fournisseurs",
      name: "DocFournisseurs",
      meta: { title: "Documentation page fournisseurs" },
      component: DocFournisseurs 
    },
    /*     {
          path: "/:etablissementId/login",
          name: "login",
          meta: { allowAnonymous: true },
          component: Login
        }, */
   /* {
      path: "/login",
      name: "login",
      meta: { allowAnonymous: true },
      component: Login
    },
*/
    {
      path: "/*",
      name: 'NotFound',
      meta: { title: "Introuvable" },
      component: NotFound
    }
  ]
})

router.beforeEach(function (to, from, next) {
  if (to.params["etablissementId"]) {
    const etablissementId = parseInt(to.params["etablissementId"])
    if (!isNaN(etablissementId) && store.state.etablissements.etablissement.etablissementId != etablissementId) {
      store.commit('etablissements/setEtablissement',
        { etablissementId: etablissementId, userId: store.state.user.userInfo.id })
      // this.$emit('etablissement-selectionne', etablissementId)
    }
  }
  // Nettoyage forcé du cache dans 20% de cas
  if (Math.random() < 0.2) {
    if (window.requestIdleCallback) {
      // console.log("Flush cache en idle")
      window.requestIdleCallback(lscache.flushExpired);
    }
    else {
     // console.log("Flush cache sans idle")
      lscache.flushExpired()
    }
  }

  //mise à jour du front
  var lastVersionCheck = localStorage.lastVersionCheck && JSON.parse(localStorage.lastVersionCheck)
  if (lastVersionCheck && lastVersionCheck.updated) {//mise à jour effectuée, on l'annonce
    Vue.toast('Application mise à jour', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      className: ['toast-success'],
      duration: 1500
    })
    lastVersionCheck.updated = false
    localStorage.lastVersionCheck = JSON.stringify(lastVersionCheck)
  }

  if (!lastVersionCheck || !lastVersionCheck.time || lastVersionCheck.time + 300000 < (new Date()).getTime()) {//max une vérification toutes les 5 minutes
    // eslint-disable-next-line promise/catch-or-return
    fetch(location.origin)//vérification de la version actuelle
      .then(r => r.text())
      .then(r => {
        var ts = r.substr(r.indexOf("data-build-timestamp=") + 22)
        ts = ts.substr(0, ts.indexOf("\""))
        lastVersionCheck = {
          time: (new Date()).getTime(),
          dateVersion: document.documentElement.dataset.buildTimestamp,
          updated: ts !== document.documentElement.dataset.buildTimestamp
        }
        localStorage.lastVersionCheck = JSON.stringify(lastVersionCheck)
        if (lastVersionCheck.updated) {
          window.location.replace(window.location.href)//mise à jour
        }
        return
      })
  }
  next()
})
// router.beforeEach(function(to, from, next) {
//   if (!to.matched.some(record => record.meta.allowAnonymous)) {
//     if (!store.getters.estAuthentifie) {
//       next({ name: 'login', query: { redirectTo: to.fullPath } })
//     } else {
//       next()
//     }
//   } else {
//     next()
//   }
// })

export default router
