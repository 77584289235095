<template>
  <b-modal ref="modalAjoutProduits" size="xxl" hide-footer
    :title="'Commande : ' + commande.nom + ' (Total actuel : ' + commande.total + ' € - Total après modification : ' + totalPCB() + ')'"
    ok-title="Valider"
    cancel-title="Annuler"
    no-close-on-backdrop
    @ok="validerAjout">
    <template slot="default">
      <div class="container-fluid flex-center-space-between">
        <Mercuriales ref="mercuriales"
          class="pr-1 pl-0"
          :startFilters="filters"
          :windowMode="true"
          :fournExclus="idsFournisseursVerrouilles || []"
          @clic-produit="ajouterProduit"
          @clic-tarif="ajouterProduit"
          @filter-changed="filterChanged" />

        <div class="w-25 pl-1">
          <div class="card" style="height:210px">
            <div class="card-header">
              {{ produit.dAgap || 'Description produit sélectionné' }}
            </div>
            <div v-if="produit.foId" class="card-body">
              <div class="flex-center-space-between">
                <div><b>{{ produit.fournisseur }} <i v-if="produit.estFavori" class="fas fa-star text-primary"></i></b></div>
                <BoutonFicheTechnique v-if="produit.foId && produit.Ref" size="sm" :fournisseurId="produit.foId" :reference="produit.Ref" />
              </div>
              <div>{{ produit.dFou || produit.dAgap }}</div>
              <div><span v-if="produit.Ref || produit.marque">(</span>{{ produit.Ref }}<span v-if="produit.Ref && produit.marque"> - </span>{{ produit.marque }}<span v-if="produit.Ref || produit.marque">)</span></div>
              <div><b>{{ produit.pua }} / {{ produit.uc }}</b></div>
              <div>Montant total d'un lot : {{ produit.plot }}</div>
              <div><b :class="[{'text-warning':(produit.qDem % produit.pcb) !== 0}]">Vendu par {{ produit.pcb }} {{ produit.uc }}</b></div>
            </div>
            <div v-else-if="produit.pId" class="card-body">{{ produit.nbFournisseurs }} prix disponibles</div>
            <div v-else class="card-body">Veuillez sélectionner le(s) produit(s)</div>
          </div>

          <div class="card table-scroll height-responsive-panier">
            <div class="card-header flex-center-space-between">
              <span>Produits sélectionnés</span>
              <span>{{ totalPCB() }}</span>
            </div>
            <table class="table table-bordered, table-sm table-header-secondary">
              <colgroup>
                <col>
                <col style="width:100px">
              </colgroup>
              <thead>
                <th>Désignation</th>
                <th>Qté.</th>
              </thead>
            </table>
            <div class="table-body">
              <table class="table table-sm">
                <colgroup>
                  <col>
                  <col style="width:95px">
                </colgroup>
                <tbody>
                  <tr v-for="(prod, iProd) in produits" :key="iProd" :class="[{'ligne-selectionnee':prod.pId === produit.pId}]" @click="selectionnerProduit(prod)">
                    <template v-if="prod.famille">
                      <td colspan="2" class="table-header-tertiary">{{ prod.famille }}</td>
                    </template>
                    <template v-else>
                      <td :title="prod.dFou"><label :for="'produit'+prod.pId">{{ prod.dFou || prod.dAgap }}</label></td>
                      <td><InputNumber :id="'produit'+prod.pId" v-model="prod.qDem" typeFiltre="quantite" zeroSiVide="1" :data-pid="prod.pId" :data-tid="prod.tId" style="width: 45px;" @move="deplacerSelectionProduit($event)" @focus="selectionnerProduit(prod)" /> {{abregerTexte(prod.uc, 7)}}</td>
                    </template>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="actions flex-end">
            <b-button class="btn btn-danger" style="margin-right: 10px;" @click="hide">Annuler</b-button>
            <b-button class="btn btn-primary " @click="validerAjout">Valider</b-button>
          </div>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import vm from "@/helpers/vm-toolkit-vue"
import jquery from 'jquery'
import Mercuriales from '@/components/Mercuriales/Mercuriales'
import InputNumber from '@/components/Inputs/InputNumber'
import BoutonFicheTechnique from '@/components/Divers/BoutonFicheTechnique'
import { abregerTexte, arrondiPrix, prixPCB } from '@/helpers/utils'
import { obtenirFavoris } from "@/helpers/mercuriales"
import { codeFamille } from '@/mixins/recette'
import { mapGetters } from "vuex"

export default {
  name: "ModalAjoutProduits",
  props: [ "commande", "idsFournisseursVerrouilles" ],
  mixins: [ codeFamille ],
  components: {
    Mercuriales,
    InputNumber,
    BoutonFicheTechnique
  },
  data() {
    return {
      filters: {
        sel: "0"
      },
      produit: {},
      familles: {},
      produits: [],
    }
  },
  computed: {
    ...mapGetters("fournisseurs", ["nomFournisseur"]),
    ...mapGetters("definitions", ["trouverFamille"]),
  },
  methods: {
    abregerTexte(texte, longueur) {
      return abregerTexte(texte, longueur)
    },
    ajouterProduitDansFamille(codeFamille, produit) {
      if (!this.familles[codeFamille]) {
        this.familles[codeFamille] = []
      }
      this.familles[codeFamille].push(produit)
    },
    async creerProduit(produit) {
      let resultat = {}
      if (this.produit.pId !== produit.prod.Id || this.produit.tId !== produit.tarif?.idTarif) {
        resultat = {
          indice: 0,
          cId: this.commande.id,
          faCode: this.filters.pg,
          faId: this.trouverFamille(this.filters.pg)?.id,
          pId: produit.prod.Id,
          ingId: produit.prod.ingredientId === '0' ? null : produit.prod.ingredientId,
          uc: produit.prod.UC,
          foId: produit.tarif?.idFourn,
          qDem: this.familles[this.filters.pg]?.find(p => p.pId === produit.prod.Id)?.qDem  || 0,
          tId: produit.tarif?.idTarif,
          marque: produit.tarif?.Marque,
          Ref: produit.tarif?.Reference,
          pu: produit.tarif?.PrixAchat,
          pua: produit.tarif ? arrondiPrix(produit.tarif?.PrixAchat) : "-",
          pcb: produit.tarif?.PCB,
          plot: produit.tarif ? arrondiPrix(produit.tarif?.PrixAchat * produit.tarif?.PCB) : "-",
          dFou: produit.tarif?.Designation,
          dAgap: produit.prod.Designation,
          fournisseur: this.nomFournisseur(produit.tarif?.idFourn),
          selection: this.filters.sel,   // Correspond au type de mercuriale (complète / HM / Local)
          nbFournisseurs: Object.keys(produit.prod.tarifs || {}).filter(f => produit.prod.tarifs[f].PrixAchat).length,
          estFavori: (await obtenirFavoris())?.find(f => f.produitId === produit.prod.Id && f.fournisseursIds.includes(produit.tarif?.idFourn))
        }
      }
      return resultat
    },
    clonerProduitCommande(produit) {
      return {
          indice: produit.indice,
          cId: produit.cId,
          faCode: produit.faCode || produit.faId ? this.codeFamille(produit.faId) : "",
          faId: produit.faId,
          pId: produit.pId,
          ingId: produit.ingId,
          uc: produit.uc,
          foId: produit.foId,
          qDem: produit.qDem,
          tId: produit.tId,
          marque: produit.marque,
          Ref: produit.Ref,
          pu: produit.pu,
          pua: produit.tId ? arrondiPrix(produit.pu) : "-",
          pcb: produit.pcb,
          plot: produit.tId ? arrondiPrix(produit.pu * produit.pcb) : "-",
          dFou: produit.dFou,
          dAgap: produit.dAgap,
          fournisseur: this.nomFournisseur(produit.foId)
      }
    },
    async ajouterProduit(e) {
      this.afficherProduitDansPanier(e.prod.Id, await this.creerProduit(e))
    },
    async afficherProduitDansPanier(produitId, produit) {
      this.produit = produit
      let index = this.familles[this.filters.pg] ? this.familles[this.filters.pg].findIndex(p => p.pId === produitId) : -1
      if (index > -1) {
        // Suppression de l'élément en position "index" : utilisé pour changer l'article sélectionné
        this.familles[this.filters.pg].splice(index, 1)
      }
      if (this.produit.pId) {
        if (index > -1) {
          // Ajoute l'élément en position "index" : utilisé pour réinsérer l'article qui a été supprimé précédemment (cas du changement d'article sur une ligne produit dans la mercuriale)
          this.familles[this.filters.pg].splice(index, 0, this.produit)
        } else {
          // Ajoute l'article sélectionné à la fin de la liste de la famille sélectionnée
          this.ajouterProduitDansFamille(this.filters.pg, this.produit)
        }
      }
      this.selectionnerProduit(this.produit)
    },
    mettreAJourPanier() {
      this.produits = []
      vm.forEach(this.familles, (produits, famille) => {
        this.produits.push({famille: famille})
        produits.forEach(produit => this.produits.push(produit))
      })
      setTimeout(() => {
        document.querySelector("[data-pid='" + this.produit.pId + "']")?.focus()
      }, 10)
      this.marquerPanier()
    },
    initialiserPanier() {
      this.commande.lignes.filter(l => !l.deleted && l.pId).sort((a, b) => a.indice - b.indice || this.nomFournisseur(a.foId).localeCompare(this.nomFournisseur(b.foId)) || a.dAgap.localeCompare(b.dAgap)).forEach(l => {
        let produit = this.clonerProduitCommande(l)
        this.ajouterProduitDansFamille(produit.faCode, produit)
      })
      this.mettreAJourPanier()
    },
    async selectionnerProduit(produit) {
      this.mettreAJourPanier()

      this.produit = this.produits.find(p => p.pId === produit.pId)

      // Bascule de la famille et du type de mercuriale si la famille ou le type de mercuriale sélectionnés sont différents de la famille et du type de mercuriale du produit ou de l'article fournisseur sélectionné
      let contexteMercuriale = this.$refs.mercuriales
      if (produit.pId && this.filters.pg !== this.produit.faCode) {
        let filtreFamille = contexteMercuriale.filtres.familles
        await filtreFamille.filtrer(contexteMercuriale, this.produit.faCode)
      }

      if (!this.produit.selection) {
        this.produit.selection = contexteMercuriale.obtenirSelection(this.produit.pId)
      }

      if (!this.produit.foId && !this.produit.nbFournisseurs) {
        let produitMercuriale = contexteMercuriale.obtenirProduitMercuriale(this.produit.pId, this.produit.selection)
        this.produit.nbFournisseurs = Object.keys(produitMercuriale?.tarifs || {}).filter(f => produitMercuriale?.tarifs[f].PrixAchat).length
      }

      if (!this.produit.estFavori) {
        this.produit.estFavori = (await obtenirFavoris())?.find(f => f.produitId === produit.pId && f.fournisseursIds.includes(produit.foId))?.estFavori
        // Les 2 lignes suivantes servent à forcer le rafraichissement du produit sélectionné dans la card de description
        this.produit = null
        this.produit = this.produits.find(p => p.pId === produit.pId)
      }

      if (produit.pId && this.filters.sel !== this.produit.selection) {
        let filtreSelections = contexteMercuriale.filtres.selections
        filtreSelections.filtrer(contexteMercuriale, this.produit.selection)
      }

      this.afficherProduitSelectionne(produit)
    },
    afficherProduitSelectionne(produit) {
      this.$nextTick(() => {
        // Déplacement de l'ascenseur pour positionner l'affichage de la mercuriale sur le produit ou l'article fournisseur sélectionné
        document.querySelector("[data-idprod='" + produit.pId + "']")?.scrollIntoView({ block: "center" })

        // Sélection du produit ou du tarif choisi
        jquery("[data-key]").removeClass("selected")
        let key = produit.pId + (produit.foId ? ("-" + produit.foId) : "")
        jquery("[data-key='" + key + "']").addClass("selected")
      })
    },
    trouverProduit(sens) {
      if (!this.produits?.length || !sens) {
        return null
      }
      let iProduit = this.produits.findIndex(p => p.pId === this.produit?.pId)

      let produit = null
      do {
        iProduit += sens
        if (iProduit < 0) {
          iProduit = this.produits.length - 1
        } else if (iProduit >= this.produits.length) {
          iProduit = 0
        }
        produit = this.produits[iProduit]
      } while (!produit?.pId)

      return produit
    },
    deplacerSelectionProduit(sens) {
      if (sens.direction === "enter") {
        jquery(".search")?.focus()
      } else {
        let iSens = sens.direction === "up" ? -1 : sens.direction === "down" ? 1 : 0
        let produit = this.trouverProduit(iSens)
        this.selectionnerProduit(produit)
      }
    },
    validerAjout() {
      this.$emit("ajouter-articles", this.produits.filter(p => p.pId))
      this.hide()
    },
    filterChanged() {
      setTimeout(this.marquerPanier, 100)
    },
    marquerPanier() {
      this.$nextTick(() => {
        jquery("[data-key]").removeClass("au-panier")
        let panier = this.familles[this.filters.pg]
        panier?.forEach(p => {
          let key = p.pId + (p.foId ? ("-" + p.foId) : "")
          jquery("[data-key='" + key + "']").addClass("au-panier")
        })

        if (this.produit.pId) {
          this.afficherProduitSelectionne(this.produit)
        }
      })
    },
    totalPCB() {
      return arrondiPrix(this.produits.reduce((s, p) => s + prixPCB(p.qDem, p.pcb, p.pu), 0), 2)
    },
    arrondiPrix(valeur, dec) {
      return arrondiPrix(valeur, dec)
    },
    show() {
      this.produit = {}
      this.produits = []
      this.familles = {}
      this.initialiserPanier()
      this.$refs.modalAjoutProduits.show()
    },
    hide() {
      this.$refs.modalAjoutProduits.hide()
    }
  }
}
</script>

<style>
.modal-ajout {
  display: flex;
}
/*.modal-ajout > div:nth-of-type(1) {
  width: calc(100% - 305px);
}
.modal-ajout > div:nth-of-type(2) {
  width: 300px;
}*/
/*.modal-ajout > div:nth-of-type(1) > div:nth-of-type(1) {
  position: static!important;
  height: unset!important;
}*/
/*.modal-ajout > div:nth-of-type(1) > div:nth-of-type(2) {
  margin-top: 0!important;
}*/
/*.modal-ajout .gv-catalog > div:nth-of-type(1) {
  position: static!important;
  overflow-y: auto!important;
  height: 100%;
}
.modal-ajout .gv-catalog th {
  /*position: sticky;*//*TODO: permet de garder l'en-tête de la table, mais passe au desssus des combo de filtres, fix à trouver...*/
/*  top: 0;
  background-color: inherit;
}*/
/*.modal-ajout > .panier {
  font-weight: bold;
  overflow-y: auto;
  position: fixed;
  top: 20px;
  right: 20px;
  bottom: 65px;
}
.modal-ajout > .panier .card-body {
  min-height: 140px;
}
.modal-ajout > .panier .actions {
  position: fixed;
  bottom: 25px;
  right: 12px;
}*/
</style>