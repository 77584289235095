import Bugsnag from '@bugsnag/js'


/* export const bugsnagClient = bugsnag({
    apiKey: '0b3a2d5e29d4f8f83c1b6e2143d9c888',
    notifyReleaseStages: ['production']
}); */

export function setUserBugsnag(utilisateur) {
    if (!utilisateur)
        return
    const userId = utilisateur.id || utilisateur.userId
    if (!userId)
    {
       return
    }
    Bugsnag.setUser(userId.toString(), utilisateur.email, utilisateur.userName)
}
