<template>
  <div>
    <b-modal id="modalInformations" size="xl" title="Information Agap'pro" hide-footer>
      <template slot="default">
        <div v-for="(info, i) in informations" :key="i">
          <div class="card">
            <div class="card-header" :style="info.couleur ? ('background-color:' + info.couleur) : ''">
              <div class="card-title">
                {{info.titre}}
              </div>
            </div>
            <div class="card-body">
              <span v-html="info.message"></span>
            </div>
          </div>
        </div>
      </template>
    </b-modal>

    <div class="card">
      <div class="card-header">
        Information Agap'pro
      </div>
      <div class="card-container">
        <div v-if="!gotInformations" style="text-align:center;margin-top:50px;">
          <img alt="en attente" src="/assets/images/spinners/cooking-blue-court.gif" style='height:100px;margin-left:-8px;' />
        </div>
        <div v-else>
          <div class="card card-minimal table-scroll height-responsive-top-50">
            <div class="table-body">


              <!--<span v-for="(info, i) in resumeInformations" :key="i">
                <div class="row">
                  <div class="col-2 text-center">
                    {{ formatHumanDateAvecSlashes(new Date(info.dateDebut)) }}
                  </div>
                  <div class="col-10">
                <h6 class="mt-1"><b>{{info.titre}}</b></h6>
                <p class="info">{{info.resume}}</p>
                  </div>
                </div>
                  <div class="row">
                    <div class="col-12">
                      <hr v-if="i < 2" />
                    </div>
                  </div>
              </span>-->


              <table class="table table-sm">
                <colgroup>
                  <col width="90px">
                  <col>
                </colgroup>
                <tbody>
                  <tr v-for="(info, i) in resumeInformations" :key="i">
                    <td class="text-center">
                      {{ formatHumanDateAvecSlashes(new Date(info.dateDebut)) }}
                    </td>
                    <td class="pt-3 pb-3">
                      <h6 class="pb-1"><b>{{info.titre}}</b></h6>
                      {{info.resume}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!--<div v-else class="container-fluid mt-3">
          <span v-for="(info, i) in resumeInformations" :key="i">
            <div class="row">
              <div class="col-2">
                {{ formatHumanDateAvecSlashes(new Date(info.dateDebut)) }}
              </div>
              <div class="col-10">
            <h6 class="mt-1"><b>{{info.titre}}</b></h6>
            <p class="info">{{info.resume}}</p>
              </div>
            </div>
              <div class="row">
                <div class="col-12">
                  <hr v-if="i < 2" />
                </div>
              </div>
          </span>
        </div>-->
        <div class="text-center mb-2">
          <b-button v-b-modal.modalInformations class="btn btn-primary">Voir plus de détails</b-button>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import _ from 'lodash'
import { mapGetters } from "vuex"
import { abregerTexte } from "@/helpers/utils"
import { formatHumanDateAvecSlashes } from '@/mixins/dateFormats'

export default {
  name: "WidgetInformations",
  props: [],
  mixins: [ formatHumanDateAvecSlashes ],
  components: {},
  data() {
    return {
      informations: [],
      resumeInformations: [],
      gotInformations: false,
    }
  },
  mounted() {
    this.chargerInformations()
  },
  computed: {
        ...mapGetters("definitions", ["informationsAccueil"]),
  },
  methods: {
    chargerInformations() {
      this.informations = _.cloneDeep(this.informationsAccueil.messages)
      if (this.informations) {
        this.resumeInformations = _.cloneDeep(this.informationsAccueil.messages).splice(0, 3)
        this.resumeInformations.forEach((info) => {//suppression des balises html
          var div = document.createElement("div")
          div.innerHTML = info.message
          info.resume = div.textContent || div.innerText
          info.resume = abregerTexte(info.resume, 200)
        })
      }
      this.$nextTick(() => this.gotInformations = true)
    }
  }
}
</script>

<style scoped>
.info {
  font-size: 12px;
  width: 99%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
