export const setProduitStatus = {
  methods: {
    setProduitStatus(produit) {
      if (produit.tarifs) {
        produit.status = []
        if (produit.tarifs.filter(tarif => tarif.ftEstOk === true).length === produit.tarifs.length) {
          produit.status.push('EST OK')
        }
        if (produit.tarifs.filter(tarif => tarif.ftVerifieLe === null).length !== 0) {
          produit.status.push('À FAIRE')
        }
        if (!produit.designation || !produit.grammage || !produit.ingredientId || produit.ingredientId < 5000 || !produit.uc || produit.commentairesLigne || produit.tarifs.filter(tarif => tarif.ftVerifieLe !== null && !!tarif.pcb && tarif.ftEstOk).length !== produit.tarifs.length) {
          if (produit.status.includes('EST OK')) {
            let i = produit.status.indexOf('EST OK')
            produit.status.splice(i, 1)
          }
          produit.status.push('INVALIDE')
        }
      }
      return produit
    }
  }
}
