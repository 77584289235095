import Vue from "vue"
import VueSweetalert2 from 'vue-sweetalert2'
import Bugsnag from '@bugsnag/js'
Vue.use(VueSweetalert2)

export const showAlerts = {
  methods: {
    async alerteSucces(message) {
      /*await this.$bvModal.msgBoxOk(message, {
        title: "Opération réussie",
        headerBgVariant: "success",
        okVariant: "success"
      })*/
      // Use sweetalert2
      await this.$swal({
        title: message,
        icon: "success",
        timer: 2000,
        // showCancelButton: false, // exemple gardé pour le cancel
        showConfirmButton: false
      })
    },
    logEtMontrerAlerte(message, exception) {
      if (exception)
        Bugsnag.notify(exception)
      this.alerteErreur(message, exception)
    },
    async alerteErreur(message, exception) {
      let consolemsg = message
      if (exception) {
        if (exception.erreurDejaAffichee)
          return
        consolemsg += "\n" + exception.stack
      }
      console.error(consolemsg)
      /*await this.$bvModal.msgBoxOk(message, {
        title: "Opération impossible",
        headerBgVariant: "danger",
        okVariant: "danger"
      })*/
      await this.$swal.fire({
        icon: "error",
        //  timer: 2500,
        title: "Opération impossible",
        html: message,
        showConfirmButton: true
      })
    },
    async alerteEnvironnementTest() {
      await this.alerteErreur("Cette fonctionnalité est désactivée car vous travaillez sur un environnement de test.<br/><br/>Si vous souhaitez passer des commandes réelles, allez à l'adresse https://mercudyn.agap-pro.com puis déconnectez et reconnectez-vous.")
    },
    async alerteConfirmationEnregistrement()
    {
      /*let result = await this.$bvModal.msgBoxConfirm("Souhaitez-vous enregistrer vos modifications en cours ?", {
        title: "Enregistrer les modifications",
        headerBgVariant: "primary",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        hideHeaderClose: false,
        centered: false
      })
      return result*/
      let result = await this.$swal.fire({
        title: "Enregistrer les modifications",
        html: "Souhaitez-vous enregistrer vos modifications en cours ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#025DBF",
        cancelButtonColor: "#585858",
        confirmButtonText: "Oui",
        cancelButtonText: "Non",
        focusCancel: true,
        reverseButtons: true
      })
      return result.value
    },
    async alerteConfirmation(titre, message, confirm, cancel)
    {
      if (!confirm)
        confirm = "Oui"
      if (!cancel)
        cancel = "Non"
      /*let result = await this.$bvModal.msgBoxConfirm(message, {
        title: titre,
        headerBgVariant: "primary",
        okVariant: "primary",
        okTitle: confirm,
        cancelTitle: cancel,
        hideHeaderClose: false,
        centered: false
      })
      return result*/
      let result = await this.$swal.fire({
        title: titre,
        html: message,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#025DBF",
        cancelButtonColor: "#585858",
        confirmButtonText: confirm,
        cancelButtonText: cancel,
        focusCancel: true,
        reverseButtons: true
      })
      return result.value
    },
    async alerteConfirmationSuppression(titre, message, confirm, cancel)
    {
      if (!confirm)
        confirm = "Supprimer"
      if (!cancel)
        cancel = "Annuler"
      /*let result = await this.$bvModal.msgBoxConfirm(message, {
        title: titre,
        headerBgVariant: "danger",
        okVariant: "danger",
        okTitle: confirm,
        cancelTitle: cancel,
        hideHeaderClose: false,
        centered: false
      })
      return result*/
      var result = await this.$swal.fire({
        title: titre,
        html: message,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#D30943",
        cancelButtonColor: "#585858",
        confirmButtonText: confirm,
        cancelButtonText: cancel,
        focusCancel: true,
        reverseButtons: true
      })
      return result.value
    },
    toastSucces(message) {
      this.$toast(message, {
        horizontalPosition: "center",
        verticalPosition: "top",
        className: ["toast-success"],
        duration: 1500
      })
    },
    toastErreur(message) {
      this.$toast(message, {
        horizontalPosition: "center",
        verticalPosition: "top",
        className: ["toast-error"],
        duration: 5000
      })
    }
  }
}