<template>
  <div v-if="(actions.barreGauche && actions.barreGauche.length) || (actions.barreDroite && actions.barreDroite.length)" class="card">
    <div class="card-body flex-center-space-between">
      <!-- Actions de gauche -->
      <div class="flex-start barre-actions-gauche">
        <div v-for="action in actions.barreGauche" :key="action.label || action.icone">
          <b-button class="btn" :class="action.class ? action.class : 'btn-primary'" :hidden="action.hidden" :disabled="disabled || action.disabled" @click="appliquerAction(action.methode)"><i v-if="action.icone" :class="action.icone"></i>{{ action.label }}</b-button>
        </div>
      </div>
      <!-- Actions de droite -->
      <div class="flex-end barre-actions-droite">
        <div v-for="action in actions.barreDroite" :key="action.label || action.icone">
          <b-button class="btn" :class="action.class ? action.class : 'btn-primary'" :hidden="action.hidden" :disabled="disabled || action.disabled" @click="appliquerAction(action.methode)">{{ action.label }}</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { showAlerts } from "@/mixins/alerts"

export default {
  name: "BarreActions",
  mixins: [ showAlerts ],
  props: [ "actions", "disabled" ],
  mounted() {
  },
  computed: {
  },
  methods: {
    appliquerAction(methode) {
      this.$emit("appliquer-action", methode)
    },
    retourArriere() {
      if (!this.$route.params.retour) {
        this.alerteErreur("Aucune URL de retour n'est configurée.")
        return
      }
      this.$router.push(this.$route.params.retour)
    }
  }
}
</script>
