import format from 'date-fns/format'
import fr from 'date-fns/locale/fr'
import { mettreLesInitialesEnMajuscules } from '@/helpers/utils'

export const formatageDate = {
  methods: {
    // renvoie la date au format AAAA-MM-JJ
    dateIso(date) {
      if (!date || !date.toLocaleDateString)
        return date;
      return date.toLocaleDateString('fr-CA').substring(0,10)
    },
    apiDate(date) {
      if (!date)
        return date;
      return date.split('-').join('')
    },
    formatDatePourApiSansSeparation(date) {
      if (date) {
        let d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear()

        if (month.length < 2) month = '0' + month
        if (day.length < 2) day = '0' + day

        return [year, month, day].join('')
      }
    },
    formatDateAvecTirets(date) {
      if (date) {
        let d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear()

        if (month.length < 2) month = '0' + month
        if (day.length < 2) day = '0' + day

        return [year, month, day].join('-')
      }
    },
    formatHumanDateAvecJour(date) {
      let formattedDate = ''
      if (date && date.startsWith &&  date.startsWith('0001-01-01'))
        return ''
      if (!date.toLocaleDateString) {
        date = new Date(date)
      }
      if (date) {
        formattedDate = mettreLesInitialesEnMajuscules(date.toLocaleDateString('fr-FR', {weekday: "long", year: "numeric", month: "long", day: "numeric"}))
      }
      return formattedDate
    },
    formatHumanDate(date) {
      let formattedDate = ''
      if (date) {
        formattedDate = format(date,'D MMM YYYY', { locale: fr })
      }
      return formattedDate
    },
    formatHumanDateHeure(date) {
      let formattedDate = ''
      if (date) {
        formattedDate = format(date,'D MMM YYYY HH:mm', { locale: fr })
      }
      return formattedDate
    },
    formatHumanDates(dateDebut, dateFin) {
      let formattedDates = ''
      if (dateDebut) {
        formattedDates = 'Du ' + format(dateDebut, 'D MMM', { locale: fr })
      }
      if (dateFin) {
        formattedDates += ' au ' + format(dateFin, 'D MMM', { locale: fr })
      }
      return formattedDates
    },
    dateSeule(date) {
      var objDate = new Date(date)
      return new Date(objDate.getFullYear(), objDate.getMonth() + 1, objDate.getDate())
    },
    // A voir si on le déplace dans une autre librairie
    periodesCompatibles(dateDebut1, dateFin1, dateDebut2, dateFin2) {
      if (!dateDebut1 || !dateFin1 || !dateDebut2 || !dateFin2) {
        return false
      }
      return this.formatDateAvecTirets(dateDebut1) <= this.formatDateAvecTirets(dateFin2) && this.formatDateAvecTirets(dateFin1) >= this.formatDateAvecTirets(dateDebut2)
    }
  }
}

export const livreLe = {
  data() {
    return {
      dateFormat: 'D MMM YYYY'
    }
  },
  methods: {
    livreLe(date) {
      let formattedDate = ''
      if (date) {
        formattedDate = format(date, this.dateFormat, { locale: fr })
      }
      return 'Livré le ' + formattedDate
    }
  }
}

export const formatHumanDateAvecSlashes = {
  methods: {
    formatHumanDateAvecSlashes(date) {
      if (date) {
        let d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear()

        if (month.length < 2) month = '0' + month
        if (day.length < 2) day = '0' + day

        return [day, month, year].join('/')
      } else {
        return ''
      }
    }
  }
}
