<template>
  <b-modal ref="modalRenommerCommande" size="md" title="Renommer une commande"
    ok-title="Renommer" :ok-disabled="!$v.nouveauNom.required || !$v.nouveauNom.minLength"
    cancel-title="Annuler"
    no-close-on-backdrop
    @show="initialiserModale()"
    @shown="$refs.nouveauNomCommande.focus()"
    @ok="renommerCommande()">
    <template slot="default">
      <div class="form-group">
        <label for="nouveauNomCommande">Nom de la commande</label>
        <input type="text" id="nouveauNomCommande" ref="nouveauNomCommande" class="form-control" v-model.trim="nouveauNom" maxlength="80" @focus="$event.target.select()">
        <div class="error" v-if="!$v.nouveauNom.required">Ce champ est requis</div>
        <div class="error" v-if="!$v.nouveauNom.minLength">Ce champ doit contenir au moins 3 caractères</div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { renommerCommande } from '@/api/gpao/commandes'
import { showAlerts } from '@/mixins/alerts'
import { required, minLength, maxLength } from "vuelidate/lib/validators"

export default {
  name: "ModalCreationCommande",
  mixins: [ showAlerts ],
  props: [ "commande" ],
  data() {
    return {
      nouveauNom: ""
    }
  },
  validations: {  
    nouveauNom: {
        required,
        minLength: minLength(3),
        maxlength: maxLength(80)
      }
  },
  watch: {
    commande() {
      this.nouveauNom = this.commande?.nom //this.commande est undefined lors de la suppression d'une commande, donc ça fait une erreur Javascript => ajout de ?
    }
  },
  methods: {
    initialiserModale() {
      this.nouveauNom = this.commande.nom
    },
    renommerCommande() {
      return renommerCommande(this.commande.id, { nom: this.nouveauNom })
      .then(() => {
        this.hide()
        this.alerteSucces("Commande renommée !")
        this.$emit("commande-renommee", null)
        return
      })
    },
    show() {
      this.$refs.modalRenommerCommande.show()
    },
    hide() {
      this.$refs.modalRenommerCommande.hide()
    }
  }
}
</script>
