<template>
  <div class="relative">
    <div style="position:relative;">
      <input ref="search" type="text" :placeholder="placeHolder" class="px-5 pl-10 py-2" style="width:100%;"
        v-model="query"
        @blur="resultatsDeRechercheVisible = false"
        @click="resultatsDeRechercheVisible = true"
        @focus="resultatsDeRechercheVisible = true"
        @input="softReset"
        @keydown.up.prevent="highlightPrevious"
        @keydown.down.prevent="highlightNext"
        @keydown.enter.prevent="pressEnterIngredient()"
        @keydown.esc="resultatsDeRechercheVisible = false"
        @keyup="startTimeout">
      <div style="position:absolute;top:7px;left:5px">
        <svg fill="currentColor" class="h-5 w-5" viewBox="0 0 24 24" width="24" height="24" style="color:grey">
          <path class="heroicon-ui" d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"></path>
        </svg>
      </div>
      <div v-if="query.length > 0" style="position: absolute;top:3px;right: 10px;color:grey;font-size:1.5rem" @click="reset">
        <i class="fa fa-window-close" aria-hidden="true"></i>
      </div>
    </div>

    <transition name="fade">
      <div v-if="query.length >= 3 && resultatsDeRechercheVisible" :style="'max-height:' + (small ? '11' : '32') + 'rem;overflow:hidden;overflow-y:auto;border:1px solid #dfdfdf;background:white;'">
        <div ref="results">
          <span v-for="(ingredient, index) in searchResults" :key="index" @click="selectionIngredient(ingredient)" @mousedown.prevent="resultatsDeRechercheVisible = true" @mouseover="focusOn(index)" style="padding:.5rem; display: flex; flex-direction: column;text-decoration:none;color:black;cursor:pointer" :class="{ 'bg-vert': index === highlightedIndex }">
            <div style="display:flex;line-height:2.5rem;">
              <i v-if="ingredient.item.estPerso" class="fas fa-user push-right-1rem" style="height:20px;margin-top:10px;"></i>
              <i v-if="ingredient.item.estEtab" class="far fa-building push-right-1rem" style="height:20px;margin-top:10px;"></i>
              <div>
                {{ /*ingredient.score + " " +*/ (ajouterBIO ? ingredient.item.nomBIO : ingredient.item.nom) }}
              </div>
              <div v-if="ingredient.item.produits" style="display:flex;margin-left:1rem">
                <span v-for="(famille, index) in famillesPossibles(ingredient.item.produits)" :key="index">
                  <img :src="'/assets/images/icones/' + famille + '-NOIR' + '.png'" alt="" style="width:32px;">
                </span>
              </div>
            </div>
          </span>
          <div v-if="searchResults.length === 0 && query.length > 3" class="font-normal w-full border-b cursor-pointer p-4">
            <p class="my-0">Aucun résultat pour '<strong>{{ query }}</strong>'</p>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Fuse from "fuse.js"
import { mapGetters } from "vuex"
export default {
  props: {
    liste: Array,
    ajouterBIO: {type: Boolean, default: false},
    placeHolder: String,
    masquerVide: Boolean,
    cacherAuto: Boolean,
    small: Boolean
  },
  data() {
    return {
      query: '',
      resultatsDeRechercheVisible: false,
      searchResults: [],
      highlightedIndex: 0,
      fuse: null,
      timeoutRecherche: null,
      options: {
        distance: 300,
        includeMatches: true,
        includeScore: true,
        keys: this.ajouterBIO ? ['nomBIO'] : ['nom'],
        location: 0,
        matchAllTokens: true,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        threshold: 0.4,
        tokenize: true
      }
    }
  },
  watch: {
    liste() {
      this.initFuse()
    }
  },
  computed: {
    ...mapGetters("produits", ["getProduits"])
  },
  methods: {
    focusOn(index) {
      this.highlightedIndex = index
    },
    pressEnterIngredient() {
      if (this.searchResults[this.highlightedIndex]) {
        this.query = this.searchResults[this.highlightedIndex].item.nom
        this.selectionIngredient(this.searchResults[this.highlightedIndex])
      }
    },
    selectionIngredient(ingredient) {
      this.query = ingredient.item.nom
      if (this.cacherAuto) {
        this.resultatsDeRechercheVisible = false
      }
      this.$emit('selection-ingredient', ingredient.item)
    },
    famillesPossibles(ingredientProduits) {
      return ingredientProduits.map(el => el.fam)
    },
    reset() {
      this.query = ''
      this.highlightedIndex = 0
      this.$emit('reset-ingredient')
    },
    softReset() {
      this.resultatsDeRechercheVisible = true
      this.highlightedIndex = 0
    },
    initFuse() {
      this.fuse = new Fuse(this.liste, this.options)
    },
    startTimeout() {
      clearTimeout(this.timeoutRecherche)
      this.timeoutRecherche = setTimeout(this.rechercher, 500)
    },
    rechercher() {
      if (this.query.length >= 3) {
        let results = this.fuse.search(this.query)
        if (this.masquerVide) {//uniquement les ingrédients rattachés à des produits
          results = results.filter(r => r.item.produits)
        } else {//malus pour les ingrédients non rattachés à des produits
          results.forEach(r => {
            if (!r.item.produits) {
              r.score += 0.3//le score est compris entre 0 et 1, il est possible de remplacer l'addition par une multiplication, suivant l'effet souhaité
            }
          })
        }
        results.sort((a, b) => {//tri par score croissant
          return a.score < b.score ? -1 : 1
        })
        this.searchResults = results
        this.resultatsDeRechercheVisible = true
      }
    },
    highlightPrevious() {
      if (this.highlightedIndex > 0) {
        this.highlightedIndex = this.highlightedIndex - 1
        this.scrollIntoView()
      }
    },
    highlightNext() {
      if (this.highlightedIndex < this.searchResults.length - 1) {
        this.highlightedIndex = this.highlightedIndex + 1
        this.scrollIntoView()
      }
    },
    scrollIntoView() {
      this.$refs.results.children[this.highlightedIndex].scrollIntoView({ block: 'nearest' })
    },
    initIngredients() {
      var produits = JSON.parse(JSON.stringify(this.getProduits))
      this.liste.forEach(ing => {
        if (!ing.produits) {
          var prods = produits.filter(p => p.ingredientId === ing.id)
          if (prods.length) {
            ing.produits = prods
          }
        }
      })
    }
  },
  mounted() {
    this.initIngredients()
    this.initFuse()
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.bg-vert {
  background: #53d3a247;
}
.scroller {
  height: 100%;
}
.user {
  height: 32%;
  padding: 0 12px;
  display: flex;
  align-items: center;
}
</style>

