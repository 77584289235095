<template>
  <div style="position:relative;">
    <div style="position:relative;">
      <input ref="search" type="text" :placeholder="placeHolder" class="px-5 pl-10 py-2" style="width:100%;"
        v-model="query"
        :disabled="disabled"
        @blur="resultatsDeRechercheVisible = false"
        @click="resultatsDeRechercheVisible = true"
        @focus="resultatsDeRechercheVisible = true"
        @input="softReset"
        @keydown.up.prevent="highlightPrevious"
        @keydown.down.prevent="highlightNext"
        @keydown.enter.prevent="pressEnter()"
        @keydown.esc="resultatsDeRechercheVisible = false"
        @keyup="startTimeout">
      <div style="position:absolute;left:5px" :style="small ? 'top:1px' : 'top:5px'">
        <svg fill="currentColor" class="h-5 w-5" viewBox="0 0 24 24" :width="small ? 16 : 24" :height="small ? 16 : 24" style="color:grey">
          <path class="heroicon-ui" d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"></path>
        </svg>
      </div>
      <div v-if="!disabled && query.length > 0" style="position:absolute;right:0px;color:grey" :style="small ? 'top:2px' : 'top:3px'" @click="reset">
        <i class="fa fa-window-close" :style="small ? 'font-size:1.2rem' : ''" aria-hidden="true"></i>
      </div>
    </div>

    <transition name="fade">
      <div v-if="query.length >= 3 && resultatsDeRechercheVisible" style="position:absolute;z-index:1000;border-radius: 4px;border:1px solid #dfdfdf;box-shadow:rgba(0, 0, 0, 0.5) 0px 1px 6px, rgba(0, 0, 0, 0.5) 0px 1px 4px !important;background:white;color:black" :style="chercherProduits ? afficherPrix ? 'width:1160px' : 'width:860px' : 'width:440px'">
        <div v-if="!recherchePrete" class="card m-0 border-0">
          <div class="card-body">
            <i>Recherche en cours...</i>
          </div>
        </div>
        <div v-else-if="resultatRecherche.length > 0" class="card table-scroll m-0" :class="small ? 'max-height-responsive-35' : 'max-height-responsive-65'">
          <table class="table table-bordered table-sm text-center table-header">
            <colgroup>
              <col v-if="chercherProduits || (chercherIngredients && chercherRecettes)" width="160">
              <col width="280">
              <col v-if="chercherProduits" width="420">
              <col v-if="chercherProduits && afficherPrix" width="150">
              <col v-if="chercherProduits && afficherPrix" width="150">
            </colgroup>
            <thead>
              <th v-if="chercherProduits || (chercherIngredients && chercherRecettes)">Famille</th>
              <th>Ingrédient</th>
              <th v-if="chercherProduits">Produit</th>
              <th v-if="chercherProduits && afficherPrix">Prix 1</th>
              <th v-if="chercherProduits && afficherPrix">Prix 2</th>
            </thead>
          </table>
          <div class="table-body">
            <table class="table table-bordered table-sm text-center">
              <colgroup>
                <col v-if="chercherProduits || (chercherIngredients && chercherRecettes)" width="160">
                <col width="280">
                <col v-if="chercherProduits" :width="afficherPrix ? '420' : '415'">
                <col v-if="chercherProduits && afficherPrix" width="150">
                <col v-if="chercherProduits && afficherPrix" width="145">
              </colgroup>
              <tbody>
                <tr v-for="(resultat, index) in resultatRecherche" :key="index" :class="[{'ligne-desactivee':!resultat.nomIngredient}]"
                  @click="selectionner(resultat)" @mousedown.prevent="resultatsDeRechercheVisible = true" @mouseover="focusOn(index)">
                  <td v-if="chercherProduits || (chercherIngredients && chercherRecettes)" :class="[{'text-italic':!afficherPrix}]">{{ resultat.nomFamille }}</td>
                  <td :class="[{'bold600':chercherProduits && !afficherPrix}]"><span v-if="!resultat.nomIngredient" class="text-error"><i class="fas fa-exclamation-triangle"></i> Ingrédient à configurer</span>{{ resultat.nomIngredient }}</td>
                  <td v-if="chercherProduits" :class="[{'text-italic':!afficherPrix}]">{{ resultat.designation }} <i v-if="favoris.includes(resultat.id)" class="fas fa-star text-primary"></i></td>
                  <td v-if="chercherProduits && afficherPrix"><span v-if="resultat.prix1">{{ resultat.prix1 | afficherPrix }} x {{ resultat.pcb1 }} {{ resultat.uc }}</span></td>
                  <td v-if="chercherProduits && afficherPrix"><span v-if="resultat.prix2">{{ resultat.prix2 | afficherPrix }} x {{ resultat.pcb2 }} {{ resultat.uc }}</span></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-else class="card m-0 border-0">
          <div class="card-body">
            Aucun résultat pour '<strong>{{ query }}</strong>'
          </div>
          <div v-if="ajoutActif" class="card-footer">
            <button type="button" class="btn btn-primary" @click="declencherAjout()" @mousedown.prevent="resultatsDeRechercheVisible = true">
              Ajouter un produit
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { connecteData } from "@/mixins/connecteData"
import { obtenirProduitsFournisseur } from '@/api/gpao/produits'
import { getIngredientsPourRechercheComplete, getRecettesPourRechercheComplete } from '@/api/gpao/mercuriales'
import { rechercherMultiTexte, formaterPourRecherche } from '@/helpers/utils'
import { chargerParFiltre } from "@/api/gpao/recettes"
import { obtenirFavoris } from "@/helpers/mercuriales"

export default {
  mixins: [connecteData],
  props: {
    chercherProduits: {type: Boolean, default: false},
    chercherIngredients: {type: Boolean, default: false},
    chercherRecettes: {type: Boolean, default: false},
    listeProduits: {type: Object, default: null},
    listeIngredients: {type: Object, default: null},
    listeRecettes: {type: Object, default: null},
    afficherPrix: {type: Boolean, default: true},
    selectionActive: {type: Boolean, default: true},
    fournisseurId: {type: Number, default: null},
    initValeur: {type: String, default: null},
    disabled: {type: Boolean, default: false},
    placeHolder: {type: String, default: ""},
    small: {type: Boolean, default: false},
    ajoutActif: {type: Boolean, default: false}
  },
  data() {
    return {
      query: '',
      liste: [],
      favoris: [],
      resultatsDeRechercheVisible: false,
      searchResults: [],
      highlightedIndex: 0,
      timeoutRecherche: null,
      options: {
        distance: 300,
        includeMatches: true,
        includeScore: true,
        keys: ['nom'],
        location: 0,
        matchAllTokens: true,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        threshold: 0.4,
        tokenize: true
      },
      recherchePrete: false
    }
  },
  computed: {
    ...mapGetters("produits", ["getProduits"]),
    resultatRecherche() {
      return this.searchResults
    }
  },
  watch: {
    query() {
      if (this.resultatsDeRechercheVisible) {
        this.majFavoris()
        this.rechercher()
      }
    }
  },
  methods: {
    async majFavoris() {
      this.favoris = (await obtenirFavoris())?.map(f => f.produitId) || []
    },
    focusOn(index) {
      this.highlightedIndex = index
    },
    pressEnter() {
      if (this.searchResults[this.highlightedIndex]) {
        this.selectionner(this.searchResults[this.highlightedIndex])
      }
    },
    selectionner(selection) {
      this.resultatsDeRechercheVisible = false
      if (this.selectionActive) {
        this.query = selection.nomIngredient
      }
      this.$emit('input', selection)
    },
    declencherAjout() {
      this.$emit('ajouter')
      this.resultatsDeRechercheVisible = false
    },
    reset() {
      this.query = ''
      this.highlightedIndex = 0
      this.$refs.search.focus()
      this.$emit('reset')
    },
    softReset() {
      this.resultatsDeRechercheVisible = true
      this.highlightedIndex = 0
    },
    selectionnerValeur(valeur) {
      this.resultatsDeRechercheVisible = false
      this.query = valeur
    },
    startTimeout() {
      clearTimeout(this.timeoutRecherche)
      this.timeoutRecherche = setTimeout(this.rechercher, 500)
    },
    rechercher() {
      if (this.query.length >= 3) {
        // On recherche les produits qui contiennent tous les mots recherchés puis on trie par pertinence (on affiche en priorité les produits qui commencent par le premier mot saisi dans la recherche)
        this.searchResults = this.liste.filter(elt => rechercherMultiTexte(elt.recherche, this.query)).sort((a, b) => formaterPourRecherche(a.recherche).indexOf(formaterPourRecherche(this.query.split(" ")[0])) < formaterPourRecherche(b.recherche).indexOf(formaterPourRecherche(this.query.split(" ")[0])) ? -1 : 1)
        this.resultatsDeRechercheVisible = true
      } else {
        this.resultatsDeRechercheVisible = false
      }
    },
    highlightPrevious() {
      if (this.highlightedIndex > 0) {
        this.highlightedIndex = this.highlightedIndex - 1
        this.scrollIntoView()
      }
    },
    highlightNext() {
      if (this.highlightedIndex < this.searchResults.length - 1) {
        this.highlightedIndex = this.highlightedIndex + 1
        this.scrollIntoView()
      }
    },
    scrollIntoView() {
      this.$refs.results.children[this.highlightedIndex].scrollIntoView({ block: 'nearest' })
    }
  },
  async mounted() {
    if (this.chercherProduits) {
      let produits = this.listeProduits
      if (!produits) {
        produits = this.getProduits
        if (this.fournisseurId) {
          let response = await obtenirProduitsFournisseur(this.fournisseurId)
          produits = produits.filter(p => response.data.some(d => d === p.id))
        }
      }
      this.liste.push(...produits)
    }
    if (this.chercherIngredients) {
      let ingredients = this.listeIngredients
      if (!ingredients) {
        let response = await getIngredientsPourRechercheComplete()
        ingredients = response.data
      }
      this.liste.push(...ingredients)
    }
    if (this.chercherRecettes) {
      let recettes = this.listeRecettes
      if (!recettes) {
        let responseRecettes = await getRecettesPourRechercheComplete(this.etabCourantId)
        let responseSousRecettes = await chargerParFiltre(null, { etablissementId: this.etabCourantId, propriete: "&filtre=0", unitesProduction: "KG,L" })
        recettes = responseRecettes.data.filter(r => responseSousRecettes.data.some(sr => sr.id === r.ingredientId))
      }
      this.liste.push(...recettes)
    }
    if (this.initValeur) {
      this.query = this.initValeur
      await this.rechercher()
      this.resultatsDeRechercheVisible = false
    }
    await this.majFavoris()
    this.recherchePrete = true
  }
}
</script>
