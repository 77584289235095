<template>
  <div>
    <ModalAjoutProduitHM ref="modalAjoutProduit" :fournisseur="{fournisseurId}" :paramModale="{modeAffichage:'ajoutProduit'}" @produit-modifie="selectionNouveauProduitHM($event)" />
    <RechercheCompleteProduits ref="recherche" placeHolder="Rechercher un produit..." :ajoutActif="ajoutActif" :selectionActive="false" :fournisseurId="fournisseurId" :chercherProduits="true" :afficherPrix="false" @input="selectionProduit($event)" @reset="reset()" @ajouter="creationArticle()" />
  </div>
</template>

<script>
import { connecteData } from "@/mixins/connecteData"
import { tousArticlesPour } from '@/api/gpao/mercuriales'
import ModalAjoutProduitHM from '@/components/Divers/ModalAjoutProduitHM'
import RechercheCompleteProduits from "@/components/Mercuriales/RechercheCompleteProduits"

export default {
  props: ['fournisseurId', 'ajoutActif'],
  mixins: [connecteData],
  components: {
    ModalAjoutProduitHM,
    RechercheCompleteProduits
  },
  mounted() {
    setTimeout(() => { this.focusRecherche() }, 10)
  },
  methods: {
    focusRecherche() {
      if (this.$refs.recherche) {
        this.$refs.recherche.$vnode.elm.querySelector("input").focus()
      }
    },

    reset(optionalState) {
      this.$emit("change-ingredient", {})
      this.$emit("change-state", optionalState || "")
    },

    selectionNouveauProduitHM(objet) {
      if (objet?.produits?.length) {
        let pId = objet.produits[0].produitId
        if (pId) {
          this.selectionProduit({ id: pId })
        }
      } else {
        this.reset()
      }
    },

    selectionProduit(produit) {
      this.$emit("change-state", "Recherche en cours...")
      if (produit) {
        return tousArticlesPour(this.etabCourantId, produit.id, this.fournisseurId)
          .then(r => {
            this.updateArticles(r.data || {})
            return
          })
      } else {
        this.reset()
      }
    },

    creationArticle() {
      this.$refs.modalAjoutProduit.show()
    },

    updateArticles(data) {
      var state
      if (data.parProduit && Object.values(data.parProduit).length) {
        state = ""
        this.$emit("change-state", state)
        this.$emit("change-ingredient", data)
      } else {
        this.reset("Aucun article ne correspond à ces critères de recherche")
      }
    }
  }
}
</script>

<style>
#selectFamilleRechercheArticles > div > div.dropdown {
  width: 100%;
  box-shadow: none;
}

#selectFamilleRechercheArticles > div > div.dropdown > button {
  text-align: left;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  height: 41px;
}

#selectFamilleRechercheArticles > div > div.dropdown > button > span {
  vertical-align: sub;
}

#selectFamilleRechercheArticles > div > div.dropdown > button::after {
  float: right;
  transform: scale(1.5);
  color: rgba(60, 60, 60, 0.5);
  margin-right: 8px;
}
</style>
