<template>
  <b-modal ref="modalDuplicationCommande" size="md" title="Dupliquer une commande"
    ok-title="Dupliquer" :ok-disabled="!$v.optionsDuplication.nom.required || !$v.optionsDuplication.nom.minLength || !$v.optionsDuplication.dateLivraisonDebut.required"
    cancel-title="Annuler"
    no-close-on-backdrop
    @show="initialiserModale()"
    @shown="$refs.nomNouvelleCommande.focus()"
    @ok="dupliquerCommande()">
    <template slot="default">
      <div class="form-group">
        <label for="nomNouvelleCommande">Nom de la nouvelle commande</label>
        <input type="text" class="container-fluid" id="nomNouvelleCommande" ref="nomNouvelleCommande" v-model.trim="optionsDuplication.nom" maxlength="80" @focus="$event.target.select()">
        <div class="error" v-if="!$v.optionsDuplication.nom.required">Ce champ est requis</div>
        <div class="error" v-if="!$v.optionsDuplication.nom.minLength">Ce champ doit contenir au moins 3 caractères</div>
      </div>

      <!--<div class="form-check background-primary form-check-inline">
        <input class="form-check-input" type="checkbox" id="cbAvecQuantites" v-model="optionsDuplication.avecQuantites" />
        <label for="cbAvecQuantites" class="form-check-label">Dupliquer les quantités</label>
      </div>-->

      <div class="form-group">
        <label for="dpDateLivraisonDebut">Livraisons souhaitées à partir du...</label>
        <v-date-picker class="date-picker" id="dpDateLivraisonDebut" v-model="optionsDuplication.dateLivraisonDebut" :popover="{ visibility: 'click' }" @input="optionsDuplication.dateLivraisonDebut = $event">
          <template v-slot="{ inputValue, inputEvents }">
            <input :value="inputValue ? inputValue : 'Livraisons souhaitées à partir du...'" v-on="inputEvents" />
          </template>
        </v-date-picker>
        <div class="error" v-if="!$v.optionsDuplication.dateLivraisonDebut.required">Ce champ est requis</div>
      </div>

      <div class="form-group">
        <label for="selEtablissement">Vers établissement</label>
        <v-select id="selEtablissement" v-model="etablissementSelectionne" :options="optionsEtablissements" value="eId" label="eRS"
          placeholder="Établissements..." :clearable="false" @input="optionsDuplication.nouvelEtablissementId = etablissementSelectionne.eId">
        </v-select>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { dupliquerCommande } from '@/api/gpao/commandes'
import { connecteData } from "@/mixins/connecteData"
import { showAlerts } from '@/mixins/alerts'
import { required, minLength, maxLength } from "vuelidate/lib/validators"

export default {
  name: "ModalDuplicationCommande",
  mixins: [ connecteData, showAlerts ],
  props: [ 'commande' ],
  data() {
    return {
      etablissementSelectionne: null,
      optionsDuplication: {}
    }
  },
  validations: {
    optionsDuplication: {
      nom: {
        required,
        minLength: minLength(3),
        maxlength: maxLength(80)
      },
      dateLivraisonDebut: { required }
    }
  },
  computed: {
    optionsEtablissements() {
      let result = []
      this.etablissementsCourants.map(el => {
        result.push({eId: el.eId, eRS: el.eRS})
      })
      result.sort(function(a,b){  return a.eRS.localeCompare(b.eRS)})
      return result;
    }
  },
  methods: {
    initialiserModale() {
      this.etablissementSelectionne = this.optionsEtablissements.find(e => e.eId === this.etabCourantId)
      this.optionsDuplication = {
        nom: "",
        avecQuantites: true,
        avecTarifs: true,
        nouvelEtablissementId: this.etablissementSelectionne.eId,
        dateLivraisonDebut: ""
      }
    },
    dupliquerCommande() {
      return dupliquerCommande(this.commande.id, this.optionsDuplication)
      .then((response) => {
        this.hide()
        this.alerteSucces("Commande dupliquée sur l'établissement " + this.etablissementSelectionne.eRS + " !")
        this.$emit("commande-dupliquee", { commande: response.data, etablissementId: this.etablissementSelectionne.eId })
        return
      })
    },
    show() {
      this.$refs.modalDuplicationCommande.show()
    },
    hide() {
      this.$refs.modalDuplicationCommande.hide()
    }
  }
}
</script>
