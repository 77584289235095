<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-3 pr-1">
        <!-- Filtres -->
        <div class="card table-scroll height-responsive-filtre-100">
          <div class="card-header">
            Filtres
          </div>
          <div class="table-body">
            <table class="table table-sm table-borderless">
              <tbody>
                <!-- Catégories -->
                <tr class="table-header-secondary" style="position:sticky;top:0;">
                  <td>Catégories</td>
                </tr>
                <template v-for="(categorie, iCat) in categories">
                  <tr :key="'cat'+iCat" style="position:sticky;top:26px;background:white;">
                    <td class="form-group">
                      <label class="label-checkbox mt-1">
                        <input type="checkbox" v-model="categorie.checked" @change="getfilteredDataCategories(categorie)">
                        {{ categorie[0] }}
                      </label>
                    </td>
                  </tr>
                  <tr v-for="(categorieItem, iSousCat) in categorie[1]" :key="'cat'+iCat+'sousCat'+iSousCat">
                    <td class="pl-4">
                      <label class="label-checkbox mt-1">
                        <input type="checkbox" v-model="categorieItem.checked" @change="getfilteredDataCategories(categorieItem)">
                        {{ categorieItem.text }}
                      </label>
                    </td>
                  </tr>
                </template>

                <!-- Composantes -->
                <tr class="table-header-secondary" style="position:sticky;top:0;">
                  <td>Composantes</td>
                </tr>
                <template v-for="composante in composantes">
                  <tr v-for="(composanteItem, i) in composante[1]" :key="'comp'+i" style="position:sticky;top:26px;background:white;">
                    <td class="form-group">
                      <label class="label-checkbox mt-1">
                        <input type="checkbox" v-model="composanteItem.checked" @change="getfilteredDataComposantes">
                        {{ composanteItem.text }}
                      </label>
                    </td>
                  </tr>
                </template>

                <!-- Régimes -->
                <tr class="table-header-secondary" style="position:sticky;top:0;">
                  <td>Régimes</td>
                </tr>
                <template v-for="regime in regimesSelect">
                  <tr v-for="(regimeItem, i) in regime[1]" :key="'reg'+i" style="position:sticky;top:26px;background:white;">
                    <td class="form-group">
                      <label class="label-checkbox mt-1">
                        <input type="checkbox" v-model="regimeItem.checked" @change="getfilteredDataRegimes">
                        {{ regimeItem.text }}
                      </label>
                    </td>
                  </tr>
                </template>

                <!-- Textures -->
                <tr class="table-header-secondary" style="position:sticky;top:0;">
                  <td>Textures</td>
                </tr>
                <template v-for="texture in texturesSelect">
                  <tr v-for="(textureItem, i) in texture[1]" :key="'text'+i" style="position:sticky;top:26px;background:white;">
                    <td class="form-group">
                      <label class="label-checkbox mt-1">
                        <input type="checkbox" v-model="textureItem.checked" @change="getfilteredDataTextures">
                        {{ textureItem.text }}
                      </label>
                    </td>
                  </tr>
                </template>

                <!-- Catégories GEMRCN -->
                <tr class="table-header-secondary" style="position:sticky;top:0;">
                  <td>Catégories GEMRCN</td>
                </tr>
                <template v-for="categorieGEMRCN in categoriesGEMRCNSelect">
                  <tr v-for="(categorieGEMRCNItem, i) in categorieGEMRCN[1]" :key="'gemrcn'+i" style="position:sticky;top:26px;background:white;">
                    <td class="form-group">
                      <label class="label-checkbox mt-1">
                        <input type="checkbox" v-model="categorieGEMRCNItem.checked" @change="getFilteredDataCategoriesGEMRCN">
                        {{ categorieGEMRCNItem.text }}
                      </label>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- Recettes -->
      <div class="col-4 pl-1 pr-1">
        <div class="card table-scroll height-responsive-filtre-100">
          <div class="card-header flex-center-space-between">
            Recettes
            <div class="flex-center-space-between">
              <i v-tooltip="{ content: detailsSwitch('personnelles') }" class="fas fa-user cursor-pointer" style="font-size:1.2rem;color:rgba(255, 255, 255, 0.4)" id="rdbUser" @click="switchType('personnelles')" :class="typeDeRecherche === 'personnelles' ? 'selected' : ''"></i>
              <i v-tooltip="{ content: detailsSwitch('etablissement') }" class="far fa-building cursor-pointer" style="margin-left:1rem;font-size:1.2rem;color:rgba(255, 255, 255, 0.4)" id="rdbEtab" @click="switchType('etablissement')" :class="typeDeRecherche === 'etablissement' ? 'selected' : ''"></i>
              <i v-tooltip="{ content: detailsSwitch('universelles') }" class="fas fa-globe cursor-pointer" style="margin-left: 1rem;font-size: 1.4rem;display: flex;;color:rgba(255, 255, 255, 0.4)" id="rdbUniv" @click="switchType('universelles')" :class="typeDeRecherche === 'universelles' ? 'selected' : ''"></i>
              <i v-tooltip="{ content: detailsSwitch('toutes') }" class="fas fa-infinity cursor-pointer" style="margin-left: 1rem;font-size: 1.2rem;display: flex;;color:rgba(255, 255, 255, 0.4)" id="rdbUniv" @click="switchType('toutes')" :class="typeDeRecherche === 'toutes' ? 'selected' : ''"></i>
            </div>
            <span v-if="allowMultiAction">
              <label class="label-checkbox mt-1">
                <span class="ml-2">Multi</span>
                <ToggleButton class="ml-2 mt-1" v-model="enableMulti" :color="{checked: '#16D39A', unchecked: '#CCCCCC'}" :height="12" :width="20" @change="activerFiltreLignesSurFournisseurs()" />
              </label>
            </span>
            <b-button v-if="$route.name !== 'Menus' && isUtilisateur" class="btn btn-success btn-sm" @click="nouvelleRecette()" :disabled="enableMulti" alt="Nouvelle" style="margin-left:6px">Nouvelle</b-button>
          </div>
          <table class="table table-sm table-borderless m-0 p-0" v-if="listeDesRecettes" ref="listeRecettes">
            <thead>
              <th class="p-0">
                <VueFuse class="p-0"
                  :placeholder="compTermeDeRecherche"
                  :list="list"
                  :keys="['nom']"
                  :search="termeDeRecherche"
                  @fuse-input-changed="getTermeDeRecherche"
                  @fuse-results-updated="retourRecherche"
                  @keyup.enter="currentItem = 0" />
                <div style="text-align:right">
                  <span v-if="termeDeRecherche" v-tooltip="{ content: 'Effacer la recherche'}" @click="effacerLaRecherche()" class="fas fa-window-close mt-1 pt-3"></span>
                </div>
              </th>
            </thead>
          </table>
          <div class="table-body">
            <table class="table table-sm table-borderless">
              <tbody>
                <tr v-for="(recetteInList, i) in listeDesRecettes" :key="i" @keyup='nextItem' @click.stop="currentRecette(i)" :class="{'active-item_recetteliste': (enableMulti && selectedMulti.indexOf(recetteInList.id) > -1 || !enableMulti && currentItem === i)}">
                  <td class="pl-4 pr-4 flex-center-space-between">
                    <div>
                      <i v-if="enableMulti && selectedMulti.indexOf(recetteInList.id) === -1" class="fas fa-square" style="margin-right: 10px;"></i>
                      <i v-if="enableMulti && selectedMulti.indexOf(recetteInList.id) > -1" class="fas fa-check-square" style="margin-right: 10px;"></i>
                      <i v-if="recetteInList.estPerso" class="fas fa-user push-right-1rem"></i>
                      <i v-if="recetteInList.estEtab" class="far fa-building push-right-1rem"></i>
                      <i v-if="recetteInList.estDeclinaisonPersonnalisee" class="fas fa-check push-right-1rem"></i>
                      <i v-if="enableMulti && recetteInList.categorieValideeEnMasse" class="fas fa-check push-right-1rem" style="color:blue"></i>
                      <i v-if="enableMulti && recetteInList.zonePreparationValideeEnMasse" class="fas fa-check push-right-1rem" style="color:pink"></i>
                      <i v-if="enableMulti && recetteInList.texturesValideesEnMasse" class="fas fa-check push-right-1rem" style="color:yellow"></i>
                      <i v-if="enableMulti && recetteInList.regimesValidesEnMasse" class="fas fa-check push-right-1rem" style="color:green"></i>
                      <i v-if="enableMulti && recetteInList.composantesValideesEnMasse" class="fas fa-check push-right-1rem" style="color:orange"></i>
                      <i v-if="enableMulti && recetteInList.categoriesGEMRCNValideesEnMasse" class="fas fa-check push-right-1rem" style="color:brown"></i>
                      {{recetteInList.nom.charAt(0).toUpperCase() + recetteInList.nom.slice(1)}}
                    </div>
                    <span v-if="menu && menu.menuSurPlanAlimentaire && nbUtilisationsRecette(recetteInList)" class="bg-white border border-dark" style="font-size:12px;height:18px;width:50px;right:0;border-radius: 5px;text-align:center">PA : {{ nbUtilisationsRecette(recetteInList) }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- Aperçu de recette -->
      <div class="col-5 pl-1">
        <div class="card">
          <div v-if="enableMulti"><!-- Actions de masse -->
            <div class="card-header flex-center-space-between">
              <template v-if="selectedMulti.length === 0">VEUILLEZ SELECTIONNER DES RECETTES</template>
              <template v-else>ACTIONS {{ selectedMulti.length > 1 ? "EN MASSE " : "" }}SUR {{ selectedMulti.length }} RECETTE{{ selectedMulti.length > 1 ? "S" : "" }}</template>
            </div>

            <div class="card-body">
              <div v-if="selectedMulti.length === 0" class="card-body">
                Utilisez la colonne centrale pour sélectionner des recettes
              </div>

              <div v-else class="card-body"><!-- Paramètres -->
                <div class="p-2" style="border:1px solid blue">
                  <label style="font-size:10px!important;color:rgba(0,0,0,.54);margin-bottom:2px">Catégorie</label>
                  <b-form-select v-model.trim="changesMulti.categorieRecetteId" :options="categorieRecettes" :text-field="'text'" :value-field="'value'">
                    <template slot="first">
                      <option :value="null" selected>Catégorie...</option>
                    </template>
                  </b-form-select>
                </div>

                <div class="p-2 mt-3" style="border:1px solid pink">
                  <label style="font-size:10px!important;color:rgba(0,0,0,.54);margin-bottom:2px">Zone de préparation</label>
                  <b-form-select v-model.trim="changesMulti.zonePreparationId" :options="zonesPreparations" :text-field="'text'" :value-field="'value'">
                    <template slot="first">
                      <option :value="null" selected>Zone de préparation...</option>
                    </template>
                  </b-form-select>
                </div>

                <div class="p-2 mt-3" style="border:1px solid yellow">
                  <label style="font-size:10px!important;color:rgba(0,0,0,.54);margin-bottom:2px">Textures incompatibles</label>
                  <MultiSelect :value="changesMulti.textureIds" :items="texturesPourExclusion" placeholder="Textures..." emptyText="Toutes les textures ont été ajoutées" @change="changesMulti.textureIds = $event; changesMulti.aucuneTextureIncompatible = false" />
                  <input type="checkbox" id="aucuneTextureIncompatible" class="form-check-input ml-0" :checked="changesMulti.aucuneTextureIncompatible" @change="setAucuneTextureIncompatible($event.currentTarget.checked)" />
                  <label class="form-check-label ml-4" for="aucuneTextureIncompatible">Aucune incompatibilité ?</label>
                </div>

                <div class="p-2 mt-3" style="border:1px solid green">
                  <label style="font-size:10px!important;color:rgba(0,0,0,.54);margin-bottom:2px">Régimes alimentaires incompatibles</label>
                  <MultiSelect :value="changesMulti.regimeIds" :items="regimesPourExclusion" placeholder="Régimes alimentaires..." emptyText="Tous les régimes ont été ajoutés" @change="changesMulti.regimeIds = $event; changesMulti.aucunRegimeIncompatible = false" />
                  <input type="checkbox" id="aucunRegimeIncompatible" class="form-check-input ml-0" :checked="changesMulti.aucunRegimeIncompatible" @change="setAucunRegimeIncompatible($event.currentTarget.checked)" />
                  <label class="form-check-label ml-4" for="aucunRegimeIncompatible">Aucune incompatibilité ?</label>
                </div>

                <div class="p-2 mt-3" style="border:1px solid orange">
                  <label style="font-size:10px!important;color:rgba(0,0,0,.54);margin-bottom:2px">Composantes de repas</label>
                  <MultiSelect :value="changesMulti.composanteMenuIds" :items="composantesRepas" placeholder="Composantes..." emptyText="Toutes les composantes ont été ajoutées" @change="changesMulti.composanteMenuIds = $event" />
                </div>

                <div class="p-2 mt-3" style="border:1px solid brown">
                  <label style="font-size:10px!important;color:rgba(0,0,0,.54);margin-bottom:2px">Catégories GEMRCN</label>
                  <MultiSelect :value="changesMulti.gemrcnIds" :items="gemrcn" placeholder="Catégories GEMRCN..." emptyText="Toutes les catégories ont été ajoutées" @change="changesMulti.gemrcnIds = $event" />
                </div>

                <b-button class="btn btn-success" style="margin-top: 20px;" @click="enregistrerMulti()" alt="Enregistrer">Enregistrer</b-button>
              </div>
            </div>

            <div class="card"><!-- Liste des recettes sélectionnées -->
              <div class="card-body">
                <h4 v-if="selectedMulti.length === 0">Aucune recette sélectionnée</h4>
                <h4 v-else>Recette{{ selectedMulti.length > 1 ? "s" : "" }} sélectionnée{{ selectedMulti.length > 1 ? "s" : "" }}</h4>
                <div v-for="id in selectedMulti" :key="id" class="flex-center-space-between">
                  <div>{{ nomRecette(id) }}</div>
                  <div style="cursor: pointer;" v-tooltip="{ content: 'Retirer \'\'' + nomRecette(id) + '\'\' de la sélection'}" @click="toggleRecetteMulti(id)"><i class="fas fa-times"></i></div>
                </div>
              </div>
            </div>
          </div>

          <div v-else-if="!pasDeRecette"><!-- Détails recette -->
            <RecetteApercu v-if="menuExpanded" :recette="recette" :composanteEffectif="composanteEffectif" :destination="{composanteId: composanteId, serviceId: serviceId, cleComposee: cleComposee}" @recette-supprimee="rechargerListe()" />
            <RecetteApercu v-else :recette="recette" :composanteEffectif="composanteEffectif" :destination="{composanteId: composanteId, serviceId: serviceId, cleComposee: cleComposee}" @recette-supprimee="rechargerListe()" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash"
import MultiSelect from "@/components/Inputs/MultiSelect"
import { recetteLignesFromData, apiTypeDeRecherche, verifierAutorisations } from "@/mixins/recette"
import { chargerParFiltre, obtenirPourEdition, modifierRecettesEnMasse } from "@/api/gpao/recettes"
import VueFuse from "@/components/Divers/VueFuse"
import RecetteApercu from "@/components/Recettes/RecetteApercu"
import { connecteData } from "@/mixins/connecteData"
import { mapGetters } from "vuex"
import { showAlerts } from "@/mixins/alerts"
import { constantesId } from "@/helpers/constantes"

export default {
  name: "RecetteListe",
  props: [
    "cleComposee",
    "recetteList",
    "composanteId",
    "regimeIdsInit",
    "textureIdsInit",
    "categoriesGEMRCNIdsInit",
    "serviceId",
    "recetteId",
    "composanteEffectif",
    "allowMultiAction",
    "menu"
  ],
  components: {
    VueFuse,
    RecetteApercu,
    MultiSelect,
  },
  mixins: [recetteLignesFromData, apiTypeDeRecherche, connecteData, verifierAutorisations, showAlerts],
  data() {
    return {
      menuExpanded: false,
      currentItem: 0,
      recette: null,
      total: "",
      categories: [],
      composantes: [],
      regimesSelect: [],
      texturesSelect: [],
      categoriesGEMRCNSelect: [],
      categorieIds: [],
      composanteIds: [],
      regimeIds: [],
      textureIds: [],
      categoriesGEMRCNIds: [],
      list: [],
      filteredList: [],
      montrerLesCategories: true,
      montrerLesComposantes: true,
      montrerLesRegimes: true,
      montrerLesTextures: true,
      montrerLesCategoriesGEMRCN: true,
      typeDeRecherche: "toutes",
      termeDeRecherche: "",
      propriete: null,
      pasDeRecette: false,
      premierChargement: false,
      enableMulti: false,
      selectedMulti: [],
      changesMulti: {
        categorieRecetteId: null,
        zonePreparationId: null,
        textureIds: [],
        aucuneTextureIncompatible: false,
        gemrcnIds: [],
        composanteMenuIds: [],
        regimeIds: [],
        aucunRegimeIncompatible: false
      },
      texturesPourExclusion: [],
      regimesPourExclusion: []
    }
  },
  computed: {
    ...mapGetters("auth", ["isUtilisateur"]),
    ...mapGetters("definitions", ["textures", "composantesRepas", "regimes", "gemrcn", "categorieRecettes", "zonesPreparations"]),
    listeDesRecettes() {
      return this.filteredList
    },
    compTermeDeRecherche() {
      if (this.termeDeRecherche) {
        return this.termeDeRecherche + "..."
      } else {
        return (
          "Rechercher dans " +
          this.list.length +
          (this.list.length === 0 || this.list.length === 1
            ? " recette..."
            : " recettes...")
        )
      }
    }
  },
  watch: {
    enableMulti(multi) {
      this.selectedMulti = []
      if (!multi) {
        this.currentItem = 0
      }
    }
  },
  methods: {
    nbUtilisationsRecette(recette) {
      if (!recette)
        return 0
      // Nombre de fois où la recette est utilisée dans le menu actuel
      let nbUtilisationsMenuCourant = this.menu?.services?.map(s => s.composantes).flat(1).filter(c => c?.recetteId === recette.id).length || 0
      // On ajoute à ce calcul le nombre d'utilisations de cette recettes sur les autres menu du plan alimentaire rattaché à ce menu
      return recette.nbUtilisations + nbUtilisationsMenuCourant
    },
    nomRecette(id) {
      let recette = this.recetteList.find(r => r.id === id)
      if (!recette) {
        recette = this.filteredList.find(r => r.id === id)
      }
      if (recette) {
        return recette.nom.charAt(0).toUpperCase() + recette.nom.slice(1)
      }
      return ""
    },
    retourRecherche(results) {
      this.filteredList = results
      this.currentItem = 0
      this.apercuRecette(this.filteredList[0])
    },
    detailsSwitch(type) {
      if (type === "universelles") {
        return "Universelles"
      }
      if (type === "toutes") {
        return "Toutes"
      }
      if (!this.utilisateurCourant || !this.utilisateurCourant.userName)
        return;
      if (type === "personnelles") {
        let username = this.utilisateurCourant.userName
        return "Appartenant à " + username
      }
      if (type === "etablissement") {
        if (!this.etabCourant)
          return;
        let nomEtablissement = this.etabCourant.eRS
        return "Appartenant à " + nomEtablissement
      }
    },
    switchType(type) {
      this.typeDeRecherche = type
      this.$store.commit('recettes/setFiltresEnMemoire', {key: 'typeDeRechercheEnMemoire', data: this.typeDeRecherche})
      this.$emit("set-type-de-recherche", this.typeDeRecherche)
      this.premierChargement = false
      this.filterList()
    },
    filterList() {
      if (this.premierChargement) {
        return
      }
      return chargerParFiltre(this.menu?.id, {
        key: "liste",
        etablissementId: this.etabCourantId,
        categorieIds: this.categorieIds,
        composanteIds: this.composanteIds,
        regimeIds: this.regimeIds,
        textureIds: this.textureIds,
        categoriesGEMRCNIds: this.categoriesGEMRCNIds,
        propriete: this.apiTypeDeRecherche(this.typeDeRecherche)
      })
        .then(response => {
          this.list = response.data
          this.filteredList = response.data
          this.currentItem = 0
          if (!this.list.length) {
            this.pasDeRecette = true
          } else {
            this.pasDeRecette = false
            this.apercuRecette(this.list[0])
          }
          return
        })

    },
    contientUneCategorieCochee(categorie) {
      return categorie.some(el => el.checked)
    },
    currentRecette(i) {
      let recette = this.filteredList[i]
      if (this.enableMulti) {
        this.toggleRecetteMulti(recette.id)
      } else if (i === this.currentItem) {
        this.selectionRecette(recette.id)
      } else {
        this.currentItem = i
        this.verifierVisible(recette)
      }
    },
    toggleRecetteMulti(id) {
      let index = this.selectedMulti.indexOf(id)
      if (index > -1) {
        this.selectedMulti.splice(index, 1)
      } else {
        this.selectedMulti.push(id)
        this.selectedMulti.sort()
      }
    },
    async getDetailsRecette(recetteId) {
      let response = await obtenirPourEdition(recetteId, this.etabCourantId)
      this.verifierAutorisations(response.data)
      response.data.lignesCompletees = await this.recetteLignesFromData(response.data)
      return response.data
    },
    apercuRecette: _.debounce(function (recette) {
      if (recette) {
        const index = this.filteredList.findIndex(el => el.id === recette.id)
        this.currentItem = index

        return this.getDetailsRecette(recette.id)
          .then(function (result) {
            this.recette = result
            return
          }.bind(this))
      }
    }, 300),
    verifierVisible(recette) {
      setTimeout(() => {
        var el = this.$refs.listeRecettes
          ?.querySelector(".active-item_recetteliste")
          ?.getBoundingClientRect()
        if (el) {
          if (el.top < 56) {
            window.scrollBy(0, -100)
          }
          if (el.top + el.height > window.innerHeight) {
            window.scrollBy(0, 100)
          }
        }
        this.apercuRecette(recette)
      }, 0)
    },
    effacerLaRecherche() {
      this.termeDeRecherche = ""
    },
    rechargerListe() {
      this.premierChargement = false
      this.filterList()
    },
    getTermeDeRecherche(evt) {
      this.termeDeRecherche = evt
      if (this.$route.name !== "Menus") {
        this.$store.commit('recettes/setFiltresEnMemoire', {key: 'termeDeRechercheEnMemoire', data: this.termeDeRecherche})
      }
      this.$emit("set-terme-de-recherche", evt)
    },
    removeSpace(word) {
      return word.replace(/ +/g, "")
    },
    checkFilterSiVide() {
      if (!this.categorieIds.length && !this.composanteIds.length && !this.regimeIds.length && !this.textureIds.length && !this.categoriesGEMRCNIds.length) {
        this.list = this.recetteList
        this.currentItem = 0
      }
    },
    getfilteredDataCategories(item) {
      if (item.length) {//modification d'une catégorie mère => mise à jour des sous-catégories
        item[1].forEach(sCategorie => {
          sCategorie.checked = item.checked
        })
      } else {//modification d'une sous-catégorie => mise à jour de la catégorie mère
        let categorie = this.categories.find(c => c[1].find(sc => sc.value === item.value))
        categorie.checked = categorie[1].filter(sc => sc.checked).length > 0//on coche la catégorie mère si au moins une sous-catégorie est cochée
      }
      this.enregistrerLesFiltres()
      this.checkFilterSiVide()
      this.premierChargement = false
      this.filterList()
      this.$emit("set-categories", this.categorieIds)
    },
    getfilteredDataComposantes() {
      this.$emit("set-composantes", this.composanteIds)
      this.updateListeRecettes()
    },
    getfilteredDataRegimes() {
      this.updateListeRecettes()
    },
    getfilteredDataTextures() {
      this.updateListeRecettes()
    },
    getFilteredDataCategoriesGEMRCN() {
      this.updateListeRecettes()
    },
    updateListeRecettes() {
      this.enregistrerLesFiltres()
      this.checkFilterSiVide()
      this.premierChargement = false
      this.filterList()
    },
    nouvelleRecette() {
      var opt = {
        name: "RecetteEdition",
      }
      if (this.$route.name === "Menus") {
        opt.params = {
          retour: {name: "Menus"},
          destination: {
            composanteId: this.composanteId,
            serviceId: this.serviceId,
            cleComposee: this.cleComposee,
          }
        }
      }
      this.$router.push(opt)
    },
    selectionRecette(recetteId) {
      if (this.$route.name === "Recettes") {
        this.$router.push({
          name: "RecetteEdition",
          params: { recetteId: recetteId }
        })
      }
      if (this.$route.name === "Menus") {
        let recette = this.recette
        if (!recette || recetteId != recette.id) {
          console.log("RecetteListe/Recherchercette")
          recette = this.listeDesRecettes.find(r => r.id == recetteId)
        }
        this.$emit("changement-recette", {
          composanteId: this.composanteId,
          serviceId: this.serviceId,
          cleComposee: this.cleComposee,
          recetteId: recetteId
        })
      }
    },
    nextItem() {
      if (this.enableMulti) {
        return
      }
      if (event.key === "ArrowUp" && this.currentItem > 0) {
        this.currentItem--
        this.verifierVisible(this.filteredList[this.currentItem])
      }
      if (
        event.key === "ArrowDown" &&
        this.currentItem < this.filteredList.length
      ) {
        this.currentItem++
        this.verifierVisible(this.filteredList[this.currentItem])
      }
      if (event.key === "Enter") {
        this.$router.push({
          name: "RecetteEdition",
          params: { recetteId: this.filteredList[this.currentItem].id }
        })
      }
    },
    itemPrice(prixUnitaire, quantite) {
      return prixUnitaire * quantite
    },
    setTotal(array) {
      let hasUndefined = function (element) {
        return element === undefined
      }
      if (
        !array.map(item => item.prixUnitaire).some(hasUndefined) &&
        !array.map(item => item.quantite).some(hasUndefined)
      ) {
        this.total = array
          .map(item => item.prixUnitaire * item.quantite)
          .reduce(function (acc, val) {
            return acc + val
          }, 0)
          .toFixed(2)
        return this.total + " €"
      }
    },
    chargerLesFiltres() {
      if (this.$route.name === "Menus") {
        this.regimeIds = this.regimeIdsInit || []
        this.textureIds = this.textureIdsInit || []
        this.categoriesGEMRCNIds = this.categoriesGEMRCNIdsInit || []
        this.typeDeRecherche = _.cloneDeep(this.$store.state.recettes.filtresEnMemoire.typeDeRechercheEnMemoire) || "toutes"
      } else {
        this.categorieIds = _.cloneDeep(this.$store.state.recettes.filtresEnMemoire.categorieIdsEnMemoire)
        this.composanteIds = _.cloneDeep(this.$store.state.recettes.filtresEnMemoire.composanteIdsEnMemoire)
        this.regimeIds = _.cloneDeep(this.$store.state.recettes.filtresEnMemoire.regimeIdsEnMemoire)
        this.textureIds = _.cloneDeep(this.$store.state.recettes.filtresEnMemoire.textureIdsEnMemoire)
        this.termeDeRecherche = _.cloneDeep(this.$store.state.recettes.filtresEnMemoire.termeDeRechercheEnMemoire)
        this.typeDeRecherche = _.cloneDeep(this.$store.state.recettes.filtresEnMemoire.typeDeRechercheEnMemoire) || "toutes"
      }
      this.categories.forEach(function (categorie) {
        categorie[1].forEach(function (sCategorie) {
          sCategorie.checked = this.categorieIds.includes(sCategorie.value)
        }.bind(this))
        categorie.checked = categorie[1].length === categorie[1].filter(item => item.checked === true).length
      }.bind(this))

      this.composantes[0][1].forEach(function (composante) {
        composante.checked = this.composanteId === composante.value || this.composanteIds.includes(composante.value)
      }.bind(this))

      this.regimesSelect[0][1].forEach(function (regime) {
        regime.checked = this.regimeIds.includes(regime.value)
      }.bind(this))

      this.texturesSelect[0][1].forEach(function (texture) {
        texture.checked = this.textureIds.includes(texture.value)
      }.bind(this))

      this.categoriesGEMRCNSelect[0][1].forEach(function (categorieGEMRCN) {
        categorieGEMRCN.checked = this.categoriesGEMRCNIds.includes(categorieGEMRCN.value)
      }.bind(this))
    },
    enregistrerLesFiltres() {
      this.categorieIds = []
      this.categories.forEach(function (categorie) {
        categorie[1].forEach(function (sCategorie) {
          if (sCategorie.checked) {
            if (!this.categorieIds.includes(sCategorie.value)) {
              this.categorieIds.push(sCategorie.value)
            }
          } else {
            _.remove(this.categorieIds, function (cId) {
              return cId === sCategorie.value
            })
          }
        }.bind(this))
      }.bind(this))
      
      this.composanteIds = []
      this.composantes[0][1].forEach(function (composante) {
        if (composante.checked) {
          if (!this.composanteIds.includes(composante.value)) {
            this.composanteIds.push(composante.value)
          }
        } else {
          _.remove(this.composanteIds, function (cId) {
            return cId === composante.value
          })
        }
      }.bind(this))
      
      this.regimeIds = []
      this.regimesSelect[0][1].forEach(function (regime) {
        if (regime.checked) {
          if (!this.regimeIds.includes(regime.value)) {
            this.regimeIds.push(regime.value)
          }
        } else {
          _.remove(this.regimeIds, function (rId) {
            return rId === regime.value
          })
        }
      }.bind(this))
      
      this.textureIds = []
      this.texturesSelect[0][1].forEach(function (texture) {
        if (texture.checked) {
          if (!this.textureIds.includes(texture.value)) {
            this.textureIds.push(texture.value)
          }
        } else {
          _.remove(this.textureIds, function (tId) {
            return tId === texture.value
          })
        }
      }.bind(this))

      this.categoriesGEMRCNIds = []
      this.categoriesGEMRCNSelect[0][1].forEach(function (categorieGEMRCN) {
        if (categorieGEMRCN.checked) {
          if (!this.categoriesGEMRCNIds.includes(categorieGEMRCN.value)) {
            this.categoriesGEMRCNIds.push(categorieGEMRCN.value)
          }
        } else {
          _.remove(this.categoriesGEMRCNIds, function (cgId) {
            return cgId === categorieGEMRCN.value
          })
        }
      }.bind(this))

      if (this.$route.name !== "Menus") {
        this.$store.commit('recettes/setFiltresEnMemoire', {key: 'categorieIdsEnMemoire', data: this.categorieIds})
        this.$store.commit('recettes/setFiltresEnMemoire', {key: 'composanteIdsEnMemoire', data: this.composanteIds})
        this.$store.commit('recettes/setFiltresEnMemoire', {key: 'regimeIdsEnMemoire', data: this.regimeIds})
        this.$store.commit('recettes/setFiltresEnMemoire', {key: 'textureIdsEnMemoire', data: this.textureIds})
      }
    },
    fixHeight() {
      var card = this.$refs.listeRecettes
      if (card) {
        card.style.maxHeight = (window.innerHeight - 220) + "px"
        card.style.marginBottom = "10px"
        card.style.overflowY = "scroll"
      }
    },
    setAucuneTextureIncompatible(valeur) {
      this.changesMulti.aucuneTextureIncompatible = valeur
      if (this.changesMulti.aucuneTextureIncompatible)
        this.changesMulti.textureIds = []
    },
    setAucunRegimeIncompatible(valeur) {
      this.changesMulti.aucunRegimeIncompatible = valeur
      if (this.changesMulti.aucunRegimeIncompatible)
        this.changesMulti.regimeIds = []
    },
    async enregistrerMulti() {
      let result = await this.alerteConfirmation('Appliquer les modifications de masse', "Les modifications effectuées seront appliquées sur " + this.selectedMulti.length + " recette" + (this.selectedMulti.length > 1 ? "s" : "") + ".<br/>Voulez-vous les enregistrer ?")
      if (result) {
        console.log({
          recetteIds: this.selectedMulti,
          changes: this.changesMulti
        })
        this.changesMulti.recettesAModifierIds = this.selectedMulti
        let response = await modifierRecettesEnMasse(this.etabCourantId, this.selectedMulti, this.changesMulti)
        if (response.status === 200) {
          this.toastSucces("Modifications enregistrées")
          // Affichage du résultat à l'écran
          this.listeDesRecettes.filter(elt => this.selectedMulti.find(s => s === elt.id)).forEach(elt => {
            if (this.changesMulti.categorieRecetteId)
              elt.categorieValideeEnMasse = true
            if (this.changesMulti.zonePreparationId)
              elt.zonePreparationValideeEnMasse = true
            if ((this.changesMulti.textureIds && this.changesMulti.textureIds.length) || this.changesMulti.aucuneTextureIncompatible)
              elt.texturesValideesEnMasse = true
            if ((this.changesMulti.regimeIds && this.changesMulti.regimeIds.length) || this.changesMulti.aucunRegimeIncompatible)
              elt.regimesValidesEnMasse = true
            if (this.changesMulti.gemrcnIds && this.changesMulti.gemrcnIds.length)
              elt.categoriesGEMRCNValideesEnMasse = true
            if (this.changesMulti.composanteMenuIds && this.changesMulti.composanteMenuIds.length)
              elt.composantesValideesEnMasse = true
          })
          this.selectedMulti = []// réinitialisation
          this.changesMulti = {
            categorieRecetteId: null,
            zonePreparationId: null,
            textureIds: [],
            aucuneTextureIncompatible: false,
            gemrcnIds: [],
            composanteMenuIds: [],
            regimeIds: [],
            aucunRegimeIncompatible: false
          }
        }
      }
    },
  },
  mounted() {
    this.texturesPourExclusion = this.textures.filter(t => t.value !== constantesId.textureNormale)
    this.regimesPourExclusion = this.regimes.filter(t => t.value !== constantesId.regimeNormal)

    this.categories = _.cloneDeep(Object.entries(_.groupBy(this.$store.state.definitions.categorieRecettes, "groupement")))
    this.composantes = _.cloneDeep(Object.entries(_.groupBy(this.$store.state.definitions.composantesRepas, "groupement")))
    this.regimesSelect = _.cloneDeep(Object.entries(_.groupBy(this.$store.state.definitions.regimes.filter(t => t.value !== constantesId.regimeNormal), "groupement")))
    this.texturesSelect = _.cloneDeep(Object.entries(_.groupBy(this.$store.state.definitions.textures.filter(t => t.value !== constantesId.textureNormale), "groupement")))
    this.categoriesGEMRCNSelect = _.cloneDeep(Object.entries(_.groupBy(this.$store.state.definitions.gemrcn, "groupement")))

    this.regimesSelect[0][1].sort((a, b) => a.ordre - b.ordre)
    this.texturesSelect[0][1].sort((a, b) => a.ordre - b.ordre)
    this.categoriesGEMRCNSelect[0][1].sort((a,b) => a.ordre - b.ordre)

    this.chargerLesFiltres()

    if (this.$route.name === "Menus") {
      if (this.composanteId) {
        // charger la modal avec filtre de la composante depuis menus
        this.composantes[0][1].find(
          comp => comp.value === this.composanteId
        ).checked = true
        if (!this.composanteIds.includes(this.composanteId)) {
          this.composanteIds.push(this.composanteId)
        }
        //this.propriete = "&Propriete=0"
        chargerParFiltre(this.menu?.id, {
          key: "liste",
          etablissementId: this.etabCourantId,
          categorieIds: [],
          composanteIds: this.composanteId,
          regimeIds: this.regimeIds,
          textureIds: this.textureIds,
          categoriesGEMRCNIds: this.categoriesGEMRCNIds
          //propriete: this.propriete
        })
          .then(response => {
            this.list = response.data
            this.currentItem = 0
            if (this.recetteId) {
              const result = this.list.findIndex(el => el.id === this.recetteId)
              if (result) {
                this.currentItem = result
              }
            }
            this.apercuRecette(this.list[this.currentItem])
            return
          })
          .catch(error => {
            console.log(error)
          })
      }
    } else {
      this.premierChargement = true
      chargerParFiltre(this.menu?.id, {
        key: "liste",
        etablissementId: this.etabCourantId,
        categorieIds: this.categorieIds,
        composanteIds: this.composanteIds,
        regimeIds: this.regimeIds,
        textureIds: this.textureIds,
        categoriesGEMRCNIds: this.categoriesGEMRCNIds,
        propriete: this.apiTypeDeRecherche(this.typeDeRecherche)
      })
        .then(response => {
          this.list = response.data
          return
        })
        .then(() => {
          this.currentItem = 0
          this.apercuRecette(this.filteredList[this.currentItem])
          return
        })
        .catch(error => {
          console.log(error)
        })
    }

    if (this.$route.name === "Menus") {
      this.montrerLesCategories = this.categorieIds.length !== 0
      this.montrerLesComposantes = this.composanteIds.length !== 0
      this.montrerLesRegimes = this.regimeIds.length !== 0
      this.montrerLesTextures = this.textureIds.length !== 0
      this.montrerLesCategoriesGEMRCN = this.categoriesGEMRCNIds.length !== 0

      this.fixHeight()
    }
    document.addEventListener("keyup", this.nextItem)
    this.$nextTick(() => {
      this.menuExpanded = !!document.getElementsByClassName("menu-expanded")
        .length
    })
  },
  destroyed() {
    document.removeEventListener("keyup", this.nextItem)
  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.form-check {
  padding-left: 3.25rem !important;
}
th {
  font-weight: 600;
}
.card-category {
  padding-top: 1rem;
}
.picto-famille {
  background: #2c3e50;
  padding: 0 3px;
  border-radius: 2px;
  color: white;
}
.form-check-simple {
  line-height: 1.8rem;
  padding-left: 1.8rem !important;
  margin: 1rem 0;
  font-weight: 600;
}
.form-check-input {
  margin-top: -0.3rem;
  box-shadow: none;
}
.text-right {
  text-align: right;
}
.btn.disabled,
.btn:disabled {
  border: none !important;
}

.switch-3-positions {
  margin: auto;
  font-size: 1em;
  height: 2em;
  line-height: 2.1rem;
  background: #00b712;
  display: block;
  position: relative;
  float: left;
  color: white;
  border-radius: 3px;
}

.switch.toutes,
.switch.universelles,
.switch.personnelles {
  cursor: pointer;
  position: relative;
  display: block;
  float: left;
  transition: 300ms ease-out;
  padding: 0 1em;
}
.fa-window-close {
  cursor: pointer;
  position: absolute;
  right: 1.2rem;
  top: 1.2rem;
  font-size: 2rem;
  color: #d0d0d0;
}

.selected {
  color: white !important;
}

.table-scroll.height-responsive-filtre-100 {
  height: calc(100vh - 78px);
  //height: 90vh;
}
</style>
