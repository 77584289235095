import { arrondiNombre, arrondiMercuriale } from '@/helpers/utils'
import { getMercurialesDate, getMercuriale, getSelection, getFavoris } from '@/api/gpao/mercuriales'
import lscache from 'lscache'
import store from "../store"
import vm from "@/helpers/vm-toolkit-vue"

var cats
let loadingMercuriale = false
let favoris
let timeoutIDs = []
console.log("helper mercuriales chargé")
export function viderMercuriales() {
  cats = undefined
}

export async function obtenirFavoris() {
  if (!favoris) {
    let response = await getFavoris()
    favoris = response && response.status === 200 && response.data || []
  }
  return favoris
}
export function chargerMercuriales(etablissementId, vider, dateMercu, tauxMercu) {
  return getFavoris()
    .then(fav => {
      favoris = fav && fav.status === 200 && fav.data || []
      return chargerLesMercuriales(etablissementId, vider, dateMercu, tauxMercu)
    })
}

function chargerLesMercuriales(etablissementId, vider, dateMercu, tauxMercu) {
  function p_charger(r_charger) {

    function wait() {//attente de la fin du chargement
      if (loadingMercuriale) {
        setTimeout(wait, 100)
      } else {
        done()
      }
    }

    function timedOut() {
      clearTimeouts()
      console.log("### timedout chargement Mercuriales ###", Date())
      chargerMercuriales(etablissementId, true, dateMercu, tauxMercu)
    }

    function clearTimeouts() {
      if (timeoutIDs.length > 1) {
        console.log("/!\\ 2 chargements des mercuriales en parallèle...")
      }
      timeoutIDs.forEach(clearTimeout)
      timeoutIDs = []
    }

    function done() {//tout est chargé, tri des catalogues par indice et renvoie
      var fCats = []
      vm.forEach(cats, (a, b) => {
        return a.indice > b.indice ? 1 : -1
      }, c => {
        if (c) {
          fCats.push(c)
        }
      })
      clearTimeouts()
//console.log("### fin chargement Mercuriales", Date())
      store.commit('uxHelpers/loadedMercuriales')
      r_charger(fCats)
    }

    function gotOne(merc) {//obtention d'une mercuriale à jour, mise en cache et génération du html
      merc.isCurrent = true
      var tmp = lscache.get("Mercuriales") || {}
      tmp[merc.id] = merc
      lscache.set("Mercuriales", tmp, 60)
      return updateCatalogue(merc, {all: true})
    }

//console.log("### début chargement Mercuriales", Date())
    if (loadingMercuriale) {//en cours de chargement, on attend
      wait()
    } else {
      timeoutIDs.push(setTimeout(timedOut, 30000))
      if (vider) {
        viderMercuriales()
        lscache.remove("Mercuriales")
      }

      if (vm.count(cats)) {//données locales existantes, sont-elles valides ?
        let toGet = []
        vm.forEach(cats, c => {
          if (c.isCurrent) {
            updateCatalogue(c, {all: true})
          } else {//catalogue non valide, préparation appel serveur
            toGet.push(getMercuriale(etablissementId, c.id, null, tauxMercu)
              .then(response => {//copie des infos manquantes dans la réponse de getMercuriale
                var merc = response.data[0]
                merc.code = c.code
                merc.estGamme = c.estGamme
                merc.indice = c.indice
                merc.locale = c.locale
                merc.nom = c.nom
                cats[merc.id] = merc
                return gotOne(merc)
              }))
          }
        })
        if (toGet.length) {
          store.commit('uxHelpers/loadingMercuriales')
          return Promise.all(toGet)//appel serveur pour les catalogues non valides
              // eslint-disable-next-line promise/no-callback-in-promise
            .then(done)
        }
        // eslint-disable-next-line promise/no-callback-in-promise
        done()
      } else {//pas de catalogues en local, appel serveur complet
        loadingMercuriale = true
        store.commit('uxHelpers/loadingMercuriales')
        return getMercurialesDate(etablissementId, dateMercu, tauxMercu)
          .then(response => {
            initCatalogues(response.data)
            vm.forEach(response.data, gotOne)
            loadingMercuriale = false
            // eslint-disable-next-line promise/no-callback-in-promise
            done()
            return
          })
      }
    }
  }
  // eslint-disable-next-line promise/avoid-new
  return new Promise(p_charger)
}

export function chargerSelection(selectionId) {
  var selection = {}
  vm.forEach(cats, function (cat) {
    if (cat.selections[selectionId]) {
      selection = cat.selections[selectionId]
    }
  })
  if (selection.Produits) {
    return Promise.resolve(selection)
  }
  return getSelection(selectionId)
    .then(function (sel) {
      vm.add(sel.data, selection)
      selection.Produits = {}
      selection.produits.forEach(p => {
        selection.Produits[p.produitId] = p
      })
      return selection
    })
}

export function invaliderCache(idFamille) {
  if (cats && cats[idFamille]) {
    cats[idFamille].isCurrent = false
  }

  var tmp = lscache.get("Mercuriales") || {}
  if (tmp[idFamille]) {
    tmp[idFamille].isCurrent = false
  }
  lscache.set("Mercuriales", tmp, 60)
}

function initCatalogues(mercs) {//préparation des catalogues
  var iMax = 0
  cats = {}
  if (!mercs) {
    return
  }
  vm.forEach(mercs, m => {//création des catalogues pour les familles
    if (!m.estGamme) {
      cats[m.id] = vm.add({
        estGamme: !!m.estGamme,
        isCurrent: m.isCurrent === undefined ? true : Boolean(m.isCurrent)
      }, m)
      if (m.indice > iMax) {
        iMax = m.indice
      }
    }
  })

  vm.forEach(mercs, m => {//création des catalogues pour les gammes et correction de l'indice, si besoin
    if (m.estGamme) {
      while (cats[iMax]) {
        iMax += 1
      }
      m.indice = iMax
      cats[m.id] = vm.add({
        indice: iMax,
        estGamme: !!m.estGamme,
        isCurrent: m.isCurrent === undefined ? true : Boolean(m.isCurrent)
      }, m)
    }
  })

}

function updateCatalogue(merc, opt) {//lecture des données mercuriale

  function head() {

    var cols;
    var head1;
    var head2;

    var sNew = "style=\"width:40px;\"";
    var sUC = "style=\"width:50px;\"";
    var sBrand = "style=\"width:62px;\"";
    var sBrand4 = "style=\"width:100px;\"";
    var sBrand5 = "style=\"width:150px;\"";
    var sBrand6 = "style=\"width:200px;\"";
    var sCode = "style=\"width:52px;\"";
    var sPrice = "style=\"width:50px;\"";
    var sFav = "style=\"width:18px;\"";

    var addChecked = "<th style=\"padding:0!important;top:24px;\">" +
      
    "</th>";

    function readFournisseur(Nom, num) {

      if (opt.local && (!merc.locale || !merc.locale.ligneProduits[0])) {

        return;

      }

      var idFourn 

      if (opt.local) {
        idFourn = Number(merc.locale.ligneProduits[0][5 + (7 * num)])
        cats[merc.id].fournisseursL.push({
          idFourn: idFourn,
          Nom: Nom
        });
      } else {
        idFourn = Number(merc.mercuriale.ligneProduits[0][5 + (7 * num)])
        cats[merc.id].fournisseurs.push({
          idFourn: idFourn,
          Nom: Nom
        });
      }

      cols["1"] += "<col data-idfourn=\"" + idFourn + "\" " + sPrice + "><col data-idfourn=\"" + idFourn + "\" " + sFav + ">";

      cols["2"] += "<col data-idfourn=\"" + idFourn + "\" " + sCode + "><col " + sPrice + "><col data-idfourn=\"" + idFourn + "\" " + sFav + ">";

      cols["3"] += "<col data-idfourn=\"" + idFourn + "\" " + sBrand + "><col " + sCode + "><col " + sPrice + "><col data-idfourn=\"" + idFourn + "\" " + sFav + ">";

      cols["4"] += "<col data-idfourn=\"" + idFourn + "\" " + sBrand4 + "><col " + sCode + "><col " + sPrice + "><col data-idfourn=\"" + idFourn + "\" " + sFav + ">";

      cols["5"] += "<col data-idfourn=\"" + idFourn + "\" " + sBrand5 + "><col " + sCode + "><col " + sPrice + "><col data-idfourn=\"" + idFourn + "\" " + sFav + ">";

      cols["6"] += "<col data-idfourn=\"" + idFourn + "\" " + sBrand6 + "><col " + sCode + "><col " + sPrice + "><col data-idfourn=\"" + idFourn + "\" " + sFav + ">";

      head1["1"] += "<th data-idfourn=\"" + idFourn + "\" class=\"fournisseur\" colspan=\"2\" title=\"" + Nom + "\">" + Nom + "</th>";

      head1["2"] += "<th data-idfourn=\"" + idFourn + "\" class=\"fournisseur\" colspan=\"3\">" + Nom + "</th>";

      head1["3"] += "<th data-idfourn=\"" + idFourn + "\" class=\"fournisseur\" colspan=\"4\">" + Nom + "</th>";

      head2["1"] += "<th data-idfourn=\"" + idFourn + "\" colspan=\"2\" style=\"top:24px;\">Tarif</th>";

      head2["2"] += "<th data-idfourn=\"" + idFourn + "\" style=\"top:24px;\">Réf.</th><th data-idfourn=\"" + idFourn + "\" colspan=\"2\" style=\"top:24px;\">Tarif</th>";

      head2["3"] += "<th data-idfourn=\"" + idFourn + "\" style=\"top:24px;\">Marque</th><th data-idfourn=\"" + idFourn + "\" style=\"top:24px;\">Réf.</th><th data-idfourn=\"" + idFourn + "\" colspan=\"2\" style=\"top:24px;\">Tarif</th>";

    }

    cols = {
      "1": "<col " + sNew + "><col><col " + sFav + "><col " + sUC + ">",
      "2": "<col " + sNew + "><col><col " + sFav + "><col " + sUC + ">",
      "3": "<col " + sNew + "><col><col " + sFav + "><col " + sUC + ">",
      "4": "<col " + sNew + "><col><col " + sFav + "><col " + sUC + ">",
      "5": "<col " + sNew + "><col><col " + sFav + "><col " + sUC + ">",
      "6": "<col " + sNew + "><col><col " + sFav + "><col " + sUC + ">"
    };

    head1 = {
      "1": "",
      "2": "",
      "3": ""
    };

    head2 = {
      "1": "",
      "2": "",
      "3": ""
    };

    if (opt.local) {

      cats[merc.id].fournisseursL = [];

      merc.locale.fournisseurs.forEach(readFournisseur);

      cats[merc.id].colsL = cols;

      cats[merc.id].headL = {
        "1": "<tr><th colspan=\"4\"></th>" + head1["1"] + "</tr><tr>" + addChecked + "<th colspan=\"2\" style=\"top:24px;\">Désignation</th><th style=\"top:24px;\">UC</th>" + head2["1"] + "</tr>",
        "2": "<tr><th colspan=\"4\"></th>" + head1["2"] + "</tr><tr>" + addChecked + "<th colspan=\"2\" style=\"top:24px;\">Désignation</th><th style=\"top:24px;\">UC</th>" + head2["2"] + "</tr>",
        "3": "<tr><th colspan=\"4\"></th>" + head1["3"] + "</tr><tr>" + addChecked + "<th colspan=\"2\" style=\"top:24px;\">Désignation</th><th style=\"top:24px;\">UC</th>" + head2["3"] + "</tr>"
      };

    } else {

      cats[merc.id].fournisseurs = [];

      merc.mercuriale.fournisseurs.forEach(readFournisseur);

      cats[merc.id].cols = cols;

      cats[merc.id].head = {
        "1": "<tr><th colspan=\"4\"></th>" + head1["1"] + "</tr><tr>" + addChecked + "<th colspan=\"2\" style=\"top:24px;\">Désignation</th><th style=\"top:24px;\">UC</th>" + head2["1"] + "</tr>",
        "2": "<tr><th colspan=\"4\"></th>" + head1["2"] + "</tr><tr>" + addChecked + "<th colspan=\"2\" style=\"top:24px;\">Désignation</th><th style=\"top:24px;\">UC</th>" + head2["2"] + "</tr>",
        "3": "<tr><th colspan=\"4\"></th>" + head1["3"] + "</tr><tr>" + addChecked + "<th colspan=\"2\" style=\"top:24px;\">Désignation</th><th style=\"top:24px;\">UC</th>" + head2["3"] + "</tr>"
      };

    }

  }

  function body() {

    var idCat;
    var idGroup;
    var idSGroup;

    function readLine(line) {
      var count;
      var prod;
      var pgFav;

      function readOffers() {
        var tarifs;
        var num;
        var order;

        function readOffer() {
          var offer;
          var price;
          var idFourn;
          var found = false;
          price = arrondiNombre(line[num + 1].replace(",", "."), 6);
          idFourn = Number(line[num]);
          if (opt.local) {
            cats[merc.id].fournisseursL.forEach(function (f) {
              if (f.idFourn === idFourn) {
                found = true;
              }
            });
          } else {
            cats[merc.id].fournisseurs.forEach(function (f) {
              if (f.idFourn === idFourn) {
                found = true;
              }
            });
          }
          if (found) {
            if (price >= 0) {
              offer = {
                key: prod.Id + "-" + idFourn,
                idFourn: idFourn,
                isCheapest: price === prod.cheapest,
                PrixAchat: price,
                idTarif: line[num + 2],
                Reference: line[num + 3] || "",
                Marque: line[num + 4] || "",
                Designation: line[num + 5] || "",
                PCB: arrondiNombre(line[num + 6].replace(",", ".")),
                estFavori: pgFav.fournisseursIds.indexOf(idFourn) > -1,
                order: order,
              };
            } else {
              offer = {
                idFourn: idFourn,
                order: order,
              }
            }
            if (offer) {
              tarifs[idFourn] = offer;
            }
          }
          num += 7;
          order += 1;
        }
        tarifs = {};
        num = 5;
        order = 0;
        while (num < count - 5) {
          readOffer();
        }
        prod.tarifs = tarifs;
      }
      count = line.length;
      prod = {
        Id: line[0],
        idType: Number(line[1]),
        Designation: line[4]
      };

      if (prod.idType === 2) {// famille
        if (merc.estGamme) {
          idCat = Number(prod.Id);
          prod.idCat = idCat;
        } else {
          idGroup = prod.Id;
          prod.idGroup = idGroup;
        }
      } else if (prod.idType === 3) {// sous-famille
        if (merc.estGamme) {
          idGroup = prod.Id;
          prod.idCat = idCat
          prod.idGroup = idGroup
        } else {
          idSGroup = prod.Id;
          prod.idGroup = idGroup
          prod.idSGroup = idSGroup
        }
      } else if (prod.idType === 1) {// produit
        prod.idCat = idCat
        prod.idGroup = idGroup
        prod.idSGroup = idSGroup
        prod.cheapest = arrondiNombre(line[count - 1].replace(",", "."), 6)
        prod.isNew = line[2] === "New"
        prod.type = line[count - 2]
        prod.ingredientId = line[count - 4]
        prod.ingredient = line[count - 3]
        prod.UC = line[3]
        prod.isHM = Boolean(opt.hm)
        prod.isLocal = Boolean(opt.local)
        pgFav = favoris.find(f => f.produitId === prod.Id) || {
          produitId: prod.Id,
          estFavori: false,
          fournisseursIds: []
        }
        prod.estFavori = pgFav.estFavori
        readOffers();
      }

      updateLigneCatalogue(prod, cats[merc.id].fournisseurs.length)
      
      if (opt.local) {
        cats[merc.id].productsL.push(prod);
      } else if (opt.hm) {
        cats[merc.id].productsHM.push(prod);
      } else {
        cats[merc.id].products.push(prod);
      }
    }
    if (opt.local) {
      merc.locale.ligneProduits.forEach(readLine);
    } else if (opt.hm) {
      merc.horsMarche.ligneProduits.forEach(readLine);
    } else {
      if (merc.mercuriale.ligneProduits) {
        merc.mercuriale.ligneProduits.forEach(readLine);
      }
    }
  }

  function search() {

    function addProduct(prod) {
      var str = prod.Id + " " + prod.Designation;
      /*if (prod.tarifs) {
        vm.forEach(prod.tarifs, function (offer) {
          str += " " + offer.Marque + " " + offer.Reference + " " + offer.Designation;
        });
      }*/
      prod.search = vm.to.search(str);
    }

    if (opt.local) {
      cats[merc.id].productsL.forEach(addProduct);
    } else if (opt.hm) {
      cats[merc.id].productsHM.forEach(addProduct);
    } else {
      cats[merc.id].products.forEach(addProduct);
    }
  }

  opt = opt || {};
  if (opt.all) {
    updateCatalogue(merc)
    updateCatalogue(merc, {hm: true})
    updateCatalogue(merc, {local: true})

    return Promise.resolve()
  }

  if (opt.local) {
    if (merc.locale) {
      cats[merc.id].productsL = [];
      head();
      body();
      search();
    }
  } else if (opt.hm) {
    if (merc.horsMarche) {
      cats[merc.id].productsHM = [];
      body();
      search();
    }
  } else {
    cats[merc.id].products = [];
    head();
    body();
    search();
  }
  cats[merc.id].isCurrent = Boolean(merc.isCurrent)
  return Promise.resolve()
}

export async function updateLigneCatalogue(prod, nbFourns) {//génère le html d'une ligne de mercuriale
  let html
  if (prod.idType === 2) {// famille
    html = {
      "1": "<td class=\"group\"></td><td class=\"group\" colspan=\"" + ((nbFourns * 2) + 3) + "\"><div> " + prod.Designation + " </div></td>",
      "2": "<td class=\"group\"></td><td class=\"group\" colspan=\"" + ((nbFourns * 3) + 3) + "\"><div> " + prod.Designation + " </div></td>",
      "3": "<td class=\"group\"></td><td class=\"group\" colspan=\"" + ((nbFourns * 4) + 3) + "\"><div> " + prod.Designation + " </div></td>"
    };
  } else if (prod.idType === 3) {// sous-famille
    html = {
      "1": "<td class=\"sub-group\"></td><td class=\"sub-group\" colspan=\"" + ((nbFourns * 2) + 3) + "\"><div> " + prod.Designation + " </div></td>",
      "2": "<td class=\"sub-group\"></td><td class=\"sub-group\" colspan=\"" + ((nbFourns * 3) + 3) + "\"><div> " + prod.Designation + " </div></td>",
      "3": "<td class=\"sub-group\"></td><td class=\"sub-group\" colspan=\"" + ((nbFourns * 4) + 3) + "\"><div> " + prod.Designation + " </div></td>"
    };
  } else if (prod.idType === 1) {// produit
    if (!favoris) {
      favoris = await obtenirFavoris()
    }
    
    html = "<td data-key=\"" + prod.Id + "\" class=\"watchMe new\">" + (prod.isNew ? "New" : "") + "</td><td data-key=\"" + prod.Id + "\" class=\"watchMe prod\" title=\"" + prod.ingredient + " (Cliquer pour la gestion des favoris)\">" + prod.Designation + "</td><td data-key=\"" + prod.Id + "\" class=\"watchMe prod fav\" title=\"" + prod.ingredient + "\">" + ("<i class=\"fa-star" + (prod.estFavori ? " fas selected text-primary" : " far") + " toggleFavori\" title=\"Modifier favori\"></i>") + "</td><td data-key=\"" + prod.Id + "\" class=\"watchMe uc\" title=\"" + prod.ingredient + "\">" + prod.UC + "</td>"
    html = {
      "1": html,
      "2": html,
      "3": html
    }

    vm.forEach(prod.tarifs, (a, b) => a.order - b.order, offer => {
      let hPrice, hCode, hBrand 
      if (offer.PrixAchat >= 0) {
        hPrice = "<td data-key=\"" + offer.key + "\" data-idfourn=\"" + offer.idFourn + "\" class=\"watchMe price" + (offer.isCheapest ? " cheapest" : "") + "\">" + arrondiMercuriale(offer.PrixAchat) + "</td><td data-key=\"" + offer.key + "\" data-idfourn=\"" + offer.idFourn + "\" class=\"watchMe fav\">" + ("<i class=\"fa-star" + (offer.estFavori ? " fas selected text-primary" : " far") + " toggleFavori\" title=\"Modifier favori\"></i>") + "</td>"
        hCode = "<td data-key=\"" + offer.key + "\" data-idfourn=\"" + offer.idFourn + "\" class=\"watchMe code\">" + (offer.Reference || "&nbsp;") + "</td>"
        hBrand = "<td data-key=\"" + offer.key + "\" data-idfourn=\"" + offer.idFourn + "\" class=\"watchMe brand\" title=\"" + offer.Marque + "\">" + offer.Marque + "</td>"
      } else if (offer.idFourn) {
        hPrice = "<td data-idfourn=\"" + offer.idFourn + "\" class=\"price\"></td><td data-idfourn=\"" + offer.idFourn + "\" class=\"fav\"></td>"
        hCode = "<td data-idfourn=\"" + offer.idFourn + "\" class=\"code\"></td>"
        hBrand = "<td data-idfourn=\"" + offer.idFourn + "\" class=\"brand\"></td>"
      }
      html["1"] += hPrice
      html["2"] += hCode + hPrice
      html["3"] += hBrand + hCode + hPrice

      // Mise à jour de la liste des favoris à partir des tarifs du produit à rafraichir
      if (offer.estFavori && !favoris.find(f => f.produitId === prod.Id && !f.fournisseursIds.find(fourn => fourn === offer.idFourn))) {
        let favori = favoris.find(f => f.produitId === prod.Id)
        if (favori) {
          favori.fournisseursIds.push(offer.idFourn)
        } else {
          favoris.push({ produitId: prod.Id, fournisseursIds: [ offer.idFourn ], estFavori: true })
        }
      } else if (!offer.estFavori) {
        let favori = favoris.find(f => f.produitId === prod.Id)
        if (favori) {
          favori.fournisseursIds = favori.fournisseursIds.filter(f => f !== offer.idFourn)
        }
      }
    })

    // Mise à jour de la liste des favoris à partir du produit à rafraichir
    if (prod.estFavori && !favoris.find(f => f.produitId === prod.Id)) {
      favoris.push({ produitId: prod.Id, fournisseursIds: [], estFavori: true })
    } else if (!prod.estFavori && favoris.find(f => f.produitId === prod.Id && !f.fournisseursIds?.length)) {
      favoris = favoris.filter(f => f.produitId !== prod.Id)
    }
  }
  prod.html = {
    "1": "<tr data-idProd=\"" + prod.Id + "\"" + (prod.isLocal ? " class=\"local\"" : (prod.isHM ? " class=\"hm\"" : "")) + ">" + html["1"] + "</tr>",
    "2": "<tr data-idProd=\"" + prod.Id + "\"" + (prod.isLocal ? " class=\"local\"" : (prod.isHM ? " class=\"hm\"" : "")) + ">" + html["2"] + "</tr>",
    "3": "<tr data-idProd=\"" + prod.Id + "\"" + (prod.isLocal ? " class=\"local\"" : (prod.isHM ? " class=\"hm\"" : "")) + ">" + html["3"] + "</tr>"
  }
}
