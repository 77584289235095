<template>
  <div v-if="recette && recette.lignesCompletees && recette.lignesCompletees.length !== 0">
    <div v-if="nomDeRecette">
      <div class="flex-center-space-between feuille-papier" style="font-weight:bolder;font-size:120%;padding:15px 15px 0 15px;align-items:start;">
        <div>{{recette.nom}}</div>
        <div style="margin-left:10px;white-space:nowrap;">{{recette.prixUnitaire| mettreVirgule}} €/{{(recette.upSingulier)?recette.upSingulier:recette.up}}</div>
      </div>
    </div>
    <div class="flex-center-space-between feuille-papier" style="background-color:white;padding-bottom:1rem;padding:15px 15px 7px 15px;">
      <div style="font-weight:bolder">Ingrédients</div>
      <div style="font-weight:bolder">Qté. brute</div>
    </div>
    <div v-for="(ligne, index) in recette.lignesCompletees" :key="index" class="feuille-papier">
      <div style="display:flex;align-items:center;">
        <div class="flex-center-space-between" style="width:100%">
          <dropDownChoixFamille :familleSelectionnee="ligne.famille" />
          <span v-if="ligne.composant && ligne.famille" style="margin-right: auto;font-weight: 600;" :class="{warningText: estSansCotation(ligne)}">
            {{ligne.composant.nom}}         <span v-if="ligne.grammage"> ({{ligne.grammage}}g)</span>
          </span>
          <span v-else-if="ligne.composant && !ligne.famille" style="margin-right: auto;font-weight: 600;"> <!--recette -->
            <RecettePopUpIngredients  v-if="!ligne.famille" :estLien="true" :recetteId="ligne.composantId" :recetteNom="ligne.composant.nom" />
          </span>
   
          <span v-if="ligne.up" class="UP-container">{{ligne.quantite | mettreVirgule}} {{ligne.up}}</span>
        </div>
      </div>
      <div v-if="ligne.typeConviveId && (recette.conviveTypeId !== ligne.typeConviveId)" class="nom-convive">
        {{nomConviveType(ligne.typeConviveId)}}</div>
      <div>
      </div>
    </div>
    <div v-if="allergenes" class="feuille-papier" style="padding:15px;">
      Allergènes : {{allergenes}}
      <div class="error" v-if="recette.saisieAllergenesIncomplete">Attention, la liste des allergènes est peut-être incomplète car ils n'ont pas été renseignés pour un ou plusieurs ingrédients</div>
      <p style="padding-top:15px;font-size:0.9em;">
        <em>L’affichage des allergènes est effectué à titre informatif. Chaque utilisateur du logiciel doit vérifier par lui-même la véracité de ces informations auprès du ou des fournisseurs de denrées alimentaires concernés.<br/>
      En cas d’erreur, l’utilisateur ne pourra pas engager la responsabilité de la société Agap’pro, éditrice du logiciel.</em>
      </p>
    </div>
  </div>
</template>

<script>
import { nomConviveType, recette } from '@/mixins/recette'
import dropDownChoixFamille from '@/components/Recettes/dropDownChoixFamille'

export default {
  props: ['recette', 'nomDeRecette', 'afficherAllergenes'],
  components: {
    dropDownChoixFamille,
    RecettePopUpIngredients: () => import("@/components/Recettes/RecettePopUpIngredients")
  },
  mixins: [recette, nomConviveType],
  computed: {
    allergenes() {
      if (!this.afficherAllergenes || !this.recette.lignesCompletees.length) {
        return ""
      }
      return this.recette.lignesCompletees
        .filter(x => x.allergenes && x.allergenes.length)
        .map(x => x.allergenes)
        .toString().split(",")
        .filter((v, i, a) => a.indexOf(v) === i)
        .sort((a, b) => a < b ? -1 : 1)
        .join(", ")
    }
  },
  methods: {},

}
</script>

<style scoped>
.UP-container {
  margin-right: 1rem;
}
.nom-convive {
  margin-left: 48px;
  margin-top: -9px;
  font-size: 0.8rem;
  font-style: italic;
  font-weight: 600;
}
</style>
