<template>
  <div class="bg-whitepage-plan-alimentaires" style="margin-top:-15px;">
    <b-modal id="modalEditionPlan" size="lg" hide-footer header-bg-variant="gradient-bleu" header-text-variant="light">
      <template slot="modal-title">
        <h3>Édition de plan alimentaire</h3>
      </template>
      <template slot="default">
        <EditionPlanAlimentaire :plan="currentPlan" @enregistrer-plan="planEnregistre" @annuler-edition="fermerModalPlan" />
      </template>
    </b-modal>

    <div class="container-fluid bg-gradient-bleu" style="min-height:60px;padding:10px;">
      <button class="btn" style="background-color: white; font-weight: bold; font-size: 12px;" alt="Creer" @click="ajouterPlan()">Créer un plan alimentaire</button>
    </div>

    <div :key="key" class="container-fluid no-padding" style="min-height:65vh;background-color:inherit;">
      <table class="table table-sm table-bordered">
        <colgroup>
          <col>
          <col style="width:60px;">
          <col style="width:60px;">
          <col style="width:60px;">
          <col style="width:40px;">
        </colgroup>
        <thead>
          <tr>
            <th>Nom du plan alimentaire</th>
            <th>Période</th>
            <th>Nombre de services</th>
            <th>Nombre de composantes</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(plan, iPlan) in plans" :key="iPlan" style="cursor: pointer;" title="Editer le plan alimentaire" @click="editerPlan(plan)">
            <td>{{ plan.nom }}</td>
            <td style="text-align: center;">{{ periode(plan.periodeId) || "" }}</td>
            <td style="text-align: center;">{{ plan.services && plan.services.filter(s => s.composantes && s.composantes.length).length || 0 }}</td>
            <td style="text-align: center;">{{ plan.services && plan.services.length && plan.services.reduce((t, s) => t + (s.composantes && s.composantes.length || 0), 0) }}</td>
            <td class="flex-center-space-between" style="text-align: center;padding-top:5px;border: none;">
              <i class="fas fa-pen mr-2" style="margin: 2px auto;" title="Modifier le plan alimentaire" @click.stop="modifierPlan(plan)"></i>
              <i class="fas fa-trash rougeAgapInverse" style="margin: 2px auto;" title="Supprimer le plan alimentaire" @click.stop="supprimerPlan(plan)"></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { connecteData } from "@/mixins/connecteData"
import { chargerPlans, supprimerPlan } from '@/api/gpao/plansAlimentaires'
import { chargerDefinitions, chargerDonneesEtablissement } from '@/helpers/definitions'
import { showAlerts } from "@/mixins/alerts"
import EditionPlanAlimentaire from '@/components/Divers/EditionPlanAlimentaire'

export default {
  name: "PagePlansAlimentaires",
  mixins: [ connecteData, showAlerts ],
  components: { EditionPlanAlimentaire },
  data() {
    return {
      key: 1,
      plans: [],
      currentPlan: {},
      periodes: this.$store.state.definitions.periodesPlansAlimentaires
    }
  },
  computed: {
    
  },
  watch: {
    
  },
  async mounted() {
    this.rechargerPlansAlimentaires()
  },
  methods: {
    async rechargerDefinitions() {
      await chargerDefinitions()
        .then(() => {
          if (this.etabCourantId) {
            chargerDonneesEtablissement(this.etabCourantId)
          }
          return
        })
    },
    periode(periodeId) {
      let periode = this.periodes.find(p => p.value == periodeId)
      if (periode)
        return periode.text
      return ""
    },
    async rechargerPlansAlimentaires() {
      let resultat = await chargerPlans(this.etabCourantId)
      this.plans = resultat.data
      this.key += 1
    },
    ajouterPlan() {
      this.currentPlan = {}
      this.afficherModalPlan()
    },
    modifierPlan(plan) {
      this.currentPlan = plan
      this.afficherModalPlan()
    },
    editerPlan(plan) {
      this.$router.push({ name: 'PlanAlimentaire', params: { planAlimentaireId: plan.id } })
    },
    async supprimerPlan(plan) {
      let result = await this.alerteConfirmation("Supprimer le plan alimentaire", "Souhaitez-vous supprimer ce plan alimentaire ?")
      if (result) {
        await supprimerPlan(plan)
        await this.rechargerPlansAlimentaires()
      }
    },
    async planEnregistre() {
      this.fermerModalPlan()
      await this.rechargerPlansAlimentaires()
      this.alerteSucces('Plan alimentaire enregistré !')
      await this.rechargerDefinitions()
    },
    afficherModalPlan() {
      this.$bvModal.show('modalEditionPlan')
    },
    fermerModalPlan() {
      this.$bvModal.hide('modalEditionPlan')
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
