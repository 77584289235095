<template>
  <div class="container-fluid" :key="keyMain">
    <BarreFiltres v-if="mercurialesChargees" :filtres="filtres" @appliquer-filtre="appliquerFiltre($event)" />

    <!-- mercuriales -->
    <div v-if="mercurialesChargees" id="mercuriales">
      <div class="row">
        <div class="col-12 text-center push-top-2rem">{{filters.pg}}</div>
        <div class="col-12">
          <transition name="fade" mode="out-in"></transition>
        </div>
      </div>
    </div>

    <!-- chargement -->
    <div v-else class="bg-white" style="text-align:center;">
      <img alt="en attente" src="/assets/images/spinners/cooking-blue-court.gif" />
    </div>

  </div>
</template>

<script>
import BarreFiltres from '@/components/Divers/BarreFiltres'
import { chargerMercuriales, chargerSelection, updateLigneCatalogue } from "@/helpers/mercuriales"
import { articleFavori } from '@/api/gpao/commandes'
import vm from "@/helpers/vm-toolkit-vue"
import { connecteData } from "@/mixins/connecteData"
import jquery from "jquery"
import { mapGetters } from "vuex"
import { rechercherTexte } from '@/helpers/utils'

export default {
  name: "Mercuriales",
  mixins: [connecteData],
  components: {BarreFiltres},
  props: {
    "startFilters": Object,
    "fournExclus": Array,
    "windowMode": Boolean,
  },
  data() {
    return {
      filters: {
        sel: "0"
      },
      mercuriales: [],
      optionsFamilles: [],
      keyFamilles: 1,
      optionsSelections: [],
      currentCatalog: null,
      keyMain: 1,
      filtres: {
        familles: {
          label: "Familles",
          champ: "select",
          width: "200px",
          options: [],
          selection: null,
          initialiser(contexte) {
            let filtre = contexte.filtres.familles
            filtre.options = contexte.optionsFamilles?.map(f => ({ valeur: f, texte: contexte.familleNomComplet(f) })) || []
            filtre.selectionner(contexte, contexte.filters.pg)
          },
          selectionner(contexte, codeFamille) {
            let filtre = contexte.filtres.familles
            filtre.selection = filtre.options?.find(f => f.valeur === codeFamille)
          },
          async filtrer(contexte, codeFamille) {
            let filtre = contexte.filtres.familles
            if (codeFamille) {
              filtre.selectionner(contexte, codeFamille)
            }
            await contexte.onSelectFamille(filtre.selection?.valeur)
          }
        },
        selections: {
          label: "Sélections",
          champ: "select",
          width: "200px",
          options: [],
          selection: null,
          initialiser(contexte) {
            let filtre = contexte.filtres.selections
            filtre.options = contexte.optionsSelections?.map(s => ({ valeur: s.selectionId, texte: s.nom })) || []
            filtre.selection = filtre.options?.find(s => s.valeur === contexte.filters.sel)
          },
          filtrer(contexte, valeur) {
            let filtre = contexte.filtres.selections
            if (valeur) {
              filtre.selection = filtre.options?.find(s => s.valeur === valeur)
            }
            let selection = contexte.optionsSelections?.find(s => s.selectionId === contexte.filtres.selections.selection.valeur)
            if (selection) {
              contexte.onSelectSelection(selection)
            }
          }
        },
        rechercheNom: {
          label: "Recherche",
          champ: "input",
          type: "text",
          selection: "",
          class: "search",
          initialiser(contexte) {
            contexte.filtres.rechercheNom.selection = contexte.filters.search || ""
          },
          filtrer(contexte, valeur) {
            let filtre = contexte.filtres.rechercheNom
            if (valeur) {
              filtre.selection = valeur
            }
            contexte.filters.search = filtre.selection || ""
            contexte.$emit("filter-changed", contexte.filters)
          }
        },
        rechercheComplete: {
          label: "Recherche toutes familles",
          champ: "rechercheProduits",
          selection: null,
          chercherProduits: true,
          filtrer(contexte) {
            let selection = contexte.filtres.rechercheComplete.selection
            if (selection) {
              // Applique les valeurs aux filtres
              contexte.filtres.rechercheNom.filtrer(contexte, selection.designation)
              contexte.filtres.familles.filtrer(contexte, selection.codeFamille)
              contexte.filtres.selections.filtrer(contexte, selection.typeMercuriale.toString()) // "0" = Mercuriale complète => à modifier pour gérer les mercuriales HM et locaux
              // Sélectionne le champ de recherche à droite (il est nécessaire de changer le texte et sélectionner le champ de recherche en 2 étape, sinon perte de la sélection du texte lors de l'affectation du texte dans le champ)
              contexte.filtres.rechercheNom.filtrer(contexte, selection.designation)
            }
          }
        },
        choixDate: {
          label: "Date de la mercuriale",
          champ: "datepicker",
          availableDates: [],
          selection: null,
          initialiser(contexte) {
            contexte.filtres.choixDate.availableDates = []
            let date = new Date(2016,0,6)
            let maxdate = new Date()
            maxdate.setMonth(maxdate.getMonth() + 6)
            while (date < maxdate) {
              date.setDate(date.getDate() + 1)
              contexte.filtres.choixDate.availableDates.push(new Date(date))
            }
            contexte.filtres.choixDate.selection = contexte.jour
          },
          filtrer(contexte) {
            let filtre = contexte.filtres.choixDate.selection
            contexte.onSelectDateMercu(filtre)
            
          }
        },
        favoris: {
          label: "Favoris",
          champ: "checkbox",
          selection: false,
          async filtrer(contexte) {
            contexte.drawMain(true)
            contexte.$emit("filter-changed", contexte.filters)
          }
        },
        // Appeler "this.filtres.initialiser(this)" dans mounted() => génère le tableau de filtres utilisés pour afficher les filtres dans la barre de filtres
        barreGauche: [],
        barreDroite: [],
        async initialiser(contexte) {
          contexte.filtres.barreGauche = [ contexte.filtres.rechercheComplete ]
          contexte.filtres.barreDroite = [ contexte.filtres.familles, contexte.filtres.selections, contexte.filtres.rechercheNom, contexte.filtres.favoris ]
          if (contexte.profilIsLecteur) {
            contexte.filtres.barreGauche.push(contexte.filtres.choixDate)
          }
          contexte.filtres.barreGauche.forEach(f => {
            if (f.initialiser) {
              f.initialiser(contexte)
            }
          })
          contexte.filtres.barreDroite.forEach(f => {
            if (f.initialiser) {
              f.initialiser(contexte)
            }
          })
        }
      },
    }
  },
  computed: {
    ...mapGetters("uxHelpers", ["mercurialesChargees"])
  },
  watch: {
    mercurialesChargees(newState) {
      this.keyMain += 1
      if (newState) {
        return chargerMercuriales(this.etabCourantId, null, null, this.profilLecteurMercu)
          .then(function(r) {
            this.mercuriales = r
            this.getCurrentCatalog()
            this.initData()
          }.bind(this))
      }
    },
    startFilters(newFilters) {
      this.filters = newFilters

      if (this.mercuriales.length && !this.filters.pg) {
        this.filters.pg = this.mercuriales[0].code
      }

      if (!this.filters.sel) {
        this.filters.sel = "0"
      }

      if (this.filters.sel !== "-1" && this.filters.sel !== "-2") {
        return chargerSelection(this.filters.sel)
        .then(function (sel) {
          this.currentCatalog.selections[this.filters.sel] = sel
          this.drawMain()
        }.bind(this))
      }

      if (this.filters.pg && this.mercuriales.length) {
        this.drawMain()
      }

      this.keyFamilles += 1
    }
  },
  methods: {
    appliquerFiltre(filtrer) {
      // Nécessaire pour faire fonctionner les filtres de la barre de filtres
      filtrer(this)
    },

    familleNomComplet(code) {
      if (code === "Famille...") {
        return "Famille..."
      }

      if (!code) {
        return ""
      }

      if (code === "recette") {
        return "Recette"
      }

      let famille = this.$store.state.definitions.familles.find(item => item.code === code)
      if (famille) {
        return famille.nom
      }
      let gamme = this.$store.state.definitions.gammes.find(item => item.code === code)
      if (gamme) {
        return gamme.nom
      }
    },

    getCurrentCatalog() {
      this.currentCatalog = this.mercuriales.find(m => m.code === this.filters.pg)
      return this.currentCatalog
    },

    /* execute draw sur les produits correspondant à filters */
    getProds(draw) {
      var that = this
      var sel

      var cat = this.getCurrentCatalog()

      function selProd(prod) {
        if (sel && sel.Produits[prod.Id]) {
          draw(prod)
        }
      }

      function searchProd(prod) {
        var words
        function testWord(word) {
          if (rechercherTexte(prod.search, word)) {
            return true
          }
          let fournisseurs = Object.keys(prod.tarifs || {})
          if (!fournisseurs.length) {
            return false
          }
          return fournisseurs.some(f => {
            let t = prod.tarifs[f]
            return rechercherTexte(t.Reference, word) || rechercherTexte(t.Designation, word)
          })
        }

        function testFavori(prod) {
          if (!that.filtres.favoris.selection) {
            // Si on ne filtre pas les favoris, cette recherche est forcément vraie
            return true
          }

          let estFavori = prod.estFavori

          if (!estFavori) {
            let fournisseurs = Object.keys(prod.tarifs || {})
            if (!fournisseurs.length) {
              return false
            }
            estFavori = fournisseurs.some(f => {
              let t = prod.tarifs[f]
              return t.estFavori
            })
          }

          return estFavori
        }

        if (testFavori(prod)) {
          words = that.filters.search.split(" ")
          if (words.every(testWord)) {
            draw(prod)
          }
        }
      }

      function filterProd(prod) {
        if (sel.Produits[prod.Id]) {
          searchProd(prod)
        }
      }

      if (!cat) {
        return
      }

      if (this.filters.sel === "0") {//mercuriale
        if (this.filters.search || this.filtres.favoris.selection) {
          cat.products.forEach(searchProd)
        } else {
          cat.products.forEach(draw)
        }
      } else if (this.filters.sel === "-1") {//mercurialeHM
        if (this.filters.search || this.filtres.favoris.selection) {
          cat.productsHM.forEach(searchProd)
        } else {
          cat.productsHM.forEach(draw)
        }
      } else if (this.filters.sel === "-2") {//mercurialeL
        if (this.filters.search || this.filtres.favoris.selection) {
          cat.productsL?.forEach(searchProd)
        } else {
          cat.productsL?.forEach(draw)
        }
      } else {//sélection
        sel = cat.selections[this.filters.sel]
        if (this.filters.search || this.filtres.favoris.selection) {
          cat.products.forEach(filterProd)
          cat.productsHM.forEach(filterProd)
        } else {
          cat.products.forEach(selProd)
          cat.productsHM.forEach(selProd)
        }
      }
    },

    /* dessine la grille si le scrolling vertical a changé, ou avec drawGrid(true);  */
    drawGrid(e) {
      let grid

      if (e && e.stopPropagation && e.preventDefault) {
        e.stopPropagation()
        e.preventDefault()
        e.returnValue = false
      }
      grid = this.getCurrentCatalog().grid
      //start = window.pageYOffset / ((grid.bodyHeight - grid.height) / (grid.allRows.length - grid.displayCount))
      //if (e === true || start !== grid.start) {
        //grid.start = start
        //if (this.windowMode) {
          grid.rows = grid.allRows
        //} else {
        //  grid.rows = grid.allRows.slice(start, start + grid.displayCount + 1)
        //}
        if (e) {
          jquery(grid.drawTo)
            .find("tbody")
            .html(vm.st.getBody(grid)) //mise à jour du body
        } else {
          vm.st(grid) //dessin complet
        }
        if (this.fournExclus?.length) {
          this.cacherFournisseurs()
        }
      //}
      this.bindMain()
    },


		findProd(idProd, prods) {
			var prod;

			function find(list) {
				if (!list) {
					return;
				}
				vm.forEach(list, function (p) {
					if (p.Id === idProd) {
						prod = p;
					}
				});
			}

			if (typeof idProd === "number") {
				prod = this.mercuriales.find(m => {return m.id === idProd});
			} else if (prods) {
				if (prods.products) {
					find(prods.products);
				}
				if (!prod && prods.productsHM) {
					find(prods.productsHM);
				}
				if (!prod && prods.productsL) {
					find(prods.productsL);
				}
				if (!prods.products && !prods.productsHM) {
					find(prods);
				}
			} else {
        this.getCurrentCatalog()
				if (this.currentCatalog && this.currentCatalog.products) {
					find(this.currentCatalog.products);
				}
				if (!prod && this.currentCatalog && this.currentCatalog.productsHM) {
					find(this.currentCatalog.productsHM);
				}
				if (!prod && this.currentCatalog && this.currentCatalog.productsL) {
					find(this.currentCatalog.productsL);
				}
				if (!prod) {
					vm.forEach(this.mercuriales, function (cat) {
						if (!cat.EstGamme) {
							find(cat.products);
							if (!prod) {
								find(cat.productsHM);
							}
							if (!prod) {
								find(cat.productsL);
							}
						}
					});
				}
			}

			return prod;
		},


    drawMain(gridOnly) {
      var that = this

      function updateSelections() {
        that.optionsSelections = []
        that.optionsSelections.push({selectionId: "0", nom: "Mercuriale complète"})
        that.optionsSelections.push({selectionId: "-1", nom: "Produits hors marché"})
        if (that.estAutoriseCreationFournisseur && !that.currentCatalog.EstGamme) {
          that.optionsSelections.push({selectionId: "-2", nom: "Fournisseurs locaux"})
        }
        vm.forEach(
          that.currentCatalog.selections, //ajout des sélections
          function(a, b) {//tri par nom "naturel"
            var al = vm.to.search(a.nom)
            var bl = vm.to.search(b.nom)
            if (al === bl) {
              return 0
            }
            if (al < bl) {
              return -1
            }
            return 1
          },
          function(sel) {
            that.optionsSelections.push({selectionId: sel.selectionId, nom: sel.nom})
          }
        )

        return Promise.resolve()
      }

      function drawTable() {
        var params
        var width
        var colCount
        var widthCount
        var height
        var rowHeight

        rowHeight = 24
        if (that.filters.sel === "-2") {
          width = (jquery('#mercuriales').width() - 600) / (that.currentCatalog.fournisseursL?.length || 1)
        } else {
          width = (jquery('#mercuriales').width() - 600) / that.currentCatalog.fournisseurs.length
        }
        if (width > 300) {
          colCount = "3"
          widthCount = "6"
        } else if (width > 250) {
          colCount = "3"
          widthCount = "5"
        } else if (width > 200) {
          colCount = "3"
          widthCount = "4"
        } else if (width > 150) {
          colCount = "3"
        } else if (width > 100) {
          colCount = "2"
        } else {
          colCount = "1"
        }
        if (jquery("#mercuriales").length) {
          height = window.innerHeight - jquery("#mercuriales").offset().top - 15
        }
        height = parseInt(height)

        params = {
          class: "gv-catalog",
          colCount: colCount,
          cols: (that.filters.sel === "-2" && that.currentCatalog.colsL && that.currentCatalog.colsL[widthCount || colCount]) ||
            that.currentCatalog.cols[widthCount || colCount],
          displayCount: Math.floor((height - 46) / rowHeight),
          drawTo: '#mercuriales',
          head: (that.filters.sel === "-2" && that.currentCatalog.headL && that.currentCatalog.headL[colCount]) ||
            that.currentCatalog.head[colCount],
          height: height,
          rowHeight: rowHeight,
          allRows: []
        }

        that.getProds(function(prod) {
          params.allRows.push(prod.html[params.colCount])
        })
        //params.bodyHeight = params.allRows.length * params.rowHeight
        that.currentCatalog.grid = params
        that.drawGrid()
        return Promise.resolve()
      }

      function drawVoid() {
        var body =
          "<div>Tous les fournisseurs sont désactivés, pas de Mercuriale disponible.</div>" +
          '<div>Vous pouvez en réactiver sur la page <a class="hasNav" data-url="#Fournisseurs">fournisseurs</a>.'
        jquery('#mercuriales').html(body)
        vm.bind()
        return Promise.resolve()
      }

      this.getCurrentCatalog()

      if (!this.currentCatalog) {
        return drawVoid()
      }

      if (gridOnly) {
        return drawTable()
          .then(this.bindMain)
      }

      return updateSelections()
        .then(drawTable)
        .then(this.showTooltip)
        .then(this.bindMain)
    },

    cacherFournisseurs() {
      var strFourn = ""
      this.fournExclus.forEach(f => {
        strFourn += (strFourn ? "," : "") + "th[data-idfourn=\"" + f + "\"],td[data-idfourn=\"" + f + "\"]"
      })
      jquery("#mercuriales").find(strFourn).hide()

      var grid = this.getCurrentCatalog().grid
      var span = grid.cols.match(/col/g).length - this.fournExclus.length * grid.colCount
      jquery("#mercuriales").find(".group[colspan], .sub-group[colspan]").prop("colspan", span)
    },

    obtenirSelection(idProd, idFourn) {
      if (this.currentCatalog.products?.find(l => l.Id === idProd))
        return "0"
      let produitHM = this.currentCatalog.productsHM?.find(l => l.Id === idProd)
      if (produitHM && (!idFourn || produitHM.tarifs[idFourn]))
        return "-1"
      // Inutile de tester de nouveau le fournisseur (s'il n'a pas été trouvé auparavant, il est forcément présent parmis les fournisseurs locaux)
      if (this.currentCatalog.productsL?.find(l => l.Id === idProd))
        return "-2"
    },

    obtenirProduitMercuriale(idProd, selection) {
      if (selection === "0")
        return this.currentCatalog.products?.find(l => l.Id === idProd)
      if (selection === "-1")
        return this.currentCatalog.productsHM?.find(l => l.Id === idProd)
      if (selection === "-2")
        return this.currentCatalog.productsL?.find(l => l.Id === idProd)
    },

    updateFavori(actif, idProd, idFourn) {

      async function majFavori(ligne, idFourn, actif) {
        if (!ligne) {
          return
        }

        if (idFourn) {
          let fourn = ligne.tarifs[idFourn]
          if (fourn) {
            fourn.estFavori = actif
          }
        } else {
          ligne.estFavori = actif
        }
        await updateLigneCatalogue(ligne)
      }

      majFavori(this.currentCatalog.products?.find(l => l.Id === idProd), idFourn, actif)
      majFavori(this.currentCatalog.productsHM?.find(l => l.Id === idProd), idFourn, actif)
      majFavori(this.currentCatalog.productsL?.find(l => l.Id === idProd), idFourn, actif)

      this.drawMain(true)
    },

    bindMain() {

      var that = this

			var search;
      var clearSearch;
      var toggleFavori;
      var nomProduit;
			/* var btnEditSel;
			var btnNewSel;
			var btnNewProd;
			var btnNewFourn;
			var btnShowCart;
			var btnFullSearch;
			var btnDLOne;
			var btnDLAllC;
			var btnDLAllS;
			var btnDLSameS;
			var btnDLPap;
			var btnAddChecked;
			var checkbox; */


			function onSearch(e) {
				that.onSearch({target: e.currentTarget}, that.drawMain);
			}

			function onClearSearch(e) {
				that.onClearSearch(e.currentTarget, that.drawMain);
			}

      function onClick(current) {
        if (!current.locked) {
          return;
        }
				current.idProd = current.target.closest("[data-idprod]").data("idprod");
				current.idFourn = current.target.data("idfourn");
        if (that.estAutoriseCreationFournisseur && that.currentCatalog.fournisseursL?.find(f => f.idFourn === current.idFourn)) {
          current.prod = that.findProd(current.idProd, that.currentCatalog.productsL);
        } else {
          current.prod = that.findProd(current.idProd);
          if (!current.prod) {
            current.prod = that.findProd(current.idProd, that.currentCatalog.productsHM);
          }
				}
				current.tarif = current.prod && current.prod.tarifs && current.prod.tarifs[current.idFourn];
				if (current.idFourn) {
					that.clicTarif(current, (current.prod.isHM && that.canUpdateHM) || (current.prod.isLocal && that.estAutoriseCreationFournisseur));
				} else {
					that.clicProd(current, (current.prod.isHM && that.canUpdateHM) || (current.prod.isLocal && that.estAutoriseCreationFournisseur));
				}
      }

      async function onToggleFavori(e) {
        if (e.target.classList.contains("fa-spin")) {
          return
        }
        e.target.classList.add("fa-spin")
        e.stopPropagation()
        let idProd = e.target.closest("tr").dataset.idprod
        let idFourn = e.target.parentNode.dataset.idfourn || null
        let newState = await articleFavori(idProd, idFourn)
        that.updateFavori(Boolean(newState.data), idProd, idFourn)
        that.$emit("filter-changed", that.filters)
        that.$toast('Favori modifié !', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          className: ['toast-success'],
          duration: 1500
        })
      }

      function onHoverProduit(e) {//gestion du tooltip sur le nom des produits
        if (e.target.offsetWidth < e.target.scrollWidth) {//nom du produit tronqué, on affiche les noms du produit et de l'ingrédient
          e.target.parentNode.title = e.target.dataset.produit + "\nIngrédient : " + e.target.dataset.ingredient
        } else {//pas d'overflow, on affiche seulement le nom de l'ingrédient
          e.target.parentNode.title = e.target.dataset.ingredient
        }
      }

			/* 
      
      function onCheck(e) {

				var checked;
				var prod;

				if (e && e.currentTarget) {//lancé par un clic, mise à jour de la sélection

					checked = e.currentTarget.checked;

					prod = that.findProd(jquery(e.currentTarget).closest("[data-idprod]").data("idprod"));

					if (prod.idType === 1) {//produit

						prod.checked = checked;

					} else if (prod.idType === 2 || (prod.idType === 3 && that.currentCatalog.EstGamme)) {//groupe

						that.getProds(function (p) {

							if (p.idGroup === prod.idGroup) {

								p.checked = checked;

							}

						});

					} else if (prod.idType === 3) {//sous-groupe

						that.getProds(function (p) {

							if (p.idSGroup === prod.idSGroup) {

								p.checked = checked;

							}

						});

					} else if (that.currentCatalog.EstGamme) {//famille

						prod.checked = checked;

						that.getProds(function (p) {

							if (p.idCat === prod.Id) {

								p.checked = checked;

							}

						});

					}

				}

				if (!checked) {//activation du bouton si nécessaire

					that.getProds(function (p) {

						if (p.checked && p.idType === 1) {

							checked = true;

						}

					});

				}

				if (checked) {

					if (btnAddChecked.attr("disabled")) {

						btnAddChecked.attr("disabled", false);

						that.showTooltip();

					}

				} else {

					if (!btnAddChecked.attr("disabled")) {

						btnAddChecked.attr("disabled", true);

					}

				}

				if (e) {//dessin

					that.drawGrid(true);

				}

			}

			function addChecked() {

				var msg;

				var count = 0;

				if (btnAddChecked.attr("disabled")) {

					vm.ask({
						head: "Information",
						body: "Veuillez cocher les produits à ajouter."
					});

					return;

				}

				that.getProds(function (p) {

					if (p.checked) {

						p.checked = false;

						if (p.idType === 1) {

							count += 1;

							that.addProd({prod: p}, {noSave: true});

						}

					}

				});

				if (count === 1) {

					msg = "1 produit a été ajouté";

				} else {

					msg = count + " produits ont été ajoutés";

				}

				if (that.pinnedOrder) {

					msg += " à le commande " + that.pinnedOrder.title + ", pour un total de " + vm.count(that.pinnedOrder.LignesCommande) + " produits.";

				} else {

					msg += "  au panier, pour un total de " + vm.count(that.cart) + " produits.";

				}

				vm.flash(msg);

				that.drawGrid(true);

				that.drawBtnCart();

			}

			function bindMenu() {

				btnDLOne = jquery(".btnDLOne");
				btnDLOne.off("click");
				btnDLOne.on("click", that.dl);

				btnDLAllC = jquery(".btnDLAllC");
				btnDLAllC.off("click");
				btnDLAllC.on("click", function () {

					return that.dl({allCats: true});

				});

				btnDLAllS = jquery(".btnDLAllS");
				btnDLAllS.off("click");
				btnDLAllS.on("click", function () {

					return that.dl({allSels: true});

				});

				btnDLSameS = jquery(".btnDLSameS");
				btnDLSameS.off("click");
				btnDLSameS.on("click", function () {

					return that.dl({selName: that.currentCatalog.selections[that.filters.sel].nom});

				});

				btnDLPap = jquery(".topbar .btnDLPap");
				btnDLPap.off("click");
				btnDLPap.on("click", function () {

					jquery(".btnDL").removeClass("showDetails").popover("hide");

					return that.post.getMercuPap()
						.then(function (result) {

							if (result && result.result && result.result.size) {

								vm.saveBlob(result.result, "Mercuriale papier - " + vm.v.date.iso() + ".xlsx");

							}

              vm.unlock(true);
              
              return

						})
						.catch(that.error);

				});

			}

			vm.bind({
				details: {
					noHover: true,
					onShow: bindMenu
				}
			}); */

			vm.st.bind({
        noLock: true,
				onChange: onClick,
				noTitle: true
      });

      search = jquery(".search");
      search.off("input");
      search.on("input", onSearch);

			clearSearch = jquery(".clearSearch");
      clearSearch.off("click");
      clearSearch.on("click", onClearSearch);

			toggleFavori = jquery(".toggleFavori");
      toggleFavori.off("click");
      toggleFavori.on("click", onToggleFavori);

			nomProduit = jquery(".gv-catalog .prod > span");
      nomProduit.off("mouseenter");
      nomProduit.on("mouseenter", onHoverProduit);

			/* 

      checkbox = jquery(".gv-catalog input[type=\"checkbox\"]");
      checkbox.off("change");
      checkbox.on("change", onCheck);

			btnAddChecked = jquery(".gv-catalog .btnAddChecked");
			btnAddChecked.off("click");
			btnAddChecked.on("click", addChecked);

			btnEditSel = jquery(".btnEditSel");
      btnEditSel.off("click");
      btnEditSel.on("click", function () {
				if (btnEditSel.is(":hidden")) {
					return;
				}
				that.drawSelection();

			});

			btnNewSel = jquery(".btnNewSel");
      btnNewSel.off("click");
      btnNewSel.on("click", function () {
				if (btnNewSel.is(":hidden")) {
					return;
				}
				that.drawSelection(false, false, true);
			});

			btnNewProd = jquery(".btnNewProd");
      btnNewProd.off("click");
      btnNewProd.on("click", function () {
				if (btnNewProd.is(":hidden")) {
					return;
				}
				that.clicProd(undefined, that.canUpdateHM);
			});

			btnNewFourn = jquery(".btnNewFourn");
      btnNewFourn.off("click");
      btnNewFourn.on("click", function () {
				if (btnNewFourn.is(":hidden")) {
					return;
				}
				return that.fournisseurs.drawNew()
          .then(that.drawMain);
			});

			btnShowCart = jquery(".btnShowCart");
      btnShowCart.off("click");
      btnShowCart.on("click", function () {
				if (btnShowCart.attr("disabled")) {
					return;
				}
				that.drawCart();
				
			});

			btnFullSearch = jquery(".btnFullSearch");
			btnFullSearch.off("click");
			btnFullSearch.on("click", that.drawFullSearch);
      */

			if (that.filters.search) {
				clearSearch.show();
			} else {
				clearSearch.hide();
			}
      search.focus();
      window.onscroll = this.drawGrid;
      window.onresize = this.drawMain;
      return Promise.resolve();
    },

    clicProd(prod) {
      this.$emit("clic-produit", prod)
    },

    clicTarif(prod) {
      this.$emit("clic-tarif", prod)
    },

		onSelectSelection(selection) {
			if (selection.selectionId !== "-2" && selection.selectionId !== "-1" && selection.selectionId !== "0") {
        return chargerSelection(selection.selectionId)
          .then(function (sel) {
            this.currentCatalog.selections[selection.selectionId] = sel
            this.filters.sel = selection.selectionId
            this.$emit("filter-changed", this.filters)
            this.drawMain();
          }.bind(this))
      } else {
        this.filters.sel = selection.selectionId
        this.$emit("filter-changed", this.filters)
				this.drawMain();
      }
    },
    
    onSelectDateMercu(selection) {
      let datestring = selection.getFullYear() + ("0"+(selection.getMonth()+1)).slice(-2) + ("0" + selection.getDate()).slice(-2)

      return chargerMercuriales(this.etabCourantId, true, datestring, this.profilLecteurMercu)
          .then(function(r) {
            this.mercuriales = r
            this.getCurrentCatalog()
            this.initData()
          }.bind(this))
    },

    async onSelectFamille(code) {
      var selName
      var cat
      var found

      if (this.filters.sel !== "0" && this.filters.sel !== "-1" && this.filters.sel !== "-2") {
        selName = this.currentCatalog.selections[this.filters.sel].Nom;
      }
      this.filters.pg = code;
      cat = this.getCurrentCatalog()
      if (this.filters.sel === "-2") {//pas de mercuriale locale pour les gammes
        if (cat.EstGamme) {
          this.filters.sel = "0";
        }
      } else if (this.filters.sel !== "0" && this.filters.sel !== "-1") {//recherche d'une sélection portant le même nom
        vm.forEach(cat.selections, function (s) {
          if (s.Nom === selName) {
            this.filters.sel = s.selectionId;
            found = true;
          }
        }.bind(this));
        if (!found) {
          this.filters.sel = "0";
        }
      }
      this.$emit("filter-changed", this.filters)
      this.drawMain()
      this.keyFamilles += 1
    },

		onSearch(params, done) {
			if (params.target) {
				params.target = jquery(params.target);
			} else {
				params.target = jquery("input.search");
			}
			if (params.str === undefined) {
				params.str = params.target.val();
			}
			if (params.str) {
				params.target.siblings(".clearSearch").show();
			} else {
				params.target.siblings(".clearSearch").hide();
			}
			params.target.val(params.str);
			this.filters.search = vm.to.search(params.str);
			done(true);
		},

		onClearSearch(target, done) {
			target = jquery(target);
			this.filters.search = "";
			target.siblings(".search").val("");
			target.hide();
			done(true);
    },
    
    initData() {
      this.optionsFamilles = []
      vm.forEach(
        this.mercuriales,
        function(a, b) {
          return a.indice > b.indice ? 1 : -1
        },
        function(pg) {
          this.optionsFamilles.push(pg.code)
        }.bind(this)
      )
      if (!this.filters.pg) {
        this.filters.pg = this.optionsFamilles[0]
      }
      this.onSelectFamille(this.filters.pg)
    }
  },
  created() {
    this.filters = this.startFilters
    return chargerMercuriales(this.etabCourantId, null, null, this.profilLecteurMercu)
      .then(function(r) {
        this.mercuriales = r
        this.getCurrentCatalog()
        this.initData()
        this.filtres.initialiser(this)
      }.bind(this))
  },
  beforeRouteLeave(to, from, next) {
    window.onscroll = undefined
    window.onresize = undefined
    next()
  }
}
</script>

<style lang="scss" scoped>
/*.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.titre-plan {
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.05rem;
  font-size: 1.5rem;
  padding: 0.5rem;
  color: white;
}
.fa-trash-alt {
  color: #ffffff;
  background: #ea0000de;
  padding: 10px;
  border-radius: 5px;
  margin-left: 0rem;
  cursor: pointer;
}
.fa-less-than,
.fa-greater-than {
  cursor: pointer;
  color: white;
}
.fa-less-than {
  margin-right: 0.5rem;
}
.fa-greater-than {
  margin-left: 0.5rem;
}
.arrows {
  margin: 0 !important;
  font-size: 2rem;
}
/*html body{
  background-color: white !important;
}*/
</style>

<style>
/*.filter {
  margin: 6px;
  font-size: 12px;
  font-weight: normal;
}
.line > * {
  display: inline-block;
  vertical-align: top;
}
.filter label {
  display: block;
  margin: 0;
}
.filter select {
  padding: 0 14px 0 0;
}
.filter * {
  font-weight: inherit;
}*/
/*html body .content.app-content {
  overflow: visible;
}*/
/*html body .content .content-wrapper {
  padding: 0;
}*/
.scroll-table > * {
  cursor: default;
  overflow-x: hidden;
  overflow-y: hidden;
  user-select: none;
}
/*.scroll-table > *:nth-of-type(1) {/* head */
/*	margin-bottom: -1px;
  z-index: -1;
}*/
/*.scroll-table > *:nth-of-type(2) {/* body */
/*	height: 100%;
}*/
.gv-catalog table {
  background-color: transparent;
  border: 1px solid #ddd;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 12px;
  margin: 0;
  table-layout: fixed;
  width: 100%;
  max-width: 100%;
}
.gv-catalog tr {
  background-color: inherit;
  height: 22px;
}
.gv-catalog th, .scroll-table td {
  font-weight: normal;
  overflow: hidden;
  padding: 2px !important;
  text-align: left !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/*.gv-catalog th {
  color: black;
}*/
/*.clearSearch, .clearFullSearch {
  margin-left: -25px;
  text-decoration: none;
  cursor: pointer;
  display: inline;
}*/
/*.selections > .dropdown {
  will-change: unset!important;
  transform: scale(0.9);
  margin-left: -8px;
}
.selections > .dropdown > button {
  padding: 9px 10px 13px 12px;
}*/
/*.recherche > input {
  color: black;
  font-size: 13px;
  margin-top: 4px;
  padding: 16px 10px 21px 12px;
}*/
</style>
