<template>
  <div class="card" style="min-height:300px;">
    <div class="card-header">
      Productions du jour
    </div>
    <div class="card-container">
      <div v-if="!gotProductions" style="text-align:center;margin-top:50px;">
        <img alt="en attente" src="/assets/images/spinners/cooking-blue-court.gif" style='height:100px;margin-left:-8px;' />
      </div>
      <div v-else>
        <h2 class="text-center mt-2"><b>{{ nombreProductions }}</b></h2>
        <div class="text-center"><b>production{{ productions.length > 1 ? "s" : "" }} prévue{{ productions.length > 1 ? "s" : "" }}</b></div>
        <div class="text-center"><b>entre aujourd'hui et demain</b></div>

        <div class="card card-minimal table-scroll height-responsive-inner-bottom-50 mt-4">
          <table class="table table-sm table-borderless text-center"> <!-- style="display:block;max-height:800px;overflow:auto" -->
            <colgroup>
              <col>
              <col width="130">
              <col width="130">
              <col width="130">
            </colgroup>
            <thead>
              <th>Productions</th>
              <th>Fiche de prod</th>
              <th>Fiche de sortie</th>
              <th>Bon de livraison</th>
            </thead>
          </table>
          <hr class="mt-0 mb-0" />
          <div class="table-body">
            <table class="table table-sm table-borderless text-center">
              <colgroup>
                <col>
                <col width="130">
                <col width="130">
                <col width="125">
              </colgroup>
              <tbody>
                <!-- Affichage des productions d'aujourd'hui -->
                <tr v-if="productions.some(l => formatDateAvecTirets(l.dateProduction) === formatDateAvecTirets(new Date()))">
                  <td colspan="4"><b>{{ formatHumanDate(new Date()) }}</b></td>
                </tr>
                <tr v-for="(production, iProduction) in productions.filter(l => formatDateAvecTirets(l.dateProduction) === formatDateAvecTirets(new Date()))" :key="iProduction">
                  <td>{{ production.nom }}</td>
                  <td class="p-1">
                    <b-button class="btn btn-primary" @click="telechargerFichesProd(production.id)" title="Télécharger la fiche de production">Voir</b-button>
                  </td>
                  <td>
                    <b-button class="btn btn-primary" @click="telechargerFichesSortie(production.id)" title="Télécharger la fiche de sortie">Voir</b-button>
                  </td>
                  <td>
                    <b-button class="btn btn-primary" @click="telechargerBonDeLivraison(production.id)" title="Télécharger le bon de livraison">Voir</b-button>
                  </td>
                </tr>
                <!-- Affichage des productions de demain -->
                <tr v-if="productions.some(l => formatDateAvecTirets(l.dateProduction) === formatDateAvecTirets(demain()))">
                  <td colspan="4"><b>{{ formatHumanDate(demain()) }}</b></td>
                </tr>
                <tr v-for="(production, iProduction) in productions.filter(l => formatDateAvecTirets(l.dateProduction) === formatDateAvecTirets(demain()))" :key="'demain'+iProduction">
                  <td>{{ production.nom }}</td>
                  <td class="p-1">
                    <b-button class="btn btn-primary" @click="telechargerFichesProd(production.id)" title="Télécharger la fiche de production">Voir</b-button>
                  </td>
                  <td>
                    <b-button class="btn btn-primary" @click="telechargerFichesSortie(production.id)" title="Télécharger la fiche de sortie">Voir</b-button>
                  </td>
                  <td>
                    <b-button class="btn btn-primary" @click="telechargerBonDeLivraison(production.id)" title="Télécharger le bon de livraison">Voir</b-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="text-center mt-2 mb-2">
          <b-button :disabled="!profilIsUtilisateur" class="btn btn-primary" @click="$router.push({ name: 'SuiviProduction', params: { etablissementId: etabCourantId } })">Voir plus de détails</b-button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { lancerTelechargementDeResponse } from '@/helpers/utils'
import { telechargerFichesProdPdf, telechargerFichesSortiePdf, downloadBonDeLivraison } from "@/api/gpao/production"
import { formatageDate, formatHumanDateAvecSlashes } from '@/mixins/dateFormats'
import { connecteData } from "@/mixins/connecteData"
import { mapGetters } from "vuex"

export default {
  name: "WidgetMenus",
  props: [],
  mixins: [formatageDate, formatHumanDateAvecSlashes, connecteData],
  data() {
    return {
      productions: [],
      gotProductions: false,
      indexProduction: 0
    }
  },
  mounted() {
    this.chargerProductions()
  },
  computed: {
    ...mapGetters("definitions", ["informationsAccueil"]),
  },
  methods: {
    demain() {
      let demain = new Date()
      demain.setDate((new Date()).getDate() + 1)
      return demain
    },
    chargerProductions() {
      this.productions = this.informationsAccueil?.productions || []
      this.nombreProductions = String(this.productions?.length || 0)
      if (this.nombreProductions.length < 2) {
        this.nombreProductions = "0" + this.nombreProductions
      }

      this.$nextTick(() => this.gotProductions = true)
    },
    async telechargerFichesProd(productionId) {
      let response = await telechargerFichesProdPdf(productionId)
      lancerTelechargementDeResponse(response)
    },
    async telechargerFichesSortie(productionId) {
      let response = await telechargerFichesSortiePdf(productionId)
      lancerTelechargementDeResponse(response)
    },
    async telechargerBonDeLivraison(productionId) {
      let response = await downloadBonDeLivraison("pdf", productionId)
      lancerTelechargementDeResponse(response)
    }
  }
}
</script>
