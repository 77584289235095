import { connecteData } from "@/mixins/connecteData"
import { chargerToutes } from '@/api/gpao/recettes'

export const recette = {
  methods: {
    estSansCotation(ligne) { // par exemple, abricot en hiver
      return ligne.cotationManquante || ( !ligne.nonCommandable && !ligne.prixUnitaire && ligne.familleId);
      // le !nonCommandable permet d'ecarter l'eau du robinet
    },
    typeAffectationRecette(recette) {
      if (recette.etablissementId)
        return "etab"
      if (recette.utilisateurId)
        return "user";
      return "univ"
    },
    reordonner(lignes) {
      if (lignes) {
        lignes.forEach((ligne, index) => {
          ligne['ordre'] = index + 1
        })
        return lignes
      }
    }
  }
}

export const nomConviveType = {
  methods: {
    nomConviveType(value) { return this.$store.state.definitions.convivesTypes.find(el => el.value === value).text
    }
  }
}

export const nomFamille = {
  methods: {
    nomFamille(id) {
      return this.$store.state.definitions.familles.find(famille => famille.id === id).nom
    }
  }
}

export const codeFamille = {
  methods: {
    codeFamille(id) {
      return this.$store.state.definitions.familles.find(famille => famille.id === id).code
    }
  }
}

export const iconeFamille = {
  methods: {
    iconeFamille(id, couleur) {
      return '/assets/images/icones/' + this.$store.state.definitions.familles.find(famille => famille.id === id).code.toUpperCase() + "-" + couleur.toUpperCase() + '.png'
    }
  }
}

export const estRecetteInvalide = {
  computed: {
    estRecetteInvalide() {
 //     if (!this.$v.recette.zonePreparationId.required)
   //       return true;
      if (this.qteTypeforPersons) {
        return !this.$v.recette.nom.required || !this.$v.recette.pourNombre.required || !this.$v.recette.up.required || this.inputForPersonsIntegerRequired || !this.recette.conviveTypeId || this.recette.lignes.length < 1
      } else {
        return !this.$v.recette.nom.required || !this.$v.recette.pourNombre.required || !this.$v.recette.up.required || this.inputForPersonsIntegerRequired || this.recette.lignes.length < 1
      }
    }
  }
}

export const recetteLignesFromData = {
  methods: {
    reordonner(lignes) {
      lignes.forEach(function (ligne, index) {
        ligne['ordre'] = index + 1
      })
      return lignes
    },
    async recetteLignesFromData(recette) {
      let response = await chargerToutes(this.etabCourantId)
      let recettes = response.data
      let resultLignes = []
      recette.lignes.forEach((element) => {
        let ligne = Object.assign({}, element)
        if (ligne.familleId) {
          ligne.composant = this.$store.getters["definitions/obtenirIngredient"](ligne.composantId)
          ligne["famille"] = this.$store.getters["definitions/obtenirFamille"](ligne.familleId).code
          if (ligne.details && ligne.details.length) {
            ligne["UPs"] = Object.keys(ligne.details[0].quantites)
          }
        }
        else {
          ligne.composant = recettes.find(r => r.id === ligne.composantId)
        }
        resultLignes.push(ligne)
      })
      return resultLignes
    }
  }
}

export const quantitesParAssietteFromData = {
  methods: {
    quantitesParAssietteFromData(recette, convives, services) {
      var quantites = []
      convives.forEach(c => {
        var convive = {
          typeConviveId: c.value,
          typeConviveNom: c.text,
          ordre: c.ordre,
          services: [],
          afficher: false
        }
        services.forEach(s => {
          var quantite = recette.quantitesParAssiette.find(q => q.typeConviveId === c.value && q.serviceId === s.value)
          quantite = quantite && quantite.quantite || 0
          if (quantite > 0) {
            convive.afficher = true
          }
          convive.services.push({
            serviceId: s.value,
            serviceNom: s.text,
            ordre: s.ordre,
            quantite: quantite,
            ratio: s.ratio
          })
        })
        convive.services.sort((a, b) => a.ordre > b.ordre ? 1 : -1)
        if (convive.typeConviveId === recette.conviveTypeId) {//calcul du grammage de référence
          if (convive.services.find(s => s.serviceId === "SE2").quantite) {//si le déjeuner existe, c'est lui
            convive.base100 = "SE2"
          } else if (convive.services.find(s => s.serviceId === "SE4").quantite) {//sinon, si le dîner existe, c'est lui
            convive.base100 = "SE4"
          } else {
            let first = convive.services.find(s => s.quantite)
            if (first) {//sinon, on prend le premier qui existe
              convive.base100 = first.serviceId
            } else {//sinon, on prend le premier
              convive.base100 = convive.services[0].serviceId
            }
          }
        }
        quantites.push(convive)
      })
      quantites.sort((a, b) => a.ordre > b.ordre ? 1 : -1)
      this.quantitesParAssiette = quantites
    }
  }
}


export const apiTypeDeRecherche = {
  methods: {
    apiTypeDeRecherche(type) {
      if (type === 'toutes') {
        return '&Propriete=0'
      }
      if (type === 'universelles') {
        return '&Propriete=1'
      }
      if (type === 'personnelles') {
        return '&Propriete=2'
      }
      if (type === 'etablissement') {
        return '&Propriete=3'
      }
    }
  }
}

export const verifierAutorisations = {//fix temporaire pour s'assurer que les utilisateurGPAO puissent modifier leurs recettes et celles de leur établissement (et que les lecteurGPAO ne puissent rien modifier)
  mixins: [connecteData],
  methods: {
    verifierAutorisations(recette) {
      if (this.profilIsLecteur && !recette.lectureSeule) {
        recette.lectureSeule = true
        console.log("correction autorisation recette")
      }
      if (this.profilIsUtilisateur && (!recette.estUniverselle || recette.recetteOririneId) && recette.lectureSeule) {
        recette.lectureSeule = false
        console.log("correction autorisation recette")
      }
    }
  }
}
