<template>
  <div style="display:inline">
    <b-modal :id="idModal" size="xl" :title="titreModal + (action === 'out' && quantiteDisponible ? ' - Quantité disponible en stock : ' + quantiteDisponible + ' ' + lot.uc : '')"
      ok-title="Enregistrer" 
      :ok-disabled="!$v.typeMouvement.required || !$v.quantite.required || !$v.dateMouvement.required || !$v.article.required || !$v.serviceId.required || !$v.menuPlanId.required || !$v.dateConsommation.required || !$v.commentaire.required"
      cancel-title="Annuler"
      no-close-on-backdrop
      @show="initArticle()"
      @ok="envoyer()">
      <template slot="default">
        <div v-if="lot">
          <h5>Article : {{lot.designationFournisseur}}</h5>
        </div>

        <div v-else-if="zoneStockage">
          <h5>Zone de stockage à vider : {{zoneStockage.designation}}</h5>
        </div>

        <div v-else>
          <RechercheArticles @change-ingredient="changeIngredient($event)" @change-state="changeEtatRecherche($event)" />

          <h5 class="push-top-1rem" :key="stateSearch" v-if="stateSearch">{{stateSearch}}</h5>

          <ArticlesParProduit v-if="ingredient && ingredient.parProduit" :articles="ingredient.parProduit" :favoris="ingredient.favoris" :ingredient="ingredient" :index="0" :key="keyArticles" @selection-article="setArticle($event)" />

          <h5 v-if="article && article.designation" class="push-top-1rem">Article : {{article.designation}}</h5>
        </div>

        <div class="card" v-if="article.tarifId || zoneStockage">
          <div class="card-body">
            <div v-if="article.tarifId" class="flex-center">
              <div class="col-4 flex-start">
                <div class="label-input push-right-1rem">N° Lot : </div>
                <input type="text" :disabled="action === 'out'" v-model="NumLot">
              </div>
              <div class="col-4 flex-start">
                <div class="flex-center-space-between">
                  <b-input-group>
                    <button type="button" class="btn btn-primary" :disabled="action === 'out'" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" :title="!dlc && 'Date de Durabilité Minimale (ex. DLUO)'">
                      {{dlc ? 'DLC' : 'DDM'}}
                    </button>
                    <div class="dropdown-menu">
                      <a class="dropdown-item" href="#" @click="dlc=true">DLC</a>
                      <a class="dropdown-item" href="#" @click="dlc=false">DDM</a>
                    </div>
                  </b-input-group>
                  <v-date-picker class="date-picker" :disabled="action === 'out'" :columns="1" :step="1" v-model="dlcDluo" :popover="{ visibility: 'click' }" @input="setDateDlcDluo()">
                    <template v-slot="{ inputValue, inputEvents }">
                      <input :value="inputValue ? inputValue : dlc ? 'DLC...' : 'DDM...'" v-on="inputEvents" />
                    </template>
                  </v-date-picker>
                </div>
              </div>
              <div class="col-4 flex-end">
                <div class="label-input push-right-1rem">PU HT :</div>
                <InputNumber :disabled="action === 'out'" typeFiltre="montant" v-model="valeurUnitaire" v-tooltip="{ content: 'Prix Unitaire', placement: 'top' }" style="width:100px"/>
                <div class="label-input ml-1">€</div>
              </div>
            </div>
            <div class="flex-center push-top-1rem">
              <div v-if="mode === 'stock'" class="col-4 flex-start">
                <div v-if="action === 'in'" class="label-input push-right-1rem">Entrée au : </div>
                <div v-if="action === 'out'" class="label-input push-right-1rem">Sortie au : </div>
                <v-date-picker v-model="dateMouvement" :disabled-dates="datesInvalides" :popover="{ visibility: 'click' }" @input="setDateMouvement($event)">
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="flex flex-col sm:flex-row justify-start items-center">
                      <div class="relative flex-grow">
                        <input style="height:40px;" class="flex-grow pl-8 pr-5 py-1 bg-gray-100 border rounded w-full text-gray-900"
                          :value="inputValue ? inputValue : 'Choisir la date...'"
                          v-on="inputEvents"
                        />
                      </div>
                    </div>
                  </template>
                </v-date-picker>
              </div>
              <div v-if="mode === 'stock'" class="col-4">
                <!-- En attendant le end-point ou j'ai pas bien vu -->
                <b-form-radio-group v-model="typeMouvement" :options="optionsTypeMouvement" @change="typeMouvementChange" />
                <div class="error" v-if="!$v.typeMouvement.required">
                  Ce champ est requis
                </div>
              </div>
              <div v-if="!zoneStockage" class="col-4" :class="mode === 'stock' ? ' flex-end' : ' flex-start'">
                <div class="label-input push-right-1rem">Quantité&nbsp;:</div>
                <div>
                  <div class="flex-center">
                    <InputNumber id="quantite" typeFiltre="quantite" v-model.trim="$v.quantite.$model" style="max-width:130px" :disabled="this.razQuantite" :key="keyQuantite" />
                    <div class="push-left-1rem">
                      <span v-if="ingredient.uc"> {{ingredient.uc}} </span>
                    </div>
                    <div class="flex-end ml-2" v-if="action === 'out'">
                      (
                      <div class="custom-control custom-checkbox ml-2" v-tooltip="{ content: 'Vide le stock pour cet article', placement: 'top' }">
                        <input class="custom-control-input cursor-pointer" id="raz" type="checkbox" v-model="razQuantite" @click="razQte()">
                        <label for="raz" class="custom-control-label cursor-pointer mr-1">RàZ</label>
                      </div>
                      )
                    </div>
                  </div>
                  <div class="error" v-if="!$v.quantite.required">
                    Ce champ est requis
                  </div>
                </div>
              </div>
            </div>
            <div v-if="action === 'out' && article.tarifId" class="flex-center push-top-1rem mb-4">
              <div class="col-12">
                <label for="datesMenus">Choisir le jour de la consommation à partir du menu :</label>
                <SelectDatesMenusPlansServices id="datesMenus" :multiSelect="false" :multiDates="false" :disabled="!sortieJournee" :dateDebut="optionsSortieStock.dateDebut" :dateFin="optionsSortieStock.dateFin" :menuPlanIds="optionsSortieStock.menusPlansIds" :serviceIds="optionsSortieStock.servicesIds" viderMenusPlans="Vider" @changer-dms="changerParametresMenus($event)" />
                <div class="error" v-if="!$v.serviceId.required || !$v.menuPlanId.required || !$v.dateConsommation.required">
                  Ces champs sont requis
                </div>
              </div>
            </div>
            <div class="flex-center push-top-1rem">
              <div v-if="mode === 'stock'" class="col-12">
                <b-form-textarea v-model="commentaire" placeholder="Commentaire..." rows="4" max-rows="4" style="height:100px"></b-form-textarea>
                <div class="error" v-if="!$v.commentaire.required">
                  Ce champ est requis
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { requiredIf } from 'vuelidate/lib/validators'
import { formatageDate } from '@/mixins/dateFormats'
import { connecteData } from "@/mixins/connecteData"
import { constantesId } from "@/helpers/constantes"
import { arrondiNombre } from '@/helpers/utils'
import InputNumber from '@/components/Inputs/InputNumber'
import RechercheArticles from "@/components/Divers/RechercheArticles"
import ArticlesParProduit from '@/components/Stock/ArticlesParProduit'
import SelectDatesMenusPlansServices from '@/components/Divers/SelectDatesMenusPlansServices'
import vm from "@/helpers/vm-toolkit-vue"

export default {
  name: "ModalAjoutMvtStock",
  props: ['id', 'dateEtatAu', 'type', 'btnImage', 'title', 'action', 'disabled', 'mode', 'datesInvalides', 'zoneStockage'],
  mixins: [formatageDate, connecteData],
  components: {
    InputNumber,
    RechercheArticles,
    ArticlesParProduit,
    SelectDatesMenusPlansServices
  },
  data() {
    return {
      lot: null,
      idModal: this.id ? this.id : "nouvelArticle",
      titreModal: this.title,
      iClass: this.btnImage,
      article: {},
      dateMouvement: '',
      typeMouvement: null,
      quantite: '',
      quantiteDisponible: null,
      NumLot: '',
      dlc: true,
      dlcDluo: '',
      dlcDluoPourApi: null,
      valeurUnitaire: null,
      optionsSortieStock: {},
      dateConsommation: '',
      menuPlanId: null,
      serviceId: null,
      optionsMenuPlans: [],
      optionsServices: [],
      listeServices: [],
      listeStructure: [],
      commentaire: '',
      ingredient: {},
      keyArticles: 1,
      stateSearch: "",
      razQuantite: false,
      keyQuantite: 1
    }
  },
  validations: {
    typeMouvement: {
      required: requiredIf(function () {
        return this.mode === "stock"
      })
    },
    quantite: {
      required: requiredIf(function() {
        return !this.razQuantite && !this.zoneStockage
      })
    },
    dateMouvement: {
      required: requiredIf(function () {
        return this.mode === "stock"
      })
    },
    article: {
      required: requiredIf(function () {
        return !this.lot && !this.zoneStockage
      })
    },
    commentaire: {
      required: requiredIf(function() {
        return this.razQuantite || this.zoneStockage
      })
    },
    dateConsommation: {
      required: requiredIf(function() {
        return this.sortieJournee
      })
    },
    menuPlanId: {
      required: requiredIf(function() {
        return this.sortieJournee
      })
    },
    serviceId: {
      required: requiredIf(function() {
        return this.sortieJournee
      })
    }
  },
  computed: {
    afficherBouton() {
      return this.modeBouton
    },
    sortieJournee() {
      return this.typeMouvement === constantesId.tMvtStockSortieJournee
    },
    optionsTypeMouvement() {
      var options = []
      if (this.action === 'in') {
        options.push({ text: "Entrée de Marchandise", value: constantesId.tMvtStockEntreeMarchandise })
      }
      if (this.action === 'out' && !this.zoneStockage) {
        options.push({ text: "Journée", value: constantesId.tMvtStockSortieJournee })
      }
      options.push({ text: "Régularisation", value: constantesId.tMvtStockRegularisation })
      if (this.action === 'out') {
        options.push({ text: "Autre", value: constantesId.tMvtStockSortieAutre })
      }
      return options
    }
  },
  methods: {
    initArticle() {
      this.titreModal = this.title
      if (this.lot) {
        this.setArticle({ produit: this.lot })
        this.NumLot = this.lot.NumLot
        this.dlc = this.lot.dlc
        this.quantiteDisponible = this.lot.quantite
        this.ingredient.uc = this.lot.uc
        this.valeurUnitaire = arrondiNombre(this.lot.valeurUnitaire ?? 0)
        this.setDateDlcDluo(this.lot.dlcDluo)
        this.titreModal += " de " + this.lot.designationFournisseur
      } else {
        this.article = {}
        this.NumLot = ''
        this.dlc = true
        this.dlcDluo = ''
        this.dlcDluoPourApi = null
        this.quantiteDisponible = null
        this.valeurUnitaire = null
        this.changeIngredient({})
      }
      this.razQuantite = false
      this.quantite = ''
      this.typeMouvement = null
      this.commentaire = ''
      this.setDateMouvement(this.dateEtatAu)
    },
    changeEtatRecherche(etat) {
      this.stateSearch = etat
    },
    changeIngredient(ingredient) {
      this.ingredient = ingredient
      this.article = {}
      this.stateSearch = ""
      this.keyArticles += 1
    },
    setArticle(elt) {
      let article = elt.produit
      if (this.ingredient.parProduit) {//ouvre l'article sélectionné
        vm.forEach(this.ingredient.parProduit, a => {
          a.forEach(r => {
            r.ouvrirAccordeon = r.tarifId === article.tarifId
          })
        })
      }
      this.article = article
      this.ingredient.tarifId = article.tarifId
      this.ingredient.uc = article.uc
      if (article.id) {
        this.$set(this.article, 'id', article.id)
      }
      this.$set(this.article, 'tarifId', article.tarifId)
      this.$set(this.article, 'designationFournisseur', article.designationFournisseur || article.designation)
      this.$set(this.article, 'uc', article.uc)
      this.$set(this.article, 'valeurUnitaire', article.valeurUnitaire || article.prixUnitaire)
      this.$set(this.article, 'reference', article.reference)
      this.$set(this.article, 'marque', article.marque)
      this.$set(this.article, 'fournisseurId', article.fournisseurId)
      if (elt.produitId) {
        this.$set(this.article, 'produitId', elt.produitId)
      }
      this.valeurUnitaire = arrondiNombre(this.article.valeurUnitaire)
      this.keyArticles += 1
    },
    setDateDlcDluo(date) {
      if (date)
        this.dlcDluo = this.formatDateAvecTirets(date)
      if (this.dlcDluo)
        this.dlcDluoPourApi = this.formatDateAvecTirets(this.dlcDluo)
    },
    setDateMouvement(event) {
      if (event && this.dateMouvementValide(event)) {
        this.dateMouvement = event
      } else {
        this.dateMouvement = ''
      }
    },
    dateMouvementValide(date) {
      return !this.datesInvalides.includes(date)
    },
    initialiserJournee() {
      this.optionsSortieStock.dateDebut = ''
      this.optionsSortieStock.dateFin = ''
      this.optionsSortieStock.menusPlansIds = []
      this.optionsSortieStock.servicesIds = []
      this.optionsSortieStock.listeServices = []
      this.optionsSortieStock.listeStructures = []

      this.dateConsommation = this.optionsSortieStock.dateDebut
      this.menuPlanId = null
      this.serviceId = null
      this.commentaire = ''
    },
    typeMouvementChange() {
      if (!this.sortieJournee) {
        this.initialiserJournee()
      }
    },
    razQte() {
      if (!this.razQuantite) {
        this.quantite = this.quantiteDisponible
        ++this.keyQuantite
      }
    },
    envoyer() {
      if (this.zoneStockage) {
        this.viderZoneStockage()
      } else {
        this.envoyerArticle()
      }
    },
    viderZoneStockage() {
      let zs = {}
      this.$set(zs, 'etablissementId', this.etabCourantId)
      this.$set(zs, 'code', this.zoneStockage.code)
      this.$set(zs, 'typeMouvement', this.typeMouvement)
      this.$set(zs, 'commentaire', this.commentaire)
      this.$emit('vider-zone-stockage', { date: this.formatDateAvecTirets(this.dateMouvement), zoneStockage: zs })
      this.hide()
    },
    envoyerArticle() {
      this.$set(this.article, 'etablissementId', this.etabCourantId)
      this.$set(this.article, 'quantite', this.quantite)
      this.$set(this.article, 'NumLot', this.NumLot)
      this.$set(this.article, 'dlc', this.dlc)
      this.$set(this.article, 'dlcDluo', this.dlcDluoPourApi)
      this.$set(this.article, 'typeMouvement', this.typeMouvement)
      this.$set(this.article, 'valeurUnitaire', this.valeurUnitaire)
      this.$set(this.article, 'commentaire', this.commentaire)
      if (this.sortieJournee) {
        this.$set(this.article, 'dateConsommation', this.optionsSortieStock.dateDebut)
        this.$set(this.article, 'menuPlanId', this.menuPlanId)
        this.$set(this.article, 'serviceId', this.serviceId)
      }
      if (this.action === 'in') {
        this.$set(this.article, 'id', 0)
      }
      this.$emit('envoyer-article', { date: this.formatDateAvecTirets(this.dateMouvement), article: this.article })
      this.hide()
    },
    show(lot) {
      this.lot = lot
      this.$bvModal.show(this.idModal)
    },
    hide() {
      this.$bvModal.hide(this.idModal)
    },
    changerParametresMenus(parametres) {
      this.initialiserJournee()

      this.optionsSortieStock.dateDebut = parametres.dateDebut
      this.optionsSortieStock.dateFin = parametres.dateFin
      this.optionsSortieStock.menusPlansIds = parametres.menuPlanIds
      this.optionsSortieStock.servicesIds = parametres.serviceIds
      this.optionsSortieStock.listeServices = parametres.listeServices
      this.optionsSortieStock.listeStructures = parametres.listeStructures

      //Pour la gestion de la validation
      this.dateConsommation = this.optionsSortieStock.dateDebut
      if (this.dateConsommation) {
        let d = new Date(this.dateConsommation)
        let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
        this.commentaire = 'Production pour : ' + d.toLocaleDateString("fr-FR", options)
      }
      
      //Ici, le tableau menusPlansIds ne contient qu'un seul élément, car le composant n'est pas en multiselect, de meme avec serviceIds
      if (this.optionsSortieStock.menusPlansIds.length > 0) {
        this.commentaire += '; Structure : ' + this.optionsSortieStock.listeStructures.find(x => x.id === this.optionsSortieStock.menusPlansIds[0]).nom
        this.menuPlanId = this.optionsSortieStock.menusPlansIds[0]
      }

      if (this.optionsSortieStock.servicesIds.length > 0) {
        this.commentaire += '; Service : ' + this.optionsSortieStock.listeServices.find(x => x.id === this.optionsSortieStock.servicesIds[0]).nom
        this.serviceId = this.optionsSortieStock.servicesIds[0]
      }
    }
  },
  mounted() {
    this.setDateMouvement(this.dateEtatAu)
  }
}
</script>

<style>
textarea {
  box-shadow: 1px 2px 2px 1px rgba(0, 0, 0, 0.06);
  border: none !important;
  border-radius: 0 !important;
}
