import service from '@/helpers/serviceGPAO'

export async function listerProductionEnCours(etabId) {
  const r = await service.get('production/ouvertes/' + etabId)
  r.data.forEach(suivi => {
    mettreTexteStatut(suivi)
  });
  return r
}

export async function listerProductionArchive(etabId) {
  const r = await service.get('production/archivees/' + etabId)
  r.data.forEach(suivi => {
    mettreTexteStatut(suivi)
  });
  return r
}

export async function creerProduction(payload) {
  var r = await service.put('production/', payload)
  mettreTexteStatut(r.data)
  return r
}

export async function mettreEnProduction(suiviId) {
  var r = await service.patch('production/lancer/' + suiviId)
  mettreTexteStatut(r.data)
  return r
}

function mettreTexteStatut(suivi) {
  switch (suivi.statut) {
    case 1:
      suivi.statutTexte = "En préparation";
      break;
    case 2:
      suivi.statutTexte = "En production";
      break;
    case 3:
      suivi.statutTexte = "En clôture";
      break;
    case 4:
      suivi.statutTexte = "Terminé";
      break;
    default:
      suivi.statutTexte = "Statut inconnu " + suivi.statut
  }

}

export async function obtenirSuiviAJour(suiviId) {
  var r = await service.patch('production/obtenirSuiviAJour/' + suiviId)
  mettreTexteStatut(r.data)
  return r;
}

export async function atterrir(suiviId) {
  var r = await service.patch('production/atterrir/' + suiviId);
  mettreTexteStatut(r.data);
  return r;
}

export async function obtenirAtterrissage(suiviId) {
  var r = await service.get('production/atterrissage/' + suiviId);
  return r;
}

export async function terminer(suiviId) {
  return await service.patch('production/terminer/' + suiviId);
}

export async function indiquerEffectifs(suiviId, effectifs) {
  return await service.patch('production/indiquerEffectifs/' + suiviId, effectifs);
}

export function telechargerFichesProdPdf(suiviId) {
  return service.get('production/obtenirFichesProductionPdf/' + suiviId, { responseType: 'blob' })
}

export function telechargerFichesProdXlsx(suiviId) {
  return service.get('production/obtenirFichesProductionXlsx/' + suiviId, { responseType: 'blob' })
}

export function telechargerFichesSortiePdf(suiviId) {
  return service.get('production/obtenirFichesSortiePdf/' + suiviId, { responseType: 'blob' })
}

export function telechargerFichesSortieXlsx(suiviId) {
  return service.get('production/obtenirFichesSortieXlsx/' + suiviId, { responseType: 'blob' })
}

// format : xlsx ou pdf
export function downloadBonDeLivraison(format,suiviId) {
  return service.get(`production/obtenirbondelivraison/${format}/${suiviId}`, { responseType: 'blob' })
}

export function telechargerProdReleveTemperature(format, suiviId) {
  return service.get(`production/obtenirProdReleveTemperature/${format}/${suiviId}`, { responseType: 'blob' })
}

export function telechargerProdRefroidissement(format, suiviId) {
  return service.get(`production/obtenirProdRefroidissement/${format}/${suiviId}`, { responseType: 'blob' })
}

export function telechargerProdAllotissement(format, suiviId) {
  return service.get(`production/obtenirProdAllotissement/${format}/${suiviId}`, { responseType: 'blob' })
}

export function supprimerProduction(suiviId) {
  return service.delete('production/' + suiviId)
}