<template>
  <div class="form-body">
    <div class="row">
      <div class="col-10">
        <div class="form-group">
          <label :for="'libelle' + lieuEnCours.id">Libellé</label>
          <input @focus="$event.target.select()" class="form-control" :id="'libelle' + lieuEnCours.id" type="text" v-model="lieuEnCours.libelle" @input="checkLieu">
          <div class="error" v-if="!nomValide" :key="keyLieu">Ce champ est requis</div>
        </div>
      </div>
      <div class="col-2">
        <div class="form-group">
          <label>Actif</label>
          <ToggleButton v-model="lieuEnCours.actif" :labels="{checked: 'Oui', unchecked: 'Non'}" :color="{checked: '#16D39A', unchecked: '#CCCCCC'}" :height="30" :width="85" />
        </div>
      </div>
    </div>
    <div class="form-group">
      <label :for="'adresse1' + lieuEnCours.id">Adresse</label>
      <input @focus="$event.target.select()" class="form-control" :id="'adresse1' + lieuEnCours.id" type="text" v-model="lieuEnCours.adresse1">
    </div>
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label :for="'adresse2' + lieuEnCours.id">Complément d'adresse 1</label>
          <input @focus="$event.target.select()" class="form-control" :id="'adresse2' + lieuEnCours.id" type="text" v-model="lieuEnCours.adresse2">
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label :for="'adresse3' + lieuEnCours.id">Complément d'adresse 2</label>
          <input @focus="$event.target.select()" class="form-control" :id="'adresse3' + lieuEnCours.id" type="text" v-model="lieuEnCours.adresse3">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-2">
        <div class="form-group">
          <label :for="'codePostal' + lieuEnCours.id">Code postal</label>
          <input @focus="$event.target.select()" class="form-control" :id="'codePostal' + lieuEnCours.id" type="text" v-model="lieuEnCours.codePostal">
        </div>
      </div>
      <div class="col-7">
        <div class="form-group">
          <label :for="'ville' + lieuEnCours.id">Ville</label>
          <input @focus="$event.target.select()" class="form-control" :id="'ville' + lieuEnCours.id" type="text" v-model="lieuEnCours.ville">
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label :for="'separation' + lieuEnCours.id" title="Sur les bons de livraison, on sépare par régime et texture">Séparation sur BL</label>
          <ToggleButton v-model="lieuEnCours.regimesSepares" :labels="{checked: 'Oui', unchecked: 'Non'}" :color="{checked: '#16D39A', unchecked: '#CCCCCC'}" :height="30" :width="85" :key="lieuEnCours.id"/>
        </div>
      </div>
      <div class="col-10">
        <div class="form-group">
          <label for="jours">Jours d'ouverture</label>
          <div class="line">
            <label class="label-checkbox" style="margin-left:0;"><input type="checkbox" :checked="lieuEnCours.joursOuverture.includes(1)" @change="jourChanged(1, $event)">Lundi</label>
            <label class="label-checkbox"><input type="checkbox" :checked="lieuEnCours.joursOuverture.includes(2)" @change="jourChanged(2, $event)">Mardi</label>
            <label class="label-checkbox"><input type="checkbox" :checked="lieuEnCours.joursOuverture.includes(3)" @change="jourChanged(3, $event)">Mercredi</label>
            <label class="label-checkbox"><input type="checkbox" :checked="lieuEnCours.joursOuverture.includes(4)" @change="jourChanged(4, $event)">Jeudi</label>
            <label class="label-checkbox"><input type="checkbox" :checked="lieuEnCours.joursOuverture.includes(5)" @change="jourChanged(5, $event)">Vendredi</label>
            <label class="label-checkbox"><input type="checkbox" :checked="lieuEnCours.joursOuverture.includes(6)" @change="jourChanged(6, $event)">Samedi</label>
            <label class="label-checkbox"><input type="checkbox" :checked="lieuEnCours.joursOuverture.includes(0)" @change="jourChanged(0, $event)">Dimanche</label>
          </div>
        </div>
      </div>
      <div class="col-2">
        <BoutonGestionBarquettes :donnees="lieuEnCours.donneesSupplementaires" :dropLeft="true" @enregistrer="enregistrerBarquettesLieu" />
      </div>
    </div>

    <div class="form-actions mb-2">
      <b-button variant="outline-primary" alt="Ajouter un effectif" @click="addEffectif(lieuEnCours)" :disabled="!lieuValide"><i class="fas fa-plus"></i> Ajouter un effectif</b-button>
      <b-button v-if="lieuEnCours.effectifs && lieuEnCours.effectifs.length>3" type="submit" variant="success" alt="Enregistrer" @click="enregistrerLieu" :disabled="!lieuValide" style="float:right;">Enregistrer</b-button>

    </div>

    <div v-for="(effectif, iEffectif) in lieuEnCours.effectifs.filter(x=>x.effectif!=-1)" :key="iEffectif">
      <div class="card no-radius box-shadow-0 border-secondary bg-transparent">
        <div class="card-header no-radius">
          <h4 class="card-title">{{effectif.structure + ' x' + effectif.effectif}} {{effectif.service}} {{effectif.regime}} {{effectif.texture}}</h4>
          <div class="heading-elements" style="right:7px !important; top:7px !important;">
            <ul class="list-inline mb-0">
              <li><button class="btn rougeAgap" style="transform:scale(0.8);" v-tooltip="{ content: 'Supprimer cet effectif'}"  @click="supprimerEffectif(effectif)" >
                <i style="transform: scale(1.5);" class="ft-x "></i></button></li>
            </ul>
          </div>
  
        </div> 
        <div class="card-content">
          <div class="card-body">
            <div class="row">
              <div class="col-10">
                <div class="form-group">
                  <label>Structure de menu</label>
                  <b-form-select v-model="effectif.structureMenuId" :options="structures" @change="checkLieu" value-field="id" text-field="nom">
                    <template slot="first">
                      <option :value="null" disabled>Structure de menu...</option>
                    </template>
                  </b-form-select>
                </div>
                <div class="error" v-if="!effectif.valide" :key="keyLieu">Ce champ est requis</div>
              </div>
              <div class="col-2">
                <div class="form-group">
                  <label>Plat témoin</label>
                  <ToggleButton v-model="effectif.aPlatTemoin" :labels="{checked: 'Oui', unchecked: 'Non'}" :color="{checked: '#16D39A', unchecked: '#CCCCCC'}" :height="30" :width="85" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <div class="form-group">
                  <label>Effectif</label>
                  <InputNumber v-model="effectif.effectif" typeFiltre="quantiteEntiere" style="width:100%;" title="Effectif" />
                </div>
              </div>
              <div class="col-8">
                <div class="form-group">
                  <label>Service</label>
                  <b-form-select v-model="effectif.serviceId" :options="optionsServices" v-tooltip="{ content: 'Sans sélection manuelle, tous les services sont sélectionnés', placement: 'top' }">
                    <template slot="first">
                      <option :value="null" disabled>Service...</option>
                    </template>
                  </b-form-select>
                </div>
              </div>
              <div class="col-2">
                <BoutonGestionBarquettes :donnees="effectif.donneesSupplementaires" :dropLeft="true" @enregistrer="enregistrerBarquettesEffectif(effectif, $event)" />
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label>Régime</label>
                  <b-form-select v-model="effectif.regimeId" :options="optionsRegimes" style="margin-top:3px;">
                    <template slot="first">
                      <option :value="null" disabled>Régime...</option>
                    </template>
                  </b-form-select>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Texture</label>
                  <b-form-select v-model="effectif.textureId" :options="optionsTextures" style="margin-top:3px;">
                    <template slot="first">
                      <option :value="null" disabled>Texture...</option>
                    </template>
                  </b-form-select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="error" v-if="!lieuEnCours.effectifs || lieuEnCours.effectifs.length==0">
      Il faut indiquer au moins un effectif
    </div>
    <div class="form-actions flex-center-space-between">
      <b-button variant="outline-primary" alt="Ajouter un effectif" @click="addEffectif(lieuEnCours)" :disabled="!lieuValide"><i class="fas fa-plus"></i> Ajouter un effectif</b-button>
      <div>
        <b-button type="submit" variant="success" alt="Enregistrer" style="float:right;" :disabled="!lieuValide" @click="enregistrerLieu">Enregistrer</b-button>
        <b-button type="submit" variant="danger" alt="Annuler" style="float:right;margin-right:10px;" @click="annulerEdition">Annuler</b-button>
      </div>
    </div>
  </div>
  <!-- <div>
    <div class="custom-control custom-checkbox" style="float:right">
      <input class="custom-control-input" :id="'actif' + lieuEnCours.id" type="checkbox" v-model="lieuEnCours.actif">
      <label :for="'actif' + lieuEnCours.id" class="custom-control-label">Actif</label>
    </div>
    <fieldset class="form-group" style="margin-top:25px;">
      <label :for="'libelle' + lieuEnCours.id">Libelle</label>
      <input @focus="$event.target.select()" class="form-control" :id="'libelle' + lieuEnCours.id" type="text" v-model="lieuEnCours.libelle" @input="checkLieu">
      <div class="error" v-if="!nomValide" :key="keyLieu">
        Ce champ est requis
      </div>
    </fieldset>
    <fieldset class="form-group">
      <label :for="'adresse1' + lieuEnCours.id">Adresse</label>
      <input @focus="$event.target.select()" class="form-control" :id="'adresse1' + lieuEnCours.id" type="text" v-model="lieuEnCours.adresse1">
    </fieldset>
    <fieldset class="form-group">
      <label :for="'adresse2' + lieuEnCours.id">Complément d'adresse 1</label>
      <input @focus="$event.target.select()" class="form-control" :id="'adresse2' + lieuEnCours.id" type="text" v-model="lieuEnCours.adresse2">
    </fieldset>
    <fieldset class="form-group">
      <label :for="'adresse3' + lieuEnCours.id">Complément d'adresse 2</label>
      <input @focus="$event.target.select()" class="form-control" :id="'adresse3' + lieuEnCours.id" type="text" v-model="lieuEnCours.adresse3">
    </fieldset>
    <div class="row">
      <fieldset class="form-group col-5">
        <label :for="'codePostal' + lieuEnCours.id">Code postal</label>
        <input @focus="$event.target.select()" class="form-control" :id="'codePostal' + lieuEnCours.id" type="text" v-model="lieuEnCours.codePostal">
      </fieldset>
      <fieldset class="form-group col-7">
        <label :for="'ville' + lieuEnCours.id">Ville</label>
        <input @focus="$event.target.select()" class="form-control" :id="'ville' + lieuEnCours.id" type="text" v-model="lieuEnCours.ville">
      </fieldset>
    </div>

    <fieldset class="form-group">
      <label>Effectifs par catégorie</label>
      <br />
      <button class="ml-1" @click="addEffectif(lieuEnCours)" :disabled="!lieuValide"><i class="fas fa-plus"></i>Ajouter</button>
      <div v-for="(effectif, iEffectif) in lieuEnCours.effectifs" :key="iEffectif" class="flex-center-space-between" style="margin-bottom:5px">
        <div style="border-radius: 8px; border: 2px solid; padding: 8px;width :100%" class="flex-wrap:wrap">
          <b-form-select v-model="effectif.structureMenuId" :options="structures" @change="checkLieu" value-field="id" text-field="nom">
            <template slot="first">
              <option :value="null" disabled>Structure Menu...</option>
            </template>
          </b-form-select>
          <div class="error" v-if="!effectif.valide" :key="keyLieu">Ce champ est requis</div>
          <div class="flex-center-space-between" style="margin-top:3px;">
            <span style="margin-left:10px;font-size:14px;">Effectif :</span>
            <InputNumber class="form-control" v-model="effectif.effectif" typeFiltre="quantiteEntiere" style="width:60px;margin-right:5px;" title="Effectif" />
            <b-form-checkbox v-model="effectif.aPlatTemoin">Plat témoin</b-form-checkbox>
          </div>
          <b-form-select v-model="effectif.regimeId" :options="optionsRegimes" style="margin-top:3px;">
            <template slot="first">
              <option :value="null" disabled>Régime...</option>
            </template>
          </b-form-select>
          <b-form-select v-model="effectif.textureId" :options="optionsTextures" style="margin-top:3px;">
            <template slot="first">
              <option :value="null" disabled>Texture...</option>
            </template>
          </b-form-select>
          <b-form-select v-model="effectif.serviceId" :options="optionsServices" style="margin-top:3px;">
            <template slot="first">
              <option :value="null" disabled>Service...</option>
            </template>
          </b-form-select>
        </div>
      </div>
      <div class="error" v-if="!lieuEnCours.effectifs || lieuEnCours.effectifs.length==0">
        Il faut indiquer au moins un effectif
      </div>
    </fieldset>

    <b-button type="submit" class="btn vertAgap" alt="Enregistrer" @click="enregistrerLieu" :disabled="!lieuValide" style="float:right;">Enregistrer</b-button>

  </div> -->
</template>

<script>
import { mapGetters } from "vuex"
import InputNumber from "@/components/Inputs/InputNumber"
import BoutonGestionBarquettes from "@/components/Divers/BoutonGestionBarquettes"
import _ from 'lodash'

export default {
  name: "EditionLieuPortage",
  props: { "lieuDePortage": Object, "structures": Array },
  components: { InputNumber, BoutonGestionBarquettes },
  mixins: [],
  data() {
    return {
      nomValide: false,
      lieuValide: false,
      keyLieu: 1,
      lieuEnCours: {...this.lieuDePortage}
    }
  },
  mounted() {
    this.checkLieu()
  },
  computed: {
    ...mapGetters("definitions", ["convivesTypesNonNeutre", "regimes", "textures", "services"]),
    optionsConviveType() { return this.convivesTypesNonNeutre },
    optionsRegimes() { return this.regimes },
    optionsTextures() { return this.textures },
    optionsServices() { return this.services }
  },
  methods: {
    supprimerEffectif(effectif) {
      effectif.effectif=-1
    },
    addEffectif() {
      this.lieuEnCours.effectifs = this.lieuEnCours.effectifs || []
      this.lieuEnCours.effectifs.push({ structureMenuId: null, regimeId: null, textureId: null, effectif: 0, valide: false, serviceId: null })
      this.checkLieu()
    },
    checkLieu() {
      var valide = this.lieuEnCours.libelle.length > 3
      this.nomValide = Boolean(valide)
      if (!this.lieuEnCours.effectifs || !this.lieuEnCours.effectifs.length) {
        this.addEffectif()
      }
      this.lieuEnCours.effectifs.forEach(function (e) {
        e.valide = Boolean(e.structureMenuId)
        if (!e.valide) {
          valide = false
        }
      }.bind(this))
      this.lieuValide = valide
      this.keyLieu += 1
    },
    enregistrerLieu() {
      this.lieuEnCours.effectifs.forEach(e => delete e.valide)
      this.$emit("enregistrer-lieu", this.lieuEnCours)
    },
    annulerEdition() {
      this.$emit("annuler-edition")
    },
    enregistrerBarquettesLieu(dataBarquettes) {
      var data = this.lieuEnCours.donneesSupplementaires && JSON.parse(this.lieuEnCours.donneesSupplementaires.replaceAll("'", "\"")) || {}
      data.Barquettage = dataBarquettes
      this.lieuEnCours.donneesSupplementaires = JSON.stringify(data).replaceAll("\"", "'")
    },
    enregistrerBarquettesEffectif(effectif, dataBarquettes) {
      var data = effectif.donneesSupplementaires && JSON.parse(effectif.donneesSupplementaires.replaceAll("'", "\"")) || {}
      data.Barquettage = dataBarquettes
      effectif.donneesSupplementaires = JSON.stringify(data).replaceAll("\"", "'")
    },
    jourChanged(jour, ouvert) {
      if (ouvert.target.checked) {
        this.lieuEnCours.joursOuverture.push(jour)
      } else {
        _.remove(this.lieuEnCours.joursOuverture, e => e === jour)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.label-checkbox {
  margin: 0 5px;
}
.label-checkbox > input {
  margin-right: 3px;
}
</style>
