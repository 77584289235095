import Vue from 'vue'
//Gestion du profil choisi si plusieurs possibles
const profils = {
  namespaced: true,
  state: {
    profil: {
      profilId: null,
      userId: null
    }
  },
  getters: {
    profilCourant: state => {
      return state.profil
    },
    profilCourantId: state => {
      if (state.profil) {
        return state.profil.profilId
      }
      return undefined
    },
    
    profilId(state) {
      return state.profil.profilId
    },
    profilUserId(state) {
      return state.profil.userId
    }
  },
  mutations: {
    setProfil(state, payload) {
      Vue.set(state, 'profil', payload)
    },

    emptyProfilId(state) {
      Vue.set(state.profil, 'profilId', null)
    }
  }
}

export default profils
