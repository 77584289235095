<template>
  <div style="display:inline">
    <ModalRecetteEdition ref="modalRecetteEdition" @recette-enregistree="recetteEnregistree($event)" @recette-enregistree-modifiee="recetteEnregistreeModifiee($event)" @recette-enregistree-inchangee="recetteEnregistreeInchangee($event)"
    @mouseleave="cacherApercuRecetteLignes()" />

    <RecetteListeIngredients ref="recetteApercu" v-if="recetteId" :recette="recette" :nomDeRecette="true" :afficherAllergenes="true" :style="'position:absolute;z-index:100;font-family:\'Roboto\';font-size:11px;line-height:initial;display:' + (apercuRecetteLignes ? 'block' : 'none')" />

    <i v-if="estDeclinaisonNonPersonnalisee" class="fas fa-exclamation-triangle text-warning" v-tooltip="{ content: 'Recette non vérifiée', placement: 'top' }" style="z-index:3"></i>
    <span v-if="!estLien" ref="nomRecette" style="cursor:default;position:relative;z-index:3" :class='texteClass' @mouseover="montrerApercuRecetteLignes()" @mouseleave="cacherApercuRecetteLignes()"> {{recetteNom}} </span>
    <a v-else ref="nomRecette" style="cursor:pointer;z-index:3" href="" :class='texteClass' @mouseover="montrerApercuRecetteLignes()" @mouseleave="cacherApercuRecetteLignes()" @click.prevent="ouvrirEditionRecette(recetteId)"> {{recetteNom}}
    </a>
  </div>
</template>

<script>
import ModalRecetteEdition from "@/components/Recettes/ModalRecetteEdition"
import RecetteListeIngredients from '@/components/Recettes/RecetteListeIngredients'
import { recetteLignesFromData } from '@/mixins/recette'
import { obtenirPourEdition } from '@/api/gpao/recettes'
import { connecteData } from '@/mixins/connecteData'

export default {
  name: "RecettePopUpIngredients",
  props: {
    'recetteId': Number,
    'recetteNom': String,
    'positionFixedRight': Boolean,
    'texteClass': String,
    'estLien': Boolean,
    'serviceId': Number,
    'typeConviveId': String,
    'effectif': Number,
    'estDeclinaisonNonPersonnalisee': Boolean
  },
  components: { ModalRecetteEdition, RecetteListeIngredients },
  mixins: [recetteLignesFromData, connecteData],
  data() {
    return {
      recette: null,
      hasLeft: false,
      enChargement: false,
      apercuRecetteLignes: false
    }
  },
  methods: {
    async init() {
      if (this.enChargement) {
        return
      }
      if (!this.recette) {
        this.enChargement = true
        let response = await obtenirPourEdition(this.recetteId, this.etabCourantId, this.typeConviveId, this.serviceId, this.effectif)
        response.data.lignesCompletees = await this.recetteLignesFromData(response.data)
        this.recette = response.data
        this.enChargement = false
        await this.$nextTick()
      }
      
      let tooltip = this.$refs.recetteApercu.$el
      if (tooltip) {
        let coordsNom = this.$refs.nomRecette.getBoundingClientRect()
        tooltip.style.border = '2px solid grey'
        tooltip.style.minWidth = '300px'
        tooltip.style.maxWidth = '400px'
        tooltip.style.color = '#5b5e61'
        if (this.positionFixedRight) {
          // utilisé pour la page calcul des besoins, col-4 placée à droite avec une position:fixed
          tooltip.style.left = '-400px'
          tooltip.style.top = coordsNom.y - this.$refs.nomRecette.closest(".utilisation").getBoundingClientRect().y - 120 + 'px'
        } else {
          if (this.$route.name === 'Recettes' || (this.$route.name === 'Menus' && tooltip.closest(".modal-body"))) {
            tooltip.style.top = coordsNom.y - 90 + 'px'
            tooltip.style.left = 10 + 'px'
          } else if (this.$route.name === 'Menus') {
            if (window.innerHeight > coordsNom.y + tooltip.clientHeight + 10) {
              tooltip.style.top = coordsNom.y + window.pageYOffset - 200 + 'px'
            } else {
              tooltip.style.top = coordsNom.y + window.pageYOffset - 200 - tooltip.clientHeight + 'px'
            }
            if (window.innerWidth > coordsNom.x + coordsNom.width + 450) {
              tooltip.style.left = coordsNom.x + coordsNom.width + 'px'
            } else {
              tooltip.style.left = coordsNom.x - (tooltip.clientWidth ? (tooltip.clientWidth + 30) : 450) + 'px'
            }
          } else if(this.$route.name === 'RecetteEdition') {
            if (tooltip.closest(".usages")) {//card utilisations
              let coordsTooltip = tooltip.getBoundingClientRect()
              if (coordsNom.left + coordsTooltip.width > window.innerWidth) {
                tooltip.style.left = window.innerWidth - coordsNom.left - coordsTooltip.width - 20 + "px"//le tooltip dépasse à droite => alignement sur le bord de la fenêtre
              } else {
                tooltip.style.left = "0px"//alignement sur le nom de la recette
              }
              if (coordsNom.top + coordsTooltip.height + 30 > window.innerHeight) {
                tooltip.style.top = - coordsTooltip.height + "px"//le tooltip dépasse en bas => affichage au dessus du nom de la recette
              } else {
                tooltip.style.top = coordsNom.height + 20 + "px"//affichage sous le nom de la recette
              }
            } else {//card ingrédients => alignement à droite du nom de la recette
              let coordsCard = tooltip.closest(".card").getBoundingClientRect()
              tooltip.style.left = coordsNom.x + coordsNom.width - coordsCard.left + 5 + "px"
              tooltip.style.top = 60 + "px"
            }
          } else if (this.$route.name === 'Home') {
            tooltip.style.top = coordsNom.y + window.pageYOffset - 50 + 'px'
            tooltip.style.left = '0px'
          } else if (this.$route.name === 'DetailCommande') {
            let coordsCard = tooltip.closest(".card").getBoundingClientRect()
            tooltip.style.left = coordsNom.x + coordsNom.width - coordsCard.left + 5 + "px"
            tooltip.style.bottom = 10 + "px"
          } else {
            tooltip.style.top = coordsNom.y - 450 + 'px'
            tooltip.style.left = coordsNom.x + 150 + 'px'
          }
        }
      }
      await this.$nextTick()
    },
    async montrerApercuRecetteLignes() {
      this.hasLeft = false
      if (!this.recette) {
        await this.init()
      }
      if (!this.hasLeft) {
        this.apercuRecetteLignes = true
        setTimeout(() => {
          this.init()
        }, 10)
      }
    },
    cacherApercuRecetteLignes() {
      this.hasLeft = true
      this.apercuRecetteLignes = false
    },
    ouvrirEditionRecette(recetteId) {
      this.$refs.modalRecetteEdition.show(recetteId)
    },
    recetteEnregistree(donnees) {
      this.$emit("recette-enregistree", donnees)
    },
    recetteEnregistreeModifiee(donnees) {
      this.$emit("recette-enregistree-modifiee", donnees)
    },
    recetteEnregistreeInchangee(donnees) {
      this.$emit("recette-enregistree-inchangee", donnees)
    }
  }
}
</script>

<style scoped>
a, a:visited, a:hover, a:active {
  color: inherit;
}
</style>
