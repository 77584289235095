<template>
  <span>{{dateAffichee}}</span>
</template>

<script>
export default {
  name: "affichageDate",
  props: {
    date: { type: String, default: "2020-03-19" },
    anneeSur2Chiffres: { type: Boolean, default: false }
  },
  computed: {
    dateAffichee: function () {
      if (!this.date || this.date === '')
        return '';
      let retour = this.date.split('T')[0].toString();
      if (this.anneeSur2Chiffres)
        retour = retour.substring(2);
      retour = retour.split('-').reverse().join('/');
      return retour;
    }
  }
}
</script>