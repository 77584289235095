export function designationZoneStockage(codeZoneStockage) {
    switch (codeZoneStockage)
    {
        case "FroidNegatif":
            return "Froid Négatif";
        case "FroidPositif":
            return "Froid Positif";
        case "FruitsLegumes":
            return "Fruits & Légumes";
        default:
            return codeZoneStockage;
    }
}
