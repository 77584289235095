import { connecteData } from '@/mixins/connecteData'
import { arrondiNombre, qtePCB, prixPCB } from '@/helpers/utils'
import { mapGetters } from "vuex"
import { xlsx } from "@/mixins/xlsx"
import { formatHumanDateAvecSlashes } from "@/mixins/dateFormats"

export const commande = {
  mixins: [connecteData, xlsx, formatHumanDateAvecSlashes],
  data() {
    return {
      impressionCmd1TableauEnCours: false
    }
  },
  computed: {
    ...mapGetters("fournisseurs", ["nomCourtFournisseur"]),
    ...mapGetters("definitions", ["obtenirFamille"]),
  },
  methods: {
    generer(commande, lignesTriees) {
      this.impressionCmd1TableauEnCours = true

      var allBorders = {//styles
				left: {style: "thin", color: {auto: 1}},
				right: {style: "thin", color: {auto: 1}},
				top: {style: "thin", color: {auto: 1}},
				bottom: {style: "thin", color: {auto: 1}}
      }
      var styles = {
        bold: {
          font: {bold: true}
        },
        boldRight: {
          alignment: {horizontal: "right"},
          font: {bold: true}
        },
        right: {
          alignment: {horizontal: "right"}
        },
        head: {
          border: allBorders,
					fill: {fgColor: {rgb: "FF808080"}},
					font: {sz: 11, bold: true, color: {rgb: "FFFFFFFF"}}
        },
        cell: {
          border: allBorders,
          font: {sz: 11}
        },
        firstCell: {
          border: {
            left: {style: "thin", color: {auto: 1}},
            right: {style: "thin", color: {auto: 1}},
            top: {style: "medium", color: {auto: 1}},
            bottom: {style: "thin", color: {auto: 1}}
          },
          font: {sz: 11}
        }
      }
      var worksheet = {
        "!pageSetup": {
          scale: 80,
          orientation: 'landscape'
        },
        "!cols": [
          {wch: 11},
          {wch: 5},
          {wch: 9},
          {wch: 25},
          {wch: 20},
          {wch: 20},
          {wch: 8},
          {wch: 8},
          {wch: 10},
          {wch: 10},
          {wch: 10}
        ],
        "!merges": [{
          s: {c: 0, r: 5},
          e: {c: 10, r: 6}
        }, {
          s: {c: 3, r: 8},
          e: {c: 4, r: 8}
        }],
        "A1": {
					v: this.etabCourant.eRS || "",
					t: "s",
					s: styles.bold
				},
        "A2": {
					v: this.etabCourant.adresse1 || "",
					t: "s"
				},
        "A3": {
					v: this.etabCourant.adresse2 || "",
					t: "s"
				},
        "A4": {
					v: this.etabCourant.adresse3 || "",
					t: "s"
				},
        "A5": {
					v: this.etabCourant.codePostal + " " + this.etabCourant.ville,
					t: "s"
				},
        "A6": {
					v: this.etabCourant.commentairePourToutFournisseur || "",
					t: "s",
          s: {
            alignment: {vertical: "center", wrapText: true},
            border: allBorders
          }
				},
        "A7": {v: "", t: "s", s: {border: allBorders}},
        "B6": {v: "", t: "s", s: {border: allBorders}},
        "B7": {v: "", t: "s", s: {border: allBorders}},
        "C6": {v: "", t: "s", s: {border: allBorders}},
        "C7": {v: "", t: "s", s: {border: allBorders}},
        "D6": {v: "", t: "s", s: {border: allBorders}},
        "D7": {v: "", t: "s", s: {border: allBorders}},
        "E6": {v: "", t: "s", s: {border: allBorders}},
        "E7": {v: "", t: "s", s: {border: allBorders}},
        "F6": {v: "", t: "s", s: {border: allBorders}},
        "F7": {v: "", t: "s", s: {border: allBorders}},
        "G6": {v: "", t: "s", s: {border: allBorders}},
        "G7": {v: "", t: "s", s: {border: allBorders}},
        "H6": {v: "", t: "s", s: {border: allBorders}},
        "H7": {v: "", t: "s", s: {border: allBorders}},
        "I6": {v: "", t: "s", s: {border: allBorders}},
        "I7": {v: "", t: "s", s: {border: allBorders}},
        "J6": {v: "", t: "s", s: {border: allBorders}},
        "J7": {v: "", t: "s", s: {border: allBorders}},
        "K6": {v: "", t: "s", s: {border: allBorders}},
        "K7": {v: "", t: "s", s: {border: allBorders}},
        "D2": {
					v: this.etabCourant.tel || "",
					t: "s"
				},
        "D3": {
					v: this.etabCourant.contact || "",
					t: "s"
				},
        "K1": {
          v: commande.nom,
					t: "s",
          s: styles.boldRight
        },
        "K4": {
          v: commande.nbLignes + " lignes",
					t: "s",
          s: styles.right
        },
        "A9": {
          v: "FOURN.",
					t: "s",
          s: styles.head
        },
        "B9": {
          v: "FAM.",
					t: "s",
          s: styles.head
        },
        "C9": {
          v: "REF.",
					t: "s",
          s: styles.head
        },
        "D9": {
          v: "DESIGNATION",
					t: "s",
          s: styles.head
        },
        "E9": {v: "", t: "s", s: styles.head},
        "F9": {
          v: "MARQUE",
					t: "s",
          s: styles.head
        },
        "G9": {
          v: "UC",
					t: "s",
          s: styles.head
        },
        "H9": {
          v: "QTE",
					t: "s",
          s: styles.head
        },
        "I9": {
          v: "PU HT",
					t: "s",
          s: styles.head
        },
        "J9": {
          v: "TOTAL HT",
					t: "s",
          s: styles.head
        },
        "K9": {
          v: "LIVRAISON",
					t: "s",
          s: styles.head
        }
      }

      var iLigne = 10
      lignesTriees.slice().filter(ligne => !ligne.deleted && ligne.qDem && ligne.pId).forEach((ligne, iLF) => {
        let nomFourn = this.nomCourtFournisseur(ligne.foId)
        let fourn = commande.detailFournisseurs.find(f => f.fournisseurId === ligne.foId) || {}
        worksheet["A" + iLigne] = {
          v: nomFourn || "",
          t: "s",
          s: iLF ? styles.cell : styles.firstCell
        }
        worksheet["B" + iLigne] = {
          v: this.obtenirFamille(ligne.faId).code,
          t: "s",
          s: iLF ? styles.cell : styles.firstCell
        }
        worksheet["C" + iLigne] = {
          v: ligne.Ref || "",
          t: "s",
          s: iLF ? styles.cell : styles.firstCell
        }
        worksheet["D" + iLigne] = {
          v: ligne.dFou || "",
          t: "s",
          s: iLF ? styles.cell : styles.firstCell
        }
        worksheet["E" + iLigne] = {
          v: "",
          t: "s",
          s: iLF ? styles.cell : styles.firstCell
        }
        worksheet["F" + iLigne] = {
          v: ligne.marque || "",
          t: "s",
          s: iLF ? styles.cell : styles.firstCell
        }
        worksheet["G" + iLigne] = {
          v: ligne.uc || "",
          t: "s",
          s: iLF ? styles.cell : styles.firstCell
        }
        worksheet["H" + iLigne] = {
          v: qtePCB(ligne.qDem, ligne.pcb),
          t: "n",
          s: iLF ? styles.cell : styles.firstCell
        }
        worksheet["I" + iLigne] = {
          v: arrondiNombre(ligne.pu),
          t: "n",
          s: iLF ? styles.cell : styles.firstCell
        }
        worksheet["J" + iLigne] = {
          v: arrondiNombre(prixPCB(ligne.qDem, ligne.pcb, ligne.pu)),
          t: "n",
          s: iLF ? styles.cell : styles.firstCell
        }
        worksheet["K" + iLigne] = {
          v: this.formatHumanDateAvecSlashes(fourn.dateLivraison || fourn.dateLivraisonSouhaitee),
          t: "s",
          s: iLF ? styles.cell : styles.firstCell
        }
        worksheet["!merges"].push({
          s: {c: 3, r: iLigne - 1},
          e: {c: 4, r: iLigne - 1}
        })
        iLigne += 1
      })

      this.xlsx.saveFile(worksheet, commande.nom.replaceAll("/", "-").replaceAll("\\", "-").replaceAll("?", "-"))
      this.impressionCmd1TableauEnCours = false
    }
  }
}
