<template>
  <div class="form-group has-search">
    <span class="fa fa-search form-control-feedback"></span>
    <input type="search" class="form-control" v-model="value" :placeholder="placeholder" v-focus>
  </div>
</template>
<script>
import Fuse from 'fuse.js'
export default {
  name: 'RechercheRecettes',
  data () {
    return {
      fuse: null,
      value: '',
      result: [],
      timeoutSearch: null
    }
  },
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    search: {
      type: String,
      default: ''
    },
    eventName: {
      type: String,
      default: 'fuse-results-updated'
    },
    inputChangeEventName: {
      type: String,
      default: 'fuse-input-changed'
    },
    defaultAll: {
      type: Boolean,
      default: true
    },
    list: {
      type: Array
    },
    caseSensitive: {
      type: Boolean,
      default: false
    },
    includeScore: {
      type: Boolean,
      default: false
    },
    includeMatches: {
      type: Boolean,
      default: false
    },
    tokenize: {
      type: Boolean,
      default: true
    },
    matchAllTokens: {
      type: Boolean,
      default: true
    },
    findAllMatches: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    },
    shouldSort: {
      type: Boolean,
      default: true
    },
    threshold: {
      type: Number,
      default: 0.6
    },
    location: {
      type: Number,
      default: 0
    },
    distance: {
      type: Number,
      default: 100
    },
    maxPatternLength: {
      type: Number,
      default: 32
    },
    minMatchCharLength: {
      type: Number,
      default: 1
    },
    keys: {
      type: Array
    }
  },
  computed: {
    options () {
      var options = {
        caseSensitive: this.caseSensitive,
        includeScore: this.includeScore,
        includeMatches: this.includeMatches,
        tokenize: this.tokenize,
        matchAllTokens: this.matchAllTokens,
        findAllMatches: this.findAllMatches,
        shouldSort: this.shouldSort,
        threshold: this.threshold,
        location: this.location,
        distance: this.distance,
        maxPatternLength: this.maxPatternLength,
        minMatchCharLength: this.minMatchCharLength,
        keys: this.keys
      }
      if (this.id !== '') {
        options.id = this.id
      }
      return options
    }
  },
  watch: {
    list () {
      this.initFuse()
      this.fuseSearch()
    },
    search () {
      this.value = this.search
    },
    value () {
      this.$parent.$emit(this.inputChangeEventName, this.value)
      this.$emit(this.inputChangeEventName, this.value)
      this.startTimeout()
    },
    result () {
      this.$emit(this.eventName, this.result)
      this.$parent.$emit(this.eventName, this.result)
    }
  },
  methods: {
    initFuse () {
      this.fuse = new Fuse(this.list, this.options)
      if (this.defaultAll) {
        this.result = this.list
      }
      if (this.search) {
        this.value = this.search
      }
    },
    startTimeout() {
      clearTimeout(this.timeoutSearch)
      this.timeoutSearch = setTimeout(this.fuseSearch, 500)
    },
    fuseSearch () {
      if (this.value.trim() === '') {
        if (this.defaultAll) {
          this.result = this.list
        } else {
          this.result = []
        }
      } else {
        this.result = this.fuse.search(this.value.trim()).map(r => r.item)
      }
    }
  },
  mounted () {
    this.initFuse()
  }
}
</script>

<style scoped>
.has-search .form-control {
  padding-left: 2.375rem;
  box-shadow: none;
}

.has-search .form-control-feedback {
  position: absolute;
  /*z-index: 2;fait apparaitre l'icone "à travers" les tooltips*/
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}
</style>
