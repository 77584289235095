import { mapGetters } from 'vuex'

/* Data du connecté */
export const connecteData = {
  computed: {
    ...mapGetters("auth",["isAdmin"]),
    ...mapGetters("auth",["isAdminGPAO"]),
    ...mapGetters("auth",["isUtilisateur"]),
    ...mapGetters("auth",["isLecteur"]),
    ...mapGetters("auth",["isMercuA"]),
    ...mapGetters("auth",["isMercuC"]),
    ...mapGetters("auth",["isMercuD"]),
    ...mapGetters("auth",["isMercuE"]),
    ...mapGetters("auth",["isMercuM"]),
    ...mapGetters("auth",["isMercuP"]),
    ...mapGetters("auth",["isMercu1"]),
    ...mapGetters("auth",["isDifferenteMercu"]),
    ...mapGetters({etabCourantId:"etablissements/etablissementId"}),
    ...mapGetters({utilisateurCourant:"user/utilisateurCourant"}),
    ...mapGetters({profilCourantId:"profils/profilId"}),
    etabCourant() {
      if (!this.etablissementsCourants)
        return
      return this.$store.state.user.userInfo.etablissements.find(x => x.eId === this.etabCourantId);
    },
    etablissementsCourants() {
      return this.$store.state.user.userInfo.etablissements;
    },
    etablissementsCourantsTries() {
      const retour = [...this.$store.state.user.userInfo.etablissements];
      retour.sort((a, b) => a.eRS.localeCompare(b.eRS))
      return retour;
    },
    userId() {
      if (!this.utilisateurCourant)
        return undefined;
      return  this.utilisateurCourant.id;
    },
    estAutoriseCreationFournisseur() {
      if (this.isAdmin)
        return true
      if (this.isUtilisateur && this.utilisateurCourant.aOptionFournisseursLocaux)
        return true
      return false
    },
    estAutoriseProduitsHM() {
      if (this.isAdmin)
        return true
      if (this.isUtilisateur && this.utilisateurCourant.aOptionHorsMarche)
        return true
      return false
    },
    estAttenteValidationCommande() {
      if (this.isAdmin)
        return false
      if (this.isUtilisateur && this.utilisateurCourant.aOptionValidationCommande)
        return true
      return false
    },
    aPlusieursProfils() {
      return this.listeProfils.length > 1
    },
    listeProfils() {
      let ret = []
      
      if (this.isAdminGPAO) 
        ret.push({id:"AdminGPAO", libelle:"Administrateur"})
      if (this.isUtilisateur)        
        ret.push({id:"UtilisateurGPAO", libelle:"Utilisateur"})
      
        
      if (this.isLecteur) {
        if (this.isDifferenteMercu) {
          if (this.isMercuA)
            ret.push({id:"LecteurGPAO-A", libelle:"Lecteur [A]"})
          if (this.isMercuC)
            ret.push({id:"LecteurGPAO-C", libelle:"Lecteur [C]"})
          if (this.isMercuD)
            ret.push({id:"LecteurGPAO-D", libelle:"Lecteur [D]"})
          if (this.isMercuE)
            ret.push({id:"LecteurGPAO-E", libelle:"Lecteur [E]"})
          if (this.isMercuM)
            ret.push({id:"LecteurGPAO-M", libelle:"Lecteur [M]"})
          if (this.isMercuP)
            ret.push({id:"LecteurGPAO-P", libelle:"Lecteur [P]"})
          if (this.isMercu1)
            ret.push({id:"LecteurGPAO-1", libelle:"Lecteur [Base 100]"})
        } else {
          ret.push({id:"LecteurGPAO", libelle:"Lecteur"})
        }
      }
        

      return ret
    },
    profilIsLecteur() {
      return this.aPlusieursProfils ? this.profilCourantId.startsWith("LecteurGPAO") : this.isLecteur
    },
    profilLecteurMercu() {
      return this.profilIsLecteur && this.isDifferenteMercu ? this.profilCourantId.at(-1) : null
    },
    profilIsAdmin() {
      return this.aPlusieursProfils ? this.profilCourantId === "AdminGPAO" : this.isAdminGPAO
    },
    profilIsUtilisateur() {
      return this.aPlusieursProfils ? this.profilCourantId === "UtilisateurGPAO" : this.isUtilisateur
    }
  }
}
