import service from '@/helpers/serviceGPAO'

export function obtenirBC(idCommande, data) {
  return service.post('documents/ObtenirBdc/' + idCommande, data, { responseType: "blob" })
}

export function exporterMenu(idMenu, data) {
  return service.post('documents/ExporterMenu/' + idMenu, data, { responseType: "blob" })
}

export function exporterInventaire(idInventaire, data) {
  return service.post('documents/ExporterInventaire/' + idInventaire, data, { responseType: "blob" })
}

export function exporterStock(idEtablissement, date, data) {
  return service.post('documents/ExporterStock/' + idEtablissement + "/" + date, data, { responseType: "blob" })
}

export function exporterMouvements(idEtablissement, dateDebut, dateFin, data) {
  return service.post('documents/ExporterMouvementsStock/' + idEtablissement + "/" + dateDebut + "/" + dateFin, data, { responseType: "blob" })
}

export function exporterRecette(recetteId,etabId) {
  return service.post('documents/ExporterRecette/' + etabId + '/' + recetteId +"/pdf",undefined,  { responseType: "blob" })
}
