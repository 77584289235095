import Vue from 'vue'

const stock = {
  namespaced: true,
  state: {
    filtresEnMemoire: {
      periode: {},
      detailLots: false
    }
  },
  mutations: {
    setItems(state, items) {
      Vue.set(state, items.key, items["data"])
    },
    setFiltresEnMemoire(state, item) {
      Vue.set(state.filtresEnMemoire, item.key, item["data"])
    }
  }
}

export default stock
