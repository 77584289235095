<template >
  <div class="container-fluid">  
    <BarreFiltres :filtres="filtres" @appliquer-filtre="appliquerFiltre($event)" />  
    <div class="flex-center-center">
      <div class="card col-12 col-xl-10 col-xxl-8 height-responsive">
        <div class="card-header" >Fournisseurs - Documentation</div>
        <div class="card-container height-responsive-body height-responsive-1b">
          <div class="card-body">
            <section>
              <p>La configuration des fournisseurs est essentielle au bon fonctionnement de Mercudyn. </p>
              <p>Sans cela, le logiciel ne saura pas quand vous livrer et optimiser vos commandes.</p>           
            </section>  
          </div>         
        
          <section id="sectionActivation">
            <div class="header header-tertiary">Activation </div>
            <div class="card-body">
                <p>Le sous-menu "activation" vous offre la possibilité, par famille de produits et par fournisseur, de gérer vos mercuriales en activant ou désactivant les fournisseurs pour lesquels vous ne commandez pas de produits.</p>
              <div class="container mb-3">
                <img class="border-black border-rounded w-100" src="../../assets/images/documentation/fournisseurs/fournisseurVueParFamille.png" alt="Illustration vue par famille fournisseurs">
              </div>
              <p>Dans cette vue, un code couleur supplémentaire est visible, le gris. Il signifie que le fournisseur est désactivé dans cette famille de produit</p>
              <p>Via un simple clic sur un fournisseur, un menu permet diverses actions.</p>
              <div class="flex-center-center mb-3">
                <div class="col-4">
                  <img class="border-black border-rounded w-100" src="../../assets/images/documentation/fournisseurs/fournisseursSousMenu.png" alt="Illustration du sous-menu d'un fournisseur">
                </div>
              </div>
              <ul class="puces">
                <li><span class="bold600">Activer/Desactiver</span>: Active ou désactive le fournisseur pour cette famille de produits.</li>
                <li><span class="bold600">Activer uniquement ce fournisseur </span>: Fait en sorte que seul ce fournisseur soit activé pour cette famille de produits.</li>
                <li><span class="bold600">Activer tous les fournisseurs </span>: Active tous les fournisseurs disponibles pour cette famille de produits.</li>
                <li><span class="bold600">Activer ce fournisseur pour toutes les familles </span>: Active ce fournisseur dans toutes les familles de produits pour lesquelles il est listé.</li>
                <li><span class="bold600">Désactiver ce fournisseur pour toutes les familles </span>: Désactive ce fournisseur dans toutes les familles de produits pour lesquelles il est listé.</li>
              </ul>
              <div class="h6 important">
                Les produits d'un fournisseur dans une famille ne sont affichés et commandables que si celui-ci est actif dans la famille de produits concernée.
              </div>
            </div>
          </section>

          <section id="sectionConfiguration">
            <div class="header header-tertiary">Configuration</div>
            <div class="card-body">
              <p>Le sous menu "configuration" affiche un résumé des fournisseurs actifs sur l'établissement.  </p>
              <p>Un code couleur est là pour avertir de l'état du fournisseur.  </p>
              <ul class="puces">
                <li><span class="bg-primary p-1 border-rounded">Bleu</span>, le fournisseur est suffisamment configuré pour permettre la commande de produits.</li>
                <li><span class="bg-error p-1 border-rounded">Rouge</span>, le fournisseur n'est pas bien configuré. Il peut manquer les jours de livraison et/ou la sélection du dépôt livrant l'établissement si plusieurs sont possibles.</li>
              </ul>
            <div class="container mb-3">
              <img class="border-black border-rounded w-100" src="../../assets/images/documentation/fournisseurs/fournisseursVueGeneral.png" alt="Illustration vue générale fournisseurs" >
            </div>  
              <p>Dans cette illustration nous pouvons noter les problèmes suivants : Les fournisseurs 4 et 6 sont en rouge ce qui indique un problème.</p>
              <p>Pour le 4 et le 6 il manque les dates de livraison. Le fournisseur 6 n'a pas non plus de dépôt de renseigné.</p>
            </div>        
          </section>

          <section id="sectionEditerFournisseur">
            <div class="header header-tertiary">Editer un fournisseur</div>
            <div class="card-body">
              <p>Cliquer sur un fournisseur permet d'éditer ses données et corriger un éventuel problème.</p>
              <div class="flex-center-center mb-3">
                <div class="col-4">
                  <img class="border-black border-rounded w-100" src="../../assets/images/documentation/fournisseurs/fournisseurEdition.png" alt="Illustration de l'édition d'un fournisseur">
                </div>
              </div>
              <p>Si plusieurs dépôts livrent votre département, il faudra sélectionner celui qui livre votre établissement. </p>
              <p>Remplissez les champs que vous jugez utiles et sélectionnez les jours de livraison du fournisseur pour votre établissement.</p>
              <div class="h6 important">
                <p>Le dépôt vous livrant et les jours de livraison dépendent directement de vos fournisseurs.  </p>
                <p> Il nous est impossible de pré remplir pour vous ces informations.</p>
                <p>Si une adresse email est ajoutée à la fiche fournisseur, elle servira de destinataire pour les commandes par email et activera cette fonctionnalité.</p>
              </div>
            </div> 
          </section>
        </div>
      </div>
    </div>  
</div>
</template>

<script>
import BarreFiltres from '@/components/Divers/BarreFiltres'

export default {
  name: "DocFournisseurs ",
  components: {
    BarreFiltres
  },
  data() {
    return {
      filtres: {
        sections: {
          label: "Choix de votre recherche...",
          champ: "select",
          options: [
          { valeur: 'sectionActivation', texte:`Activation`},
          { valeur: 'sectionConfiguration', texte: 'Configuration'},
          { valeur: 'sectionEditerFournisseur', texte: 'Configuration: Editer un fournisseur'}
          ],
          selection: [],
          filtrer(contexte) {
            let filtre = contexte.filtres.sections
            if (filtre.selection) {
              let sectionSelectionnee = document.getElementById(filtre.selection.valeur);
              if (sectionSelectionnee) {
                sectionSelectionnee.scrollIntoView();
              }  
            }
            //contexte.selectChoixRechercheDoc(contexte.filtres.sections.selection?.valeur)
          }
        },
        // Appeler "this.filtres.initialiser(this)" dans mounted() => génère le tableau de filtres utilisés pour afficher les filtres dans la barre de filtres
        barreGauche: [],
        barreDroite: [],
        initialiser(contexte) {
          contexte.filtres.barreGauche = [ contexte.filtres.sections ]
        }
      },      
    }
  }, 
  methods: {
    appliquerFiltre(filtrer) {
      // Nécessaire pour faire fonctionner les filtres de la barre de filtres
      filtrer(this)
    },   
  },
  mounted() {
    this.filtres.initialiser(this)
  }
}
</script>

