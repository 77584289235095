import service from '@/helpers/serviceGPAO'
import vm from "@/helpers/vm-toolkit-vue"
import store from '@/store/'

export function login(username, password) {
  return service.post("auth", { username, password })
}

export function logConnexion() {
  var dateVersion = localStorage.lastVersionCheck && JSON.parse(localStorage.lastVersionCheck)
  dateVersion = dateVersion && dateVersion.dateVersion
  return service.post("auth/logConnexion", {
    Source: "MercuDyn",
    Machine: JSON.stringify(vm.whereAmI()),
    DateVersion: dateVersion
  })
}

export function currentUserInfo() {
  return service.get("/users")
}

export function currentUserInfoComplet() {
  return service.get("/users/complet")
}

export function changerAfficherPopupInfo() {
  return service.patch("/users/afficherPopupInfo")
}

export function getPrefFournisseurs(etablissementId) {
  return service.get("/users/prefFournisseurs/" + etablissementId)
}

export function setPrefFournisseur(params) {
  return service.post("/users/prefFournisseurs/maj", params)
}

export function setInfosFournisseur(params) {
  return service.post("/users/etab/infos/maj", params)
}

export function getDernieresActions() {
  return service.get("/users/dernieresActions")
}

export function setInfosEtablissement(params) {
  return service.post("/users/etab/maj", params)
}

export function setLieuxPortage(lieux) {
  return service.patch("users/etab/lieuxPortage", lieux)
}

export function sortLieuxPortage(lieux) {
  return service.patch("users/etab/lieuxPortage/ordre", lieux)
}

export function groupeModifLieuxPortage(lieux) {
  return service.patch("users/etab/lieuxPortage/groupeModif", lieux)
}

export function getLieuxPortage(etablissementId, avecTexte) {
  return service.get("users/etab/lieuxPortage/" + etablissementId).then((response) => {
    if (avecTexte) {
      ajouterTexteAPortage(response.data.lieux, response.data.structures)
    }
    if (response.data.lieux.filter(l => !l.ordreLivraison).length > 1) {//initialisation des ordres de tri
      response.data.lieux.forEach((lieu, iLieu) => {
        lieu.ordreLivraison = iLieu + 1
      })
    }
    response.data.lieux.sort((a, b) => a.ordreLivraison - b.ordreLivraison).forEach((lieu, iLieu) => lieu.ordreLivraison = iLieu + 1)//suppression des "trous"
    return response;
  })
}

export function setEffectifPortage(effectifs) {
  return service.patch("users/etab/effectifPortage/", effectifs);
}

// pour : format AAAAMMJJ ou AAAA-MM-JJ
export function getEffectifPortage(etablissementId, pour, avecTexte) {
  if (pour && pour.indexOf && pour.indexOf('-') > 0)
    pour = pour.split('-').join('')
  return service.get("users/etab/effectifPortage/" + etablissementId + "/" + pour).then((response) => {
    response.data.effectifs.forEach(x => {
      if (x.effectifs && x.effectifs.length) {
        x.effectifs.forEach(e => {
          e.cle = e.id.toString() + e.lieuPortageId + e.regimeId + e.textureId + e.structureMenuId + e.dateEffectif;
        })
      }
      if (avecTexte) {
        ajouterTexteAEffectif(x.effectifs, response.data.structures, response.data.lieux)
      }
    })
    return response;
  })
}

export function getEffectifPortageParLigne(etablissementId, pour) {
  if (pour && pour.indexOf && pour.indexOf('-') > 0)
    pour = pour.split('-').join('')
  return service.get("users/etab/effectifPortageParLigne/" + etablissementId + "/" + pour)
}

export function synthesePortage(idEtablissement, dateDebut, dateFin,data) {
  let url = 'users/etab/synthesePortage/' + idEtablissement + "/" + dateDebut;
  if (dateFin)
    url += "/" + dateFin
  return service.post(url,data, { responseType: "blob" })
}
export function supprimerLieuPortage(lieuId) {
  return service.delete("users/etab/lieuxPortage/" + lieuId)
}

export function setMemo(memo) {
  return service.post("users/memo", "\"" + memo + "\"", { headers: { 'Content-Type': 'application/json' } })
}

function ajouterTexteAPortage(lieux, structures) {
  lieux.forEach(x => {
    ajouterTexteAEffectif(x.effectifs, structures)
  })
}

function ajouterTexteAEffectif(effectifs, structures, lieux) {
  if (effectifs && effectifs.length) {
    effectifs.forEach(e => {
      if (structures)
      {
        let structure=structures.find(d => d.id === e.structureMenuId)
        if (!structure) {
          structure={ nom:"Inconnu (eff ?) " + e.structureMenuId }
        }
        e.structure = structure.nom
      }
      if (lieux)
        e.lieu = lieux.find(d => d.id === e.lieuPortageId).nom
      if (e.regimeId) {
        e.regime = store.getters["definitions/regimes"].find(d => d.value === e.regimeId).text
        if (e.regime == "Normal")
          e.regime = ""
      } else {
        e.regime = ""
      }
      if (e.serviceId) {
        e.service = store.getters["definitions/services"].find(d => d.value === e.serviceId).text
      } else {
        e.service = ""
      }
      if (e.textureId) {
        e.texture = store.getters["definitions/textures"].find(d => d.value === e.textureId).text
        if (e.texture == "Normal")
          e.texture = ""
      } else {
        e.texture = ""
      }
    })
  }
}
