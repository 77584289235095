<template>
  <b-modal ref="modalAjoutProduitHM" size="lg" 
    :title="titreModal" 
    :ok-title="titreBoutonOk"
    cancel-title="Annuler"
    :ok-disabled="!ajouterOK || (!produitEstModifiee && !donneeFournisseurEstModifiee)"
    no-close-on-backdrop
    @ok="enregistrerProduit()"
    @show="initialiserModale()"
  >
    <div style="display: inline">
        <div class="container-fluid card card-body">
          <!--<h4 class="push-bottom-2rem">Informations produit</h4>-->
          <div class="row">
            <div class="col-4 form-group">
              <label>Famille du produit</label>
              <v-select id="selFamille" v-model="familleSelect" :options="optionsFamilles" value="id" label="nom" :clearable="false" @input="selectFamilleDeProduit($event)">
              </v-select>
              <div class="error" v-if="!familleSelect || (familleSelect && !familleSelect.id)">Ce champ est requis</div>
              <div v-else>&nbsp;</div>
            </div>
            <div class="col-8 form-group">
              <label>Ingrédient</label>
              <RechercheCompleteProduits ref="refChercherIngredient" :disabled="rechercheIngredientDisabled" :chercherIngredients="true" :chercherProduits="true" :afficherPrix="false" @input="key++;" @reset="resetIngredient()" v-model="produitHM.ingredient" :initValeur="produitHM.ingredient ? produitHM.ingredient.nomIngredient : ''" />
            </div>
          </div>

          <div class="row flex-center-space-between">
            
            <div class="col-4 form-group">
              <label class="push-right-2rem">Fournisseur</label>
              <v-select :disabled="modifierProduit && !selectionFournisseur" v-model="donneeFournisseur.fournisseur" :options="optionsFournisseurs" value="id" label="nom" :clearable="false" @input="key++;">
                <span slot="no-options" @click="$refs.select.open = false">
                  Veuillez sélectionner une famille ...
                </span>
              </v-select>
              <div class="error" v-if="!donneeFournisseur.fournisseur">Ce champ est requis</div>
              <div v-else>&nbsp;</div>
            </div>

            <div class="col-8 form-group">
              <label class="push-right-2rem">Désignation</label>
              <input type="text" title="Description du produit pour le fournisseur" v-model.trim="donneeFournisseur.description" style="width:100%" @input="key++;"/>
              <div class="error" v-if="!donneeFournisseur.description">Ce champ est requis</div>
              <div v-else>&nbsp;</div>
            </div>
          </div>

          <div class="row flex-center-space-between">
            <div class="col-3 form-group">
              <label>Marque</label>
              <input type="text" v-model.trim="donneeFournisseur.marque" style="width:100%" @input="key++;"/>
              <div>&nbsp;</div>
            </div>
            <div class="col-3 form-group">
              <label>Référence</label>
              <input type="text" v-model.trim="donneeFournisseur.reference" style="width:100%" @input="key++;"/>
              <div>&nbsp;</div>
            </div>
            <div class="col-6 form-group">
              <label>Allergènes</label>
              <MultiSelect v-model="donneeFournisseur.allergenesMajeurs" :items="optionsAllergenes" itemText="valeur" itemValue="id" emptyText="Tous les allergènes ont été ajoutés" @change="key++;"/>
              <div class="error" v-if="(!donneeFournisseur.allergenesMajeurs || donneeFournisseur.allergenesMajeurs.length === 0)">Ce champ est requis</div>
              <div class="error" v-else-if="allergenesKO">Vous ne pouvez pas sélectionner 'Aucun Allergène' avec d'autres allergènes</div>
              <div v-else>&nbsp;</div>
            </div>
          </div>

          <div class="row flex-center-space-between">
            <div class="col-4 form-group">
              <label>PCB</label>
              <div class="flex-start">
                <InputNumber typeFiltre="quantite" v-model="donneeFournisseur.pcb" style="margin-right:2px" @input="key++;" />
                <v-select id="selUC" v-model="ucSelect" :options="optionsUC" value="id" label="nom" style="margin-left:2px;width:200px" :clearable="false" @input="selectUCDeProduit($event);key++;">
                </v-select>
              </div>
              <div class="error" v-if="(!donneeFournisseur.pcb || !ucSelect.id)">Ces champs sont requis</div>
              <div v-else>&nbsp;</div>
            </div>

            <div class="col-4 form-group">
              <label>P.U. HT</label>
              <div class="flex-start">
                <InputNumber typeFiltre="montant" v-model="donneeFournisseur.pu" v-tooltip="{ content: 'Prix Unitaire (selon PCB)', placement: 'top' }" @input="key++;"/>
                <div class="input-group-append">
                  <span class="input-group-text">€{{ this.unitePrix(1) }}</span>
                </div>
              </div>
              <div class="error" v-if="!donneeFournisseur.pu">Ce champ est requis</div>
              <div v-else>&nbsp;</div>
            </div>
            <div class="col-4 form-group">
              <label>Montant HT</label>
              <div class="flex-center-center input-group-text">
                {{ montantHT }}
              </div>
              <div>&nbsp;</div>
            </div>
            
            <div class="col-1 form-group"></div>
          </div>

          <div class="row flex-center-space-between">
            <div class="col-4 form-group">
              <label>Taux TVA</label>
              <div class="flex-start">
                <v-select id="selTVA" v-model="tvaSelect" :options="optionsTVA" value="id" label="nom" style="margin-left:2px;width:200px" :clearable="false" @input="selectTVA($event);key++;"></v-select>
                <div class="input-group-append">
                  <span class="input-group-text"> %</span>
                </div>
              </div>
            </div>

            <div class="col-4 form-group">
              <label>P.U. TTC</label>
              <div class="flex-center-center input-group-text">
                {{ puTTC }} €{{ this.unitePrix(1) }}
              </div>
            </div>
            <div class="col-4 form-group">
              <label>Montant TTC</label>
              <div class="flex-center-center input-group-text">
                {{ montantTTC }}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <p style="padding-top:15px;font-size:0.8em">
                <em>L’affichage des allergènes est effectué à titre informatif. Chaque utilisateur du logiciel doit vérifier par lui-même la véracité de ces informations auprès du ou des fournisseurs de denrées alimentaires concernés.<br/>
                  En cas d’erreur, l’utilisateur ne pourra pas engager la responsabilité de la société Agap’pro, éditrice du logiciel.</em>
              </p>
            </div>
          </div>
        </div>
        <div class="container-fluid card card-body">
          <div class="row">
            <div class="col-12 form-group">
              <label>Paramétrage pour les recettes</label>
            </div>
          </div>
          <div class="row">
            <div class="col-1 form-group">
              <!-- <label>Poids de la portion</label>
              <div class="flex-start">
                <InputNumber class="mr-1" typeFiltre="quantite" v-model="produitHM.grammage"/>
                <div class="input-group-append">
                  <span class="input-group-text">g</span>
                </div>
              </div>
              <div>&nbsp;</div> -->
            </div>

            <div class="col-10 form-group">
              <p>Contenu du produit :</p>
              <div v-if="(donneeFournisseur.pcb && ucSelect.id)" class="flex-center-center">
                <div class="input-group-prepend">
                  <span class="input-group-text " >Dans {{donneeFournisseur.pcb}} {{plurielUC(donneeFournisseur.pcb, produitHM.uc)}} je vais avoir </span>
                </div>
                <input type="number" v-model="donneeFournisseur.nbPCE" class="saisieRepartition form-control" style="margin:2px;width:65px;text-align:right;" @input="key++;"/>
                <div class="input-group-append">
                  <span class="input-group-text">{{ plurielUC(donneeFournisseur.nbPCE, 'Pièce') + ', '}}</span>
                </div>
                <input type="number" v-model="donneeFournisseur.nbKg" class="saisieRepartition form-control" style="margin:2px;width:65px;text-align:right;" @input="key++;"/>
                <div class="input-group-append">
                  <span class="input-group-text">Kg, </span>
                </div>
                <input type="number" v-model="donneeFournisseur.nbL" class="saisieRepartition form-control" style="margin:2px;width:65px;text-align:right;" @input="key++;"/>
                <div class="input-group-append">
                  <span class="input-group-text">{{ plurielUC(donneeFournisseur.nbL, 'Litre')}}</span>
                </div>
              </div>
              <span class="input-group-text flex-center-center" v-else>Veuillez renseigner le PCB</span>
              <!--</label>-->
              <div style="height:50px" class="flex-center-center">
                <div v-if="repartitionOK && (donneeFournisseur.pcb && ucSelect.id)">
                  <em>
                    <p>{{phraseConversion(donneeFournisseur, produitHM, donneeFournisseur.pcb) || "Pas de répartition."}}</p>
                    <p>{{phraseConversion(donneeFournisseur, produitHM, 1.5 * donneeFournisseur.pcb)}}</p>
                  </em>
                </div>
                <div v-else-if="(donneeFournisseur.pcb && ucSelect.id)">
                  <em>
                    <p>Pas de répartition.</p>
                  </em>
                </div>
              </div>
            </div>
            <div class="col-1 form-group"></div>
          </div>
          
        </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex"
import { constantesId } from "@/helpers/constantes"
import { showAlerts } from "@/mixins/alerts"
import { ajouterProduitHM, modifierProduitHM } from '@/api/gpao/fournisseurs'
import { getPrefFournisseurs } from '@/api/gpao/user'
import { arrondiPrixPCB, arrondiNombre, plurielUC, phraseConversion } from '@/helpers/utils'
import { chargerMercuriales } from '@/helpers/mercuriales'
import { connecteData } from "@/mixins/connecteData"
import RechercheCompleteProduits from "@/components/Mercuriales/RechercheCompleteProduits"
import InputNumber from '@/components/Inputs/InputNumber'
import MultiSelect from "@/components/Inputs/MultiSelect"
import _ from 'lodash'

export default {
  name: "ModalAjoutProduitHM",
  props: [ 'produit', 'fournisseur', 'paramModale' ],
  mixins: [
    showAlerts,
    connecteData
  ],
  components: {
    InputNumber,
    RechercheCompleteProduits,
    MultiSelect
  },
  data() {
    return {
      key: 1,
      optionsAllergenes: constantesId.allergenes,
      ajoutProduit: false,
      modifierProduit: false,
      dupliquerProduit: false,
      rechercheIngredientDisabled: false,
      prefFourn : [],
      produitHM: {
        produitId: '',
        familleId: '',
        designation: '',
        grammage: 0,
        ingredient: null,
        uc: '',
        hierarchie: 1,
        tauxTVA: 0
      },
      donneeFournisseur: {
        fournisseur: null,
        description: '',
        marque: '',
        reference: '',
        pu: null,
        pcb: '',
        allergenesMajeurs: [],
        aucunAllergene: false,
        parPCE: '',
        parKg: '',
        parL: '',
        nbKg: '',
        nbPCE: '',
        nbL: '',
        codeEan: '',
        tarifId: ''
      },
      produitInitial: {},
      donneeFournisseurInitiale: {},
      optionsFamilles: [],
      optionsFournisseurs: [],
      optionsUC: [],
      optionsTVA: [],
      article: {},
      familleSelect: {},
      ucSelect: {},
      tvaSelect: {},
      listeIngredients: {},
      titreModal: "",
      titreBoutonOk: ""
    }
  },
  computed: {
    ...mapGetters("definitions", ["ingredients", "obtenirFamille"]),
    ...mapGetters("fournisseurs", ["fournisseursCourants"]),
    montantHT() {
      this.key
      if (this.donneeFournisseur.pu && this.donneeFournisseur.pcb && this.ucSelect.id) {
        return arrondiPrixPCB(this.donneeFournisseur.pcb, this.donneeFournisseur.pcb, this.donneeFournisseur.pu, 3) + this.unitePrix(this.donneeFournisseur.pcb)
      } else {
        return 0 + " €"
      }
    },
    montantTTC() {
      this.key
      if (this.donneeFournisseur.pu && this.donneeFournisseur.pcb && this.ucSelect.id && (this.tvaSelect.id || this.tvaSelect.id === 0)) {
        return arrondiPrixPCB(this.donneeFournisseur.pcb, this.donneeFournisseur.pcb, (this.donneeFournisseur.pu * (1 + (this.tvaSelect.id / 100))), 3) + this.unitePrix(this.donneeFournisseur.pcb)
      } else {
        return 0 + " €"
      }
    },
    puTTC() {
      this.key
      if (this.tvaSelect.id)
        return arrondiNombre(this.donneeFournisseur.pu * (1 + (this.tvaSelect.id / 100)), 3)
      else
        return this.donneeFournisseur.pu
    },
    ajouterOK() {
      this.key
      let valideProduit = this.produitHM.familleId && this.produitHM.uc
      let valideFournisseur = this.donneeFournisseur.fournisseur && this.donneeFournisseur.description && this.donneeFournisseur.pu && this.donneeFournisseur.pcb && this.donneeFournisseur.allergenesMajeurs && this.donneeFournisseur.allergenesMajeurs.length > 0 && !this.allergenesKO
      return valideProduit && valideFournisseur
    },
    repartitionOK() {
      this.key
      return (this.donneeFournisseur.nbKg || this.donneeFournisseur.nbKg === 0) && (this.donneeFournisseur.nbPCE || this.donneeFournisseur.nbPCE === 0) && (this.donneeFournisseur.nbL || this.donneeFournisseur.nbL === 0)
    },
    allergenesKO() {
      this.key
      return this.donneeFournisseur.allergenesMajeurs.find(a => a === "Aucun Allergène") && this.donneeFournisseur.allergenesMajeurs.length > 1
    },
    produitEstModifiee() {
      this.key
      if (!this.produitInitial) {
        return true
      }
      return !_.isEqual(JSON.stringify(this.produitHM), JSON.stringify(this.produitInitial))
    },
    donneeFournisseurEstModifiee() {
      this.key
      if (!this.donneeFournisseurInitiale) {
        return true
      }
      return !_.isEqual(JSON.stringify(this.donneeFournisseur), JSON.stringify(this.donneeFournisseurInitiale))
    }
    
  },
  methods: {
    plurielUC(val, uc) {
      return plurielUC(val, uc)
    },
    unitePrix(pcb) {
      if (pcb && this.ucSelect.id) {
        return " / " + pcb + " " + this.plurielUC(pcb, this.ucSelect.id)
      } else {
        return " "
      }
    },
    phraseConversion(donneeFour, produit, qteCmd) {
      //pcb, uc, nbPCE, nbKg, nbL, qteCmd
      return phraseConversion(donneeFour.pcb, produit.uc, donneeFour.nbPCE, donneeFour.nbKg, donneeFour.nbL, qteCmd)
    },
    resetProduit() {
      this.produitHM = {}
      this.produitHM.ingredient = null
      this.produitHM.produitId = ''
      this.produitHM.familleId = ''
      this.produitHM.designation = ''
      this.produitHM.grammage = 0
      this.produitHM.uc = ''
      this.produitHM.hierarchie = 1
      this.produitHM.tauxTVA = 0
      this.tvaSelect = this.optionsTVA.find(opt => opt.id === this.produitHM.tauxTVA)
    },
    resetDonneeFournisseur() {
      this.donneeFournisseur = {}
      this.donneeFournisseur.fournisseur = null
      this.donneeFournisseur.description = ''
      this.donneeFournisseur.marque = ''
      this.donneeFournisseur.reference = ''
      this.donneeFournisseur.pu = 0
      this.donneeFournisseur.pcb = ''
      this.donneeFournisseur.allergenesMajeurs = []
      this.donneeFournisseur.aucunAllergene = false
      this.donneeFournisseur.parPCE = ''
      this.donneeFournisseur.parKg = ''
      this.donneeFournisseur.parL = ''
      this.donneeFournisseur.nbKg = ''
      this.donneeFournisseur.nbPCE = ''
      this.donneeFournisseur.nbL = ''
      this.donneeFournisseur.codeEan = ''
      this.donneeFournisseur.tarifId = ''
    },
    initialiserModale() {
      this.ajoutProduit = false
      this.modifierProduit = false
      this.dupliquerProduit = false

      this.ajoutTarif = false
      this.selectionFournisseur = true

      switch(this.paramModale.modeAffichage) {
        case "ajoutProduit":
          this.ajoutProduit = true
          this.titreModal = "Ajouter un produit Hors Marché"
          this.titreBoutonOk = "Ajouter"
          break;
        case "modifierProduit":
          this.modifierProduit = true
          this.titreModal = "Modifier un produit Hors Marché"
          this.titreBoutonOk = "Modifier"
          break;
        case "dupliquerProduit":
          this.dupliquerProduit = true
          this.titreModal = "Dupliquer un produit Hors Marché"
          this.titreBoutonOk = "Ajouter"
          break;
      }

      this.optionsFamilles = this.construireListeFamilles(this.fournisseur?.fournisseurId)

      this.optionsUC = this.construireListeUC()

      if (this.ajoutProduit) {
        this.familleSelect = {}
        this.ucSelect = {}
        
        this.resetProduit()

        this.resetDonneeFournisseur()
      } else {
        this.produitHM.produitId = this.produit.produit.produitId

        this.produitHM.familleId = this.produit.famille.familleId
        this.familleSelect.id = this.produit.famille.familleId
        this.familleSelect.nom = this.produit.famille.nom

        this.produitHM.designation = this.produit.produit.designation
        this.produitHM.grammage = this.produit.produit.grammage
        
        let ing = this.ingredients.find(el => el.id === this.produit.produit.ingredientId)
        this.produitHM.ingredient = {}
        if (ing) {
          this.produitHM.ingredient.ingredientId = ing.id
          this.produitHM.ingredient.nomIngredient = ing.nom
        } else {
          this.produitHM.ingredient = null
        }
        this.produitHM.uc = this.produit.produit.uc
        this.ucSelect.id = this.produitHM.uc
        this.ucSelect.nom = this.produitHM.uc
        this.produitHM.rechercheProduits = this.produit.produit.rechercheProduits
        this.produitHM.tauxTVA = this.produit.produit.tauxTVA
        this.tvaSelect = this.optionsTVA.find(opt => opt.id === this.produitHM.tauxTVA)

        this.optionsFournisseurs = this.construireListeFournisseur(this.produitHM.familleId, this.fournisseur?.fournisseurId)
        
        let tar = this.fournisseur?.fournisseurId ? this.fournisseur : null
        if (!this.ajoutProduit && tar) {
          this.resetDonneeFournisseur()
          this.donneeFournisseur.fournisseur = {}

          let four = this.fournisseursCourants.find(f => f.id === tar.fournisseurId)

          this.donneeFournisseur.fournisseur.id = tar.fournisseurId
          this.donneeFournisseur.fournisseur.nom = four?.nom
          this.donneeFournisseur.description = tar.designation ? tar.designation : this.produit.produit.designation
          this.donneeFournisseur.pu = tar.pu
          this.donneeFournisseur.pcb = tar.pcb

          if (tar.aucunAllergene && tar.allergenesMajeurs.length === 0)
            this.donneeFournisseur.allergenesMajeurs.push("Aucun Allergène")
          else
            this.donneeFournisseur.allergenesMajeurs = tar.allergenesMajeurs

          this.donneeFournisseur.aucunAllergene = tar.aucunAllergene

          this.donneeFournisseur.parPCE = tar.parPCE
          this.donneeFournisseur.parKg = tar.parKg
          this.donneeFournisseur.parL = tar.parL
          this.donneeFournisseur.nbKg = arrondiNombre(tar.parKg * tar.pcb, 3)
          this.donneeFournisseur.nbPCE = arrondiNombre(tar.parPCE * tar.pcb, 3)
          this.donneeFournisseur.nbL = arrondiNombre(tar.parL * tar.pcb, 3)
          this.donneeFournisseur.codeEan = tar.codeEan
          this.donneeFournisseur.marque = tar.marque
          this.donneeFournisseur.reference = tar.reference
          this.donneeFournisseur.tarifId = tar.tarifId
          this.selectionFournisseur = false
        } else {
          this.resetDonneeFournisseur()
          this.donneeFournisseur.description = this.produit.produit.designation
        }
      }
      this.produitInitial = _.cloneDeep(this.produitHM)
      this.donneeFournisseurInitiale = _.cloneDeep(this.donneeFournisseur)
      
      this.key++
    },

    desactiveRepartition(unite) {
      return unite === this.produitHM.uc
    },
    estInvalidePour(up, repartition) {
      if (!this.produitHM.uc)
        return false
      if (up !== this.produitHM.uc.toUpperCase()) {
        return false;
      }
      return repartition != 1;
    },
    resetIngredient() {
      this.produitHM.ingredient = null
    },
    formatDonneeArticle() {
      this.article = {}
      this.article.familleId = this.produitHM.familleId
      this.article.produits = []
      this.article.fournisseurs = []

      if (this.donneeFournisseur.allergenesMajeurs.find(a => a === "Aucun Allergène"))
        this.donneeFournisseur.aucunAllergene = true

      let tarif = []
      if (this.donneeFournisseur) {
        tarif.push({
          designation: this.donneeFournisseur.description,
          pcb: this.donneeFournisseur.pcb,
          fournisseurId: this.donneeFournisseur.fournisseur.id,
          reference: this.donneeFournisseur.reference,
          marque: this.donneeFournisseur.marque,
          nbPCE: this.donneeFournisseur.nbPCE,
          nbKg: this.donneeFournisseur.nbKg,
          nbL: this.donneeFournisseur.nbL,
          pu: this.donneeFournisseur.pu,
          allergenesMajeurs: this.donneeFournisseur.allergenesMajeurs,
          aucunAllergene: this.donneeFournisseur.aucunAllergene
        })
      }

      this.article.produits.push({
        ingredientId: this.produitHM.ingredient?.ingredientId,
        uc: this.produitHM.uc,
        designation: this.modifierProduit ? this.produitHM.designation : this.donneeFournisseur.description,
        grammage: this.produitHM.grammage,
        familleId: this.produitHM.familleId,
        tauxTVA: this.produitHM.tauxTVA,
        rechercheProduits: this.produitHM.rechercheProduits,
        tarifs: tarif
      })
    },
    async enregistrerProduit() {
      //on format les donnees pour correspondre à la vue attendu de l'API
      this.$store.commit('uxHelpers/showSpinner')
      this.formatDonneeArticle()

      if (this.ajoutProduit || this.dupliquerProduit) {
        let resultat = await ajouterProduitHM(this.article)
        this.article = resultat.data
      }

      if (this.modifierProduit) {
        this.article.produits[0].produitId = this.produitHM.produitId
        this.article.produits[0].tarifs[0].tarifId = this.donneeFournisseur.tarifId
        await modifierProduitHM(this.article)
      }
   
      this.$emit("produit-modifie", this.article)
      this.$store.commit('uxHelpers/hideSpinner')
      
      if (this.article.produits[0]?.ingredientId) {
        this.$store.dispatch('definitions/rechargerIngredient', this.article.produits[0].ingredientId)
      }
      chargerMercuriales(this.etabCourantId, true, null, this.profilLecteurMercu)
      this.$store.dispatch('produits/produitsUtilisateurCourant', this.etabCourantId)

      this.$toast('Produit sauvegardé', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        className: ['toast-success'],
        duration: 1500
      })
    },
    show(ingredient) {
      this.$refs.modalAjoutProduitHM.show()
      if (ingredient?.ingredientId) {
        this.produitHM.ingredient = ingredient
        this.rechercheIngredientDisabled = true
      }
    },
    hide() {
      this.$refs.modalAjoutProduitHM.hide()
    },
    selectFamilleDeProduit(event) {
      this.produitHM.familleId = event.id
      this.optionsFournisseurs = this.construireListeFournisseur(event.id, this.fournisseur?.fournisseurId)
      if (this.optionsFournisseurs?.length === 1) {
        this.donneeFournisseur.fournisseur = this.optionsFournisseurs[0]
      }
      this.key++
    },
    selectUCDeProduit(event) {
      this.produitHM.uc = event.id
      if (this.donneeFournisseur.pcb) {
        if (event.id === "Kg") 
          this.donneeFournisseur.nbKg = this.donneeFournisseur.pcb
        if (event.id === "Litre")
          this.donneeFournisseur.nbL = this.donneeFournisseur.pcb
        if (event.id === "Pièce")
          this.donneeFournisseur.nbPCE = this.donneeFournisseur.pcb
      } else {
        this.donneeFournisseur.nbKg = null
        this.donneeFournisseur.nbL = null
        this.donneeFournisseur.nbPCE = null
      }
    },
    selectTVA(event) {
      this.produitHM.tauxTVA = event.id
    },
    construireListeUC() {
      let liste = []
      liste.push({id: "Kg", nom: "Kg"})
      liste.push({id: "Litre", nom: "Litre"})
      liste.push({id: "Carton", nom: "Carton"})
      liste.push({id: "Unité", nom: "Unité"})
      liste.push({id: "Pièce", nom: "Pièce"})
      liste.push({id: "Seau", nom: "Seau"})

      return liste
    },
    construireListeTVA() {
      let liste = []
      liste.push({id: 0, nom: "0"})
      liste.push({id: 5.5, nom: "5.5"})
      liste.push({id: 10, nom: "10"})
      liste.push({id: 20, nom: "20"})

      return liste
    },
    
    construireListeFamilles(fournisseurId) {
      let liste = []
      let tmpFour = this.prefFourn.data
      if (fournisseurId) {
        tmpFour = tmpFour.filter(f => f.choixFournisseurs.some(c => c.fournisseurId === fournisseurId))
      }
      tmpFour.sort((a, b) => {return a.indice - b.indice })
      tmpFour.filter(f => f.choixFournisseurs.some(c => c.actif)).forEach(element => {
        if (!liste.find(f => f.id === element.familleId)) {
            let fam = {}
            fam.id = element.familleId
            fam.nom = element.familleNom
            liste.push(fam)
          }
      });
      
      return liste
    },
    construireListeFournisseur(idFamille, fournisseurId) {
      let liste = []
      let tmpFour = this.prefFourn.data.filter(f => f.familleId === idFamille)
      //etablissements.find(eId => eId === this.etabCourantId)
      tmpFour[0]?.choixFournisseurs.filter(c => c.actif).forEach(element => {
        if (!fournisseurId || (fournisseurId && element.fournisseurId === fournisseurId)) {
          let four = {}
          four.id = element.fournisseurId
          four.nom = element.fournisseurNom
          liste.push(four)
        }
      })
      return liste
    }
  },
  async mounted() {
    this.prefFourn = await getPrefFournisseurs(this.etabCourantId)
    this.optionsUC = this.construireListeUC()
    this.optionsTVA = this.construireListeTVA()
    this.tvaSelect = this.optionsTVA[0]
    this.optionsAllergenes = constantesId.allergenes
    if (!this.optionsAllergenes.find(a => a.id === "Aucun Allergène"))
      this.optionsAllergenes.unshift({id: "Aucun Allergène", valeur: "Aucun Allergène"})

    this.key++
  }
}
</script>
<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
