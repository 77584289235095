<template>
  <div>
    <PlanEdition ref="editionStructure" @retour-ajout-structure="surRetourAjoutSructure($event)" @structure-supprimee="setUrl();$router.go()" />
    <ModalDuplicationMenu ref="modalDuplicationMenu" :disabled="donneesModifiees" :menuPlanId="menu && menu.menuPlanId" :lundiDeLaSemaine="lundiDeLaSemaine" :menuSurPlanAlimentaire="menu && menu.menuSurPlanAlimentaire" @menu-duplique="surDuplication()" />
    <b-modal v-model="afficherOptionsExport" size="md" title="Options d'export"
      ok-title="Exporter" :ok-disabled="enExportation"
      cancel-title="Annuler"
      no-close-on-backdrop
      @ok="exporter(menu)">
      <template slot="default">
        <div class="container-fluid">
          <div class="row">
            <div class="card-body form-check background-primary form-check-inline">
              <input type="checkbox" id="afficherPrix" class="form-check-input" v-model="paramExport.afficherPrix" />
              <label class="form-check-label" for="afficherPrix">Afficher les prix ?</label>
            </div>
          </div>
          <div class="row">
            <div class="card-body form-check background-primary form-check-inline">
              <input type="checkbox" id="afficherEffectif" class="form-check-input" v-model="paramExport.afficherEffectif" />
              <label class="form-check-label" for="afficherEffectif">Afficher les effectifs ?</label>
            </div>
          </div>
          <div class="row">
            <div class="card-body form-check background-primary form-check-inline">
              <input type="checkbox" id="afficherAllergenes" class="form-check-input" v-model="paramExport.afficherAllergenes" />
              <label class="form-check-label" for="afficherAllergenes">Afficher les allergènes dans le menu ?</label>
            </div>
          </div>
        </div>
      </template>
    </b-modal>

    <b-modal v-model="montrerModalPlanAlimentaire" size="md" title="Créer un menu"
      :ok-title="planAlimentaireSelectionneId ? 'Créer les semaines de menus pour ce plan alimentaire' : 'Créer le menu'" :ok-disabled="estLectureSeule"
      cancel-title="Annuler"
      no-close-on-backdrop
      @ok="creerMenuAvecPlan(planId, true)">
      <template slot="default">
        <div v-if="optionsPlansAlimentaires && optionsPlansAlimentaires.length" class="col-12 no-padding push-top-1rem">
          <b-form-select v-model="planAlimentaireSelectionneId" value-field="id" text-field="nom" :options="optionsPlansAlimentaires" style="width:100%;">
            <template slot="first">
              <option :value="null" selected>Aucun</option>
            </template>
          </b-form-select>
        </div>
        <div v-if="menuOuPlanEnCours && menuOuPlanEnCours.estUneDeclinaison" class="col-12">
          <label class="label-checkbox mb-2">
            <input type="checkbox" v-model="importerMenuUniversel" :disabled="!menuOuPlanEnCours.menuUniverselDisponible" @change="checkAllEnvoi()">
            <span class="ml-2">Importer menu Agap'pro</span>
          </label>
          <div class="warning" v-if="!menuOuPlanEnCours.menuUniverselDisponible"><i class="fas fa-exclamation-triangle"></i> Aucun menu Agap'pro n'est encore disponible pour cette semaine</div>
        </div>
      </template>
    </b-modal>

    <b-modal v-model="montrerModalRecette" size="xxl" :title="titreModalRecette" hide-footer
      @hide="cacherModalRecette">
      <template slot="default">
        <RecetteListe :menu="menu" :recetteList="recetteList" :composanteId="composanteId" :cleComposee="cleComposee" :serviceId="serviceId" :recetteId="recetteId" :composanteEffectif="composanteEffectif" :regimeIdsInit="regimesModalRecette" :textureIdsInit="texturesModalRecette" :categoriesGEMRCNIdsInit="categoriesGEMRCNModalRecette" @changement-recette="surChangementRecette" />
      </template>
    </b-modal>

    <div class="container-fluid">
      <BarreFiltres :filtres="filtres" @appliquer-filtre="appliquerFiltre($event)" />
      <BarreActions :actions="actions" @appliquer-action="executerAction($event)" />

      <div v-if="montrerCreerMenuAvecPlan" class="col-12 text-center push-top-2rem">
        <template v-if="estLectureSeule">
          <b-button class="btn btn-secondary" style="margin-bottom: 1rem;margin-right: 10rem;" @click="creerMenuAvecPlan(planId, false)" :disabled="estLectureSeule">
            Aucun menu n'existe pour cette période sur {{nomDuPlanDeMenu}}
          </b-button>
        </template>
        <template v-else>
          <b-button class="btn btn-primary" style="margin-bottom: 1rem;margin-right: 10rem;" @click="creerMenuAvecPlan(planId, false)" :disabled="estLectureSeule">
            Créer une semaine {{nomDuPlanDeMenu}}
          </b-button>
        </template>
      </div>
      <MenusEdition v-if="montrerLeMenu" ref="editionDetails"
        :key="menuEditionId" :menu="menu" :composanteId="composanteId" :lundiDeLaSemaine="lundiDeLaSemaine" :lectureSeule="estLectureSeule"
        @effacement-recette="checkDonneesModifiees" @duplication-recette="checkDonneesModifiees" @effectif-changed="checkDonneesModifiees" />
    </div>
  </div>
</template>

<script>
import BarreFiltres from '@/components/Divers/BarreFiltres'
import BarreActions from '@/components/Divers/BarreActions'
import PlanEdition from '@/components/Menus/PlanEdition'
import MenusEdition from '@/components/Menus/MenuEdition'
import RecetteListe from '@/components/Recettes/RecetteListe'
import ModalDuplicationMenu from '@/components/Menus/ModalDuplicationMenu'
import { showAlerts } from '@/mixins/alerts'
import { formatageDate } from '@/mixins/dateFormats'
import { connecteData } from "@/mixins/connecteData"
import { objetVide, alerteDonneesModifiees, getLundiDeLaSemaine } from '@/mixins/index'
import { creerMenu, modifierMenu, supprimerMenu, chargerMenuEtStructurePourDate, validerMenu, devaliderMenu } from '@/api/gpao/menus'
import { obtenirPourEditionDelinee } from "@/api/gpao/recettes"
import { exporterMenu } from '@/api/gpao/documents'
import { lancerTelechargementDeResponse } from "@/helpers/utils"
import { mapActions } from "vuex"
import { recetteLignesFromData, verifierAutorisations } from "@/mixins/recette"
import Vue from 'vue'
import _ from 'lodash'

export default {
  name: "PageMenusListe",
  mixins: [showAlerts, objetVide, formatageDate, alerteDonneesModifiees, connecteData, getLundiDeLaSemaine, recetteLignesFromData, verifierAutorisations],
  components: { BarreFiltres, BarreActions, PlanEdition, MenusEdition, RecetteListe, ModalDuplicationMenu },
  data() {
    return {
      menu: undefined,
      menusEtPlans: [],
      lundiDeLaSemaine: null,
      menuId: null,
      planId: null,
      recetteList: [],
      recetteId: null,
      composanteId: null,
      composanteEffectif: null,
      serviceId: null,
      plansDeMenus: [],
      plansDeMenusNonModifie: [],
      montrerLeMenu: false,
      montrerLePlan: false,
      aucunPlanDeMenu: false,
      btnRetourAuMenu: false,
      ready: false,
      nomDuPlan: '',
      menuEditionId: 1,
      cleComposee: null,
      montrerModalRecette: false,
      titreModalRecette: "",
      regimesModalRecette: [],
      texturesModalRecette: [],
      categoriesGEMRCNModalRecette: [],
      nomDuMenuData: '',
      dateChange: false,
      menuCree: false,
      baseUrl: '',
      enExportation: false,
      afficherOptionsExport: false,
      paramExport: { afficherPrix: false, afficherEffectif: false, afficherAllergenes: false },
      scrollTop: 0,
      lieuxPortage: [],
      afficherStructuresActives: true,
      lectureSeule: false,
      donneesModifiees: false,
      montrerModalPlanAlimentaire: false,
      optionsPlansAlimentaires: this.$store.state.definitions.plansAlimentaires,
      planAlimentaireSelectionneId: null,
      importerMenuUniversel: false,
      filtres: {
        structureMenu: {
          label: "Sélectionnez une structure de menu",
          champ: "select",
          options: [],
          selection: null,
          initialiser(contexte) {
            let filtre = contexte.filtres.structureMenu
            filtre.options = contexte.optionsPlansEtMenus?.map(o => { return { valeur: o.id, texte: o.nom }}) || []
            filtre.selection = filtre.options.find(o => o.valeur === contexte.menuId) || null
          },
          filtrer(contexte) {
            let filtre = contexte.filtres.structureMenu
            contexte.selectStructureMenu(filtre.selection.valeur)
          }
        },
        structuresActives: {
          label: "Structures actives uniquement",
          champ: "checkbox",
          selection: true,
          initialiser(contexte) {
            contexte.filtres.structuresActives.selection = contexte.afficherStructuresActives
          },
          async filtrer(contexte) {
            contexte.afficherStructuresActives = contexte.filtres.structuresActives.selection
            await contexte.surChangementAffichageStrucActive()
            contexte.filtres.structureMenu.initialiser(contexte)
          }
        },
        semaineMenu: {
          label: "Semaine de menu",
          champ: "datepicker",
          selection: null,
          customTextSemaine: true,
          initialiser(contexte) {
            contexte.filtres.semaineMenu.selection = contexte.lundiDeLaSemaine
          },
          async filtrer(contexte, date) {
            let filtre = contexte.filtres.semaineMenu
            if (filtre.disabled) {
              return
            }

            if (date) {
              filtre.selection = date
            }
            contexte.surChangementDate(filtre.selection)
          }
        },
        navigationGauche: {
          picto: "fas fa-chevron-left",
          champ: "btn",
          filtrer(contexte) {
            let filtreSemaineMenu = contexte.filtres.semaineMenu
            let date = filtreSemaineMenu.selection

            filtreSemaineMenu.filtrer(contexte, new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7))
          }
        },
        navigationDroite: {
          picto: "fas fa-chevron-right",
          champ: "btn",
          filtrer(contexte) {
            let filtreSemaineMenu = contexte.filtres.semaineMenu
            let date = filtreSemaineMenu.selection

            filtreSemaineMenu.filtrer(contexte, new Date(date.getFullYear(), date.getMonth(), date.getDate() + 7))
          }
        },
        // Appeler "this.filtres.initialiser(this)" dans mounted() => génère le tableau de filtres utilisés pour afficher les filtres dans la barre de filtres
        barreGauche: [],
        barreDroite: [],
        initialiser(contexte) {
          contexte.filtres.barreGauche = [
            contexte.filtres.navigationGauche,
            contexte.filtres.semaineMenu,
            contexte.filtres.navigationDroite,
            contexte.filtres.structureMenu,
            contexte.filtres.structuresActives
          ]
          /*contexte.filtres.barreGauche = [
            contexte.filtres.structureMenu,
            contexte.filtres.structuresActives
          ]
          contexte.filtres.barreDroite = [
            contexte.filtres.navigationGauche,
            contexte.filtres.semaineMenu,
            contexte.filtres.navigationDroite
          ]*/
          contexte.filtres.barreGauche.forEach(f => {
            if (f.initialiser) {
              f.initialiser(contexte)
            }
          })
          /*contexte.filtres.barreDroite.forEach(f => {
            if (f.initialiser) {
              f.initialiser(contexte)
            }
          })*/
        }
      },
      actions: {
        // Boutons à afficher dans la barre d'action
        // Exemple :
        /*
        nomAction: {
          label: Label affiché sur le bouton (string),
          disabled: Etat actif/inactif par défaut du bouton (bool),
          class: Classe CSS (btn-primary, btn-success, btn-warning ou btn-danger) à appliquer sur le bouton (par défaut : "btn-primary" est appliqué => il s'agit du bouton bleu Agap'pro) (string)
          methode(contexte) {
            // => contexte équivaut à this (mais this ne fonctionne pas car l'initialisation de data n'est pas encore terminée, donc data n'est pas encore rattaché à this)
            traitements à exécuter lors du clic sur le bouton
            les traitements définis dans cette page doivent être appelés sous la forme : contexte.traitement()

            Remarque : pour que le système fonctionne, il faut déclarer la méthode ci-dessous dans la section "methods" de cette page
            executerAction(methode) {
              methode(this)
            }

          }
        }
        */
        creerStructureMenu: {
          label: "Créer une structure de menu",
          hidden: this.estLectureSeule,
          methode(contexte) {
            contexte.creerStructureMenu()
          }
        },
        modifierStructureMenu: {
          label: "Modifier la structure de menu",
          hidden: this.estLectureSeule,
          disabled: true,
          methode(contexte) {
            contexte.editerLePlanDeMenu()
          }
        },
        dupliquerMenu: {
          label: "Dupliquer le menu",
          disabled: true,
          methode(contexte) {
            contexte.$refs.modalDuplicationMenu.show()
          }
        },
        exporterMenu: {
          label: "Exporter le menu",
          disabled: true,
          methode(contexte) {
            contexte.ouvrirOptionsExport()
          }
        },
        supprimerMenu: {
          label: "Supprimer le menu",
          hidden: this.estLectureSeule,
          disabled: true,
          class: "btn-danger",
          async methode(contexte) {
            if (contexte.menuId) {
              contexte.supprimerMenu(contexte.menuId)
            }
          }
        },
        validerMenu: {
          label: "Valider le menu",
          champ: "btn",
          disabled: true,
          hidden: true,
          methode(contexte) {
            contexte.validerMenu(contexte.menuId)
          }
        },
        devaliderMenu: {
          label: "Dévalider le menu",
          champ: "btn",
          disabled: true,
          hidden: true,
          methode(contexte) {
            contexte.devaliderMenu(contexte.menuId)
          }
        },
        annuler: {
          label: "Annuler",
          hidden: this.estLectureSeule,
          disabled: true,
          class: "btn-danger",
          async methode(contexte) {
            let result = await contexte.alerteConfirmation("Annuler les modifications", "Vos modifications seront définitivement perdues.<br/>Souhaitez-vous annuler vos modifications ?")
            if (result) {
              contexte.apresSelectionDate()
            }
          }
        },
        enregistrer: {
          label: "Enregistrer",
          hidden: this.estLectureSeule,
          disabled: true,
          class: "btn-success",
          methode(contexte) {
            contexte.modifier(contexte.menu)
          }
        },
        // Appeler "this.actions.initialiser(this)"" dans mounted() => génère les tableaux d'actions utilisés pour afficher les boutons à gauche et à droite de la barre d'actions
        barreGauche: [],
        barreDroite: [],
        initialiser(contexte) {
          contexte.actions.barreGauche = [ contexte.actions.creerStructureMenu, contexte.actions.modifierStructureMenu, contexte.actions.exporterMenu, contexte.actions.dupliquerMenu, contexte.actions.supprimerMenu, contexte.actions.validerMenu, contexte.actions.devaliderMenu ]
          if (contexte.profilIsAdmin) {
            contexte.actions.validerMenu.hidden = false
            contexte.actions.devaliderMenu.hidden = false
          }
          contexte.actions.barreDroite = [ contexte.actions.annuler, contexte.actions.enregistrer ]
        },
        // A gérer en cas de boutons à activer/désactiver (créer une méthode par groupe de boutons à activer/désactiver)
        disableActionsStructureMenu(actions, disabled) {
          let actionsATraiter = [ actions.modifierStructureMenu ]
          actionsATraiter.forEach(a => a.disabled = disabled)
        },
        disablePasDeMenu(actions, disabled) {
          let actionsATraiter = [ actions.dupliquerMenu, actions.exporterMenu, actions.supprimerMenu, actions.validerMenu, actions.devaliderMenu ]
          actionsATraiter.forEach(a => a.disabled = disabled)
        },
        disableActionsMenu(actions, disabled, lectureSeule) {
          if (lectureSeule) {
            disabled = lectureSeule
            actions.devaliderMenu.disabled = disabled
          } else {
            actions.devaliderMenu.disabled = !disabled
          }
          actions.supprimerMenu.disabled = disabled
          actions.validerMenu.disabled = disabled
        },
        disableActionsEnregistrer(actions, disabled) {
          actions.barreDroite.forEach(a => a.disabled = disabled)
          actions.barreGauche.forEach(a => a.disabled = !disabled)
        }
      }
    }
  },
  computed: {
    menuOuPlanEnCours() {
      return this.menusEtPlans.find(x => x.id == this.menuId)
    },
    montrerCreerMenuAvecPlan() {
      if (!this.menuId)
        return false;
      return String(this.menuId).startsWith("p")
    },
    compAucunPlanDeMenu() {
      return this.aucunPlanDeMenu
    },
    nomDuPlanDeMenu() {
      return this.nomDuPlan
    },
    nomDuMenu() {
      let result = this.optionsPlansEtMenus.find(el => el.id === this.menuId)
      if (result) {
        // eslint-disable-next-line
        this.nomDuMenuData = result.nom
        return result.nom
      } else {
        // eslint-disable-next-line
        this.nomDuMenuData = ''
        return ''
      }
    },
    planDeMenuEditable() {
      return this.planId
    },
    optionsPlansEtMenus() {
      return this.menusEtPlans
    },
    menuVide() {
      return this.objetVide(this.menu)
    },
    creerUnPlan() {
      return this.menuId === "0"
    },
    estLectureSeule() {
      return this.lectureSeule || this.menuOuPlanEnCours?.estValide
    }
  },
  watch: {
    afficherStructActives() {
      this.$store.commit('menus/saveFiltreStructActives', this.afficherStructuresActives)
      if (this.menuOuPlanEnCours)
        this.lectureSeule = this.menuOuPlanEnCours.nom.startsWith("[Inactif]") ? true : false;
    },
    planDeMenuEditable() {
      this.actions.disableActionsStructureMenu(this.actions, !this.planDeMenuEditable)
    },
    montrerLeMenu() {
      this.actions.disablePasDeMenu(this.actions, !this.montrerLeMenu)
      this.actions.disableActionsMenu(this.actions, !this.montrerLeMenu || this.estLectureSeule, !this.montrerLeMenu || this.donneesModifiees)
    },
    estLectureSeule() {
      this.actions.disableActionsMenu(this.actions, !this.montrerLeMenu || this.estLectureSeule, !this.montrerLeMenu || this.donneesModifiees)
    },
    donneesModifiees() {
      this.actions.disableActionsStructureMenu(this.actions, !this.planDeMenuEditable || this.estLectureSeule)
      this.actions.disablePasDeMenu(this.actions, !this.montrerLeMenu || this.estLectureSeule)
      this.actions.disableActionsEnregistrer(this.actions, !this.donneesModifiees || this.estLectureSeule)
      this.actions.disableActionsMenu(this.actions, !this.montrerLeMenu || this.estLectureSeule, !this.montrerLeMenu || this.donneesModifiees)
    }
  },
  methods: {
    ...mapActions("uxHelpers", ["afficherAttente", "cacherAttente"]),
    tooltiplieuxPortage() {
      let texte = []
      this.menuOuPlanEnCours.lieuxPortageId.forEach(x => {
        const trouve = this.lieuxPortage.find(l => l.id == x).libelle
        texte.push(trouve)
      })
      texte.sort()
      return texte.join("<br>")
    },
    async enregistrerDonneesModifiees() {
      await this.modifier(this.menu)
    },
    setUrl(menuId) {
      if (menuId) {
        //this.$router.push({name:"Menus", params:{etablissementId: this.etabCourantId, menuId: menuId,date: this.formatDateAvecTirets(this.lundiDeLaSemaine)}})
        history.pushState({}, '', "/" + this.etabCourantId + "/menus/" + this.formatDateAvecTirets(this.lundiDeLaSemaine) + '/' + menuId)
      } else {
        // this.$router.push({name:"Menus", params:{etablissementId: this.etabCourantId, date: this.formatDateAvecTirets(this.lundiDeLaSemaine)}})
        history.pushState({}, '', "/" + this.etabCourantId + "/menus/" + this.formatDateAvecTirets(this.lundiDeLaSemaine))
      }
    },
    checkDonneesModifiees() {
      if (!this.donneesInitiales) {
        this.donneesModifiees = false
      } else {
        const objInitiales = this.getMenuPourApi(this.donneesInitiales)
        objInitiales.composantes.sort((a, b) => { return a.id - b.id })
        const objEncours = this.getMenuPourApi(this.menu)
        objEncours.composantes.sort((a, b) => { return a.id - b.id })
        if (objInitiales.menuId !== objEncours.menuId) {//bugfix: évite les faux-positifs lors de la comparaison entre deux menus différents...
          this.donneesModifiees = false
        } else {
          const initiales = JSON.stringify(objInitiales)
          const encours = JSON.stringify(objEncours)
          this.donneesModifiees = !_.isEqual(initiales, encours)
        }
      }
    },
    async appliquerFiltre(filtrer) {
      if (this.donneesModifiees) {
        let result = await this.alerteConfirmationEnregistrement()
        if (result) {
          // Enregistrement des modifications
          await this.modifier(this.menu)
        } else {
          // Annulation des modifications
          this.setDonneesInitiales(this.menu)
          this.checkDonneesModifiees()
        }
      }
      // Nécessaire pour faire fonctionner les filtres de la barre de filtres
      filtrer(this)
    },
    executerAction(methode) {
      // Nécessaire pour faire fonctionner les actions de la barre d'action
      methode(this)
    },
    setDonneesInitiales(evt) {
      this.donneesInitiales = _.cloneDeep(evt)
    },
    editerLePlanDeMenu() {
      this.planId = this.planDeMenuEditable
      this.$refs.editionStructure.afficherModal(this.planId)
    },
    supprimerMenu(menuId) {
      return this.alerteConfirmation("Supprimer le menu", "Souhaitez-vous supprimer ce menu ?")
        .then((result) => {
          if (result) {
            // eslint-disable-next-line promise/no-nesting
            return supprimerMenu(this.nettoyerId(menuId))
              .then(() => {
                this.setUrl(this.planId)
                this.$router.go()
                return
              })
              .catch((error) => {
                console.error(error)
              })
          }
          return
        })
    },
    afficherModalRecettes(evt) {
      this.btnRetourAuMenu = false
      if (evt) {
        this.cleComposee = evt.cleComposee
        this.composanteId = evt.composanteId
        this.serviceId = evt.serviceId
        this.composanteEffectif = evt.effectif
        if (evt.recetteId) {
          this.recetteId = evt.recetteId
        }
        this.titreModalRecette = evt.titre
        this.regimesModalRecette = evt.regimeIds
        this.texturesModalRecette = evt.textureIds
        this.categoriesGEMRCNModalRecette = evt.categoriesGEMRCNIds
        this.montrerModalRecette = true
        this.scrollTop = window.document.documentElement.scrollTop//on garde le scroll avant affichage du modal
      }
    },
    cacherModalRecette() {
      this.montrerModalRecette = false
      this.$nextTick(function () {//on remet le scroll comme avant affichage du modal
        window.document.documentElement.scrollTop = this.scrollTop
      })
    },
    cacherModalPlanAlimentaire() {
      this.montrerModalPlanAlimentaire = false
      this.$nextTick(function () {//on remet le scroll comme avant affichage du modal
        window.document.documentElement.scrollTop = this.scrollTop
      })
    },
    ajouterRecetteDansMenu(evt) {
      var service = this.menu.services.find(service => service.id === evt.serviceId)
      const composante = service.composantes && service.composantes.find(composante => composante.cleComposee === evt.cleComposee && composante.composanteId === evt.composanteId)
      this.menu.recettes.push({ id: evt.recetteId, nom: evt.recetteNom, categoriesGEMRCNIds: evt.categoriesGEMRCNIds, allergenes: evt.allergenes, estUneDeclinaison: evt.estUneDeclinaison, estDeclinaisonPersonnalisee: evt.estDeclinaisonPersonnalisee })
      if (composante) {
        Vue.set(composante, "recetteId", evt.recetteId)
        composante.cotationManquante = evt.cotationManquante
        composante.prixServi = 0//force la mise à jour du prix de la recette
      }
      if (this.$refs.editionDetails) {
        this.$refs.editionDetails.majRecette(evt)
      }
      this.checkDonneesModifiees()
    },
    async surChangementRecette(evt) {
      this.$refs.editionDetails.majRecette(evt)
      this.cacherModalRecette()
      let result = await this.getDetailsRecette(evt.recetteId)
      this.ajouterRecetteDansMenu({
        recetteId: result.id,
        recetteNom: result.nom,
        composanteId: this.composanteId,
        serviceId: this.serviceId,
        cleComposee: this.cleComposee,
        cotationManquante: result.cotationManquante,
        categoriesGEMRCNIds: result.gemrcnIds,
        allergenes: result.allergenes,
        estUneDeclinaison: result.estUneDeclinaison,
        estDeclinaisonPersonnalisee: result.estDeclinaisonPersonnalisee
      })
    },
    hidePlan() {
      this.menuId = null
      this.montrerLePlan = false
      this.btnRetourAuMenu = false
    },
    nettoyerId(id) {
      return id.toString().slice(1)
    },
    getMenuPourApi(menu) {
      let menuPourApi = { menuId: menu && Number(menu.id), services: [], composantes: [] }
      if (menu && menu.services) {
        menu.services.map(service => {
          if (service.id !== -1) {
            menuPourApi.services.push(
              { id: service.id, effectif: service.effectif, effectifServi: service.effectifServi }
            )
          }
          if (service.composantes) {
            service.composantes.map(composante => {
              menuPourApi.composantes.push({ id: composante.id, recetteId: composante.recetteId, effectif: composante.effectif, effectifServi: composante.effectifServi })
            })
          }
        })
      }
      return menuPourApi
    },
    async modifier(menu) {
      await modifierMenu(this.getMenuPourApi(menu))
      this.menu = menu
      this.setDonneesInitiales(menu)
      this.checkDonneesModifiees()
      this.alerteSucces('Menu Sauvegardé !')
    },
    ouvrirOptionsExport() {
      this.afficherOptionsExport = true
    },
    async exporter(menu) {
      try {
        if (this.donneesModifiees) {
          await this.modifier(menu)
        }
        this.enExportation = true
        const response = await exporterMenu(menu.id, this.paramExport)
        lancerTelechargementDeResponse(response)
      }
      catch (erreur) {
        this.alerteErreur(erreur);
      }
      finally {
        this.enExportation = false
        this.afficherOptionsExport = false
      }
    },
    obtenirPlanDeMenu(id) {
      if (this.menusEtPlans.length) {
        let plan = this.menusEtPlans.find(p => p.id === id)
        if (plan) {
          this.nomDuPlan = plan.nom
          this.lectureSeule = plan.nom.startsWith("[Inactif]") ? true : false;
          plan = this.plansDeMenusNonModifie.find(p => p.nom === this.nomDuPlan)
          if (plan) {
            this.planId = plan.id
          } else {
            this.planId = id
          }
        } else {
          this.planId = undefined
        }
      }
    },
    async obtenirMenu(id) {
      this.$store.commit('uxHelpers/showSpinner')
      const data = await this.$store.dispatch('menus/obtenirMenu', id)
      this.menu = data
      this.setDonneesInitiales(data)
      this.checkDonneesModifiees()
      this.menuId = 'm' + data.id
      this.planId = 'p' + this.menu.menuPlanId
      this.montrerLePlan = false
      this.montrerRecettes = false
      this.montrerLeMenu = true
      this.menuEditionId++
      this.$store.commit('uxHelpers/hideSpinner')
      this.lectureSeule = (this.menuOuPlanEnCours && this.menuOuPlanEnCours.nom.startsWith("[Inactif]")) ? true : false;
      this.ready = true
    },
    selectStructureMenu(evt) {
      this.menuId = evt
      this.obtenirMenuPlan(evt)
    },
    menuCreeAvecBouton(id) {
      const menuId = 'm' + id
      this.setUrl(menuId)
      this.chargerMenusEtStructures(this.lundiDeLaSemaine, id)
    },
    creerStructureMenu() {
        this.$refs.editionStructure.afficherModal()
    },
    async obtenirMenuPlan(id) {
      if (typeof (id) === 'number') {
        await this.obtenirMenu(id)
      } else if (id.charAt(0) === 'p') {// nouveau menu à partir d'une structure de menu
        this.obtenirPlanDeMenu(id)
        this.menu = undefined;
        this.menuId = id;
        this.montrerLePlan = false
        this.montrerLeMenu = false
        this.btnRetourAuMenu = false
        this.setUrl(id)
      } else if (id.charAt(0) === 'm') {// menu existant
        this.setUrl(id)
        const menuId = this.nettoyerId(id)
        await this.obtenirMenu(menuId)
      } else if (id === '') {
        this.menuId = null
        this.planId = null
        this.montrerLePlan = false
        this.montrerLeMenu = false
        this.btnRetourAuMenu = false
      }
    },
    surDuplication() {
      this.$router.go()
    },
    surChangementDate(evt) {
      this.lundiDeLaSemaine = evt
      this.montrerLeMenu = false
      this.dateChange = true
      this.estLectureSeule;
      return this.chargerMenusEtStructures(this.lundiDeLaSemaine)
    },
    async apresSelectionDate() {
      let selection, structId, id
      if (this.menu) {
        structId = this.menu.menuPlanId
      }
      else {
        structId = this.menuId
      }
      if (structId) {
        id = structId.toString() //c'est pas clean, mais ca a l'air tout couvrir
        if (id.startsWith("p")) {
          id = id.slice(1)
        }
        selection = this.menusEtPlans.find(x => x.structId == id)
        if (!selection) {
          selection = this.menusEtPlans.find(x => x.id == 'p' + id)
        }
      }

      if (selection) {
        this.nomDuPlan = selection.nom
        this.menuId = selection.id
        if (!selection.estStructure) {
          await this.obtenirMenu(this.nettoyerId(selection.id))
        }
      }

      this.setUrl(this.menuId)
    },
    async surRetourAjoutSructure(evt) {
      await this.chargerMenusEtStructures(this.lundiDeLaSemaine)
      this.obtenirMenuPlan("p" + evt.planId)
    },
    creerMenuAvecPlan(planId, depuisModal) {
      if (!depuisModal && (this.optionsPlansAlimentaires?.length || this.menuOuPlanEnCours?.estUneDeclinaison)) {
        this.planAlimentaireSelectionneId = null
        this.importerMenuUniversel = false
        this.montrerModalPlanAlimentaire = true
        return
      }

      this.menuCree = true
      let id = typeof (planId) === 'number' ? planId : planId.slice(1)
      this.planId = id
      let apiDate = this.formatDateAvecTirets(this.lundiDeLaSemaine)

      creerMenu({ "dateDebut": apiDate, "menuPlanId": id, "planAlimentaireId": this.planAlimentaireSelectionneId, "importerMenuUniversel": this.importerMenuUniversel, "etablissementId": this.etabCourantId })
        .then((response) => {
          this.menuId = response.data
          this.menuCreeAvecBouton(this.menuId)
          this.checkDonneesModifiees()
          this.$router.go()
          return
        })
        .catch((error) => {
          console.error(error)
        })

      this.montrerModalPlanAlimentaire = false
    },
    validerMenu(menuId) {
      return this.alerteConfirmation("Valider le menu", "Le menu deviendra accessible aux utilisateurs et il ne sera plus possible de le modifier", "Valider", "Annuler")
        .then(result => {
          if (!result) {
            return
          }
          // eslint-disable-next-line promise/no-nesting
          return validerMenu(this.nettoyerId(menuId))
            .then(() => {
              this.menuOuPlanEnCours.estValide = true
              return
            })
            .catch((error) => {
              console.error(error)
              this.alerteErreur(error);
            })
        })
    },
    devaliderMenu(menuId) {
      return this.alerteConfirmation("Dévalider le menu", "Le menu ne sera plus accessible aux utilisateurs et il redeviendra possible de le modifier", "Dévalider", "Annuler")
        .then(result => {
          if (!result) {
            return
          }
          // eslint-disable-next-line promise/no-nesting
          return devaliderMenu(this.nettoyerId(menuId))
            .then(() => {
              this.menuOuPlanEnCours.estValide = false
              return
            })
            .catch((error) => {
              console.error(error)
              this.alerteErreur(error);
            })
        })
    },
    async chargerMenusEtStructures(date, evt) {
      this.lundiDeLaSemaine = this.getLundiDeLaSemaine(date)
      let apiDate = this.formatDatePourApiSansSeparation(this.lundiDeLaSemaine)
      // if (!this.afficherStructActives)
      // this.afficherStructActives = true; 
      var payLoad = { apiDate: apiDate, etablissementId: this.etabCourantId, actifSeulement: this.afficherStructuresActives };
      const reponse = await chargerMenuEtStructurePourDate(payLoad)
      this.menusEtPlans = reponse.data.liste
      this.lieuxPortage = reponse.data.lieux
      this.plansDeMenus = this.menusEtPlans.filter(x => x.estStructure)
      this.plansDeMenusNonModifie = _.cloneDeep(this.plansDeMenus)
      this.menusEtPlans.forEach(x => {
        if (x.estStructure) {
          x.id = 'p' + x.id
        }
        else {
          x.id = 'm' + x.id
        }
      })
      this.aucunPlanDeMenu = !this.menusEtPlans.length

      if (this.dateChange) {
        this.dateChange = false
        await this.apresSelectionDate()
      }
      if (this.menuCree) {
        this.menuCree = false
        await this.obtenirMenuPlan(evt)
      }
      await this.filtres.initialiser(this)
      this.ready = true
    },
    surChangementAffichageStrucActive() {
      this.$store.commit('menus/saveFiltreStructActives', this.afficherStructuresActives);
      return this.chargerMenusEtStructures(this.lundiDeLaSemaine);
    },
    async getDetailsRecette(recetteId) {
      let response = await obtenirPourEditionDelinee(recetteId, this.etabCourantId)
      this.verifierAutorisations(response.data)
      response.data.lignesCompletees = await this.recetteLignesFromData(response.data)
      return response.data
    }
  },
  async mounted() {
    this.afficherAttente()
    this.baseUrl = window.location.origin + '/' + this.etabCourantId + '/menus'
    const initialiseParUrl = this.$route.params.date || this.$route.params.menuId
    this.afficherStructuresActives = this.$store.state.menus.filtreStrucActivesUniquement;
    if (this.$route.params.date && this.$route.params.menuId) {
      this.lundiDeLaSemaine = new Date(this.$route.params.date)
      await this.chargerMenusEtStructures(this.lundiDeLaSemaine)
      this.obtenirMenuPlan(this.$route.params.menuId)
    }
    else if (this.$route.params.menuId) {
      await this.chargerMenusEtStructures(new Date())
      this.obtenirMenuPlan(this.$route.params.menuId)
    }
    if (this.$route.params.date) {
      this.lundiDeLaSemaine = new Date(this.$route.params.date)
      await this.chargerMenusEtStructures(this.lundiDeLaSemaine)
    }
    if (!initialiseParUrl) {
      let date = this.$store.state.menus.date
      if (date) {
        this.lundiDeLaSemaine = new Date(date)
        date = this.lundiDeLaSemaine
      } else {
        date = new Date()
      }
      await this.chargerMenusEtStructures(date)
      if (this.$store.state.menus.menuId && this.menusEtPlans.find(m => m.id === this.$store.state.menus.menuId)) {
        this.obtenirMenuPlan(this.$store.state.menus.menuId)
      }
    }

    if (this.$route.params.ajout) {
      while (!this.menu) {
        // eslint-disable-next-line promise/avoid-new
        await new Promise(resolve => setTimeout(resolve, 10))
      }
      this.composanteId = this.$route.params.ajout.composanteId
      this.serviceId = this.$route.params.ajout.serviceId
      this.cleComposee = this.$route.params.ajout.cleComposee
      this.surChangementRecette(this.$route.params.ajout)
    }

    this.cacherAttente()

    this.$root.$on('afficher-modal-recettes', this.afficherModalRecettes)

    //this.$root.$on('ajouter-recette-dans-menu', this.ajouterRecetteDansMenu)

    this.filtres.initialiser(this)
    this.actions.initialiser(this)
  },
  beforeRouteLeave(to, from, next) {
    if (this.lundiDeLaSemaine && this.menuId) {
      this.$store.commit('menus/setRouteEnMemoire', { date: this.formatDateAvecTirets(this.lundiDeLaSemaine), menuId: this.menuId })
    }
    next();
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/couleurs.scss";

.barreHaut {
  width: 100%;
  min-height: 60px;
  padding: 10px;
  display: flex;
  align-items: flex-start;
}
.boutons {
  display: flex;
  flex-wrap: wrap;
  margin-top: -5px;
}
.boutons > * {
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
}
.radios {
  color: white;
}
.radios > label {
  white-space: nowrap;
  margin-bottom: 0;
  margin: 0 3px;
}
.radios > label > input {
  height: 13px;
  margin: 0 3px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.titre-plan {
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.05rem;
  font-size: 1.5rem;
  padding: 0.5rem;
  color: white;
}
.fa-trash-alt {
  color: #ffffff;
  background: #ea0000de;
  padding: 10px;
  border-radius: 5px;
  margin-left: 0rem;
  cursor: pointer;
}
#lblLieuxCount {
  font-size: 16px;
  background: $vert-agap;
  color: #fff;
  padding: 0 5px;
  vertical-align: top;
  margin-left: -10px;
}
#fond {
  height: calc(100vh - 40px);
}
</style>
