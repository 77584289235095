<template>
  <div class="container-fluid">
    <BarreFiltres :filtres="filtres" @appliquer-filtre="appliquerFiltre($event)" />
    <BarreActions :actions="actions" @appliquer-action="executerAction($event)" />

    <div class="card table-scroll height-responsive-100">
      <table class="table table-bordered table-sm text-center table-header">
        <colgroup>
          <col width="100">
          <col>
          <col v-if="detaillerLots" width="100">
          <col v-if="detaillerLots" width="100">
          <col width="170">
          <col width="100">
          <col width="100">
          <col width="170">
          <col width="39">
        </colgroup>
        <thead>
          <th>Référence</th>
          <th class="aligner-gauche">Désignation</th>
          <th v-if="detaillerLots">N° Lot</th>
          <th v-if="detaillerLots">DLC/DDM</th>
          <th>Quantité au {{ debut ? formatHumanDate(debut) : "__/__/____" }}</th>
          <th>Entrées</th>
          <th>Sorties</th>
          <th>Quantité au {{ fin ? formatHumanDate(fin) : "__/__/____" }}</th>
          <th></th>
        </thead>
      </table>
      <div class="table-body">
        <table class="table table-bordered table-sm text-center">
          <colgroup>
            <col width="100">
            <col>
            <col v-if="detaillerLots" width="100">
            <col v-if="detaillerLots" width="100">
            <col width="170">
            <col width="100">
            <col width="100">
            <col width="170">
            <col width="34">
          </colgroup>
          <tbody>
            <tr v-for="(ligne, index) in lots" :key="index">
              <td>
                <span v-if="ligne.lot">{{ ligne.lot.reference }}</span>
              </td>
              <td class="aligner-gauche">
                <span v-if="ligne.lot">{{ ligne.lot.designationFournisseur }}</span>
              </td>
              <td v-if="detaillerLots">
                <span v-if="ligne.lot">{{ ligne.lot.NumLot }}</span>
              </td>
              <td v-if="detaillerLots">
                <span v-if="ligne.lot">{{ formatHumanDate(ligne.lot.dlcDluo) }}</span>
              </td>
              <td>
                {{ ligne.quantiteInitiale | afficherArrondi }} {{ ligne.lot.uc }}
              </td>
              <td>
                {{ ligne.quantiteEntree | afficherArrondi }} {{ ligne.lot.uc }}
              </td>
              <td>
                {{ ligne.quantiteSortie | afficherArrondi }} {{ ligne.lot.uc }}
              </td>
              <td :class="[{'bg-warning':anomalieStock(ligne.quantiteInitiale, ligne.quantiteEntree, ligne.quantiteSortie, ligne.quantiteFinale)}]">
                {{ ligne.quantiteFinale | afficherArrondi }} {{ ligne.lot.uc }}
              </td>
              <td>
                <button type="button" class="btn btn-primary btn-sm">
                  <BtnAffichageMvtsStock :id="ligne.lot.id" :lot="ligne.lot" :debut="debut" :fin="fin" :quantiteInitiale="ligne.quantiteInitiale" :avecDetails="detaillerLots" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import BarreFiltres from '@/components/Divers/BarreFiltres'
import BarreActions from '@/components/Divers/BarreActions'
import BtnAffichageMvtsStock from '../../components/Stock/BtnAffichageMvtsStock.vue'
import { connecteData } from "@/mixins/connecteData"
import { mapGetters } from "vuex"
import { obtenirMouvementsStockPeriode } from '@/api/gpao/stock'
import { exporterMouvements } from '@/api/gpao/documents'
import { formatageDate } from '@/mixins/dateFormats'
import { rechercherTexte, rechercherMultiTexte, arrondiNombre, lancerTelechargementDeResponse } from '@/helpers/utils'

export default {
  name: "PageMouvementsStock",
  mixins: [connecteData, formatageDate],
  components: {
    BarreFiltres,
    BarreActions,
    BtnAffichageMvtsStock
  },
  data() {
    return {
      key: 1,
      detaillerLots: false,
      debut: null,
      fin: null,
      ingredient: { lots: [] },
      multiLots: false,
      selectedDates: {
        start: new Date(this.debut),
        end: new Date(this.fin)
      },
      filtres: {
        rechercheIngredients: {
          label: "Recherche ingrédient",
          champ: "input",
          type: "text",
          selection: "",
          async filtrer(contexte) {
            let filtre = contexte.filtres.rechercheIngredients
            console.log("ingredientSelectionne: ", filtre.selection)
          }
        },
        periode: {
          label: "Choisir la période...",
          champ: "datepicker",
          type: "multi",
          selection: null,
          initialiser(contexte) {
            contexte.filtres.periode.selection = contexte.$store.state.stock.filtresEnMemoire.periode
            contexte.filtres.periode.filtrer(contexte)
          },
          async filtrer(contexte){
            if (contexte.ingredient.lots) {
              contexte.selectedDates = contexte.filtres.periode.selection
              contexte.debut = contexte.selectedDates.start
              contexte.fin = contexte.selectedDates.end
              contexte.periodeSelectionnee(contexte.selectedDates.start, contexte.selectedDates.end)
              contexte.$store.commit('stock/setFiltresEnMemoire', {key: 'periode', data: contexte.filtres.periode.selection})
              contexte.key++
            }
          },          
        },
        detaillerLots: {
          label: "Détailler les lots",
          champ: "checkbox",
          selection: false,
          initialiser(contexte) {
            contexte.filtres.detaillerLots.selection = contexte.$store.state.stock.filtresEnMemoire.detailLots
            contexte.filtres.detaillerLots.filtrer(contexte)
          },
          async filtrer(contexte) {
            contexte.detaillerLots = contexte.filtres.detaillerLots.selection
            contexte.$store.commit('stock/setFiltresEnMemoire', {key: 'detailLots', data: contexte.filtres.detaillerLots.selection})
          }
        },
        // Appeler "this.filtres.initialiser(this)" dans mounted() => génère le tableau de filtres utilisés pour afficher les filtres dans la barre de filtres
        barreGauche: [],
        barreDroite: [],
        initialiser(contexte) {
          contexte.filtres.barreGauche = [ contexte.filtres.periode, contexte.filtres.rechercheIngredients, contexte.filtres.detaillerLots ]
          contexte.filtres.barreGauche.forEach(f => {
            if (f.initialiser) {
              f.initialiser(contexte)
            }
          })
        }
      },
      actions: {
        // Boutons à afficher dans la barre d'action
        // Exemple :
        /*
        nomAction: {
          label: Label affiché sur le bouton (string),
          disabled: Etat actif/inactif par défaut du bouton (bool),
          class: Classe CSS (btn-primary, btn-success, btn-warning ou btn-danger) à appliquer sur le bouton (par défaut : "btn-primary" est appliqué => il s'agit du bouton bleu Agap'pro) (string)
          methode(contexte) {
            // => contexte équivaut à this (mais this ne fonctionne pas car l'initialisation de data n'est pas encore terminée, donc data n'est pas encore rattaché à this)
            traitements à exécuter lors du clic sur le bouton
            les traitements définis dans cette page doivent être appelés sous la forme : contexte.traitement()

            Remarque : pour que le système fonctionne, il faut déclarer la méthode ci-dessous dans la section "methods" de cette page
            executerAction(methode) {
              methode(this)
            }

          }
        }
        */
        exporter: {
          label: "Exporter",
          disabled: true,
          async methode(contexte) {
            let response = await exporterMouvements(contexte.etabCourantId, contexte.formatDateAvecTirets(contexte.debut), contexte.formatDateAvecTirets(contexte.fin))
            lancerTelechargementDeResponse(response)
          }
        },
        // Appeler "this.actions.initialiser(this)"" dans mounted() => génère les tableaux d'actions utilisés pour afficher les boutons à gauche et à droite de la barre d'actions
        barreGauche: [],
        barreDroite: [],
        initialiser(contexte) {
          contexte.actions.barreGauche = [ contexte.actions.exporter ]
        },
        // A gérer en cas de boutons à activer/désactiver (créer une méthode par groupe de boutons à activer/désactiver)
        disable(actions, disabled) {
          let actionsATraiter = [ actions.exporter ]
          actionsATraiter.forEach(a => a.disabled = disabled)
        }
      }
    }
  },
  computed: {
    ...mapGetters("definitions", ["ingredients"]),
    lots() {
      let lots = []
      this.ingredient.lots.forEach(lot => {
        let lotTrouve = null
        if (!this.detaillerLots) {
          lotTrouve = lots.find(l => (l.lot?.reference || l.lot?.designationFournisseur?.toLowerCase()) === (lot.lot?.reference || lot.lot?.designationFournisseur?.toLowerCase()))
          if (lotTrouve) {
            lotTrouve.quantiteInitiale += lot.quantiteInitiale
            lotTrouve.quantiteEntree += lot.quantiteEntree
            lotTrouve.quantiteSortie += lot.quantiteSortie
            lotTrouve.quantiteFinale += lot.quantiteFinale
          }
        }
        if (!lotTrouve) {
          lots.push({
            lot: {
              id: lot.lot.id,
              reference: lot.lot.reference,
              designationFournisseur: lot.lot.designationFournisseur,
              uc: lot.lot.uc,
              NumLot: this.detaillerLots ? lot.lot.NumLot : null,
              dlcDluo: this.detaillerLots ? lot.lot.dlcDluo : null
            },
            quantiteInitiale: lot.quantiteInitiale,
            quantiteEntree: lot.quantiteEntree,
            quantiteSortie: lot.quantiteSortie,
            quantiteFinale: lot.quantiteFinale
          })
        }
      })
      if (this.filtres.rechercheIngredients.selection) {
        lots = lots.filter(l => rechercherMultiTexte(l.lot?.reference + " " + l.lot?.designationFournisseur + " " + l.lot?.NumLot, this.filtres.rechercheIngredients.selection))
      }
      return lots
    }
  },
  methods: {
    periodeSelectionnee(dateDebut, dateFin) {
      this.ingredient = { lots: [] }
      this.actions.disable(this.actions, true)
      if (dateDebut && dateFin) {
        return obtenirMouvementsStockPeriode(this.formatDateAvecTirets(dateDebut), this.formatDateAvecTirets(dateFin), this.etabCourantId)
        .then(response => {
          this.ingredient.lots = response.data
          this.multiLots = this.ingredient?.lots?.length > 1
          this.actions.disable(this.actions, false)
          return
        })
      }
    },
    anomalieStock(quantiteInitiale, entrees, sorties, quantiteFinale) {
      return arrondiNombre(quantiteFinale) !== arrondiNombre(quantiteInitiale + entrees + sorties)
    },
    appliquerFiltre(filtrer) {
      // Nécessaire pour faire fonctionner les filtres de la barre de filtres
      filtrer(this)
    },
    executerAction(methode) {
      // Nécessaire pour faire fonctionner les actions de la barre d'actions'
      methode(this)
    }
  },
   mounted() {
    this.filtres.initialiser(this)
    this.actions.initialiser(this)
  }
}
</script>
