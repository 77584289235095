export default class userInfo {
  constructor(rawData = {}) {
    this.id = rawData.id
    this.email = rawData.email
    this.userName = rawData.userName
    this.etablissements = rawData.etablissements
    this.options = rawData.options
    this.aOptionHorsMarche = rawData.aOptionHorsMarche
    this.aOptionFournisseursLocaux = rawData.aOptionFournisseursLocaux
    this.aOptionValidationCommande = rawData.aOptionValidationCmd
    this.parent = rawData.parent
    this.parentId = rawData.parentId
    this.afficherPopupInfo = rawData.afficherPopupInfo
  }
}
