import Vue from 'vue'
import { getProduitsPourRechercheComplete } from '@/api/gpao/mercuriales'

const fournisseurs = {
  namespaced: true,
  state: {
    produitsUtilisateur: []
  },
  getters: {
    produitsFamille: (state) => (familleId) => {
      return state.familles.find(item => item.id === familleId)
    },
    getProduit: state => id => {
      return state.produitsUtilisateur.find(p => p.id === id)
    },
    getProduits: state => {
      return state.produitsUtilisateur
    }
  },
  actions: {
    clear({ commit }) {
      commit('clearState')
    },
    async produitsUtilisateurCourant({ commit }, etabCourantId) {
      return getProduitsPourRechercheComplete(etabCourantId)
      .then(function (response) {
        commit('setItems', { key: 'produitsUtilisateur', data: response.data })
        return response
      })
      .catch(function (error) {
        console.log(error)
      })
    }
  },
  mutations: {
    clearState(state) {
      state.produitsUtilisateur = []
      state.familles = []
    },
    setItems(state, items) {
      Vue.set(state, items.key, items["data"])
    }
  }
}

export default fournisseurs
