import _ from 'lodash'


export const objetVide = {
  methods: {
    objetVide(obj) {
      return Object.entries(obj).length === 0 && obj.constructor === Object
    }
  }
}

export const arrayEmpty = {
  methods: {
    arrayEmpty(obj) {
      return obj.length === 0
    }
  }
}

export const arrayToSentence = {
  methods: {
    arrayToSentence(arr) {
      return arr.slice(0).join(', ')
    }
  }
}

export const arraysDifference = {
  methods: {
    arraysDifference(a1, a2) {
      let a = [],
        diff = []
      for (let i = 0; i < a1.length; i++) {
        a[a1[i]] = true
      }
      for (let i = 0; i < a2.length; i++) {
        if (a[a2[i]]) {
          delete a[a2[i]]
        } else {
          a[a2[i]] = true;
        }
      }
      for (let k in a) {
        diff.push(k)
      }
      return diff
    }
  }
}


// obsolete, remplacé par connecteData/userId
export const userId = {
  computed: {
    userId() {
      return this.$store.getters.utilisateurCourant.id
    }
  }
}



export const closeModal = {
  methods: {
    closeModal(modalId) {
      // eslint-disable-next-line vue/custom-event-name-casing
      this.$root.$emit('bv::hide::modal', modalId)
    }
  }
}

/* Fonctionne par l'appel d'une computed du composant parent sur donneesModifiees */
export const alerteDonneesModifiees = {
  data() {
    return {
      donneesInitiales: undefined
    }
  },
  methods: {
    checkDonneesModifiees() {
      console.log('ADM:checkManuel')
      if (this.donneesModifiees) {
        return this.$swal(this.configAlerte)
          .then((result) => {
            if (result.value) {
              if (this.recette) {
                this.donneesInitiales = _.cloneDeep(this.recette)
              }
              if (this.menu) {
                this.donneesInitiales = _.cloneDeep(this.menu)
              }
            }
            return
          })
      }
      return Promise.resolve()
    }
  },
  async beforeRouteLeave(to, from, next) {
    console.log('ADM:checkRoute')
    if (this.verrouillerNavigation) {
      this.alerteErreur("La navigation est verrouillée, vous devez utiliser le bouton 'Annuler' ou le bouton 'Enregistrer' sur la page pour pouvoir reprendre la navigation normalement.")
      return
    }
    else if (this.donneesModifiees) {
      let result = await this.alerteConfirmationEnregistrement()
      if (result) {
        try {
          // Enregistre les modifications en base de données : nécessite de créer la fonction "enregistrerDonneesModifiees()" dans le composant parent
          await this.enregistrerDonneesModifiees()
        } catch {
          console.error("erreur beforeRouteLeave DonneesModifiees")
          return
        }
      }
    }
    next()
  }
}


export const getLundiDeLaSemaine = {
  methods: {
    getLundiDeLaSemaine(date) {
      var thisDay = date
      var getLundi = (d) => {
        d = new Date(d)
        var day = d.getDay()
        var diff = d.getDate() - day + (day == 0 ? -6 : 1)
        return new Date(d.setDate(diff))
      }
      return getLundi(thisDay)
    }
  }
}

export const getDimancheDeLaSemaine = {
  methods: {
    getDimancheDeLaSemaine(date) {
      var thisDay = date
      var getDimanche = (d) => {
        d = new Date(d)
        var day = d.getDay()
        var diff = d.getDate() - day + (day == 0 ? -6 : 1)
        var monday = new Date(d.setDate(diff))
        return new Date(monday.setDate(monday.getDate() + 6))
      }
      return getDimanche(thisDay)
    }
  }
}

export const getDimancheDeLaSemainePrecedente = {
  methods: {
    getDimancheDeLaSemainePrecedente(date) {
      let d = new Date(date)
      let diff = d.getDate() - 7
      let semDerniere = new Date(d.setDate(diff))
      return this.getDimancheDeLaSemaine(semDerniere)
    }
  }
}