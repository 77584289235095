<template >
<div class="container-fluid">  
  <BarreFiltres :filtres="filtres" @appliquer-filtre="appliquerFiltre($event)" />  
  <div class="flex-center-center">
    <div class="card col-12 col-xl-10 col-xxl-8 height-responsive">
      <div class="card-header" >
        Productions - Documentation 
      </div>
      <div class="card-container height-responsive-body height-responsive-1b">

        <section id="sectionProduction">
          <div class="header header-tertiary">Création d'une production</div>
          <div class="card-body">
            <p>Pour lancer une production, cliquez sur "Créer une mise en production".</p>
            <div class="container mb-3">
              <img class="border-black border-rounded w-100" src="../../assets/images/documentation/production/suivi/creationProduction1.png" alt="Illustration page production">
            </div>
            <p>Si vous sélectionnez une période qui dispose d'un menu, celui-ci s'intégrera directement dans le champ 'structure de menus à inclure'. Vous avez ensuite la possibilité d'indiquer les services concernés.</p>
            <div class="container mb-3">
              <img class="border-black border-rounded w-100" src="../../assets/images/documentation/production/suivi/creationProduction2.png" alt="Illustration page production">
            </div>
            <p>Quand vous cliquez sur "créer", une fenêtre s'affiche pour que vous puissiez attribuer un nom à votre production ainsi qu'une DLC. </p>
            <div class="flex-center-center mb-3">
              <div class="col-8">
                <img class="border-black border-rounded w-100" src="../../assets/images/documentation/production/suivi/fenetreCreationProduction.png" alt="Illustration page production">
              </div>
            </div>
            <p>Elle sera répertoriée dans "Productions en cours". Cliquez dessus pour accéder à des options supplémentaires et suivez les étapes indiquées.</p>
            <div class="container mb-3">
              <img class="border-black border-rounded w-100" src="../../assets/images/documentation/production/suivi/etapeMiseEnProd.png" alt="Illustration des étapes pour la mise en production">
            </div>
            <p>Lorsque vous atteignez l'étape numéro 2, vous avez toujours la possibilité d'ajuster les effectifs en accédant à "Menus - Suivi". Une fois que vous avez saisi les nouveaux effectifs, effectuez le recalcul des documents pour les mettre à jour. </p>
            <p>Vous pouvez choisir parmi différentes options d'exportation, soit en format PDF ou en Excel.</p> 
            <p>Si vous cliquez sur confirmer la sortie de stock, un message vous demande confirmation de la sortie de stock, correspondant à votre production</p>           
          </div>
        </section>

        <section id="sectionSupression">
          <div class="header header-tertiary">Supression d'un production</div>
          <div class="card-body">          
            <p>Une fois créée, vous pouvez supprimer une production. Pour cela, reportez-vous à la liste des productions en cours, cliquez sur celle que vous souhaitez supprimer.</p>
            <p>En bas de l'écran un champ s'affiche avec sur la gauche un bouton supprimer.</p>
            <div class="container mb-3">
              <img class="border-black border-rounded w-100" src="../../assets/images/documentation/production/suivi/suppressionProduction.png" alt="Illustration supression production">
            </div>
          </div>
        </section>
                
        <section id="sectionArchive" >
          <div class="header header-tertiary">Archive</div>
          <div class="card-body">
            <p>Une fois votre production réalisée, utilisez le bouton numéro 3 pour archiver votre production.</p>
            <p>Dans le menu "Production", vous avez la possibilité de consulter vos productions clôturées dans le sous-menu "Archive"</p> 
            <div class="container mb-3">
              <img class="border-black border-rounded w-100" src="../../assets/images/documentation/production/archive/archiveProduction.png" alt="Illustration archive production">
            </div>
            <p>Vous pouvez également télécharger les documents suivants :</p>
            <ul class="puces">
              <li>La fiche de production</li>
              <li>La fiche de sortie</li>
              <li>Le bon de livraison</li>
            </ul>
          </div> 
        </section>  
      </div>
    </div>     
  </div>
</div>
</template>

<script>
import BarreFiltres from '@/components/Divers/BarreFiltres'

export default {
  name: "DocProductions",
   components: {
    BarreFiltres
  },
  data() {
    return {
      filtres: {
        sections: {
          label: "Choix de votre recherche...",
          champ: "select",
          options: [
            {valeur: 'sectionProduction', texte: `Suivi: Création d'une production`},
            {valeur: 'sectionSupression', texte: `Suivi: Supression d'une production`},  
            {valeur: 'sectionArchive', texte: `Archive`},           
          ],
          selection: [],
          filtrer(contexte) {
            let filtre = contexte.filtres.sections
            if (filtre.selection) {
              let sectionSelectionnee = document.getElementById(filtre.selection.valeur);
              if (sectionSelectionnee) {
                sectionSelectionnee.scrollIntoView();
              }  
            }
            
          }
        },
        // Appeler "this.filtres.initialiser(this)" dans mounted() => génère le tableau de filtres utilisés pour afficher les filtres dans la barre de filtres
        barreGauche: [],
        barreDroite: [],
        initialiser(contexte) {
          contexte.filtres.barreGauche = [ contexte.filtres.sections ]
        }
      },      
    }
  }, 
 methods: {
    appliquerFiltre(filtrer) {
      // Nécessaire pour faire fonctionner les filtres de la barre de filtres
      filtrer(this)
    },
  },
  mounted() {
    this.filtres.initialiser(this)
  }
}
</script>
