<template>
  <div v-if="filtres && ((filtres.barreGauche && filtres.barreGauche.length) || (filtres.barreDroite && filtres.barreDroite.length))" class="card" :key="'filtres' + key">
    <div class="card-body flex-center-space-between">
      <!-- Filtres de gauche -->
      <div class="flex-start barre-filtres-gauche">
        <div v-for="filtre in filtres.barreGauche" :key="filtre.label">
          <OptionsBarreFiltres :filtre="filtre" :disabled="disabled" @appliquer-filtre="appliquerFiltre($event)" @executer-action="executerAction($event)" />
        </div>
      </div>
      <!-- Filtres de droite -->
      <div class="flex-end barre-filtres-droite">
        <div v-for="filtre in filtres.barreDroite" :key="filtre.label">
          <OptionsBarreFiltres :filtre="filtre" :disabled="disabled" @appliquer-filtre="appliquerFiltre($event)" @executer-action="executerAction($event)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OptionsBarreFiltres from '@/components/Divers/OptionsBarreFiltres'

export default {
  name: "BarreFiltres",
  props: [ "filtres", "disabled" ],
  components: { OptionsBarreFiltres },
  data() {
    return {
      key: 1
    }
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    async executerAction(action) {
      await this.appliquerFiltre(action)
      this.key += 1
    },
    appliquerFiltre(filtrer) {
      this.$emit("appliquer-filtre", filtrer)
    },
  }
}
</script>
