import service from '@/helpers/serviceGPAO'

export function chargerCategories(etablissementId) {
  return service.get('plansAlimentaires/gemrcn/categories/' + etablissementId)
}
export function chargerConfigurations(planAlimentaireId) {
  return service.get('plansAlimentaires/gemrcn/configurations/' + planAlimentaireId)
}
export function enregistrerCategorie(categorie, etablissementId) {
  //la même url est utilisée pour les créations (Id vide) et màj (Id renseigné)
  return service.post('plansAlimentaires/gemrcn/categorie/modifier/' + etablissementId, categorie)
}
export function supprimerConfiguration(categorie, etablissementId) {
  return service.delete('plansAlimentaires/gemrcn/categorie/configuration/supprimer/' + categorie.id + '/' + etablissementId)
}
export function supprimerCategorie(categorie) {
  return service.delete('plansAlimentaires/gemrcn/categorie/supprimer/' + categorie.id)
}

export function chargerPlan(planAlimentaireId) {
  return service.get('plansAlimentaires/detail/' + planAlimentaireId)
}
export function chargerPlans(etablissementId) {
  return service.get('plansAlimentaires/' + etablissementId)
}
export function enregistrerPlan(plan, etablissementId) {
  //la même url est utilisée pour les créations (Id vide) et màj (Id renseigné)
  return service.post('plansAlimentaires/modifier/' + etablissementId, plan)
}
export function enregistrerConfigurationPlan(plan, etablissementId) {
  // Enregistre la sélection des catégories GEMRCN sur chaque composante du plan alimentaire
  return service.post('plansAlimentaires/enregistrer/' + etablissementId, plan)
}
export function dupliquerPlanAlimentaire(nom, plan) {
  return service.post('plansAlimentaires/dupliquer/' + nom, plan)
}
export function supprimerPlan(plan) {
  return service.delete('plansAlimentaires/supprimer/' + plan.id)
}
