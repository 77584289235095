import Vue from 'vue'

const commandes = {
  namespaced: true,
  state: {
    dateDebutEnMemoire: '',
    dateFinEnMemoire: '',
    optionsImpression: null,
    filtresEnMemoire: {
      statutCommandeEnMemoire: [],
      dateCreationEnMemoire: {},
      periodeLivraisonEnMemoire: {},
      periodeMenuEnMemoire: {},
    }
  },
  mutations: {
    setDateDebutEnMemoire(state, dateDebut) {
      Vue.set(state, 'dateDebutEnMemoire', dateDebut)
    },
    setDateFinEnMemoire(state, dateFin) {
      Vue.set(state, 'dateFinEnMemoire', dateFin)
    },
    setOptionsImpression(state, options) {
      Vue.set(state, 'optionsImpression', options)
    },
    setFiltresEnMemoire(state, item) {
      Vue.set(state.filtresEnMemoire, item.key, item["data"])
    }
  }
}
export default commandes
