import Vue from 'vue'

const uxHelpers = {
  namespaced: true,
  state: {
    showSpinner: false,
    mercurialesChargees: false
  },
  actions: {
    afficherAttente({commit})
    {
      commit('showSpinner')
    },
    cacherAttente({commit})
    {
      commit('hideSpinner')
    }
  },
  getters: {
    spinnerShown(state) {
      return state.showSpinner
    },
    mercurialesChargees(state) {
      return state.mercurialesChargees
    }
  },
  mutations: {
    showSpinner(state) {
      Vue.set(state, 'showSpinner', true)
    },
    hideSpinner(state) {
      Vue.set(state, 'showSpinner', false)
    },
    loadingMercuriales(state) {
      Vue.set(state, 'mercurialesChargees', false)
    },
    loadedMercuriales(state) {
      Vue.set(state, 'mercurialesChargees', true)
    }
  }
}

export default uxHelpers
