<template>
  <div>
    <div v-if="familles && familles.length > 1">
      <v-select v-model="optionFamilleSelectionnee" :options="optionsFamilles" value="code" label="nom" :clearable="false" @input="selectFamille(optionFamilleSelectionnee)"></v-select>
    </div>
    <div v-else>
      <span v-if="afficherTexte">{{ familleNomComplet(familleSelection) }}</span>
      <img v-else :src="'/assets/images/icones/' + familleSelection + couleurChoisie + '.png'" alt="" style="width:28px;margin-left:10px;margin-right:5px"
        v-tooltip="{ content: familleNomComplet(familleSelection), placement: 'top' }" @click="$root.$emit('bv::hide::tooltip')">
    </div>
  </div>
</template>

<script>
export default {
 /* couleur : -NOIR ou -BLANC*/
  props: { familleSelectionnee: String, familles: Array, ingredient: Object, couleur: String, familleIdSelectionnee: Number, afficherTexte: Boolean },
  data() {
    return {
      optionsFamilles: [],
      optionFamilleSelectionnee: {},
      familleSelection: '',
      couleurChoisie: '-NOIR'
    }
  },
  methods: {
    familleNomComplet(code) {
      if (code === "Famille...") {
        return "Famille..."
      }

      if (!code) {
        return ""
      }

      if (code === "recette") {
        return "Recette"
      }

      let famille = this.$store.state.definitions.familles.find(item => item.code === code)
      if (famille) {
        return famille.nom
      }
      let gamme = this.$store.state.definitions.gammes.find(item => item.code === code)
      if (gamme) {
        return gamme.nom
      }
    },
    selectFamille(evt) {
      this.familleSelection = evt.code
      this.$emit('select-famille', {famille: this.familleSelection, composant: this.ingredient})
    }
  },
  mounted() {
    this.couleurChoisie = this.couleur || '-NOIR'
    if (this.familleSelectionnee) {
      this.familleSelection = this.familleSelectionnee
    } else if (this.familleIdSelectionnee) {
      this.familleSelection = this.$store.state.definitions.familles.find(item => item.id === this.familleIdSelectionnee).code
    } else if (this.familleSelectionnee !== "") {
      this.familleSelection = "recette"
    } else {
      this.familleSelection = "Famille..."
    }
    this.familles?.forEach(f => this.optionsFamilles.push({ code: f, nom: this.familleNomComplet(f) }))
    this.optionFamilleSelectionnee = this.optionsFamilles.find(f => f.code === this.familleSelection)
  }
}
</script>

<style lang="scss" scoped>
.pictos-dropdown {
  background: white !important;
  border: 0;
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.06);
}
</style>
