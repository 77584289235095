<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <!-- <router-link
          class="btn btn-primary"
          :to="{name: 'MenusPlansEdition'}">
          <i class="fas fa-plus"></i> Plan
        </router-link> -->
        <button class="btn btn-primary" @click="nouveauPlanDeMenu()">
          <i class="fas fa-plus"></i> Plan
        </button>
      </div>
      <b-modal :ref="'modal-nouveauPlanDeMenu'" size="xl" hide-header hide-footer>
        <MenusPlansEdition @close-modal="closeModal" />
      </b-modal>
      <div class="col-12" v-for="(item, index) in menuPlans" :key="index">
        <!-- <div v-if="routeNameMenu"><a href="#" @click.prevent="creerMenu(item.id)">{{item.nom}}</a></div>
        <div v-else> -->
          <div>
            <b-button v-b-modal="'modalPlanDeMenu' + item.id">{{item.id}} - {{item.nom}}</b-button>
          </div>
          <b-modal :id="'modalPlanDeMenu' + item.id" :ref="'modalPlanDeMenu' + item.id" size="xl" hide-header hide-footer>
            <PlanEdition @close-modal="closeModal(item.id)" :planId="item.id" />
          </b-modal>
          <!-- <div @click="supprimer(item.id)">supprimer</div> -->
        </div>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import { connecteData } from "@/mixins/connecteData"
import { supprimerMenuPlan } from '@/api/gpao/menus'
import PlanEdition from '@/components/Menus/PlanEdition'

export default {
  mixins: [  connecteData ],
  components: {
    PlanEdition
  },
  data() {
    return {
      menuPlans: []
    }
  },
  computed: {
    routeNameMenu() {
      return this.$route.name === 'Menus'
    }
  },
  methods: {
    nouveauPlanDeMenu() {
      this.$refs['modal-nouveauPlanDeMenu'].show()
    },
    closeModal(id) {
      if (id) {
        this.$refs['modalPlanDeMenu'].hide()
      } else {
        this.$refs['modal-nouveauPlanDeMenu'].hide()
      }
    },
    supprimer(id) {
      return this.alerteConfirmation("Supprimer la structure de menu", "Souhaitez-vous supprimer cette structure de menu ?<br />Vous perdrez tous les menus saisis avec cette structure.")
        .then((result) => {
          if (result) {
            // eslint-disable-next-line promise/no-nesting
            return supprimerMenuPlan(id)
              .then(() => {
                this.$router.go()
                return
              })
              .catch((error) => {
                console.error(error)
              })
          }
          return
        })
    }
  },
  created() {
    return this.$store.dispatch('menus/chargerTousLesPlans', this.etabCourantId)
      .then((response) => {
        this.menuPlans = response.data
        return
      })
  }
}
</script>

<style>

</style>
