<template>
  <div>
    <b-modal v-model="montrerModalSynthese" size="md" title="Export de synthèse"
      ok-title="Exporter"
      cancel-title="Annuler"
      no-close-on-backdrop
      @hide="cacherModalSynthese"
      @ok="synthesePortage()">
      <template slot="default">
        <div class="form-group">
          <v-date-picker class="date-picker" is-range :columns="2" :step="1" v-model="periodeSyntheseSelectionnee" :popover="{ visibility: 'click' }" @input="changerPeriodeSynthese($event.start, $event.end)">
            <template v-slot="{ inputValue, inputEvents }">
              <input :value="inputValue.start || inputValue.end ? inputValue.start + ' au ' + inputValue.end : 'Sélectionnez une période'" v-on="inputEvents.start" />
            </template>
          </v-date-picker>
          <div class="error" v-if="!$v.lundiDeLaSemaineOrigine.required || !$v.dimancheDeLaSemaineOrigine.required">Ce champ est requis</div>
          <em>(4 semaines maximum seront extraites)</em>
        </div>
      </template>
    </b-modal>

    <div class="container-fluid">
      <BarreFiltres :filtres="filtres" @appliquer-filtre="appliquerFiltre($event)" />
      <BarreActions :actions="actions" @appliquer-action="executerAction($event)" />

      <div class="card card-minimal table-scroll height-responsive-100">
        <table class="table table-sm table-bordered table-header">
          <thead>
            <th>Période du {{ jour && jour.toLocaleDateString("FR-fr") }} au {{ jour && getDimancheDeLaSemaine(jour) && getDimancheDeLaSemaine(jour).toLocaleDateString("FR-fr") }}</th>
          </thead>
        </table>
        <div class="table-body">
          <table class="table table-sm table-bordered">
            <thead>
              <tr style="background:white">
                <th>Lieu</th>
                <th>Menu</th>
                <th style="text-align:center;">Service</th>
                <th style="text-align:center;">Régime</th>
                <th>Texture</th>
                <th title="Présence de plats témoins">T.</th>
                <th v-for="(journee) in (effectifs[0] && effectifs[0].parJour)" :key="journee.date" style="text-align:center;">
                  {{formatHumanDate(journee.date)}}
                </th>
              </tr>
            </thead>
            <tbody v-if="effectifs.length" ref="body" :key="key">
              <tr v-if="enChargement || !effectifsFiltres[0] ">
                <td colspan="13">
                  <img v-if="enChargement" alt="en attente" src="/assets/images/spinners/cooking-blue-court.gif" style="display:block;margin-left:auto;margin-right:auto" />
                </td>
              </tr>
              <template v-else>
                <tr v-for="(ligne, iLigne) in effectifsFiltres" :key="iLigne" :style="ligne.structure.actif ? '' : 'color:lightgrey;font-style:italic;'">
                  <td>{{ nomLieu(ligne) }}</td>
                  <td>{{ nomStructure(ligne) }}
                    <span v-if="ligne.gererParMenu">
                      <i class='fas fa-users containerGereParMenu' title="Géré par le menu (le bouton 'Appliquer sur les menus' ne modifiera pas les effectifs du menu)">
                      <i class="fas fa-ban nestedGereParMenu"></i>
                      </i>
                    </span>
                  </td>
                  <td>{{nomService(ligne) }}</td>
                  <td style='font-size:smaller'>{{ nomRegime(ligne) }}</td>
                  <td style='font-size:smaller'>{{ nomTexture(ligne) }}</td>
                  <td>
                    <i v-if="ligne.aPlatTemoin" class="icone-platTemoin" style="font-size:1.3em;text-align:center"></i>
                  </td>
                  <td v-for="(journee, iJournee) in ligne.parJour" :key="iJournee" style="text-align:center;">
                    <template v-if="journee && lieuOuvert(ligne.lieuId, iJournee + 1)">
                      <template v-if="ligne.structure.facturables">
                        <span style='font-size:smaller' title='Effectif de production'>{{journee.effectif}}</span>
                        <span style='font-size:smaller' v-for="(facturable, iFacturable) in ligne.structure.facturables" :key="facturable.id" :title="facturable.libelle + ' sur un total de  ' + journee.effectif">
                          {{facturable.id.substring(0,1)}}
                          <InputNumber v-model='journee.details[facturable.id]' typeFiltre="quantiteEntiere" style="width:22px" :data-key="journee.key + '-' + facturable.id" :disabled="enEnregistrement || enChargement || !ligne.structure.actif" :class="{parDefaut: journee.id === 0 && !journee.effectifModifie, nombreModifie: journee.effectifModifie, inputEffectif: true}" @move="moveEffectif($event, iLigne, iJournee, iFacturable)" @input="inputEffectif($event, ligne, journee)" />
                        </span>
                      </template>

                      <InputNumber v-else typeFiltre="quantiteEntiere" :data-key="journee.key" v-model="journee.effectif" :disabled="enEnregistrement || enChargement || !ligne.structure.actif" :class="{parDefaut: journee.id === 0 && !journee.effectifModifie, nombreModifie: journee.effectifModifie, inputEffectif: true}" @move="moveEffectif($event, iLigne, iJournee)" @input="inputEffectif($event, ligne, journee)" />
                    </template>
                  </td>

                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarreFiltres from '@/components/Divers/BarreFiltres'
import BarreActions from '@/components/Divers/BarreActions'
import { connecteData } from "@/mixins/connecteData"
import { formatageDate } from '@/mixins/dateFormats'
import { setEffectifPortage, synthesePortage, getLieuxPortage, getEffectifPortageParLigne } from '@/api/gpao/user'
import { appliquerEffectifPortage } from '@/api/gpao/menus'
import InputNumber from '@/components/Inputs/InputNumber'
import { lancerTelechargementDeResponse } from "@/helpers/utils"
import { alerteDonneesModifiees, } from "@/mixins/index"
import { xlsx } from "@/mixins/xlsx"
import { showAlerts } from '@/mixins/alerts'
import { getLundiDeLaSemaine, getDimancheDeLaSemaine } from '@/mixins/index'
import { mapGetters } from "vuex"
import _ from 'lodash'
import { required } from "vuelidate/lib/validators"

export default {
  name: "PageEffectifPortage",
  mixins: [showAlerts, connecteData, formatageDate, alerteDonneesModifiees, getLundiDeLaSemaine, getDimancheDeLaSemaine, xlsx],
  components: { BarreFiltres, BarreActions, InputNumber },
  data() {
    return {
      enChargement: true,
      enEnregistrement: false,
      filtreTexte: undefined,
      filtreRadio: 0,
      jour: undefined,
      effectifs: [],
      effectifsFiltres: [],
      lieuxPortage: [],
      structures: [],
      donneesModifiees: false,
      dates: [],
      debugRadio: false,//true affiche les lignes supprimées par le filtre, "full" affiche aussi les lignes non supprimées
      key: 1,
      montrerModalSynthese: false,
      lundiDeLaSemaineOrigine: undefined,
      dimancheDeLaSemaineOrigine: this.getDimancheDeLaSemaine(this.jour),
      periodeSyntheseSelectionnee: {},
      filtres: {
        semaineMenus: {
          label: "Semaine de menus",
          champ: "datepicker",
          selection: null,
          customTextSemaine: true,
          //disabled: this.enEnregistrement || this.enChargement,
          initialiser(contexte) {
            contexte.filtres.semaineMenus.selection = contexte.jour
          },
          async filtrer(contexte, date) {
            let filtre = contexte.filtres.semaineMenus
            if (filtre.disabled) {
              return
            }

            if (contexte.donneesModifiees) {
              let result = await contexte.alerteConfirmationEnregistrement()
              if (result) {
                // Enregistrement des modifications
                await contexte.enregistrerDonneesModifiees()
                contexte.donneesInitiales = _.cloneDeep(contexte.effectifs)
                contexte.donneesModifiees = false
              } else {
                // Annulation des modifications
                await contexte.rechargerEffectifs()
              }
            }

            if (date) {
              filtre.selection = date
            }
            contexte.surChangementDate(filtre.selection)
          }
        },
        navigationGauche: {
          picto: "fas fa-chevron-left",
          champ: "btn",
          //disabled: this.enEnregistrement || this.enChargement,
          filtrer(contexte) {
            let filtreSemaineMenus = contexte.filtres.semaineMenus
            let date = filtreSemaineMenus.selection

            filtreSemaineMenus.filtrer(contexte, new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7))
          }
        },
        navigationDroite: {
          picto: "fas fa-chevron-right",
          champ: "btn",
          //disabled: this.enEnregistrement || this.enChargement,
          filtrer(contexte) {
            let filtreSemaineMenus = contexte.filtres.semaineMenus
            let date = filtreSemaineMenus.selection

            filtreSemaineMenus.filtrer(contexte, new Date(date.getFullYear(), date.getMonth(), date.getDate() + 7))
          }
        },
        nomLieu: {
          label: "Lieu",
          champ: "input",
          type: "text",
          selection: "",
          //disabled: this.enEnregistrement || this.enChargement,
          initialiser(contexte) {
            contexte.filtres.nomLieu.selection = contexte.filtreTexte
          },
          filtrer(contexte) {
            contexte.filtreTexte = contexte.filtres.nomLieu.selection
            contexte.filtrerEffectifs()
          }
        },
        effectifsNuls: {
          label: "Effectifs",
          champ: "select",
          options: [
            { valeur: 0, texte: "Tous les effectifs" },
            { valeur: 1, texte: "Avec effectifs non nuls" },
            { valeur: 2, texte: "Avec effectifs nuls uniquement" }
          ],
          selection: 0,
          //disabled: this.enEnregistrement || this.enChargement,
          initialiser(contexte) {
            contexte.filtres.effectifsNuls.selection = contexte.filtres.effectifsNuls.options.find(o => o.valeur === contexte.filtreRadio) || null
          },
          filtrer(contexte) {
            contexte.filtreRadio = contexte.filtres.effectifsNuls.selection.valeur
          }
        },
        // Appeler "this.filtres.initialiser(this)" dans mounted() => génère le tableau de filtres utilisés pour afficher les filtres dans la barre de filtres
        barreGauche: [],
        barreDroite: [],
        initialiser(contexte) {
          contexte.filtres.barreGauche = [
            contexte.filtres.navigationGauche,
            contexte.filtres.semaineMenus,
            contexte.filtres.navigationDroite,
            contexte.filtres.effectifsNuls,
            contexte.filtres.nomLieu
          ]
          /*contexte.filtres.barreDroite = [
            contexte.filtres.navigationGauche,
            contexte.filtres.semaineMenus,
            contexte.filtres.navigationDroite
          ]*/
          contexte.filtres.barreGauche.forEach(f => {
            if (f.initialiser) {
              f.initialiser(contexte)
            }
          })
          /*contexte.filtres.barreDroite.forEach(f => {
            if (f.initialiser) {
              f.initialiser(contexte)
            }
          })*/
        }
      },
      actions: {
        // Boutons à afficher dans la barre d'action
        // Exemple :
        /*
        nomAction: {
          label: Label affiché sur le bouton (string),
          disabled: Etat actif/inactif par défaut du bouton (bool),
          class: Classe CSS (btn-primary, btn-success, btn-warning ou btn-danger) à appliquer sur le bouton (par défaut : "btn-primary" est appliqué => il s'agit du bouton bleu Agap'pro) (string)
          methode(contexte) {
            // => contexte équivaut à this (mais this ne fonctionne pas car l'initialisation de data n'est pas encore terminée, donc data n'est pas encore rattaché à this)
            traitements à exécuter lors du clic sur le bouton
            les traitements définis dans cette page doivent être appelés sous la forme : contexte.traitement()

            Remarque : pour que le système fonctionne, il faut déclarer la méthode ci-dessous dans la section "methods" de cette page
            executerAction(methode) {
              methode(this)
            }

          }
        }
        */
        exporter: {
          label: "Export Excel",
          methode(contexte) {
            contexte.exporterXlsx()
          }
        },
        exporterSynthese: {
          label: "Synthèse",
          methode(contexte) {
            contexte.afficherModalSynthese()
          }
        },
        appliquerSurMenus: {
          label: "Appliquer sur les menus",
          methode(contexte) {
            contexte.surAppliquerMenu()
          }
        },
        annuler: {
          label: "Annuler",
          disabled: true,
          class: "btn-danger",
          async methode(contexte) {
            let result = await contexte.alerteConfirmation("Annuler les modifications", "Vos modifications seront définitivement perdues.<br/>Souhaitez-vous annuler vos modifications ?")
            if (result) {
              contexte.rechargerEffectifs()
            }
          }
        },
        enregistrer: {
          label: "Enregistrer",
          disabled: true,
          class: "btn-success",
          methode(contexte) {
            contexte.surEnregistrer()
          }
        },
        // Appeler "this.actions.initialiser(this)"" dans mounted() => génère les tableaux d'actions utilisés pour afficher les boutons à gauche et à droite de la barre d'actions
        barreGauche: [],
        barreDroite: [],
        initialiser(contexte) {
          contexte.actions.barreGauche = [ contexte.actions.appliquerSurMenus, contexte.actions.exporter, contexte.actions.exporterSynthese ]
          contexte.actions.barreDroite = [ contexte.actions.annuler, contexte.actions.enregistrer ]
        },
        // A gérer en cas de boutons à activer/désactiver (créer une méthode par groupe de boutons à activer/désactiver)
        disableActionsEnregistrer(actions, disabled) {
          actions.barreGauche.forEach(a => a.disabled = !disabled)
          actions.barreDroite.forEach(a => a.disabled = disabled)
        }/*,
        disableActionsEffectifs(actions, disabled) {
          actions.barreGauche.forEach(a => a.disabled = disabled)
        },*/
      }
    }
  },
  validations: {
    lundiDeLaSemaineOrigine: { required },
    dimancheDeLaSemaineOrigine: { required }
  },
  computed: {
    ...mapGetters("definitions", ["obtenirRegime", "obtenirService", "obtenirTexture"])
  },
  watch: {
    filtreRadio() {
      this.$store.commit('menus/saveFiltreEffectifs', this.filtreRadio)
      this.filtrerEffectifs()
    },
    effectifs() {
      this.disableActions()
    },
    donneesModifiees() {
      this.disableActions()
    },
    enEnregistrement() {
      this.disableActions()
    },
    enChargement() {
      this.disableActions()
    }
  },
  methods: {
    appliquerFiltre(filtrer) {
      // Nécessaire pour faire fonctionner les filtres de la barre de filtres
      filtrer(this)
    },
    executerAction(methode) {
      // Nécessaire pour faire fonctionner les actions de la barre d'action
      methode(this)
    },
    async enregistrerDonneesModifiees() {
      await this.surEnregistrer()
    },
    disableActions() {
      this.actions.disableActionsEnregistrer(this.actions, !this.effectifs?.length || !this.donneesModifiees || this.enEnregistrement || this.enChargement)
      //this.actions.disableActionsEffectifs(this.actions, !this.effectifs?.length || this.donneesModifiees || this.enEnregistrement || this.enChargement)
    },
    changerPeriodeSynthese(dateDebut, dateFin) {
      this.periodeSyntheseSelectionnee.start = this.getLundiDeLaSemaine(dateDebut)
      this.periodeSyntheseSelectionnee.end = this.getDimancheDeLaSemaine(dateFin)
      this.lundiDeLaSemaineOrigine = this.periodeSyntheseSelectionnee.start
      this.dimancheDeLaSemaineOrigine = this.periodeSyntheseSelectionnee.end
    },
    async filtrerEffectifs(silent) {
      var effectifs
      if (!silent) {
        this.enChargement = true
        await this.$nextTick()
      }

      if (this.filtreTexte) {
        effectifs = []
        this.effectifs.forEach(ligne => {
          if (this.nomLieu(ligne).toLowerCase().includes(this.filtreTexte.toLowerCase()))
            effectifs.push(_.cloneDeep(ligne))
        })
      } else {
        effectifs = _.cloneDeep(this.effectifs)
      }

      if (this.filtreRadio) {
        let toRemove = []
        effectifs.forEach(ligne => {//création de la liste de lignes à supprimer
          var pasZero = false
          ligne.parJour.forEach(jour => {
            if (jour.effectif) {
              pasZero = true
            }
          })
          if (this.filtreRadio === 1 && !pasZero) {
            toRemove.push(ligne)
            if (this.debugRadio) {
              console.log(ligne, "ne contient que des effectifs nuls, suppression")
            }
          } else if (this.filtreRadio === 2 && pasZero) {
            toRemove.push(ligne)
            if (this.debugRadio) {
              console.log(ligne, "contient des effectifs non nuls, suppression")
            }
          } else if (this.debugRadio === "full") {
            console.log(ligne, "pasZero: " + pasZero + ", filtre: " + this.filtreRadio + ", on garde")
          }
        })
        toRemove.forEach(r => _.remove(effectifs, e => this.memeLigne(e, r)))//suppression
      }

      effectifs.sort((a, b) => {
        if (a.ordreLieu !== b.ordreLieu) {
          return a.ordreLieu - b.ordreLieu
        } else if (a.nomMenu !== b.nomMenu) {
          return a.nomMenu.localeCompare(b.nomMenu)
        } else if (a.ordreService !== b.ordreService) {
          return a.ordreService - b.ordreService
        } else if (a.ordreRegime !== b.ordreRegime) {
          return a.ordreRegime - b.ordreRegime
        } else {
          return a.ordreTexture - b.ordreTexture
        }
      })

      this.effectifsFiltres = effectifs
      this.key += 1
      if (!silent) {
        await this.$nextTick()
        this.enChargement = false
      }
    },
    memeLigne(a, b) {
      return a.lieuId === b.lieuId && a.structureId === b.structureId && a.serviceId === b.serviceId && a.regimeId === b.regimeId && a.textureId === b.textureId
    },
    getEffectif(ligne, journee, effectifs) {
      return effectifs.find(e => this.memeLigne(e, ligne))
        .parJour.find(j => j.date === journee.date)
    },
    getLieu(id) {
      return this.lieuxPortage.find(l => l.id === id)
    },
    nomLieu(ligne) {
      var lieu = this.getLieu(ligne.lieuId)
      return lieu && lieu.libelle || ""
    },
    nomStructure(ligne) {
      var s = ligne.structure && ligne.structure.reduit || ""
      return s;
    },
    nomService(ligne) {
      return this.obtenirService(ligne.serviceId).text || ""
    },
    nomRegime(ligne) {
      return this.obtenirRegime(ligne.regimeId).text || ""
    },
    nomTexture(ligne) {
      return this.obtenirTexture(ligne.textureId).text || ""
    },
    lieuOuvert(lieuId, jour) {
      if (jour === 7) {
        jour = 0
      }
      var lieu = this.getLieu(lieuId)
      return lieu && lieu.joursOuverture.includes(jour)
    },
    moveEffectif(event, iLigne, iJournee, iFacturable) {
      var cible = false
      var vue = this

      function getCible(iL, iJ) {
        var c = vue.effectifsFiltres[iL]
        c = c && c.parJour[iJ]
        return c || {}
      }

      function search() {
        if (event.direction === "left" && iFacturable !== undefined) {
          if (iFacturable === 0) {
            if (iJournee === 0) {
              return false
            }
            iJournee -= 1
            iFacturable = Object.keys(getCible(iLigne, iJournee).details).length - 1
          } else {
            iFacturable -= 1
          }
        } else if (event.direction === "left") {
          if (iJournee === 0) {
            return false
          }
          iJournee -= 1
        } else if ((event.direction === "right" || event.direction === "enter") && iFacturable !== undefined) {
          if (iFacturable === Object.keys(getCible(iLigne, iJournee).details).length - 1) {
            if (iJournee === vue.effectifsFiltres[iLigne].parJour.length - 1) {
              return false
            }
            iJournee += 1
            iFacturable = 0
          } else {
            iFacturable += 1
          }
        } else if (event.direction === "right" || event.direction === "enter") {
          if (iJournee === vue.effectifsFiltres[iLigne].parJour.length - 1) {
            return false
          }
          iJournee += 1
        } else if (event.direction === "up") {
          if (iLigne === 0) {
            return false
          }
          iLigne -= 1
          if (!iFacturable && getCible(iLigne, iJournee).details) {
            iFacturable = 0
          }
        } else if (event.direction === "down") {
          if (iLigne === vue.effectifsFiltres.length - 1) {
            return false
          }
          iLigne += 1
          if (!iFacturable && getCible(iLigne, iJournee).details) {
            iFacturable = 0
          }
        }

        cible = getCible(iLigne, iJournee)
        var key = cible.key
        if (iFacturable !== undefined && cible.details) {
          key += "-" + Object.keys(cible.details)[iFacturable]
        }
        cible = key && vue.$refs.body.querySelector("[data-key='" + key + "']")
        if (cible && cible.disabled) {
          return true
        }
        return !cible
      }

      //console.log(iLigne, iJournee, iFacturable, event.direction)
      while (search()) {
        //console.log("recherche")
      }
      //console.log(iLigne, iJournee, iFacturable)
      if (cible) {
        cible.focus()
      } else {
        //console.log("pas de cible...")
      }
    },
    inputEffectif(val, ligne, journee) {//mise à jour de l'état de l'effectif
      var nouveau = this.getEffectif(ligne, journee, this.effectifs)
      nouveau.effectif = val
      var initial = this.getEffectif(ligne, journee, this.donneesInitiales)
      var mod = initial.effectif !== nouveau.effectif
      nouveau.effectifModifie = mod
      journee.effectifModifie = mod
      if (mod) {
        this.donneesModifiees = true
      } else {
        this.donneesModifiees = this.effectifs.map(l => l.parJour.filter(j => j.effectifModifie).length).reduce((a, b) => a + b) > 0
      }
    },
    surChangementDate(nouvelleDate) {
      this.jour = this.getLundiDeLaSemaine(nouvelleDate)
      this.changerPeriodeSynthese(this.jour, this.getDimancheDeLaSemaine(nouvelleDate))
      this.$store.commit('menus/saveDateEffectifs', this.formatDateAvecTirets(this.jour))
      history.pushState({}, '', "/" + this.etabCourantId + "/effectifs/" + this.formatDateAvecTirets(this.jour))
      this.rechargerEffectifs()
    },
    async surAppliquerMenu() {
      this.enEnregistrement = true
      var jours = []
      this.effectifsFiltres[0].parJour.forEach(x => {
        jours.push(this.formatDatePourApiSansSeparation(x.date))
      })
      await appliquerEffectifPortage(this.etabCourantId, jours)
      this.toastSucces('Effectifs appliqués !')
      this.enEnregistrement = false
    },
    async rechargerEffectifs() {
      this.enChargement = true
      const data = (await getEffectifPortageParLigne(this.etabCourantId, this.dateIso(this.jour))).data
      this.effectifs = data.lignes
      this.structures = data.structures
      this.structures.forEach(x => {
        x.reduit = x.nom
        if (x.nom.toLowerCase().startsWith("menu ")) {
          x.reduit = x.nom.substring(5)
        }
        if (!x.actif) {
          x.reduit = "[Inactif] " + x.reduit
        }
      })

      this.effectifs.forEach(e => {//préparation des ordres de tri
        e.ordreLieu = this.lieuxPortage.find(l => l.id === e.lieuId).ordreLivraison
        e.nomMenu = this.structures.find(m => m.id === e.structureId).nom
        e.ordreService = this.obtenirService(e.serviceId).ordre || 0
        e.ordreRegime = this.obtenirRegime(e.regimeId).ordre || 0
        e.ordreTexture = this.obtenirTexture(e.textureId).ordre || 0
      })

      this.effectifs.forEach(l => {
        let structure = this.structures.find(s => s.id === l.structureId)

        l.structure = structure
        l.parJour.forEach((e, i) => {
          e.key = l.lieuId + "-" + l.structureId + "-" + l.serviceId + "-" + l.regimeId + "-" + l.textureId + "-" + e.date + "-" + i
          if (structure.facturables && structure.facturables.length > 0) {
            const details = {}

            if (e.detailsF && e.detailsF.length) {
              e.detailsF.forEach(d => {
                details[d.factId] = d.eff;
              })
            }
            else {
              structure.facturables.forEach(f => {
                details[f.id] = 0;
              })
            }
            this.$set(e, 'details', details)
          }
        })
      })
      this.donneesInitiales = _.cloneDeep(this.effectifs)
      this.filtrerEffectifs(true)
      this.donneesModifiees = false
      this.enChargement = false
    },
    async surEnregistrer() {
      this.enEnregistrement = true
      let modifies = []
      this.effectifsFiltres.forEach(l => {
        l.parJour.forEach(e => {
          if (e.effectifModifie) {
            var payload = {
              lieuPortageId: l.lieuId,
              structureMenuId: l.structureId,
              serviceId: l.serviceId,
              regimeId: l.regimeId,
              textureId: l.textureId,
              dateEffectif: e.date,
              effectif: e.effectif || 0,
              effectifServi: e.effectifFinal || 0
            };
            if (e.details) {
              payload.details = JSON.stringify(e.details);
            }
            modifies.push(payload)
          }
        })
      })
      await setEffectifPortage(modifies)
      this.rechargerEffectifs()
      this.toastSucces('Effectifs Sauvegardés !')
      this.enEnregistrement = false
    },
    async synthesePortage() {
      this.montrerModalSynthese = false
      this.enEnregistrement = true
      const response = await synthesePortage(this.etabCourantId, this.formatDatePourApiSansSeparation(this.lundiDeLaSemaineOrigine), this.formatDatePourApiSansSeparation(this.dimancheDeLaSemaineOrigine))
      lancerTelechargementDeResponse(response)
      this.enEnregistrement = false
    },
    afficherModalSynthese() {
      this.montrerModalSynthese  = true
    },
    cacherModalSynthese() {
      this.montrerModalSynthese = false
    },
    exporterXlsx() {
      this.enEnregistrement = true
      var allBorders = {//styles
        left: { style: "thin", color: { auto: 1 } },
        right: { style: "thin", color: { auto: 1 } },
        top: { style: "thin", color: { auto: 1 } },
        bottom: { style: "thin", color: { auto: 1 } }
      }
      var styles = {
        head: {
          border: allBorders,
          fill: { fgColor: { rgb: "FF808080" } },
          font: { bold: true, color: { rgb: "FFFFFFFF" } }
        },
        cell: {
          border: allBorders
        },
        cellRight: {
          border: allBorders,
          alignment: { horizontal: "right" }
        }
      }

      var colWidths = []//valeurs par défaut pour les largeurs de colonnes
      for (let i = 0; i <= this.effectifsFiltres[0].parJour.length + 5; i += 1) {
        colWidths.push({ wch: 10 })
      }

      var worksheet = {//en-tête
        "A1": {
          v: "Lieu",
          t: "s",
          s: styles.head
        },
        "B1": {
          v: "Menu",
          t: "s",
          s: styles.head
        },
        "C1": {
          v: "Service",
          t: "s",
          s: styles.head
        },
        "D1": {
          v: "Régime",
          t: "s",
          s: styles.head
        },
        "E1": {
          v: "Texture",
          t: "s",
          s: styles.head
        }
      }
      this.effectifsFiltres[0].parJour.forEach((journee, iJournee) => {
        worksheet[this.xlsx.utils.encode_col(iJournee + 5) + "1"] = {
          v: this.formatHumanDate(journee.date),
          t: "s",
          s: styles.head
        }
        colWidths[iJournee + 5].wch = 12
      })
      worksheet[this.xlsx.utils.encode_col(this.effectifsFiltres[0].parJour.length + 5) + "1"] = {
        v: "Témoin",
        t: "s",
        s: styles.head
      }

      this.effectifsFiltres.forEach((ligne, iLigne) => {//données
        worksheet["A" + (iLigne + 2)] = {
          v: this.nomLieu(ligne),
          t: "s",
          s: styles.cell
        }
        worksheet["B" + (iLigne + 2)] = {
          v: this.nomStructure(ligne),
          t: "s",
          s: styles.cell
        }
        worksheet["C" + (iLigne + 2)] = {
          v: this.nomService(ligne),
          t: "s",
          s: styles.cell
        }
        worksheet["D" + (iLigne + 2)] = {
          v: this.nomRegime(ligne),
          t: "s",
          s: styles.cell
        }
        worksheet["E" + (iLigne + 2)] = {
          v: this.nomTexture(ligne),
          t: "s",
          s: styles.cell
        }
        ligne.parJour.forEach((journee, iJournee) => {
          if (this.lieuOuvert(ligne.lieuId, iJournee + 1)) {
            worksheet[this.xlsx.utils.encode_col(iJournee + 5) + (iLigne + 2)] = {
              v: journee.effectif,
              t: "n",
              s: styles.cell
            }
          } else {
            worksheet[this.xlsx.utils.encode_col(iJournee + 5) + (iLigne + 2)] = {
              v: "",
              t: "n",
              s: styles.cell
            }
          }
        })
        worksheet[this.xlsx.utils.encode_col(this.effectifsFiltres[0].parJour.length + 5) + (iLigne + 2)] = {
          v: ligne.aPlatTemoin ? "OUI" : "",
          t: "s",
          s: styles.cellRight
        }
      })

      worksheet["!cols"] = colWidths

      this.xlsx.saveFile(worksheet, "Portage")//téléchargement du fichier
      this.enEnregistrement = false
    },
    async chargerLieuxPortage() {
      let r = await getLieuxPortage(this.etabCourantId, false)
      let lieux = r.data.lieux
      lieux.forEach(l => {
        if (!l.joursOuverture.length) {
          l.joursOuverture = [0, 1, 2, 3, 4, 5, 6]
        }
      })
      this.lieuxPortage = lieux
    }
  },
  async created() {
    await this.chargerLieuxPortage()
    if (this.$route.params.pour) {
      this.surChangementDate(new Date(this.$route.params.pour))
    } else if (this.$store.state.menus.dateEffectifs) {
      this.surChangementDate(new Date(this.$store.state.menus.dateEffectifs))
    } else {
      this.surChangementDate(this.getLundiDeLaSemaine(new Date()))
    }
    this.filtreRadio = this.$store.state.menus.filtreEffectifs || 0

    this.filtres.initialiser(this)
    this.actions.initialiser(this)
  }
}
</script>

<style scoped lang="scss">
.barreHaut {
  width: 100%;
  min-height: 60px;
  padding: 10px;
  display: flex;
  align-items: flex-start;
}
.filtre {
  width: 150px;
  background-color: white;
  margin-left: 10px;
}
th,
td {
  padding: 10px;
}
.inputEffectif {
  width: 50px;
  border-width: 2px !important;
  border-style: solid !important;
  outline: none;
}
.inputEffectif.parDefaut:not(.nombreModifie) {
  border-style: solid !important;
}
.inputEffectif:not(.parDefaut),
.inputEffectif.nombreModifie {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: solid 2px !important;
}
.inputEffectif.nombreModifie {
  border-color: orange !important;
}
.inputEffectif:not(.nombreModifie) {
  border-color: rgb(40, 167, 69) !important;
}
.boutons {
  margin-top: -5px;
}
.boutons > * {
  margin-top: 5px;
}
.radios {
  color: white;
}
.radios > label {
  white-space: nowrap;
  margin-bottom: 0;
  margin: 0 3px;
}
.radios > label > input {
  height: 13px;
  margin: 0 3px;
}
.containerGereParMenu {
  position: relative
}

.nestedGereParMenu {
  position: absolute;
  top: -2px;
  left: -2px;
  font-size: 150%;
  color: rgba(217, 83, 79, 0.7);
}
</style>

<style lang="scss">
.barreHaut .custom-radio label {
  white-space: nowrap;
}
.tableEffectifs > thead > tr > th {
  position: sticky;
  inset-block-start: 30px;
  background: white;
}
</style>
