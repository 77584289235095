<template>
  <div>

    <!-- modal tarif -->
    <DetailsTarif ref="detailsTarif" />

    <!-- modal favoris -->
    <b-modal v-model="afficherFavoris" size="xl" :title="'Gestion des favoris : ' + ingredientFavori.nom" hide-footer>
      <template slot="default">
        <div class="container-fluid" v-if="ingredientFavori.articles">
          <ArticlesParProduit
            :articles="ingredientFavori.articles"
            :favoris="ingredientFavori.favoris"
            :ingredient="ingredientFavori"
            :gestion-favori-uniquement="true"
            @toggle-favori="onToggleFavoriModal" />
        </div>
      </template>
    </b-modal>

    <Mercuriales ref="mercuriales"
      :startFilters="filters"
      @clic-produit="openModalFavoris"
      @clic-tarif="openModalTarif"
      @filter-changed="setUrl" />
    
  </div>
</template>

<script>
import { tousArticlesPour } from '@/api/gpao/mercuriales'
import { connecteData } from "@/mixins/connecteData"
import ArticlesParProduit from '@/components/Stock/ArticlesParProduit'
import Mercuriales from '@/components/Mercuriales/Mercuriales'
import DetailsTarif from '@/components/Divers/DetailsTarif'
import { mapGetters } from "vuex"

export default {
  name: "PageMercuriales",
  mixins: [connecteData],
  components: {ArticlesParProduit, DetailsTarif, Mercuriales},
  data() {
    return {
      filters: {
        sel: "0"
      },
      afficherFavoris: false,
      ingredientFavori: {}
    }
  },
  computed: {
    ...mapGetters("definitions", ["trouverIngredient", "trouverFamille"])
  },
  methods: {
    setUrl(params) {
      this.filters = params
      let url="/" + this.etabCourantId + "/mercuriales"
      if (this.filters.pg)
        url += "/" + this.filters.pg
      if (this.filters.sel !== "0") {
        url+= "/" + this.filters.sel
      } 
      history.pushState({}, "", url)
    },

    async openModalFavoris(prod) {
      var ingredient = this.trouverIngredient(prod.idProd)
      const response = await tousArticlesPour(this.etabCourantId, ingredient.id, this.trouverFamille(this.filters.pg).id)
      response.data.parProduit[prod.idProd][0].ouvrirAccordeon = true
      ingredient.articles = response.data.parProduit
      ingredient.favoris = response.data.favoris || []
      this.ingredientFavori = ingredient
      this.afficherFavoris = true
    },

    onToggleFavoriModal(e) {
      this.$refs.mercuriales.updateFavori(e.estFavori, e.produitId, e.fournisseurId)
    },

    openModalTarif(prod) {
      this.$refs.detailsTarif.afficher(prod)
    },

  },
  mounted() {
    if (this.$route.params.familleCode) {
      this.filters.pg = this.$route.params.familleCode
    }
    if (this.$route.params.selectionId) {
      this.filters.sel = this.$route.params.selectionId
    } else {
      this.filters.sel = "0"
    }
  }
}
</script>
