import service from '@/helpers/serviceGPAO'

export function getMercuriales(etablissementId) {
  return service.get('mercuriale/' + etablissementId + '/toutes')
}

export function getMercurialesDate(etablissementId, dateMercuriale, tauxMercu) {

  return service.get('mercuriale/' + (dateMercuriale ? 'avecDate/' + dateMercuriale + '/' : '') + etablissementId + '/' + (tauxMercu ? tauxMercu + '/' : '') + 'toutes')
}

export function getProduitsPourRechercheComplete(etablissementId) {
  return service.get('mercuriale/' + etablissementId + '/produitsPourRecherche')
}

export function getIngredientsPourRechercheComplete() {
  return service.get('mercuriale/ingredientsPourRecherche')
}

export function getRecettesPourRechercheComplete(etablissementId) {
  return service.get('mercuriale/' + etablissementId + '/recettesPourRecherche')
}

export function getMercuriale(etablissementId, familleId, estGamme, tauxMercu) {
  return service.get('mercuriale/' + etablissementId + (estGamme ? '/gamme/' : '/famille/') + familleId + (tauxMercu ? '/' + tauxMercu : ''))
}

export function getSelection(id) {
  return service.get('users/selection/' + id)
}

export function tousArticlesPour(etablissementId, produitOuIngredientId, familleId, grammage, fournisseurId) {
  var url = 'mercuriale/tousarticles/' + etablissementId + '/' + produitOuIngredientId
  if (familleId) {
    url += "/" + familleId
  }
  if (grammage) {
    url += "/" + grammage
  }
  if (fournisseurId) {
    url += "/" + fournisseurId
  }
  return service.get(url)
}

export function tousArticlesFamilles(etablissementId, ingredientId, fournisseurId, grammage) {
  var url
  if (fournisseurId) {
    url = 'mercuriale/tousarticlesfamillespourfournisseur/' + fournisseurId + '/' + etablissementId + '/' + ingredientId
  } else {
    url = 'mercuriale/tousarticlesfamilles/' + etablissementId + '/' + ingredientId
  }
  if (grammage) {
    url += '/' + grammage
  }
  return service.get(url)
}

export function tousArticlesParReference(etablissementId, reference) {
  return service.get('mercuriale/tousArticlesParReference/' + etablissementId + '/' + reference)
}

export function getFavoris() {
  return service.get('mercuriale/favoris')
}
