<template>
  <div>

    <ModalAjoutProduitHM ref="modalAjoutProduit" :produit="produitSelectionne" :fournisseur="tarifSelectionne" :paramModale="paramModale" @produit-modifie="majListeProduit($event)" />

    <!-- Contenu de la page -->
    <div class="container-fluid">
      <BarreFiltres ref="barreFiltres" :filtres="filtres" @appliquer-filtre="appliquerFiltre($event)" />
      <BarreActions ref="barreActions" :actions="actions" @appliquer-action="executerAction($event)" />

      <div class="card table-scroll height-responsive-100">
        <table class="table table-bordered table-sm text-center table-header"> <!-- style="display:block;max-height:800px;overflow:auto" -->
        <!--<table class="table table-bordered text-center">-->
          <colgroup>
            <col width="60">
            <col width="260">
            <col>
            <col width="180">
            <col width="100">
            <col width="100">
            <col width="80">
            <col width="100">
            <col width="200">
          </colgroup>
          <thead>
            <th class="watchMe" @click="trierListe('fam')">Fam.<i v-if="afficheIconeTri('fam')" :class="iconeSensTri"></i></th>
            <th class="aligner-gauche watchMe" @click="trierListe('ingredient')">Ingrédient<i v-if="afficheIconeTri('ingredient')" :class="iconeSensTri"></i></th>
            <th class="aligner-gauche watchMe" @click="trierListe('designation')">Désignation<i v-if="afficheIconeTri('designation')" :class="iconeSensTri"></i></th>
            <th class="watchMe" @click="trierListe('fournisseur')">Fournisseur<i v-if="afficheIconeTri('fournisseur')" :class="iconeSensTri"></i></th>
            <th class="watchMe" @click="trierListe('marque')">Marque<i v-if="afficheIconeTri('marque')" :class="iconeSensTri"></i></th>
            <th class="watchMe" @click="trierListe('reference')">Référence<i v-if="afficheIconeTri('reference')" :class="iconeSensTri"></i></th>
            <th class="watchMe" @click="trierListe('prix', true)">P.U.<i v-if="afficheIconeTri('prix')" :class="iconeSensTri"></i></th>
            <th>PCB</th>
            <th>Allergènes</th>
          </thead>
        </table>
        <div class="table-body">
          <table class="table table-bordered table-sm text-center">
            <colgroup>
              <col width="60">
              <col width="260">
              <col>
              <col width="180">
              <col width="100">
              <col width="100">
              <col width="80">
              <col width="100">
              <col width="195">
            </colgroup>
            <tbody>
              <tr :class="[{'ligne-selectionnee':produits.produit.produitId === idProduitSelectionne && produits.tarif.tarifId === idTarifSelectionne}]" v-for="(produits, iProduit) in listeProduits" :key="iProduit" @click="selectionnerProduit(produits)">
                <template>
                  <td>{{ produits.famille.code }}</td>
                  <td v-if="!produits.ingredient" class="aligner-gauche">
                    <em class="text-warning">Pas d'ingrédient</em>
                  </td>
                  <td v-else class="aligner-gauche"> 
                    {{ produits.ingredient }}
                  </td>
                  <td class="aligner-gauche">{{ produits.tarif.designation ? produits.tarif.designation : produits.produit.designation }}
                  </td>
                  <td class="aligner-gauche">{{ nomFournisseur(produits.tarif.fournisseurId) }}</td>
                  <td>{{ produits.tarif.marque }}</td>
                  <td>{{ produits.tarif.reference }}</td>
                  <td>{{ produits.tarif.pu }} €</td>
                  <td>{{ produits.tarif.pcb }} {{ produits.produit.uc }}</td>
                  <td>
                    {{ formatAllergene(produits.tarif) }}
                    <em v-if="sansAllergene(produits.tarif)" class="error ml-2">
                      <i class="fas fa-exclamation-triangle ligne-modifiee"></i> Aucun allergène sélectionné
                    </em>
                  </td>
                  <!--<td>{{ produits.produit.grammage }} g</td>-->
                  <!-- <td>{{ produits.produit.uc }}</td>
                  <td>{{ produits.produit.tarifs.length }}</td> -->
                </template>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarreFiltres from '@/components/Divers/BarreFiltres'
import BarreActions from '@/components/Divers/BarreActions'
import ModalAjoutProduitHM from '@/components/Divers/ModalAjoutProduitHM'
import { connecteData } from "@/mixins/connecteData"
import { showAlerts } from "@/mixins/alerts"
import { obtenirDonneesTousProduitsHM, modifierProduits, desactiverTarifHM } from '@/api/gpao/fournisseurs'
import { supprimerProduit } from '@/api/gpao/produits'
import { chargerMercuriales } from '@/helpers/mercuriales'
import { chargerDefinitions } from '@/helpers/definitions'
import { getIngredientsPourRechercheComplete } from '@/api/gpao/mercuriales'
import _ from 'lodash'
import { mapGetters } from "vuex"

export default {
  name: "PageProduitsHM",
  components: {
    BarreFiltres,
    BarreActions,
    ModalAjoutProduitHM
  },
  mixins: [connecteData, showAlerts],
  data() {
    return {
      triAsc: null,
      colonneTriee: "",
      produitsHM: [],
      idProduitSelectionne: "",
      idTarifSelectionne: "",
      filtreProduits: '',
      produitsFiltres: [],
      produitSelectionneSave : {},
      listeProduitsEnMemoire : [],
      listeProduits:[],
      listeFamilles : [],
      listeFamillesAvecInfos : [],
      filtreFamilles : [],
      filtreTypeFamilles : ["Tous"],
      filtreEnErreur: false,
      isLoading: false,
      afficheNonAlimentaire: false,
      modeAffichageModal: "",
      paramModale: {},
      listeIngredients: {},
      filtres: {
        nomproduit : {
          label: "Recherche un produit",
          champ: "input",
          type: "text",
          selection: "",
          initialiser(contexte) {
            contexte.filtres.nomproduit.selection = contexte.filtreProduits
          },
          filtrer(contexte) {
            contexte.filtreProduits = contexte.filtres.nomproduit.selection
            contexte.filtrer()
          }
        },
        familles: {
          label: "Sélectionner une ou plusieurs familles",
          champ: "select",
          type: "multi",
          width: "300px",
          maxDisplay: 3,
          options: [],
          selection: [],
          initialiser(contexte) {
            let filtre = contexte.filtres.familles
            filtre.options = contexte.listeFamilles//.map(zs => new { valeur: zs, texte: zs })
            filtre.selection = contexte.filtreFamilles
          },
          filtrer(contexte) {
            contexte.filtreFamilles = contexte.filtres.familles.selection
            contexte.filtrer()
          }
        },
        enerreur: {
          label: "Produits en erreur",
          champ: "checkbox",
          selection: false,
          initialiser(contexte) {
            let filtre = contexte.filtres.enerreur
            filtre.selection = contexte.filtreEnErreur
          },
          filtrer(contexte) {
            contexte.filtreEnErreur = contexte.filtres.enerreur.selection
            contexte.filtrer()
          }
        },
        // Appeler "this.filtres.initialiser(this)" dans mounted() => génère le tableau de filtres utilisés pour afficher les filtres dans la barre de filtres
        barreGauche: [],
        barreDroite: [],
        initialiser(contexte) {
          contexte.reinitialiser()
          contexte.filtres.barreGauche = [ contexte.filtres.familles, contexte.filtres.nomproduit, contexte.filtres.enerreur ]
          contexte.filtres.barreGauche.forEach(f => {
            if (f.initialiser) {
              f.initialiser(contexte)
            }
          })
          contexte.keyFiltres += 1
        },
        filtrer(contexte, commande) {
          return !contexte.filtres.barreFiltres.find(f => !f.filtrer(contexte, commande))
        }
      },
      actions: {
        // Boutons à afficher dans la barre d'action
        // Exemple :
        /*
        nomAction: {
          label: Label affiché sur le bouton (string),
          disabled: Etat actif/inactif par défaut du bouton (bool),
          class: Classe CSS (btn-primary, btn-success, btn-warning ou btn-danger) à appliquer sur le bouton (par défaut : "btn-primary" est appliqué => il s'agit du bouton bleu Agap'pro) (string)
          methode(contexte) {
            // => contexte équivaut à this (mais this ne fonctionne pas car l'initialisation de data n'est pas encore terminée, donc data n'est pas encore rattaché à this)
            traitements à exécuter lors du clic sur le bouton
            les traitements définis dans cette page doivent être appelés sous la forme : contexte.traitement()

            Remarque : pour que le système fonctionne, il faut déclarer la méthode ci-dessous dans la section "methods" de cette page
            executerAction(methode) {
              methode(this)
            }

          }
        }
        */
        creer: {
          label: "Ajouter un produit",
          methode(contexte) {
            contexte.paramModale.modeAffichage = "ajoutProduit"
            contexte.$refs.modalAjoutProduit.show()
          }
        },
        dupliquer: {
          label: "Dupliquer",
          disabled: true,
          methode(contexte) {
            let cmd = contexte.produitSelectionne
            if (cmd) {
              contexte.dupliquerProduit(cmd)
            }
          }
        },
        modifier: {
          label: "Modifier",
          disabled: true,
          methode(contexte) {
            let cmd = contexte.produitSelectionne
            if (cmd) {
              contexte.modifierDetailProduit(cmd)
            }
          }
        },
        supprimer: {
          label: "Supprimer",
          disabled: true,
          class: "btn-danger",
          methode(contexte) {
            let cmd = contexte.produitSelectionne
            if (cmd) {
              contexte.supprimeProduit(cmd)
            }
          }
        },
        enregistrer: {
          label: "Enregistrer",
          disabled: true,
          hidden: true,
          class: "btn-success",
          methode(contexte) {
            contexte.enregistrer()
          }
        },
        // Appeler "this.actions.initialiser(this)"" dans mounted() => génère les tableaux d'actions utilisés pour afficher les boutons à gauche et à droite de la barre d'actions
        barreGauche: [],
        barreDroite: [],
        initialiser(contexte) {
          contexte.actions.barreGauche = [ contexte.actions.creer ]
          contexte.actions.barreDroite = [ contexte.actions.modifier, contexte.actions.dupliquer, contexte.actions.supprimer ]
          if (contexte.listeProduits.some(p => !p.ingredient)) {
            contexte.actions.barreDroite.push(contexte.actions.enregistrer)
          }
        },
        // A gérer en cas de boutons à activer/désactiver (créer une méthode par groupe de boutons à activer/désactiver)
        disable(actions, disabled) {
          let actionsATraiter = [ actions.supprimer, actions.modifier, actions.dupliquer ]
          actionsATraiter.forEach(a => a.disabled = disabled)
        },
        disableEnregistrer(actions, disabled) {
          let actionsATraiter = [ actions.enregistrer ]
          actionsATraiter.forEach(a => a.disabled = disabled)
        }
      }
    }
  },
  computed: {
    ...mapGetters("fournisseurs", ["nomFournisseur", "francoFournisseur"]),
    ...mapGetters("definitions", ["ingredients"]),
    produitSelectionne() {
      return this.listeProduits?.find(c => c.produit.produitId === this.idProduitSelectionne) ?? {}
    },
    tarifSelectionne() {
      return this.listeProduits?.find(c => c.tarif.tarifId === this.idTarifSelectionne)?.tarif ?? {}
    },
    iconeSensTri() {
      return this.triAsc ? "ml-1 fas fa-caret-down" : "ml-1 fas fa-caret-up"
    }
  },
  methods: {
    afficheIconeTri(col) {
      return this.triAsc != null && this.colonneTriee == col
    },
    trierListe(nomColonne, triNumerique) {
      let sens = this.triAsc ?? true //visiblement dans le sort, this.triAsc n'est pas connu, donc on copie dans une variable locale
      this.listeProduits.sort(function(a, b) {
        let i = sens ? (triNumerique ? a[nomColonne] || 0 : a[nomColonne]?.toLowerCase() || '')  : (triNumerique ? b[nomColonne] || 0 : b[nomColonne]?.toLowerCase() || '')
        let j = sens ? (triNumerique ? b[nomColonne] || 0 : b[nomColonne]?.toLowerCase() || '') : (triNumerique ? a[nomColonne] || 0 : a[nomColonne]?.toLowerCase() || '')

        //Pour que ça fonctionne aussi bien en numérique que Texte, on fait les tests
        if (i < j)
          return -1
        
        if (i > j)
          return 1
        
        return 0
      })

      //changement du sens du tri
      this.triAsc = !sens
      this.colonneTriee = nomColonne
    },
    modifierDetailProduit() {
      this.paramModale.modeAffichage = "modifierProduit"
      this.$refs.modalAjoutProduit.show()
    },
    dupliquerProduit() {
      this.paramModale.modeAffichage = "dupliquerProduit"
      this.$refs.modalAjoutProduit.show()
    },
    setItemIngredientId(event, produit) {
      produit.produit.ingredientId = event.ingredientId
      produit.produit.produitModifie = true
      this.$refs["refChercherIngredient_" + produit.produit.produitId][0].selectionnerValeur(event.nomIngredient)
      this.actions.disableEnregistrer(this.actions, !produit.produit.produitModifie)
      
    },
    async supprimeProduit(produit) {
      let result = await this.alerteConfirmation("Supprimer le produit", "Souhaitez-vous supprimer ce produit ? La suppression est définitive.")
      if (result) {
        if (this.tarifSelectionne.tarifId)
          await desactiverTarifHM(this.tarifSelectionne.tarifId)

        if (produit.produit.tarifs.length <= 1) {
          await supprimerProduit(produit.produit.produitId)
        }

        await this.chargerProduits()

        this.$store.dispatch('definitions/rechargerIngredient', produit.produit.ingredientId)
        chargerMercuriales(this.etabCourantId, true, null, this.profilLecteurMercu)
        this.$store.dispatch('produits/produitsUtilisateurCourant', this.etabCourantId)
      }
    },
    reinitialiser() {
      this.produitsHM = _.cloneDeep(this.listeProduitsEnMemoire)
      this.construireListeProduits()
    },
    miseaAJourProduitIngredient() {
      //let prd = this.produitsHM.map(x => x.produits)
      //let prdModif = prd.filter(x => x.produitModifie)
      /*this.produitsHM.map(x => x.produits).filter(x => x.produitModifie).forEach(x => {
      })
      */

      //On pourrait peut-etre pouvoir filtrer uniquement les produits avec produitModifie à vrai, mais je ne sais pas vraiment faire, cet indicateur est dans un sous-tableau de ProduitHM
      //et la méthode filter retourne un nouvel objet visiblement, donc on ne peut pas modifier directement les données .... piiiuuuufffff, des boucles ça marche bien aussi ....
      
      this.listeProduits.forEach(l => {
        if (l.produit.produitModifie) {
          l.produit.produitModifie = false
          l.ingredient = this.$store.getters["definitions/obtenirIngredient"](l.produit.ingredientId)?.nom
        }
      })

      this.produitsHM.forEach(x => {
        if (x.produits.length != 0) {
          x.produits.forEach(p => {
            if (p.produitModifie) {
              p.produitModifie = false
            }
          })
        }
      })

      this.listeProduitsEnMemoire = _.cloneDeep(this.produitsHM)
    },
    async enregistrer() {
      this.$store.commit('uxHelpers/showSpinner')
      let response = await modifierProduits(this.etabCourantId, this.listeProduits.map(x => x.produit))
      if (!response) {
        this.$store.commit('uxHelpers/hideSpinner')
        this.isLoading = false
        return
      }

      // Mise à jour de la liste des produits
      this.produitsHM = response.data
      this.listeProduitsEnMemoire = _.cloneDeep(response.data)

      this.construireListeProduits()

      //this.miseaAJourProduitIngredient()

      this.actions.disableEnregistrer(this.actions, true)
      
      this.alerteSucces('Produit(s) modifiée !')
      this.$store.commit('uxHelpers/hideSpinner')

      chargerDefinitions()
      chargerMercuriales(this.etabCourantId, true, null, this.profilLecteurMercu)
      this.$store.dispatch('produits/produitsUtilisateurCourant', this.etabCourantId)
    },
    filtrer() {
      setTimeout(() => { // affiche l'attente avant de continuer
        this.produitsHM = _.cloneDeep(this.listeProduitsEnMemoire)//application des filtres
        this.construireListeProduits()
        this.filtrerProduits()
      }, 1)
    },
    filtrerProduits() {
      var produitFiltre
      
      if (this.filtreProduits) {
        produitFiltre = []
        const recherche = this.filtreProduits.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""); //valeur saisie sur la page
        this.listeProduits.forEach(z => {
          var s = z.produit.rechercheProduits.filter(n => n.includes(recherche))
          if (s.length > 0)
            produitFiltre.push(_.cloneDeep(z))
        })
        this.listeProduits = produitFiltre.filter((val, ind, arr) => arr.indexOf(val) === ind) //La liste des produits à afficher sur la page est filtrée par rapport à la saisie
      }

      if (this.filtreFamilles && this.filtreFamilles.length > 0) {
        produitFiltre = []
        this.filtreFamilles.forEach(f => {
            this.listeProduits.forEach(z => {
            if (z.famille.nom === f) 
              produitFiltre.push(_.cloneDeep(z))
          })
        })
        this.listeProduits = produitFiltre.filter((val, ind, arr) => arr.indexOf(val) === ind) //La liste des produits à afficher sur la page est filtrée par rapport à la saisie
      } 

      if (this.filtreEnErreur) {
        produitFiltre = []
        this.listeProduits.forEach(z => {
          if (!z.ingredient || this.sansAllergene(z.tarif))
            produitFiltre.push(_.cloneDeep(z))
        })
        this.listeProduits = produitFiltre.filter((val, ind, arr) => arr.indexOf(val) === ind) //La liste des produits à afficher sur la page est filtrée par rapport à la saisie
      }

      if (this.idProduitSelectionne && !this.produitsFiltres.find(c => c.produitId === this.idProduitSelectionne)) {
        this.idProduitSelectionne = ""
        this.actions.disable(this.actions, !this.produitSelectionne)
      }

      this.triAsc=null
    },
    appliquerFiltre(filtrer) {
      // Nécessaire pour faire fonctionner les filtres de la barre de filtres
      filtrer(this)
    },
    selectionnerProduit(produit) {
      if (produit.produit.produitId === this.idProduitSelectionne && produit.tarif.tarifId === this.idTarifSelectionne) {
        // La ligne est déjà sélectionnée, on l'ouvre
        this.modifierDetailProduit(produit)
        return
      }
      this.idProduitSelectionne = produit.produit.produitId
      this.idTarifSelectionne = produit.tarif.tarifId
      this.produitSelectionneSave = produit
      this.actions.disable(this.actions, !this.produitSelectionne)
    },
    construireListeProduits() {
      
      this.listeProduits = []
      this.listeFamilles = []
      this.listeFamillesAvecInfos = []

      this.produitsHM.forEach(x => {
        if (x.produits.length != 0) {
          x.produits.forEach(p => {
            if (p.tarifs.length != 0) {
              p.tarifs.forEach(t => {
                let objetProduit = {}

                //je duplique des infos des objets enfants sur l'objetProduit pour gérer les tris .... à voir si on change aussi le reste de la page pour utiliser ces infos directement ...
                objetProduit.famille = x.famille
                objetProduit.produit = p
                objetProduit.produit.familleId = x.familleId
                objetProduit.ingredient = this.$store.getters["definitions/obtenirIngredient"](p.ingredientId)?.nom
                objetProduit.tarif = t
                //Pour le Tri :
                objetProduit.designation = t.designation ? t.designation : p.designation
                objetProduit.fournisseur = this.nomFournisseur(t.fournisseurId)
                objetProduit.reference = t.reference
                objetProduit.marque = t.marque
                objetProduit.prix = t.pu
                objetProduit.fam = x.famille.code
                //
                this.listeProduits.push(objetProduit)  
              })
            } else {
              let objetProduit = {}

              objetProduit.famille = x.famille
              objetProduit.produit = p
              objetProduit.produit.familleId = x.familleId
              objetProduit.ingredient = this.$store.getters["definitions/obtenirIngredient"](p.ingredientId)?.nom
              objetProduit.tarif = {}
              //Pour le Tri :
              objetProduit.designation = p.designation
              objetProduit.fournisseur = ""
              objetProduit.reference = ""
              objetProduit.marque = ""
              objetProduit.prix = null
              objetProduit.fam = x.famille.code
              //
              this.listeProduits.push(objetProduit)
            }
          })
        }

        this.listeFamillesAvecInfos.push(x.famille)  
        this.listeFamilles.push(x.famille.nom)  
        
      })
    },
    sansAllergene(tarif) {
      return (!tarif.aucunAllergene && tarif.allergenesMajeurs && tarif.allergenesMajeurs.length === 0) || !tarif.allergenesMajeurs
    },
    formatAllergene(tarif) {
      if (this.sansAllergene(tarif))
        return ""
      else
        return tarif.aucunAllergene && tarif.allergenesMajeurs.length === 0 ? "Aucun" : tarif.allergenesMajeurs.join(", ")
    },
    majListeProduit(event) {
      let prd = this.produitsHM.find(p => p.familleId === event.familleId)
      let posProduit = prd.produits.map(e => e.produitId).indexOf(event.produits[0].produitId);
      //Si le produit existe déjà, on le met à jour, sinon on l'ajoute à la liste
      if (posProduit !== -1) {
        prd.produits[posProduit] = event.produits[0]
      } else {
        prd.produits.push(event.produits[0])
      }
      
      this.listeProduitsEnMemoire = _.cloneDeep(this.produitsHM)
      this.construireListeProduits()
    },
    async chargerProduits() {
      this.isLoading = true
      this.$store.commit('uxHelpers/showSpinner')
      this.produitsHM = []

      let response = await obtenirDonneesTousProduitsHM(this.etabCourantId)
      if (!response) {
        this.$store.commit('uxHelpers/hideSpinner')
        this.isLoading = false
        return
      }
      this.$store.commit('uxHelpers/hideSpinner')

      this.produitsHM = response.data
      this.listeProduitsEnMemoire = _.cloneDeep(response.data)

      this.construireListeProduits()

      
      this.isLoading = false
    },
    executerAction(methode) {
      // Nécessaire pour faire fonctionner les actions de la barre d'action
      methode(this)
    }
  },
  mounted() {
    
  },
  async created() {
    let response = await getIngredientsPourRechercheComplete()
    this.listeIngredients = response.data
    await this.chargerProduits() //On attend le chargement de la page avant d'initialiser les filtres
    this.filtres.initialiser(this)
    this.actions.initialiser(this)
  }

}
</script>


