<template>
  <div style="display:inline">
    <b-modal ref="modal" size="xl" :title="title"
      ok-title="Ajouter" :ok-disabled="!this.article || !this.article.tarifId || !Number(this.quantite)"
      cancel-title="Annuler"
      no-close-on-backdrop
      @show="init()"
      @ok="envoyer()">
      <template slot="default">
        <RechercheArticles
          :ajoutActif="true"
          :fournisseurId="fournisseurId"
          @change-ingredient="changeIngredient($event)"
          @change-state="changeEtatRecherche($event)" />

        <h5 class="push-top-1rem" :key="stateSearch" v-if="stateSearch">{{stateSearch}}</h5>

        <ArticlesParProduit v-if="ingredient && ingredient.parProduit"
          :articles="ingredient.parProduit"
          :favoris="ingredient.favoris"
          :ingredient="ingredient"
          :index="0"
          :key="keyArticles"
          @selection-article="selectionArticle($event.produit)" />

        <div v-if="ingredient && ingredient.parProduit" class="flex-center-space-between push-top-1rem">
          <div>
            Quantité : <InputNumber v-model="quantite" typeFiltre="quantite" ref="quantite" style="width:70px" />
            {{ingredient.up}}
          </div>
          <div>
            PCB : {{article.pcb}}
          </div>
          <div>
            PU HT : {{(article.prixUnitaire || 0) | afficherPrix}}
          </div>
          <div>
            Total HT : {{((quantite * article.prixUnitaire) || 0) | afficherPrix}}
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { connecteData } from "@/mixins/connecteData"
import { mapGetters } from "vuex"
import InputNumber from "@/components/Inputs/InputNumber"
import RechercheArticles from "@/components/Divers/RechercheArticles"
import ArticlesParProduit from '@/components/Stock/ArticlesParProduit'
import vm from "@/helpers/vm-toolkit-vue"

export default {
  props: ['commandeGlobale', 'title', 'disabled', 'fournisseurId'],
  mixins: [connecteData],
  components: {
    RechercheArticles,
    ArticlesParProduit,
    InputNumber
  },
  data() {
    return {
      ingredient: {},
      quantite: 0,
      keyArticles: 1,
      stateSearch: "",
      article: {}
    }
  },
  computed: {
    ...mapGetters("definitions", ["ingredients"])
  },
  methods: {
    init() {
      this.ingredient = {}
      this.quantite = 0
      this.article = {}
      this.stateSearch = ""
    },
    changeEtatRecherche(etat) {
      this.stateSearch=etat
    },
    changeIngredient(ingredient) {
      this.ingredient = ingredient
      this.article = {}
      this.keyArticles += 1
    },
    selectionArticle(article) {
      this.ingredient.tarifId = article.tarifId
      this.article = article
      this.keyArticles += 1
      this.$refs.quantite.$vnode.elm.focus()
    },
    show() {
      this.$refs.modal.show()
    },
    hide() {
      this.$refs.modal.hide()
    },
    envoyer() {
      if (!this.article || !this.article.tarifId) {
        this.$toast("Veuillez sélectionner l'article à ajouter", {
          horizontalPosition: "center",
          verticalPosition: "top",
          className: ["toast-error"],
          duration: 2000
        })
        return
      }
      if (!Number(this.quantite)) {
        this.$toast("Veuillez sélectionner la quantité à ajouter", {
          horizontalPosition: "center",
          verticalPosition: "top",
          className: ["toast-error"],
          duration: 2000
        })
        this.$refs.quantite.$vnode.elm.focus()
        return
      }
      this.$emit("ajouter-produit", {tarifId: this.article.tarifId, quantite: Number(this.quantite), article: this.article})
      this.hide()
    }
  }
}
</script>
