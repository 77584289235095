<template>
  <div style="display: inline">
    <!--<b-modal :id="'ModalIncident'+idModal" size="xl" hide-footer header-bg-variant="gradient-bleu" header-text-variant="light">-->
    <b-modal :id="'ModalIncident'+idModal" size="xl" title="Déclaration d'incident à la réception"
      ok-title="Ajouter"
      cancel-title="Annuler"
      :ok-disabled="!incident.raisonIncidentId || !incident.typeReclamation"
      no-close-on-backdrop
      @ok="envoyerIncident(incident)">

      <template slot="default">
        <v-select v-model="incident.raisonIncidentId" :options="optionsIncident" label='designation' placeholder="Incident..." :reduce="i => i.code" />
        <b-form-radio-group v-model="incident.typeReclamation" :options="optionsTypeDeReclamation" />
        <div v-if="incident.typeReclamation === 'TR1'" style="margin: 1rem 0">
          Date de relivraison souhaitée :
          <v-date-picker v-model="incident.dateRelivraisonSouhaitee" :popover="{ visibility: 'click' }">
            <template v-slot="{ inputValue, inputEvents }">
              <div class="flex flex-col sm:flex-row justify-start items-center">
                <div class="relative flex-grow">
                  <input style="height:40px;" class="flex-grow pl-8 pr-5 py-1 bg-gray-100 border rounded w-full text-gray-900"
                    :value="inputValue ? inputValue : 'Choisir la date...'"
                    v-on="inputEvents"
                  />
                </div>
              </div>
            </template>
          </v-date-picker>
        </div>
        <div v-if="incident.typeReclamation === 'TR2'" style="margin: 1rem 0">
          Montant de l'avoir :
          <div class="flex" style="align-items:center">
            <InputNumber v-model="incident.montantAvoir" typeFiltre="quantite" style="width:8rem" />
            <div style="margin-left: .5rem;font-size: 1.5rem;">€</div>
          </div>
        </div>
        <textarea style="margin: 1rem 0" label="Commentaire" :value="incident.detailIncident" @input="incident.detailIncident = $event.target.value" hint="Détails de l'incident">
        </textarea>
        <!--
        <div style="margin: 2rem 0; text-align:right">
          <button class="btn btn-success" style="background: #d33 !important;border-color: #d33 !important;" @click="envoyerIncident(incident);closeModal('ModalIncident'+idModal)">
            Ajouter
          </button>
          <button class="btn btn-danger" style="margin-left:3rem; background: #3085d6 !important;border-color: #3085d6 !important;" @click="closeModal('ModalIncident'+idModal)">
            Annuler
          </button>
        </div>
        -->
      </template>
    </b-modal>
  </div>
</template>

<script>
import { formatageDate } from '@/mixins/dateFormats'
import { closeModal } from "@/mixins/index"
import InputNumber from '@/components/Inputs/InputNumber'

export default {
  name: "BoutonAjoutIncident",
  mixins: [formatageDate, closeModal],
  components: {
    InputNumber
  },
  props: ['idModal', 'disabled', 'optionsIncident', 'optionsTypesDeReclamations', 'size'],
  data() {
    return {
      incident: {
        raisonIncidentId: null,
        typeReclamation: null,
        dateRelivraisonSouhaitee: null,
        montantAvoir: null,
        detailIncident: null
      }
    }
  },
  computed: {
    optionsTypeDeReclamation() {
      var options = []
      this.optionsTypesDeReclamations.forEach(option => {
        options.push({
          text: option.designation,
          value: option.code
        })
      })
      return options
    }
  },
  methods: {
    setDateDeRelivraisonSouhaitee(event) {
      this.$set(this.incident, 'dateRelivraisonSouhaitee', event)
    },
    envoyerIncident(event) {
      if (event) {
        if (event.typeReclamation !== "TR1") {
          event.dateRelivraisonSouhaitee = null
        }
        if (event.typeReclamation !== "TR2") {
          event.montantAvoir = null
        }
        this.$emit('incident', event)
      }
    },
    show(montantAvoir) {
      this.incident.typeReclamation = null
      this.incident.montantAvoir = montantAvoir
      this.$bvModal.show('ModalIncident' + this.idModal)
    }
  }
}
</script>
