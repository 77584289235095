<template>
  <b-modal ref="modalPopupInfo" size="lg" title="Aide au démarrage" hide-footer
    no-close-on-backdrop
    hide-header-close>
    <h5 class="mb-3 mt-2 " style="font-weight: bold">Comment passer votre première commande ?</h5>
    <div>
      <h6 >Etape 1</h6>
      <p>Cliquez sur le menu "Commandes".</p>
      <div class="flex-center-center mb-3">
        <div class="col-8">
          <img class="border-black border-rounded w-100" src="../../assets/images/modalPopupInfo/etape1.png" alt="Passer une commande étape 1">
        </div> 
      </div> 
      <h6>Etape 2</h6>
      <p>Cliquez sur le bouton "Créer une nouvelle commande".</p>
      <div class="flex-center-center mb-3">
        <div class="col-8">
          <img class="border-black border-rounded w-100" src="../../assets/images/modalPopupInfo/etape2.png" alt="Passer une commande étape 1">
        </div> 
      </div> 
      <h6>Etape 3</h6>
      <p>Donnez un nom à votre commande, et cliquer "Créer".</p>
      <div class="flex-center-center mb-3">
        <div class="col-8">
          <img class="border-black border-rounded w-100" src="../../assets/images/modalPopupInfo/etape3.png" alt="Passer une commande étape 1">
        </div> 
      </div> 
      <h6>Etape 4</h6>
      <p>Cliquez sur "Choisir produits" pour accèder à la Mercuriale.</p>
      <div class="flex-center-center mb-3">
        <div class="col-8">
          <img class="border-black border-rounded w-100" src="../../assets/images/modalPopupInfo/etape4.png" alt="Passer une commande étape 1">
        </div> 
      </div> 
        <p>Pour plus d'information, dirigez-vous vers nos pages "<a href="#" @click="$router.push({ name: 'DocCommandes' })">Aide</a>".
      </p>
    </div>  

    <div class="modal-footer p-0">
      <label>
        <input type="checkbox" v-model="nePlusAfficher">
        <span class="ml-2">Ne plus afficher</span>
      </label>
      <b-button class="btn btn-secondary ml-4" @click="fermer()">
        Fermer
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { changerAfficherPopupInfo } from '@/api/gpao/user'
import { connecteData } from "@/mixins/connecteData"

export default {
  name: "ModalPopupInfo",
  mixins: [connecteData],
  
  data() {
    return {
      nePlusAfficher: false,
    }
  },

  methods: {
    show() {
      if (this.profilIsUtilisateur && this.utilisateurCourant.afficherPopupInfo) {
        this.$refs.modalPopupInfo.show();
      }
    },
    
    hide() {
      this.$refs.modalPopupInfo.hide();
    },
    
    async fermer() {
      if (this.nePlusAfficher) {
        let response = await changerAfficherPopupInfo()
        this.$store.commit('user/setAfficherPopupInfo', response.data)
      }
      this.hide()
    }
  }
}
</script>