<template>
  <span>
    <b-button class="btn btn-primary btn-sm" :disabled="disabled" size="sm" @click="show()" title="Substitution d'article">
      <i class="fas fa-exchange-alt"></i>
    </b-button>

    <b-modal v-model="afficherModale" size="xl" title="Substitution d'article"
      ok-title="Choisir" :ok-disabled="!article"
      cancel-title="Annuler"
      no-close-on-backdrop
      @show="init()"
      @ok="substituerArticle()">
      <template slot="default">
        <RechercheArticles :ajoutActif="true" :fournisseurId="fournisseurId" @change-ingredient="changeIngredient($event)" @change-state="changeEtatRecherche($event)" />
        <h5 class="push-top-1rem" :key="stateSearch" v-if="stateSearch">
          {{stateSearch}}
        </h5>
        <ArticlesParProduit v-if="ingredient && ingredient.parProduit" :articles="ingredient.parProduit" :favoris="ingredient.favoris" :ingredient="ingredient" :index="0" :key="keyArticles" @selection-article="setArticle($event)" />
      </template>
    </b-modal>
  </span>
</template>

<script>
import { mapGetters } from "vuex"
import { obtenirDonneesProduits } from "@/api/gpao/fournisseurs"
import { connecteData } from '@/mixins/connecteData'
import { fournisseurs } from '@/mixins/fournisseurs'
import RechercheArticles from './RechercheArticles.vue'
import ArticlesParProduit from '../Stock/ArticlesParProduit.vue'
import vm from "@/helpers/vm-toolkit-vue"

export default {
  name: "RechercheAjoutProduit",
  props: [ 'fournisseurId', 'disabled' ],
  mixins: [ fournisseurs, connecteData ],
  components: {
    RechercheArticles,
    ArticlesParProduit
  },
  data() {
    return {
      optionsProduits: [],
      afficherModale: false,
      referenceArticle: '',
      afficherRechercheParFamille: false,
      afficherResultat: false,
      ajouterProduit: false,
      familleId: null,
      produitId: null,
      produit: null,
      article: null,
      keyProduit: 0,
      keyArticle: 0,
      keyArticles: 0,
      fournisseur: this.$store.state.fournisseurs.fournisseursCourants.find(el => el.id === this.fournisseurId),
      stateSearch: '',
      ingredient: null,
      creerArticle: false
    }
  },
  computed: {
    ...mapGetters("definitions", ["ingredients"]),
    ...mapGetters("definitions", ["familles"]),
    famillesFournisseur() {
      let etab = this.utilisateurCourant.etablissements.find(x => x.eId === this.etabCourantId)
      let ff = etab.choixFournisseurs.filter(x => x.foId === this.fournisseurId)
      return this.familles.filter(x => ff.map(y => y.fId).includes(x.id) && x.actif)
    }
  },
  methods: {
    init() {
      this.ingredient = {}
      this.referenceArticle = ''
      this.afficherRechercheParFamille = false
      this.afficherResultat = false
      this.ajouterProduit = false
      this.familleId = null
      this.produit = null
      this.article = null
      this.stateSearch = ""
    },
    show() {
      this.afficherModale = true
    },
    hide() {
      this.afficherModale = false
    },
    changeEtatRecherche(etat) {
      this.stateSearch = etat
    },
    changeIngredient(ingredient) {
      this.creerArticle = false
      this.ajouterProduit = false
      this.ingredient = ingredient
      this.familleId = null
      this.produitId = null
      this.produit = null
      this.article = null
      ++this.keyArticles
    },
    ouvrirAjoutProduit() {
      this.article = null
      this.produit = null
      this.produitId = null
      this.ajouterProduit = true
      ++this.keyProduit
      ++this.keyArticle
    },
    setArticle(elt) {
      let article = elt.produit
      if (this.ingredient.parProduit) {//ouvre l'article sélectionné
        vm.forEach(this.ingredient.parProduit, a => {
          a.forEach(r => {
            r.ouvrirAccordeon = r.tarifId === article.tarifId
          })
        })
      }
      this.article = article
      this.ingredient.tarifId = article.tarifId
      this.ingredient.uc = article.uc
      if (article.id) {
        this.$set(this.article, 'id', article.id)
      }
      this.$set(this.article, 'tarifId', article.tarifId)
      this.$set(this.article, 'designationFournisseur', article.designationFournisseur || article.designation)
      this.$set(this.article, 'uc', article.uc)
      this.$set(this.article, 'valeurUnitaire', article.valeurUnitaire || article.prixUnitaire)
      this.$set(this.article, 'reference', article.reference)
      this.$set(this.article, 'marque', article.marque)
      this.$set(this.article, 'fournisseurId', article.fournisseurId)
      if (elt.produitId) {
        this.$set(this.article, 'produitId', elt.produitId)
      }
      ++this.keyArticles
    },
    async obtenirProduits() {
      this.$store.commit('uxHelpers/showSpinner')
      this.produit = null
      this.article = null
      this.optionsProduits = []
      try {
        const response = await obtenirDonneesProduits(this.familleId, this.etabCourantId)
        let data = response.data
        let categorie = null
        let sousCategorie = null
        for (let i = 0; i < data.produits.length; i++) {
          let produit = data.produits[i]
          if (produit.hierarchieProduit === 2) {
            categorie = produit.designation
          }
          if (produit.hierarchieProduit === 3) {
            sousCategorie = produit.designation
          }
          if (produit.hierarchieProduit === 1) {
            produit["categorie"] = categorie
            produit["sousCategorie"] = sousCategorie
            this.optionsProduits.push(produit)
            this.optionsProduits = this.optionsProduits.sort((p1, p2) => p1.designation.localeCompare(p2.designation))
          }
        }
      }
      finally {
        ++this.keyProduit
        ++this.keyArticle
        this.$store.commit("uxHelpers/hideSpinner")
      }
    },
    obtenirArticle() {
      this.produit = null
      this.article = null
      this.produit = this.optionsProduits.find(p => p.produitId === this.produitId)
      if (this.produit) {
        this.article = this.produit.tarifs.find(el => el.fournisseurId === this.fournisseurId)
      }
      ++this.keyProduit
      ++this.keyArticle
    },
    produitAjoute(event) {
      this.produit = event.produit
      if (this.optionsProduits.find(p => p.produitId === this.produit.produitId)) {
        this.optionsProduits = this.optionsProduits.filter(p => p.produitId !== this.produit.produitId)
      }
      this.optionsProduits.push(this.produit)
      this.optionsProduits.sort((p1, p2) => p1.designation.localeCompare(p2.designation))
      this.produitId = this.produit.produitId
      this.ajouterProduit = false
      ++this.keyArticle
      ++this.keyProduit
    },
    affecterArticle(event) {
      this.article = event.article
      ++this.keyProduit
      ++this.keyArticle
    },
    substituerArticle() {
      this.$emit('substituer-article', { article: this.article })
      this.hide()
    },
    annuler() {
      this.hide()
    }
  }
}
</script>
