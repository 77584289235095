<template>
  <div class="container-fluid">
    <ModalPopupInfo ref="modalPopupInfo"/>
    <div class="flex-center-space-between">
      <div class="mr-1" style="width:50%"><WidgetLivraisons /></div>
      <div class="ml-1" style="width:50%"><WidgetInformations /></div>
    </div>
    <div class="flex-center-space-between">
      <div class="mr-1" style="width:50%"><WidgetProductions /></div>
      <div class="ml-1" style="width:50%"><WidgetActualites /></div>
    </div>
  </div>
</template>

<script>
import store from '../store'
import WidgetLivraisons from "@/components/Accueil/WidgetLivraisons"
import ModalPopupInfo from "@/components/Accueil/ModalPopupInfo"
import WidgetInformations from "@/components/Accueil/WidgetInformations"
import WidgetProductions from "@/components/Accueil/WidgetProductions"
import WidgetActualites from "@/components/Accueil/WidgetActualites"
import { mapActions } from "vuex"
import { connecteData } from "@/mixins/connecteData"

export default {
  name: "home",
  mixins: [connecteData],
  components: { WidgetLivraisons, WidgetInformations, WidgetProductions, WidgetActualites, ModalPopupInfo },
  data() {
    return {
      informations: []
    }
  },
  async mounted() {    
    this.$refs.modalPopupInfo.show()
    await store.dispatch('definitions/chargerDefinitions', { key: "informationsAccueil", etablissementId: this.etabCourantId })
    this.cacherAttente()
  },
  methods: {
    ...mapActions("uxHelpers", ["cacherAttente"])
  }
}
</script>
