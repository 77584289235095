import service from '@/helpers/serviceGPAO'

export function chargerDefinitions(cle, etablissementId) {
  let url = "definitions/" + cle
  if (etablissementId) {
    url += "/" + etablissementId
  }
  return service.get(url)
}

export function chargerFamillesEtGammes(etablissementId) {
  return service.get("users/etab/" + etablissementId + "/famEtGamme")
}

export function chargerRaisonsIncidents() {
  return service.get("IncidentsReclamations/raisonsIncidents")
}

export function chargerTypesReclamations() {
  return service.get("IncidentsReclamations/typesReclamations")
}

export function chargerInfosApp() {
  return service.get("definitions/infosApp")
}

export function chargerIngredient(ingredientId) {
  return service.get("definitions/ingredients/" + ingredientId)
}

export function chargerTypesComposantes() {
  return service.get("definitions/typesComposantes")
}

export function chargerTypesConvives() {
  return service.get("definitions/typesConvives")
}

export function chargerServices() {
  return service.get("definitions/services")
}

export function chargerPeriodesPlansAlimentaires() {
  return service.get("definitions/periodes")
}
