import axios from 'axios'
import store from '../store'
import interceptorsSetup from './interceptors'

  const serviceGPAO = axios.create({
    baseURL: process.env.VUE_APP_BASE_API_GPAO,
    timeout: process.env.VUE_APP_API_TIMEOUT
  })
  interceptorsSetup(serviceGPAO)
  serviceGPAO.interceptors.request.use(
    config => {
      if (store.getters["auth/token"]) {
        config.headers["Authorization"] = "Bearer " + store.getters["auth/token"].accessToken;
      }
      return config
    })

  export default serviceGPAO
