<template>
  <div v-if="recette">
    <div class="card-header flex-center-space-between">
      {{recette.nom}}
      <div class="flex-center-space-between">
        <!--       <span v-if="recette.lectureSeule && !routeMenus"><i class="far fa-eye" style="font-size:2.5rem"></i></span> -->
        <span v-if="!routeMenus && (profilIsAdmin || !recette.lectureSeule)" class="btn btn-success btn-sm" v-tooltip="{ content: 'Modifier la recette', placement: 'top' }" @click="routeRecetteEdition()">
          Modifier
        </span>
        <span v-if="!routeMenus" class="btn btn-success btn-sm" v-tooltip="{ content: 'Dupliquer la recette', placement: 'top' }" @click="dupliquerRecette()" style="margin-left:6px">
          Dupliquer
        </span>
        <span v-if="!routeMenus && (estPerso || estCuisine || profilIsAdmin)" class="btn btn-danger btn-sm" v-tooltip="{ content: 'Supprimer la recette', placement: 'top' }" @click.stop="destroy(recette.id,recette.nom)" style="margin-left:6px">
          Supprimer
        </span>
      </div>
    </div>
    <div class="card-body" style="background:white">
      <div v-if="recette.pourNombre && recette.up && recette.prixTotal && composanteEffectif && (composanteEffectif !== recette.pourNombre)" style="text-align:right">
        <!-- <span style="font-weight:bolder">{{ratioBaseSurComposanteMenu}}</span> -->
        <span style="font-weight:bolder;color: #2baf7f;">{{prixBaseSurComposanteMenu}}</span>
      </div>
      <div v-if="recette.pourNombre && recette.up && recette.prixTotal" class="push-bottom-1rem flex-center-space-between">
        <span style="font-weight:bolder;">PAR {{recette.up === 'Personnes' ? 'PERSONNE' : recette.up}} {{(recette.prixTotal/recette.pourNombre) | afficherPrix}} </span>
        <span style="font-weight:bolder;text-align:right;">POUR {{recette.pourNombre}} {{recette.up}} {{recette.prixTotal | afficherPrix}} </span>
      </div>
      <div class="push-bottom-1rem">
        <RecetteListeIngredients :recette="recette" :key="recette.id" />
      </div>
      <div v-if="recette.regimeIds && recette.regimeIds.length !== 0" class="push-bottom-1rem">
        <span class="titre-gras">Régimes alimentaires incompatibles</span><br /> {{ idsToSentence(recette.regimeIds, 'regimes') }}
      </div>
      <div v-if="recette.textureIds && recette.textureIds.length !== 0" class="push-bottom-1rem">
        <span class="titre-gras">Textures incompatibles</span><br /> {{ idsToSentence(recette.textureIds, 'textures') }}
      </div>
      <div v-if="recette.categorieRecetteId" class="push-bottom-1rem">
        <span class="titre-gras">Catégorie</span><br /> {{ idToWord(recette.categorieRecetteId, 'categorieRecettes') }}
      </div>
      <div v-if="recette.zonePreparationId" class="push-bottom-1rem">
        <span class="titre-gras">Zone de préparation</span><br /> {{ idToWord(recette.zonePreparationId, 'zonesPreparations') }}
      </div>
      <div v-if="recette.gemrcnIds && recette.gemrcnIds.length !== 0">
        <span class="titre-gras">Catégories GEMRCN</span><br /> {{ idsToSentence(recette.gemrcnIds, 'gemrcn') }}
      </div>
      <div v-if="recette.allergenes && recette.allergenes.length !== 0">
        <span class="titre-gras">Allergènes</span> (suivant vos favoris produits)<br /> {{ recette.allergenes.map(x => nomAllergene(x)).join(", ") }}
      </div>
      <div class="error" v-if="recette.saisieAllergenesIncomplete">Attention, la liste des allergènes est peut-être incomplète car ils n'ont pas été renseignés pour un ou plusieurs ingrédients
      </div>
      <div style="padding-top:15px;font-size:0.8em;">
        <em>L’affichage des allergènes est effectué à titre informatif. Chaque utilisateur du logiciel doit vérifier par lui-même la véracité de ces informations auprès du ou des fournisseurs de denrées alimentaires concernés.<br />
          En cas d’erreur, l’utilisateur ne pourra pas engager la responsabilité de la société Agap’pro, éditrice du logiciel.</em>
      </div>
    </div>
  </div>
</template>

<script>
import { supprimerRecette } from "@/api/gpao/recettes"
import { arrayToSentence } from "@/mixins/index"
import { constantesId } from "@/helpers/constantes"
import { connecteData } from "@/mixins/connecteData"
import { showAlerts } from '@/mixins/alerts'
import RecetteListeIngredients from "@/components/Recettes/RecetteListeIngredients"

export default {
  props: ["recette", "composanteEffectif", "destination"],
  components: {
    RecetteListeIngredients
  },
  mixins: [arrayToSentence, connecteData, showAlerts],
  computed: {
    estPerso() {
      return this.recette.estPerso;
    },
    estCuisine() {
      return this.recette.etablissementId == this.etabCourantId;
    },
    prixBaseSurComposanteMenu() {
      return (
        "POUR " +
        this.composanteEffectif +
        " " +
        this.recette.up +
        " " +
        (
          (this.composanteEffectif * this.recette.prixTotal) /
          this.recette.pourNombre
        )
          .toFixed(1)
          .toString()
          .replace(".", ",") +
        " €"
      )
    },
    routeMenus() {
      return this.$route.name === "Menus"
    },
    allergenes() {
      return constantesId.allergenes
    },
  },
  methods: {
    nomAllergene(id) {
      return this.allergenes.find(el => el.id === id).valeur
    },
    routeRecetteEdition() {
      this.$router.push({
        name: "RecetteEdition",
        params: { recetteId: this.recette.id }
      })
    },
    dupliquerRecette() {
      var opt = {
        name: "RecetteDupliquer",
        params: { recetteId: this.recette.id },
      }
      if (this.$route.name === "Menus") {
        opt.params.retour = {
          name: "Menus"
        }
        opt.params.destination = this.destination
      }
      this.$router.push(opt)
    },
    idsToSentence(ids, key) {
      let array = []
      ids.forEach(id => {
        array.push(
          this.$store.state.definitions[key].find(item => item.value === id)
        )
      })
      return this.arrayToSentence(array.map(item => item && item.text))
    },
    idToWord(id, key) {
      let item = this.$store.state.definitions[key].find(item => item.value === id)
      if (item) {
        return item.text
      }
    },
    async destroy(id, nom) {
      let suppressionOk = await this.alerteConfirmation('Supprimer la recette', "Souhaitez-vous supprimer la recette ?<br/><strong>" + nom + "</strong>");
      if (suppressionOk) {
        supprimerRecette(id)
          .then(() => this.$emit("recette-supprimee"))
          .catch(error => {
            console.error(error)
          })
      }
    }
  }
}
</script>

<style scoped>
.card-fixed {
  position: fixed !important;
}
.card-title-recette {
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.05rem;
  font-size: 1.5rem;
  padding: 0.5rem;
}
.links-container {
  display: flex;
  text-align: right;
}
.btn-black > i {
  color: black;
  background: white;
  padding: 10px;
  border-radius: 5px;
  margin-left: 0rem;
  width: 38px;
  text-align: center;
  cursor: pointer;
}
.card-links {
  display: flex;
  justify-content: space-between;
}
.UP-container {
  text-align: center;
  margin-top: 0.3rem;
}
</style>


