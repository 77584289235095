<template>
  <b-modal ref="modalSubstituerArticle" size="xl" title="Substitution d'article" hide-footer
    no-close-on-backdrop
    @show="initialiserModale()">
    <template slot="default">
      <div class="ml-1">
        <div class="row">
          <div class="col-2 small">Quantité demandée</div>
          <div v-if="ingredient.produitId" class="col-10 small">Article sélectionné pour substitution</div>
          <div v-else class="col-10 small">Ingrédient sélectionné</div>
        </div>
        <div class="row">
          <div class="col-2">
            <h5>{{ingredient.quantiteBesoinEnUC}} {{ingredient.uc}}</h5>
          </div>
          <div class="col-10">
            <h5>
              {{ingredient.produitId && ingredient.designation ? ingredient.designation.toUpperCase() : nomIngredient.toUpperCase()}}
              {{ingredient.marque ? ' - ' + ingredient.marque : ''}}
              {{ingredient.fournisseurId ? ' - ' + fournisseurNom(ingredient.fournisseurId) : ''}}<br />
              <span v-if="ingredient.prixUnitaire && ingredient.uc">({{ingredient.prixUnitaire}} € / {{ingredient.uc}}, prix total: {{(ingredient.quantiteBesoinEnUC * ingredient.prixUnitaire) | afficherPrix}})</span>
            </h5>
          </div>
        </div>
      </div>
      <div class="flex-start ml-1 mt-3 mb-1 small">Articles associés à l'ingrédient "{{nomIngredient}}"</div>
      <div v-if="articles.parProduit">
        <!--<ArticlesParProduit :articles="articles.parProduit" :favoris="articles.favoris" :ingredient="ingredient" :gestion-favori-uniquement="gestionFavoriUniquement" :recette="recette" :recettes="recettes" :key="articlesParProduitKey" @selection-article="articleSelectionne" @toggle-favori="toggleFavori" />-->
        <ArticlesParProduit :articles="articles.parProduit" :favoris="articles.favoris" :ingredient="ingredient" :gestion-favori-uniquement="gestionFavoriUniquement" :recette="recette" :key="articlesParProduitKey" @selection-article="articleSelectionne" @toggle-favori="toggleFavori" />
      </div>
    </template>
  </b-modal>
</template>

<script>
import ArticlesParProduit from '@/components/Stock/ArticlesParProduit'
import { connecteData } from '@/mixins/connecteData'
import { fournisseurs } from '@/mixins/fournisseurs'
import { tousArticlesPour, tousArticlesFamilles } from '@/api/gpao/mercuriales'
import { mapGetters } from "vuex"

export default {
  name: "ModalSubstitutionArticle",
  props: {
    /*propIngredient: Object,
    small: Boolean,
    recette: Object,
    recettes: [],*/
    gestionFavoriUniquement: Boolean,
    idsBloques: [] //Id des fournisseurs non sélectionnables
  },
  components: { ArticlesParProduit, },
  mixins: [fournisseurs, connecteData],
  data() {
    return {
      articles: {},
      recette: {},
      ingredient: {},
      articlesParProduitKey: 1,
      favorisModifies: false // indique si on a modifié un favori
    }
  },
  computed: {
    ...mapGetters("definitions", ["obtenirIngredient"]),
    nomIngredient() {
      return this.obtenirIngredient(this.ingredient?.ingredientId)?.nom || ""
    }
  },
  methods: {
    initialiserModale() {
      /*if (this.propIngredient) {
        this.ingredient = this.propIngredient
      }*/
      this.obtenirArticles(this.ingredient)
    },
    /*surFermeture() {
      this.$emit("sur-fermeture", { ingredient: this.ingredient, favorisModifies: this.favorisModifies })
    },*/
    async obtenirArticles(besoin) {
      let response = {}
      if (besoin.familleId) {
        response = await tousArticlesPour(this.etabCourantId, besoin.ingredientId, besoin.familleId, besoin.grammage)
        response.data = [response.data]
      } else {
        response = await tousArticlesFamilles(this.etabCourantId, besoin.ingredientId, null, besoin.grammage)
      }

      let nbArticles = 0
      this.ingredientChoisi = besoin
      let articles = { ingredientId: besoin.ingredientId, parProduit: {}, favoris: [] }
      response.data.forEach(a => {
        for (let key in a.parProduit) {
          articles.parProduit[key] = a.parProduit[key]
          articles.parProduit[key].forEach(p => { p.familleId = a.familleId })
        }
        if (a.favoris?.length) {
          articles.favoris.push(...a.favoris)
        }
      })
      for (let key in articles.parProduit) {
        let produits = articles.parProduit[key]
        nbArticles += produits.length
        produits.forEach(p => {
          if (this.idsBloques && this.idsBloques.indexOf(p.fournisseurId) !== -1) {
            p.bloque = true
          }
        })
      }
      this.articles = articles

      if (nbArticles > 0) {
        this.show()
      } else {
        this.$toast('Aucune substitution trouvée avec ces paramètres', {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          className: ['toast-error'],
          duration: 1500
        })
      }
    },
    articleSelectionne(event) {
      this.$emit('selection-article', event)
      this.hide()
    },
    toggleFavori(payload) {
      this.favorisModifies = true
      this.$emit("toggle-favori", payload)
    },
    show(ingredient) {
      if (ingredient) {
        this.ingredient = ingredient
      }
      if (this.ingredient?.recette) {
        this.recette = this.ingredient.recette
      }
      this.articlesParProduitKey++ //pour forcer le refresh de l'articles dans le composant ArticlesParProduit .... afin de bien voir le moinsdisant entouré de vert
      this.$refs.modalSubstituerArticle.show()
    },
    hide() {
      this.$refs.modalSubstituerArticle.hide()
    }
  }
}
</script>