export const optionsJours = {
  data() {
    return {
      optionsJours: [
        {id: 1, nom: 'Lundi', nomCourt: "Lun."},
        {id: 2, nom: 'Mardi', nomCourt: "Mar."},
        {id: 3, nom: 'Mercredi', nomCourt: "Mer."},
        {id: 4, nom: 'Jeudi', nomCourt: "Jeu."},
        {id: 5, nom: 'Vendredi', nomCourt: "Ven."},
        {id: 6, nom: 'Samedi', nomCourt: "Sam."},
        {id: 0, nom: 'Dimanche', nomCourt: "Dim."},
      ]
    }
  }
}
