<template>
  <b-modal ref="modalChoisirFournisseur" size="xs" :title="'Sélection d\'un fournisseur'"
    ok-title="Choisir"
    cancel-title="Annuler"
    no-close-on-backdrop
    @show="initialiserModale()"
    @ok="choisirFournisseur()">
    <template slot="default">

      <b-container v-if="isLoading">
        <b-row>
          <b-col></b-col>
          <b-col>
            <img alt="en attente" src="/assets/images/spinners/cooking-blue-court.gif" />
          </b-col>
          <b-col></b-col>
        </b-row>
      </b-container>

      <div v-else class="row">
        <div class="col-12">
          <div class="card">
            <!--<div class="card-header flex-start">
              <label class="label-checkbox mb-2">
                <span class="ml-2">Fournisseurs disponibles</span>
              </label>
            </div>-->
            <div class="card-body">
              <div class="flex-center">
                <b-form-radio-group v-model="selectFour" :options="optionsFourn" stacked name="type-de-recherche" ref="radio" />
              </div>
              <!--<label class="label-checkbox" v-for="(fourn) in fournisseursVueGenerale" :key="fourn.id">
                <input type="checkbox">
                {{ fourn.nom }}
              </label>-->
            </div>
          </div>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex"
import { connecteData } from "@/mixins/connecteData"
import { showAlerts } from '@/mixins/alerts'
import { choisirFournisseur, listerFournisseursPossibles } from '@/api/gpao/commandes'

export default {
  name: "ModalChoisirFournisseur",
  mixins: [ connecteData, showAlerts ],
  props: {
    commande: {type: Object, required: true},
    disabled: {type: Boolean}
  },
  data() {
    return {
      fournisseurs: [],
      optionsFourn: [],
      fournisseurPossible: [],
      selectFour: 0,
      isLoading: false
    }
  },
  validations: {

  },
  computed: {
    ...mapGetters("definitions", ["environnementProduction"]),
    ...mapGetters("fournisseurs", ["nomFournisseur", "fournisseursCourants"]),
  },
  watch: {

  },
  mounted() {
    
  },
  methods: {
    async initialiserModale() {
      this.isLoading = true
      this.selectFour=0
      let ret = await listerFournisseursPossibles(this.commande)
      this.fournisseurPossible = ret.data
      this.fournisseursVueGenerale()
      this.isLoading = false
    },
    fournisseursVueGenerale() {
      this.optionsFourn = []
      
      this.fournisseurPossible.forEach(f => {
        let four = this.fournisseursCourants.find(c => c.id == f)
        let element = {}
        element.value = four.id
        element.text = four.nom
        this.optionsFourn.push(element)
      })

/*
      this.fournisseursCourants.forEach(f => {
        if (this.etabCourant.choixFournisseurs.find(c => c.actif && c.foId === f.id)) {
          
          let test = this.commande.lignes.some(l => f.familles.includes(l.faId))
          if (this.commande.lignes.some(l => f.familles.includes(l.faId))) {
            let element = {}
            element.value = f.id
            element.text = f.nom
            this.optionsFourn.push(element)
          }
        }
      })
      */
      //this.optionsFourn.sort((a, b) => a.info > b.info ? -1 : 1)
    },
    async choisirFournisseur() {
      let select = this.selectFour

      this.$store.commit('uxHelpers/showSpinner')
      let response = await choisirFournisseur(select, this.commande)
      this.$emit('commande-envoyee', response.data)
      this.$store.commit('uxHelpers/hideSpinner')

      this.hide()
    },
    show(fournisseurId) {
      if (fournisseurId) {
        this.fournisseurId = fournisseurId
      }
      this.$refs.modalChoisirFournisseur.show()
    },
    hide() {
      this.$refs.modalChoisirFournisseur.hide()
    }
  }
}
</script>
