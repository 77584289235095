<template>
  <div class="form-body" :key="key">
    <div class="row">
      <div class="col-9">
        <div class="form-group">
          <label for="nom">Nom</label>
          <input @focus="$event.target.select()" class="form-control" id="nom" type="text" maxlength="80" v-model="planAlimentaire.nom">
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label>Période</label>
          <b-form-select v-model="planAlimentaire.periodeId" :options="optionsPeriodes"></b-form-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label>Convives impactés</label>
          <MultiSelect :value="planAlimentaire.typesConvivesIds" :items="optionsConvives" placeholder="Convives..." emptyText="Tous les convives ont été ajoutés" @change="planAlimentaire.typesConvivesIds = $event" />
        </div>
      </div>
    </div>
      
    <div class="push-top-1rem">
        <div class="row">
          <div class="col-12" ref="effectifs">
            <div v-for="(service, iService) in planAlimentaire.services" :key="iService" :id="'accordion' + iService">
              <div class="card no-margin-bottom">
                <div class="card-header cursor-pointer" :id="'headingOne' + iService" data-toggle="collapse" :data-target="'#collapseOne' + iService" aria-expanded="true" :aria-controls="'collapseOne' + iService">
                  <h5>
                    {{ service.nom }}
                  </h5>
                </div>

                <div :id="'collapseOne' + iService" :class="service.composantes.length ? 'collapse.show' : 'collapse'" :aria-labelledby="'headingOne' + iService" :data-parent="'#accordion' + iService">
                  <div class="card-body">

                    <b-button class="btn-sm btn-secondary" @click="ajoutComposante(service)"><i class="fas fa-plus"></i> composante</b-button>
                    
                    <table class="table" style="table-layout:fixed;word-wrap:break-word;">
                      <colgroup>
                        <col>
                        <col width="300">
                        <col width="70">
                      </colgroup>
                      <tbody>
                        <tr v-for="(composante, iComposante) in service.composantes" :key="iComposante" class="ligne-composante-titre">
                          <td>
                            <div>{{ composante.nom }}</div>
                          </td>
                          <!--<td>
                            <div style="width: 120px;display: inline-block;">
                              <b-form-select v-if="composante.index !== 0" v-model="composante.regimeId" :options="optionsRegimes" @change="
                                composanteRT($event, composante, 'regime' ) ">
                                <template slot="first">
                                  <option :value="null" disabled>Régime...</option>
                                </template>
                              </b-form-select>
                            </div>
                            <div style="width: 120px;display: inline-block;">
                              <b-form-select v-if="composante.index !== 0" v-model="composante.textureId" :options="optionsTextures" @change="composanteRT($event, composante, 'texture')" style="margin-left:15px;margin-right:10px">
                                <template slot="first">
                                  <option :value="null" disabled>Texture...</option>
                                </template>
                              </b-form-select>
                            </div>
                          </td>-->
                          <td class="flex-center-space-between cursor-pointer">
                            <!--<div v-if="composante.index === 0" @click="dupliquerComposante(service, composante)" v-tooltip="{content: 'Dupliquer la composante', placement: 'top'}">
                              <i class="far fa-copy"></i>
                            </div>
                            <div v-else style="width:15px"></div>-->
                            <div style="width:15px"></div>
                            <div @click="effacerComposante(service, composante)" v-tooltip="{content: 'Supprimer la composante', placement: 'top'}">
                              <i class="fas fa-times"></i>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    <div class="form-actions flex-right">
      <b-button type="submit" :disabled="enregistrementEnCours" variant="success" alt="Enregistrer" @click="enregistrerPlan" style="float:right;">Enregistrer</b-button>
      <b-button type="submit" variant="danger" alt="Annuler" style="float:right;margin-right:10px;" @click="annulerEdition">Annuler</b-button>
    </div>
  </div>
</template>

<script>
import _ from "lodash"
import { connecteData } from "@/mixins/connecteData"
import { enregistrerPlan } from '@/api/gpao/plansAlimentaires'
import { showAlerts } from "@/mixins/alerts"
import MultiSelect from "@/components/Inputs/MultiSelect"

export default {
  name: "EditionPlanAlimentaire",
  props: { "plan": Object },
  components: { MultiSelect },
  mixins: [ connecteData, showAlerts ],
  data() {
    return {
      key: 1,
      planAlimentaire: {...this.plan},
      optionsComposantes: this.$store.state.definitions.typesComposantes,
      optionsConvives: this.$store.state.definitions.typesConvives,
      optionsServices: this.$store.state.definitions.typesServices,
      optionsPeriodes: this.$store.state.definitions.periodesPlansAlimentaires,
      optionsRegimes: this.$store.state.definitions.regimes,
      optionsTextures: this.$store.state.definitions.textures,
      enregistrementEnCours: false
    }
  },
  mounted() {
    if(!this.planAlimentaire.services) {
      this.planAlimentaire.services = []
    }
    if (!this.planAlimentaire.services.length) {
      [...this.optionsServices].sort((a, b) => a.ordre - b.ordre).forEach(s => {
        this.planAlimentaire.services.push({
          id: s.value,
          nom: s.text,
          composantes: []
        })
      })
    }
    this.key += 1
  },
  computed: {
  },
  methods: {
    saisieValide() {
      let message = ""
      if (!this.planAlimentaire.nom)
        message += "Le nom est obligatoire.<br>"
      if (!this.planAlimentaire.periodeId)
        message += "La période est obligatoire.<br>"
      if (!this.planAlimentaire.typesConvivesIds || !this.planAlimentaire.typesConvivesIds.length)
        message += "Au moins un type de convive doit être sélectionné.<br>"
      if (message) {
        this.alerteErreur(message)
        return false
      }
      return true
    },
    async ajoutComposante(service) {
      let htmlComposante = ""
      let optionsComposantes = null
      if (service.composantes.length === 0) {
        optionsComposantes = this.optionsComposantes
      } else {
        let optionsDejaPresentes = service.composantes.map(c => {
            return this.optionsComposantes.find(
              comp => comp.value === c.composanteId
            )
          })
          optionsComposantes = _.difference(this.optionsComposantes, optionsDejaPresentes)
      }
      optionsComposantes.forEach(element => {
        htmlComposante += `<div style="text-align:left;margin-left:30%"><div class="custom-control custom-checkbox"><input type="checkbox" class="custom-control-input" name="${element.value}" value="${element.value}" id="${element.value}"><label for="${element.value}" class="custom-control-label">${element.text}</label></input></div></div>`
      })
      let checkboxIds = []
      optionsComposantes.forEach(element => {
        checkboxIds.push(element.value)
      })
      let composantesSelectionnees = []
      await this.$swal.fire({
        title: "Ajouter des composantes",
        html: htmlComposante,
        focusConfirm: false,
        preConfirm: () => {
          return [
            checkboxIds.forEach(id => {
              if (document.getElementById(id).checked) {
                composantesSelectionnees.push(
                  document.getElementById(id).value
                )
              }
            })
          ]
        }
      })
      if (composantesSelectionnees.length) {
        composantesSelectionnees.forEach(id => {
          let composante = this.optionsComposantes.find(c => c.value === id)
          service.composantes.push({
            id: id,
            index: 0,
            nom: composante.text/*,
            regimeId: constantesId.regimeNormal,
            textureId: constantesId.textureNormale*/
          })
        })
      }
      this.key += 1
    },
    /*composanteRT(id, composante, categorie) {
      if (categorie === "regime") {
        composante.regimeId = id
      }
      if (categorie === "texture") {
        composante.textureId = id
      }
    },
    dupliquerComposante(service, composante) {
      let indexGeneral = service.composantes.length - 1 - service.composantes.reverse().findIndex(
        c => c.id === composante.id
      )
      let composantes = service.composantes.reverse().filter(
        c => c.id === composante.id
      )
      let newCompo = { ...composantes[composantes.length - 1] }
      newCompo.id = -1
      newCompo.index++
      service.composantes.splice(indexGeneral + 1, 0, newCompo)
      composantes.push(newCompo)
      this.key += 1
    },*/
    effacerComposante(service, composante) {
      _.remove(service.composantes, c => c.id === composante.id && c.index === composante.index)
      this.key += 1
    },
    async enregistrerPlan() {
      this.enregistrementEnCours = true
      if (!this.saisieValide()) {
        this.enregistrementEnCours = false
        return
      }
      await enregistrerPlan(this.planAlimentaire, this.etabCourantId)
        .then(() => this.$emit("enregistrer-plan", this.planAlimentaire))
        .finally(() => this.enregistrementEnCours = false)
    },
    annulerEdition() {
      this.$emit("annuler-edition")
    },
  }
}
</script>

<style lang="scss" scoped>
.label-checkbox {
  margin: 0 5px;
}
</style>
