<template>
  <div id="app" data-app>
    <Spinner message="Traitement en cours" v-if="spinnerShown" />
    <topbar v-if="estAuthentifie && etabCourantId" />
    <div v-if="estAuthentifie && etabCourantId" class="app-content content">
      <div class="content-wrapper">
        <div class="content-body">
          <router-view :key="$route.fullPath" />
        </div>
      </div>
    </div>
    <div v-else>
      <b-modal v-model="afficherLogin" no-close-on-backdrop hide-header hide-footer>
        <Login />
      </b-modal>
    </div>
  </div>
</template>
<script>
import topbar from "@/components/TopBar";
import { mapGetters } from "vuex";
import Spinner from '../src/components/Spinner'
import Login from '@/views/Login'
import { connecteData } from "@/mixins/connecteData"
import { objetVide } from '@/mixins/index'
import { chargerMercuriales } from '@/helpers/mercuriales'

export default {
  name: "app",
  components: { topbar, Spinner, Login },
  mixins: [objetVide, connecteData],
  data() {
    return {
      notificationSystem: {
        options: {
          error: {
            position: "topRight",
            timeout: 0
          }
        }
      }
    };
  },
  watch: {
    '$route'(to /*, from*/) {
      document.title = to.meta.title || 'MercuDyn'
    }
  },
  mounted() {
    this.initMercuriales()
  },
  updated() {
   
  },
  computed: {
    ...mapGetters("auth", ["estAuthentifie"]),
    afficherLogin() {
      return !(this.etabCourantId && this.estAuthentifie)
    },
    spinnerShown() {
      return this.$store.getters['uxHelpers/spinnerShown']
    }
  },
  methods: {
    disalowClickOutside(e) {
      e.preventDefault()
    },
    initMercuriales() {
      if (this.estAuthentifie && this.etabCourantId) {
        chargerMercuriales(this.etabCourantId, null, null, this.profilLecteurMercu)
      }
    }
  }
}
</script>

<style lang="scss">
@import "./assets/css/style.scss";
@import "./assets/css/font-awesome5.css";
</style>
