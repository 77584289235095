<template>
  <b-modal ref="modalRecetteEdition" size="xxl" :title="'Recette : ' + recetteAModifierNom"
    modal-class="height-responsive" body-class="height-responsive-body height-responsive-0b"
    ok-title="Enregistrer" :ok-disabled="this.disableEnregistrement"
    cancel-title="Annuler"
    no-close-on-backdrop
    @show="initialiserModale()"
    @hide="verifierAValider($event)"
    @ok="enregistrerDonneesModifiees()">
    <template slot="default">
      <RecetteEdition ref="recetteEdition" :recetteId="recetteAModifierId" :enAjout="false" :enDuplication="false" :lienRecetteActif="false"
        @recette-initialisee="recetteInitialisee($event)"
        @recette-modifiee="recetteModifiee($event)"
        @recette-enregistree="recetteEnregistree($event)"
        @recette-enregistree-modifiee="recetteEnregistreeModifiee($event)"
        @recette-enregistree-inchangee="recetteEnregistreeInchangee($event)" />
    </template>
  </b-modal>
</template>

<script>

import { showAlerts } from "@/mixins/alerts"

export default {
  name: "ModalRecetteEdition",
  props: [ "recetteId" ],
  mixins: [ showAlerts ],
  components: {
    RecetteEdition: () => import('@/components/Recettes/RecetteEdition.vue')
  },
  data() {
    return {
      recetteAModifierId: this.recetteId,
      recetteAModifierNom: null,
      enEnregistrement: false,
      disableEnregistrement: true,
      recetteInvalide: false,
      aValider: true
    }
  },
  methods: {
    initialiserModale() {
      // Initialisation de l'état de la modale
      this.enEnregistrement = false
    },
    recetteInitialisee(donnees) {
      this.recetteAModifierNom = donnees.recette.nom
    },
    recetteModifiee(donnees) {
      this.disableEnregistrement = !donnees.enregistrementPossible
      this.recetteInvalide = donnees.recetteInvalide
      this.aValider = donnees.aValider
    },
    recetteEnregistreeModifiee(donnees) {
      this.$emit("recette-enregistree-modifiee", donnees)
    },
    recetteEnregistreeInchangee(donnees) {
      this.$emit("recette-enregistree-inchangee", donnees)
    },
    recetteEnregistree(donnees) {
      this.$emit("recette-enregistree", donnees)
      this.enEnregistrement = false
      this.aValider = false
      this.hide()
    },
    async enregistrerDonneesModifiees() {
      this.enEnregistrement = true
      try {
        await this.$refs.recetteEdition.enregistrerDonneesModifiees()
      } catch (erreur) {
        this.enEnregistrement = false
        this.alerteErreur("La recette n'a pas pu être enregistrée !", erreur)
        console.error("erreur close Modal - Validation déclinaison de recette")
      }
    },
    async verifierAValider(event) {
      if (this.enEnregistrement) {
        event.preventDefault()
      }
      if (this.aValider) {
        event.preventDefault()
        let result = await this.alerteConfirmation("Valider la recette", "Cette recette n'a jamais été modifiée.<br/>Vous convient-elle ?")
        if (result) {
          await this.enregistrerDonneesModifiees()
        } else {
          this.aValider = false
          this.hide()
        }
      }
    },
    show(recetteId) {
      this.recetteAModifierId = recetteId
      this.$refs.modalRecetteEdition.show()
    },
    hide() {
      this.$refs.modalRecetteEdition.hide()
    }
  }
}
</script>
