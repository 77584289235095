<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header bg-gradient-bleu text-white">
            <div class="card-title ">Liste des réclamations</div>
          </div>
          <div class="card-body">
            <b-table striped hover :items="reclamations" :fields="fields">
              <template v-slot:cell(dateReclamation)="data">
                <AffichageDate :date="data.value" />
              </template>
              <template v-slot:cell(montantAvoir)="data">
                <span>{{data.value}}</span><span class="ml-1" v-if="data.value>0">€</span>
              </template>
              <template v-slot:cell(dateRelivraisonSouhaitee)="data">
                <AffichageDate :date="data.value" />
              </template>
              <template v-slot:cell(estTraitee)="data">
                <i v-if="data.value" class="fas fa-check text-success"></i>
                <i v-else class="fas fa-times text-danger"></i>
              </template>
              <template v-slot:cell(id)="data">
                <b-btn v-if="!data.item.estTraitee" class="btn btn-primary" @click="traiter(data.value)">Marquer traitée</b-btn>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { connecteData } from "@/mixins/connecteData"
import AffichageDate from '@/components/Divers/AffichageDate'
import { listerReclamations, traiterReclamation } from "@/api/gpao/reclamation"
export default {
  components: { AffichageDate },
  mixins: [connecteData],
  data() {
    return {
      reclamations: [],
      fields: [
        { key: 'dateReclamation', label: 'Date de réclamation', },
        { key: 'nomCommande', label: 'Nom de la commande' },
        'fournisseur',
        { key: 'designationCommandeLigne', label: 'Désignation' },
        'type',
        { key: 'raisonIncident', label: 'Raison de l\'incident' },
        { key: 'montantAvoir', label: 'Montant de l\'avoir' },
        { key: 'dateRelivraisonSouhaitee', label: 'Date de relivraison' },
        { key: 'estTraitee', label: 'Est traitée' },
        { key: 'id', label: 'Action' }]
    }
  },
  methods: {
    hydraterReclamations(reclamations) {
      for (let i = 0; i < reclamations.length; ++i) {
        reclamations[i].fournisseur = this.$store.state.fournisseurs.fournisseursCourants.find(element => element.id == reclamations[i].fournisseurId).nom
        reclamations[i].type = this.$store.state.definitions.typesReclamations.find(element => element.code == reclamations[i].type).designation
      }
      return reclamations;
    },
    traiter(reclamationId){
      return traiterReclamation(reclamationId).then(() => {
        this.reclamations.find(x => x.id === reclamationId).estTraitee = true
        return
      })
    }
  },
  mounted() {
    return listerReclamations(this.etabCourantId).then((response) => {
      this.reclamations = this.hydraterReclamations(response.data)
      return
    });
  }
}
</script>