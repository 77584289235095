const sanitize = require("sanitize-filename");

export { Nombre, afficherDecimal, arrondiInferieur, arrondirDecimales, arrondiNombre, arrondiMercuriale, arrondiPrix, qtePCB, prixPCB, arrondiPrixPCB, fMod, lancerTelechargement, lancerTelechargementParUrl, lancerTelechargementDeResponse, mettreLesInitialesEnMajuscules, abregerTexte, couleurTexteSelonFond, formaterPourRecherche, rechercherTexte, rechercherMultiTexte, plurielUC, phraseConversion }

function Nombre(texte) {
  return Number(texte.replace(',', '.'))
}

function afficherDecimal(valeur) {
  if (valeur == undefined)
    return ""
  return valeur.toString().replace('.', ',')
}

   /** https://stackoverflow.com/a/7592235/130420
 * Capitalizes first letters of words in string.
 * @param {string} str String to be modified
 * @param {boolean=false} lower Whether all other letters should be lowercased
 * @return {string}
 * @usage
 *   capitalize('fix this string');     // -> 'Fix This String'
 *   capitalize('javaSCrIPT');          // -> 'JavaSCrIPT'
 *   capitalize('javaSCrIPT', true);    // -> 'Javascript'
 */
const mettreLesInitialesEnMajuscules = (str, lower = false) =>
(lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());

// Source: https: //www.jacklmoore.com/notes/rounding-in-javascript/
// function arrondiNombre(value, decimals) {
//   return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
// }

function abregerTexte(texte, longueur) {
  if (!texte?.length)
    return ""
  if (texte.length <= longueur)
    return texte
  return texte.substring(0, longueur - 3) + "..."
}

function couleurTexteSelonFond(couleur, couleurParDefaut, couleurSurFonce) {
  // Il s'agit du seuil de déclenchement du changement de couleur (il faut ajuster cette valeur en cas de problème de lisibilité sur les couleurs intermédiaires)
  let seuilNuance = 22

  if (!couleurParDefaut)
    couleurParDefaut = "black"
  if (!couleurSurFonce)
    couleurSurFonce = "white"

  if (couleur) {
    let c = couleur.replace('#', '')

    // On ne prend que le caractère hexadécimal fort de chaque couleur
    let rouge = parseInt(c.substring(0, 1), 16)
    let vert = parseInt(c.substring(2, 3), 16)
    let bleu = parseInt(c.substring(4, 5), 16)

    if (rouge + vert + bleu < seuilNuance) {
      return couleurSurFonce
    }
  }
  
  return couleurParDefaut
}

function arrondiInferieur(value, dec) {
  dec = Number(dec) || 3
  let p = Math.pow(10, dec)
  return Number(Math.floor(value * p) / p)
}

function arrondirDecimales(nombre) {
  return nombre = Math.round((nombre + Number.EPSILON) * 100) / 100
}

function arrondiNombre(value, dec) {
  dec = Number(dec) || 3
  return Number(Math.round(value + 'e' + dec) + 'e-' + dec)
}

function arrondiMercuriale(value, dec) {
  return arrondiNombre(value, dec).toString().replace('.', ',')
}

function arrondiPrix(value, dec) {
  return arrondiMercuriale(value, dec) + ' €'
}

function qtePCB(qteDem, pcb) {
  if (!qteDem || !pcb) {
    return 0
  }
  // Au bûcher Javascript !!! il est incapable de réaliser des calculs sur des virgules flottantes
  return (Math.ceil(((qteDem * 1000) / (pcb * 1000)).toFixed(3)) * (pcb * 1000)) / 1000
}

function prixPCB(qteDem, pcb, prixUnitaire) {
  if (!prixUnitaire) {
    return 0
  }
  return qtePCB(qteDem, pcb) * prixUnitaire
}

function arrondiPrixPCB(qteDem, pcb, prixUnitaire, dec) {
  return arrondiPrix(prixPCB(qteDem, pcb, prixUnitaire), dec)
}

// Calcul du modulo de valeurs décimales
function fMod(numerateur, denominateur) {
  let div = Math.floor(numerateur / denominateur)
  return numerateur - (div * denominateur)
}

function lancerTelechargement(blob, nomFichier) {
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, nomFichier);
    return;
  }
  const url = window.URL.createObjectURL(blob)
  lancerTelechargementParUrl(url, nomFichier);
}

function lancerTelechargementParUrl(url, nomFichier) {
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', sanitize(nomFichier))
  document.body.appendChild(link)
  link.click()
  link.remove()
  window.URL.revokeObjectURL(url)
}

function lancerTelechargementDeResponse(response) {
  const contentDisposition = response.headers['content-disposition'];
  let nomFichier = 'fichier';
  if (contentDisposition) {
    const fileNameMatch = contentDisposition.match(/filename=(.+);/);
    if (fileNameMatch.length === 2)
      nomFichier = fileNameMatch[1];
  }
  lancerTelechargement(response.data, nomFichier)
}

function formaterPourRecherche(texte) {
  if (!texte) {
    return ""
  }
  return texte.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[\u0152-\u0153]/g, "oe")
}

function rechercherTexte(texte, recherche) {
  // Recherche du texte dans un texte
  return formaterPourRecherche(texte).indexOf(formaterPourRecherche(recherche)) !== -1
}

function rechercherMultiTexte(texte, recherche) {
  // Recherche du texte dans un texte
  return formaterPourRecherche(recherche).split(" ").every(r => rechercherTexte(texte, r))
}

function plurielUC(val, uc) {
  //permet de mettre au pluriel l'UC passé en parametre en fonction de la valeur
  if (val > 1) {
    if (uc.toLowerCase() != "kg" && uc.toLowerCase() != "seau")
      return uc + "s"
    else if (uc.toLowerCase() === "seau")
      return uc + "x"
    else
      return uc
  } else {
    return uc
  }
}

function phraseConversion(pcb, uc, nbPCE, nbKg, nbL, qteCmd) {
  var phrase = ""

  if (pcb > 0) {
    let qtPcb = qtePCB(qteCmd, pcb) / pcb
    let poidsPiece = arrondirDecimales(nbKg / nbPCE)
    let poidsLitre = arrondirDecimales(nbL / nbPCE)
    let nbPiecesKg = (nbPCE > 0 ? arrondirDecimales(nbPCE * qtPcb) + " pièce" + ((arrondirDecimales(nbPCE * qtPcb)) > 1 ? "s" : "") + " de " + poidsPiece + " kg" : "") 
    let nbPiecesL = (nbPCE > 0 ? arrondirDecimales(nbPCE * qtPcb) + " pièce" + ((arrondirDecimales(nbPCE * qtPcb)) > 1 ? "s" : "") + " de " + poidsLitre + " " + plurielUC(poidsLitre, "litre") : "") 

    phrase += "Si je commande " + arrondirDecimales(qteCmd) + " " + plurielUC(qteCmd, uc) + " je recevrai "

    if (nbKg > 0) {
      phrase += arrondirDecimales(nbKg * qtPcb) + " kg"

      if (nbPCE > 0 || nbL > 0) {
        phrase += " (soit " + nbPiecesKg + (nbPCE > 0 && nbL > 0 ? " ou " + nbPiecesL : "")  + ")."
      } else {
        phrase += "."
      }
    } else if (nbPCE > 0 || nbL > 0) {
      let tarParL = arrondirDecimales(nbL * qtPcb)

      phrase += tarParL + " " + plurielUC(tarParL, "litre")

      phrase += " (soit " + nbPiecesL + ")."
    } else
      phrase = null

  } else
    phrase = "Aucun PCB n'est paramétré."

  return phrase
}