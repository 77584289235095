<template>
  <div style="display:inline">
    <div class="text-center mb-2">
      <img class="logo" alt="mercudyn" src="../assets/images/logo/logo-mercudyn-RVB-72pp.png" />
    </div>
    <div class="font-small-3 text-muted text-center mb-4">
      <q>{{motd}}</q>
    </div>

    <form @submit.prevent="login">
      <div v-if="erreurLogin">
        <div class="alert alert-warning" role="alert">
          {{ erreurLogin }}
        </div>
      </div>
      <div v-if="!selectEtablissementVisible">
        <div class="form-group mb-0">
          <label for="user-name">Nom d'utilisateur</label>
          <fieldset class="position-relative has-icon-left">
            <input v-model="userName" type="text" required class="form-control" id="user-name" autofocus autocomplete="username" placeholder="Nom d'utilisateur" ref="login-username" />
            <div class="form-control-position" style="top:0;">
              <i class="fas fa-user"></i>
            </div>
          </fieldset>
        </div>
        <div class="form-group mb-2">
          <label for="user-password">Mot de passe</label>
          <fieldset class="position-relative has-icon-left">
            <input v-model="password" :type="afficherMdP ? 'text' : 'password'" required class="form-control" id="user-password" autocomplete="current-password" placeholder="Mot de passe" />
            <div class="form-control-position" style="top:0;">
              <i class="fas fa-key"></i>
            </div>
            <div class="form-control-position" style="top:0;right:0;cursor:pointer;" :title="afficherMdP ? 'masquer le mot de passe' : 'afficher le mot de passe'" @click="afficherMdP = !afficherMdP">
              <i :class="afficherMdP ? 'fas fa-eye' : 'fas fa-eye-slash'"></i>
            </div>
          </fieldset>
        </div>
      </div>
      <SelectEtablissement v-if="selectEtablissementVisible" @etablissement-selectionne="changerEtablissement($event)" @profil-selectionne="changerProfil($event)" />
      <div v-if="erreur" class="erreur">{{erreur}}</div>
      <button v-if="!selectEtablissementVisible" type="submit" class="btn btn-primary btn-block" :disabled="isLoading" :class="{ boutonDesactive: isLoading }">
        <i :class="['fas', 'fa-lock', {'fa-spin':isLoading}]"></i>
        <span class="ml-2">Connexion</span>
      </button>
    </form>

    <div v-if="!selectEtablissementVisible" class="mt-2">
      <span class="float-right font-small-3"><a class="card-link" href="https://adherents.agap.pro/Identity/Account/ForgotPassword">Mot de passe oublié ?</a></span>
    </div>
  </div>
</template>

<script>
import vm from "@/helpers/vm-toolkit-vue"
import lscache from 'lscache'
import SelectEtablissement from '@/components/Etablissements/SelectEtablissement'
import { logConnexion } from '@/api/gpao/user'
import { setUserBugsnag } from "@/helpers/bugsnagM5"
import { AUTH_REQUEST } from "../store/actions/auth"
import { connecteData } from "@/mixins/connecteData"
import { chargerMercuriales } from '@/helpers/mercuriales'
import { viderStore, chargerInfosApp, chargerDefinitions, chargerDonneesEtablissement } from '@/helpers/definitions'
import { viderMercuriales } from '@/helpers/mercuriales'

export default {
  name: "login",
  mixins: [ connecteData],
  components: {
    SelectEtablissement
  },
  data() {
    return {
      isLoading: false,
      userName: "",
      password: "",
      erreurLogin: "",
      selectEtablissementVisible: false,
      motd: '',
      erreur: false,
      afficherMdP: false
    };
  },
  created() {
    this.nettoyerCache()
    var env = vm.whereAmI()
    if (env.browser === "ie" || env.browser === "edge") {
      this.erreur = "Votre navigateur est obsolète et le fonctionnement n'est pas garanti avec notre application."
    }
  },
  mounted() {
    var motds = ['La bonne cuisine est honnête, sincère et simple.', 'On doit mettre tout son coeur dans la cuisine.', 'On doit laisser en paix les gens chargés de la cuisine', 'Pas d’artifice en cuisine pour déguiser une nourriture sans goût.'
      , 'En France, la cuisine est une forme sérieuse d’art et un sport national.', "La cuisine simple, c'est ce qu'il y a de plus compliqué.", "On ne peut pas faire de cuisine si l'on n'aime pas les gens!", "Un homme civilisé ne peut vivre sans cuisiner.",
      "Cuisiner est une activité qui exige d’être aussi créatif et imaginatif qu’une activité telle que le dessin, la sculpture sur bois ou la musique.", "Il n’y a pas de bonne cuisine si elle n’est pas faite par amitié pour celui ou celle à qui elle est destinée.",
      "La vraie cuisine est une forme d’art, un cadeau à partager.", "Pour bien cuisiner il faut de bons ingrédients, un palais, du coeur et des amis.", "Une cuisine est toujours nouvelle quand elle est bonne.", "Cuisiner suppose une tête légère, un esprit généreux et un coeur large.",
      "La cuisine est un art qui, pour être partagé, doit être créé avec passion." ]
    this.motd = motds[this.getRandomInt(motds.length)]
    this.$nextTick(() => {
      this.$refs["login-username"].focus()
    })
  }
  ,
  computed: {

  },
  methods: {
    getRandomInt(max) {
      return Math.floor(Math.random() * Math.floor(max))
    },
    async nettoyerCache() {
      await viderStore()
      await viderMercuriales()
      await lscache.flush()
    },
    async login() {
      const { userName, password } = this
      this.isLoading = true
      this.erreurLogin = ""

      try {
        this.$store.commit('etablissements/emptyEtablissementId')
        this.$store.commit('profils/emptyProfilId')
        const error = await this.$store.dispatch('auth/' + AUTH_REQUEST, { userName, password })
        if (error) {
          throw error
        }
      } catch (error) {
        this.isLoading = false
        this.erreurLogin = "Login ou mot de passe invalide !"
        if (error.message && error.message == "Network Error") {
          this.erreurLogin = "Erreur réseau / connexion internet, ou serveur manquant"
        }
        console.error(error)
        return
      }
      setUserBugsnag(this.utilisateurCourant)

      logConnexion()

      //this.selectEtablissementVisible = true
      //let et = this.etablissementsCourants
      if (this.etablissementsCourants && this.etablissementsCourants.length == 1 && !this.aPlusieursProfils) {
        await this.$store.commit('etablissements/setEtablissement', { etablissementId: this.etablissementsCourants[0].eId, userId: this.userId })
      }
      else {
        this.selectEtablissementVisible = true
      }

      /*
      if (ancienIdEtabEnCours && this.etablissementsCourants && this.etablissementsCourants.find(et => et.eId === ancienIdEtabEnCours)) {
        await this.$store.commit('etablissements/setEtablissement', { etablissementId: ancienIdEtabEnCours, userId: this.userId })
      } else { // l'etablissement en cours n'appartient pas a l'utilisateur connecté
        if (this.etablissementsCourants && this.etablissementsCourants.length == 1) {
          await this.$store.commit('etablissements/setEtablissement', { etablissementId: this.etablissementsCourants[0].eId, userId: this.userId })
        }
        else {
          this.selectEtablissementVisible = true
        }
      }
      */

      chargerInfosApp()
      this.chargerDefinitions()
    },
    chargerDefinitions() {
      this.isLoading = true
      return chargerDefinitions()
        .then(() => {
          this.isLoading = false
          if (this.etabCourantId) {
            return this.chargerDonneesEtablissement()
          }
          return
        })
    },
    chargerDonneesEtablissement() {
      return chargerDonneesEtablissement(this.etabCourantId)
        .then(function () {
          chargerMercuriales(this.etabCourantId, null, null, this.profilLecteurMercu)
        }.bind(this))

    },
    changerEtablissement(etablissementId) {
      this.$store.commit('etablissements/setEtablissement', { etablissementId: etablissementId, userId: this.$store.state.user.userInfo.id })
      lscache.flush()
      this.chargerDonneesEtablissement()
    },
    changerProfil(profilId) {
      this.$store.commit('profils/setProfil', { profilId: profilId, userId: this.$store.state.user.userInfo.id })
      lscache.flush()
    },
  }
}
</script>
