/* wrapper autour de xlsx-style, src: https://github.com/protobi/js-xlsx
  permet la lecture et l'écriture de fichiers xlsx */

import { saveAs } from 'file-saver'

export const xlsx = {
  data() {
    return {
      srcPath: "/assets/js/xlsx.js"
    }
  },
  computed: {
    xlsx() {
      window.XLSX.saveFile = (worksheet, name) => {//génere et télécharge un fichier xlsx contenant les données de worksheet
        //cf PageEffectifPortage.exporterXlsx() pour un exemple ou https://github.com/protobi/js-xlsx pour la doc

        const max = Object.keys(worksheet).reduce((max, cur) => {//calcul de la zone à prendre en compte
          const row = Number(cur.replace(/[^0-9]/g, ''))
          const col = cur.replace(row, '')
          if (row > max.row) {
            max.row = row
          }
          if (col > max.col) {
            max.col = col
          }
          return max
        }, {col: "A", row: 1})
        worksheet["!ref"] = "A1:" + max.col + max.row

        var workbook = {//création du fichier
          SheetNames: [name],
          Sheets: {}
        }
        workbook.Sheets[name] = worksheet
        workbook = this.xlsx.write(workbook, {
          bookType: "xlsx",
          type: "binary"
        })

        saveAs(new Blob([this.s2ab(workbook)], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}), name + ".xlsx")//téléchargement
      }

      return window.XLSX
    }
  },
  methods: {
    s2ab(s) {//renvoi un ArrayBuffer contenant s
      var buf = new ArrayBuffer(s.length)
      var view = new Uint8Array(buf)
      for (let i = 0; i < s.length; i += 1) {
        view[i] = s.charCodeAt(i) & 0xFF
      }
      return buf
    }
  },
  mounted() {//ajout du script
    var xlsx = document.createElement("script")
    xlsx.setAttribute("src", this.srcPath)
    document.body.appendChild(xlsx)
  },
  destroyed() {//suppression du script
    document.body.removeChild(document.body.querySelector("script[src='" + this.srcPath + "']"))
  }
}