<template>
  <div :key="'choixDateMenus' + key">
    <div v-if="!multiLigne" class="row flex-center-space-between">
      <div v-if="multiDates" class="col-4">
        <v-date-picker class="date-picker" is-range :columns="2" :step="1" v-model="selectedDates" :popover="{ visibility: 'click' }">
          <template v-slot="{ inputValue, inputEvents }">
            <input :disabled="disabled" :value="inputValue.start || inputValue.end ? inputValue.start + ' - ' + inputValue.end : 'Choisir les dates...'" v-on="inputEvents.start" />
          </template>
        </v-date-picker>
        <span v-if="debut || fin" style="cursor:pointer;position:absolute;left:220px;top:4px;font-size:2rem;" v-tooltip="{ content: 'Effacer les dates'}" @click="effacerLesDates()" class="fas fa-window-close"></span>
      </div>
      <div v-else class="col-4">
        <v-date-picker class="date-picker" v-model="selectedDates.start" :popover="{ visibility: 'click' }" @input="selectedDates.end=selectedDates.start; datesChanged();">
          <template v-slot="{ inputValue, inputEvents }">
            <input :disabled="disabled" :value="inputValue ? inputValue : 'Choisir la date...'" v-on="inputEvents" />
          </template>
        </v-date-picker>
      </div>
      <div class="col-5">
        <MultiSelect v-if="multiSelect" :disabled="!optionsMenusPlans.length || estDesactive" :value="menusPlans" :items="optionsMenusPlans" itemText="nom" itemValue="id" placeholder="Structures de menus à inclure..." emptyText="Toutes les structures ont été ajoutées" :clearText="viderMenusPlans" :maxDisplay="2" :dropdownPosition="dropdownPosition" @blur="menusPlansChanged" />
        <v-select v-else :disabled="!optionsMenusPlans.length || estDesactive" :options="optionsMenusPlans" v-model="menuPlanSingle" label="nom" id="menuPlans" placeholder="Structures de menus ..." @input="menuPlanSingleChanged" />
      </div>
      <div class="col-3">
        <MultiSelect v-if="multiSelect" :disabled="!optionsServices.length || estDesactive" :value="services" :items="optionsServices" itemText="nom" itemValue="id" placeholder="Services..." emptyText="Tous les services ont été ajoutés" :dropdownPosition="dropdownPosition" @blur="servicesChanged" />
        <v-select v-else :disabled="!optionsServices.length || estDesactive" :options="optionsServices" v-model="serviceSingle" label="nom" id="services" placeholder="Services ..." @input="serviceSingleChanged" />
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div v-if="multiDates" class="col-4">
          <v-date-picker class="date-picker" is-range :columns="2" :step="1" v-model="selectedDates" :popover="{ visibility: 'click' }">
            <template v-slot="{ inputValue, inputEvents }">
              <input :disabled="disabled" :value="inputValue.start || inputValue.end ? inputValue.start + ' - ' + inputValue.end : 'Choisir les dates...'" v-on="inputEvents.start" />
            </template>
          </v-date-picker>
          <span v-if="debut || fin" style="cursor:pointer;position:absolute;left:220px;top:4px;font-size:2rem;" v-tooltip="{ content: 'Effacer les dates'}" @click="effacerLesDates()" class="fas fa-window-close"></span>
        </div>
        <div v-else class="col-4">
          <v-date-picker class="date-picker" v-model="selectedDates.start" :popover="{ visibility: 'click' }" @input="selectedDates.end=selectedDates.start; datesChanged();">
            <template v-slot="{ inputValue, inputEvents }">
              <input :disabled="disabled" :value="inputValue ? inputValue : 'Choisir la date...'" v-on="inputEvents" />
            </template>
          </v-date-picker>
        </div>
      </div>
      <div class="mt-5 row flex-center-space-between">
        <div class="col-8">
          <MultiSelect v-if="multiSelect" :disabled="!optionsMenusPlans.length || estDesactive" :value="menusPlans" :items="optionsMenusPlans" itemText="nom" itemValue="id" placeholder="Structures de menus à inclure..." emptyText="Toutes les structures ont été ajoutées" :clearText="viderMenusPlans" :dropdownPosition="dropdownPosition" @blur="menusPlansChanged" />
          <v-select v-else :disabled="!optionsMenusPlans.length || estDesactive" :options="optionsMenusPlans" v-model="menuPlanSingle" label="nom" id="menuPlans" placeholder="Structures de menus ..." @input="menuPlanSingleChanged" />
          <div class="error" v-if="selectedDates && menusPlans && menusPlans.length === 0">Ce champ est requis</div>
        </div>
        <div class="col-4">
          <MultiSelect v-if="multiSelect" :disabled="!optionsServices.length || estDesactive" :value="services" :items="optionsServices" itemText="nom" itemValue="id" placeholder="Services..." emptyText="Tous les services ont été ajoutés" :dropdownPosition="dropdownPosition" @blur="servicesChanged" />
          <v-select v-else :disabled="!optionsServices.length || estDesactive" :options="optionsServices" v-model="serviceSingle" label="nom" id="services" placeholder="Services ..." @input="serviceSingleChanged" />
          <div class="error" v-if="selectedDates && services && services.length === 0">Ce champ est requis</div>
        </div>
      </div>
    </div>
    <div v-if="(debut || fin) && !optionsMenusPlans.length"><i class="fas fa-exclamation-triangle warning"></i> <span class="note">La période sélectionnée ne contient aucun menu</span></div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { formatageDate } from '@/mixins/dateFormats'
import { chargerMenuPlansAvecRecetteDeuxDates } from '@/api/gpao/menus'
import { connecteData } from '@/mixins/connecteData'
import MultiSelect from "@/components/Inputs/MultiSelect"

export default {
  name: "SelectDatesMenusPlansServices",
  props: {
    dateDebut: String,
    dateFin: String,
    menuPlanIds: Array,
    serviceIds: Array,
    listeServices: Array,
    listeStructures: Array,
    titre: {type: String, default: 'Période de consommation...'},
    viderMenusPlans: {type: String, default: ""},
    disabled: {type: Boolean, default: false},
    dropdownPosition: {type: String, default: undefined},
    multiDates: {type: Boolean, default: true},
    multiSelect: {type: Boolean, default: true},
    multiLigne: {type: Boolean, default: false},
    structureObligatoire :{type: Boolean, default: false},
    serviceObligatoire :{type: Boolean, default: false}
  },
  components: {MultiSelect},
  mixins: [formatageDate, connecteData],
  data() {
    return {
      debut: '',
      fin: '',
      menusPlans: [],
      optionsMenusPlans: [],
      services: [],
      optionsServices: [],
      menuPlanSingle : null,
      serviceSingle : null,
      selectedDates: {
        start: new Date(this.dateDebut),
        end: new Date(this.dateFin)
      },
      key: 1
    }
  },
  computed: {
    estDesactive() {
      return this.disabled
    }
  },
  watch: {
    selectedDates() {
      this.datesChanged()
    }
  },
  methods: {
    ...mapActions("uxHelpers", ["afficherAttente", "cacherAttente"]),
    datesChanged() {
      this.debut = this.formatDateAvecTirets(this.selectedDates?.start)
      this.fin = this.formatDateAvecTirets(this.selectedDates?.end)
      this.menuPlanSingle = null
      this.serviceSingle = null
      this.resetMenusPlansEtServices()
    },
    effacerLesDates() {
      //this.dateDebut = ''
      //this.dateFin = ''
      this.debut = ''
      this.fin = ''
      this.selectedDates = {
        start: "",
        end: ""
      }
      this.menusPlans = []
      this.optionsMenusPlans = []
      this.services = []
      this.optionsServices = []
      //this.emitChange()
    },
    resetMenusPlansEtServices(init) {
      if (this.debut && !this.fin) {
        this.fin = this.debut
      }
      this.optionsMenusPlans = []
      this.optionsServices = []
      this.menusPlans = []
      this.services = []
      this.afficherAttente()
      if (this.debut && this.fin) {
        chargerMenuPlansAvecRecetteDeuxDates({ etablissementId: this.etabCourantId, apiDateDebut: this.apiDate(this.debut), apiDateFin: this.apiDate(this.fin) })
          .then(function (response) {
            this.affichageUsage = {}
            if (response.data.menuPlans.length) {
              this.optionsMenusPlans = response.data.menuPlans.map(el => {
                return { id: el.id.toString(), nom: el.nom }
              })
              this.menusPlans = this.multiSelect ? response.data.menuPlans.map(el => { return el.id.toString() }) : [];
              this.optionsServices = response.data.servicesPresents.map(function (el) {
                return { id: el, nom: this.$store.state.definitions.services.find(service => service.value === el).text }
              }.bind(this))
              this.services = this.multiSelect ? response.data.servicesPresents.map(el => { return el }) : [];
              this.key += 1
            }
            if (init) {
              this.menusPlans = this.menuPlanIds
            } else {
              this.emitChange()
            }
          }.bind(this))
          .catch(e => console.log(e))
      }
      this.emitChange()
      this.cacherAttente()
    },
    menuPlanSingleChanged(e) {
      this.menusPlans = []
      if (e) {
        this.menusPlans.push(e.id)
      }
      this.emitChange()
    },
    menusPlansChanged(e) {
      if (e && e !== this.menusPlans) {
        this.menusPlans = e
        this.emitChange()
      }
    },
    serviceSingleChanged(e) {
      this.services = []
      if (e) {
        this.services.push(e.id)
      }
      this.emitChange()
    },
    servicesChanged(e) {
      if (e && e !== this.services) {
        this.services = e
        this.emitChange()
      }
    },
    emitChange() {
      this.$emit("changer-dms", { dateDebut: this.debut, dateFin: this.fin, menuPlanIds: this.menusPlans, serviceIds: this.services, listeServices: this.optionsServices, listeStructures: this.optionsMenusPlans })
    }
  },
  mounted() {
    this.debut = this.dateDebut
    this.fin = this.dateFin
    if (this.debut && this.fin) {
      this.resetMenusPlansEtServices(true)
    }
    this.menusPlans = this.multiSelect ? this.menuPlanIds : []
    this.services = this.serviceIds
  }
}
</script>

<style lang="scss" scoped>
.datepicker {
  box-shadow: none;
  border-bottom: 1px solid #949494 !important;
  color: #5f5f5f;
  background-color: #fff;
  font-size: 1rem;
  width: 100%;
  font-size: 16px;
  height: 59.2px;
  padding: 6px 0 0 0;
  ::placeholder {
    color: rgba(0, 0, 0, 0.87);
  }
}
</style>
