<template>
  <div class="card">
    <div class="card-header flex-center-space-between">
      <span>Livraisons prévues</span>
      <span v-if="inventaireOuvert" class="text-warning">
        <i class="fas fa-exclamation-triangle text-warning"></i> Inventaire en cours : Contrôle à réception verrouillé
      </span>
      <span></span>
    </div>
    <div class="card-container">
      <div v-if="!gotLivraisons" style="text-align:center;margin-top:40px;">
        <img alt="en attente" src="/assets/images/spinners/cooking-blue-court.gif" style='height:100px;margin-left:-8px;' />
      </div>
      <div v-else>
        <h2 class="text-center mt-2"><b>{{ nombreLivraisons }}</b></h2>
        <div class="text-center"><b>livraison{{ livraisons.length > 1 ? "s" : "" }} prévue{{ livraisons.length > 1 ? "s" : "" }}</b></div>
        <div class="text-center"><b>entre aujourd'hui et demain</b></div>

        <div class="card card-minimal table-scroll height-responsive-inner-top-50 mt-4">
          <table class="table table-sm table-borderless text-center"> <!-- style="display:block;max-height:800px;overflow:auto" -->
            <colgroup>
              <col>
              <col>
              <col width="120">
              <col width="120">
              <col width="90">
              <col width="35">
            </colgroup>
            <thead>
              <th class="col-fixed-width-150-sm col-fixed-width-200-lg col-fixed-width-200-xl">Fournisseurs</th>
              <th>Commandes</th>
              <th>N° BdC</th>
              <th>produits (<em>fam.</em>)</th>
              <th>Total HT</th>
              <th></th>
            </thead>
          </table>
          <hr class="mt-0 mb-0" />
          <div class="table-body">
            <table class="table table-sm table-borderless text-center">
              <colgroup>
                <col>
                <col>
                <col width="120">
                <col width="120">
                <col width="90">
                <col width="30">
              </colgroup>
              <tbody>
                <!-- Affichage des livraisons d'aujourd'hui -->
                <tr v-if="livraisons.some(l => formatDateAvecTirets(l.dateLivraison) === formatDateAvecTirets(new Date()))">
                  <td colspan="7"><b>{{ formatHumanDate(new Date()) }}</b></td>
                </tr>
                <tr v-for="(livraison, iLivraison) in livraisons.filter(l => formatDateAvecTirets(l.dateLivraison) === formatDateAvecTirets(new Date()))" :key="iLivraison">
                  <td class="col-fixed-width-150-sm col-fixed-width-200-lg col-fixed-width-200-xl">{{ livraison.fournisseur }}</td>
                  <td>{{ livraison.nomCommande }}</td>
                  <td>{{ livraison.bonDeCommande }}</td>
                  <td>{{ livraison.produits }} (<em>{{ livraison.fCodes }}</em>)</td>
                  <td>{{ livraison.totalHT | afficherPrix2 }}</td>
                  <td class="input">
                    <b-button class="btn btn-primary btn-sm" :disabled="inventaireOuvert" @click="$router.push({ name: 'DetailCommandeFournisseur', params: { etablissementId: etabCourantId, commandeId: livraison.commandeId, fournisseurId: livraison.fournisseurId } })"
                      title="Réceptionner la commande"><i class="fas fa-dolly-flatbed"></i></b-button>
                  </td>
                </tr>
                <!-- Affichage des livraisons de demain -->
                <tr v-if="livraisons.some(l => formatDateAvecTirets(l.dateLivraison) === formatDateAvecTirets(demain()))">
                  <td colspan="7"><b>{{ formatHumanDate(demain()) }}</b></td>
                </tr>
                <tr v-for="(livraison, iLivraisonDemain) in livraisons.filter(l => formatDateAvecTirets(l.dateLivraison) === formatDateAvecTirets(demain()))" :key="'demain'+iLivraisonDemain">
                  <td class="col-fixed-width-150-sm col-fixed-width-200-lg col-fixed-width-200-xl">{{ livraison.fournisseur }}</td>
                  <td>{{ livraison.nomCommande }}</td>
                  <td>{{ livraison.bonDeCommande }}</td>
                  <td>{{ livraison.produits }} (<em>{{ livraison.fCodes }}</em>)</td>
                  <td>{{ livraison.totalHT | afficherPrix2 }}</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="text-center mt-2 mb-2">
          <b-button :disabled="!profilIsUtilisateur" class="btn btn-primary" @click="$router.push({ name: 'ListeDesCommandes', params: { etablissementId: etabCourantId } })">Voir plus de détails</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { verifierInventaireOuvert } from "@/helpers/verifications"
import { connecteData } from "@/mixins/connecteData"
import { formatageDate } from '@/mixins/dateFormats'
import { mapGetters } from "vuex"

export default {
  name: "WidgetLivraisons",
  props: [],
  mixins: [ connecteData, formatageDate ],
  data() {
    return {
      livraisons: [],
      gotLivraisons: false,
      nombreLivraisons: "00",
      inventaireOuvert: false
    }
  },
  mounted() {
    this.chargerLivraisons()
    this.verifierInventaire()
  },
  computed: {
    ...mapGetters("definitions", ["informationsAccueil", "familleParId"]),
    ...mapGetters("fournisseurs", ["nomFournisseur"]),
  },
  methods: {
    demain() {
      let demain = new Date()
      demain.setDate((new Date()).getDate() + 1)
      return demain
    },
    chargerLivraisons() {
      this.livraisons = []
      if (this.informationsAccueil.livraisons) {
        this.informationsAccueil.livraisons.forEach(l => {
          let livre = { familleIds: l.familleIds, commandeId: l.commandeId, nomCommande: l.nomCommande, fournisseurId: l.fournisseurId, dateLivraison: l.dateLivraison, produits: l.nbProduits, bonDeCommande: l.bonDeCommande, totalHT: l.totalHT }
          livre.fCodes = livre.familleIds.map(f => this.familleParId(f).code).join(", ")
          livre.fournisseur = this.nomFournisseur(livre.fournisseurId)
          this.livraisons.push(livre)
        })
      }
      this.nombreLivraisons = String(this.livraisons?.length || 0)
      if (this.nombreLivraisons.length < 2) {
        this.nombreLivraisons = "0" + this.nombreLivraisons
      }

      this.$nextTick(() => this.gotLivraisons = true)
    },
    async verifierInventaire() {
      this.inventaireOuvert = await verifierInventaireOuvert(this.etabCourantId)
    }
  }
}
</script>
