<template>
  <div style="display: inline">
    <div>
      <div class="container-fluid">
        <div class="row flex-center-space-between">
          <div class="col-6">
            <input :disabled="produit && produit.typeProduit === 'Produit'" type="text" placeholder="Désignation" required title="Désignation de votre produit" v-model.trim="produitLocal.designation" v-tooltip="{ content: 'Désignation générique du produit', placement: 'top' }" style="width:100%"/>
            <div class="error" v-if="!$v.produitLocal.designation.required">Ce champ est requis</div>
          </div>
          <div class="col-3 flex-end">
            <span>Grammage</span>
            <InputNumber class="ml-1 mr-1" :disabled="produit && produit.typeProduit === 'Produit'" typeFiltre="quantite" v-model="produitLocal.grammage" style="width:100px"/>g
            <div class="error" v-if="!$v.produitLocal.grammage.required">Ce champ est requis</div>
          </div>
          <div class="col-3 flex-end">
            <span>Coté en </span>
            <input class="ml-1" :disabled="produit && produit.typeProduit === 'Produit'" type="text" v-model="produitLocal.uc" list="texteUC" style="width:100px"/>
                <datalist id="texteUC">
                      <option value="Kg" />
                      <option value="Litre" />
                      <option value="Carton" />
                      <option value="Unité" />
                      <option value="Pièce" />
                      <option value="Seau" />
                    </datalist>
            <div class="error" v-if="!$v.produitLocal.uc.required">Ce champ est requis</div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row push-top-1rem">
          <div class="col-6">
            <div class="push-right-2rem">Ingrédient</div>
            <div class="bg-gradient-bleu text-white">{{nomIngredient(produitLocal.ingredientId)}}</div>
            <RechercheIngredients v-if="!produit || produit.typeProduit !== 'Produit'" :liste="ingredients" placeHolder="Rechercher un ingrédient..." :cacherAuto="true" :small="true" @selection-ingredient="setItemIngredientId($event)" />
            <div class="error" v-if="!$v.produitLocal.ingredientId.required">Ce champ est requis</div>
          </div>
          <div class="col-6">
            <span>Unité de production par défaut</span>
            <div class="bg-gradient-bleu text-white pl-2"><span v-html="upIngredient(produitLocal.ingredientId)"></span></div>
          </div>
        </div>
        <div class="row flex-end" v-if="!produit || produit.typeProduit !== 'Produit'">
          <button :disabled="!produitEstModifie || !$v.produitLocal.designation.required || !$v.produitLocal.ingredientId.required
            || !$v.produitLocal.grammage.required || !$v.produitLocal.uc.required"
            class="btn bleuAgap" @click="enregistrer()">Enregistrer</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { showAlerts } from "@/mixins/alerts"
import { ajouterProduit, modifierProduit } from '@/api/gpao/produits'
import RechercheIngredients from '@/components/Recettes/RechercheIngredients'
import InputNumber from '@/components/Inputs/InputNumber'
import { required } from "vuelidate/lib/validators"
import store from '@/store/'

export default {
  name: "AjoutProduitGreAGre",
  props: [ 'familleId', 'produit' ],
  mixins: [
    showAlerts
  ],
  components: {
    InputNumber,
    RechercheIngredients
  },
  data() {
    return {
      produitLocal: {
        produitId: this.produit ? this.produit.produitId : '',
        familleId: this.familleId,
        designation: this.produit ? this.produit.designation : '',
        grammage: this.produit ? this.produit.grammage : 0,
        ingredientId: this.produit ? this.produit.ingredientId : '',
        uc: this.produit ? this.produit.uc :  '',
        hierarchie: this.produit ? this.produit.hierarchieProduit : 1
      }
    }
  },
  validations: {
    produitLocal: {
      designation: { required },
      grammage: { required },
      ingredientId: { required },
      uc: { required }
    }
  },
  computed: {
    ...mapGetters("definitions", ["ingredients"]),
    produitEstModifie() {
      if (!this.produit) {
        return true
      }
      return this.produitLocal.designation !== this.produit.designation
        || this.produitLocal.ingredientId !== this.produit.ingredientId
        || this.produitLocal.grammage !== this.produit.grammage
        || this.produitLocal.uc !== this.produit.uc
    }
  },
  methods: {
    setItemIngredientId(event) {
      this.produitLocal.ingredientId = event.id
    },
    getIngredient(ingredientId) {
      if (!ingredientId)
        return null
      let ingredient = this.ingredients.find(el => el.id === ingredientId)
      if (!ingredient)
        return null
      return ingredient
    },
    nomIngredient(ingredientId) {
      let ingredient = this.getIngredient(ingredientId)
      if (!ingredient)
        return ''
      return ingredient.nom
    },
    upIngredient(ingredientId) {
      let ingredient = this.getIngredient(ingredientId)
      if (!ingredient)
        return '<i>definissez un ingrédient</i>'
      return ingredient.up
    },
    async enregistrer() {
      //data['commentairesLigne'] = produit.commentairesLigne
      if (this.produitLocal.produitId) {
        await modifierProduit(this.produitLocal.produitId, this.produitLocal)
      } else {
        let response = await ajouterProduit(this.produitLocal)
        this.produitLocal.produitId = response.data
        this.$emit('produit-ajoute', { produit: this.produitLocal })
      }
      
      this.$toast('Produit sauvegardé', {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        className: ['toast-success'],
        duration: 1500
      })
        store.dispatch('definitions/rechargerIngredient', this.produitLocal.ingredientId)
    }
  }
}
</script>
