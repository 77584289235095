<template>
  <div :key="key" :style="categorie ? 'background-color:' + categorie.couleur : ''">
    <div v-if="enChargement" class="composante-container" style="text-align:center;">
      <img alt="en attente" src="/assets/images/spinners/cooking-blue-court.gif" style="height:80px;margin-left:-8px;" />
    </div>

    <div v-else-if="composante.categorieGEMRCNId" class="composante-container">
      <div class="flex-center-space-between">
        <div style="height:16px;overflow:hidden;white-space:nowrap;">
          <span :style="'cursor:default;' + (categorie ? 'color:' + couleurTexte(categorie.couleur, '#6F6F6F', '#BFBFBF') : '')">{{ composante.nom }}</span>
        </div>
        <div style="white-space:nowrap;">
          <button v-tooltip="{ content: 'Changer la catégorie'}" @click="afficherModalCategories" type="button" class="btn btn-primary" style="padding:0 4px;border-radius:8px;transform:scale(0.7);margin-right:-5px;"><i class="fas fa-exchange-alt"></i></button>
          <button v-tooltip="{ content: 'Effacer la catégorie'}" @click="effacerCategorie" type="button" class="btn btn-danger" style="padding:0 6.5px;border-radius:8px;margin-left:4px;transform:scale(0.7);"><i class="fas fa-times"></i></button>
        </div>
      </div>
      <div class="flex-center-space-between" style="align-items:flex-start;">
        <div>
          <div :style="'height:45px;font-size:12px;' + (categorie ? 'color:' + couleurTexte(categorie.couleur, 'black', 'white') : '')">
            <div>
              {{ categorie && categorie.nom }}
            </div>
          </div>
        </div>
      </div>
      <div style="position: absolute;bottom: 0;width: 100%;left: 0;">
        <div v-for="(limite, iLimite) in limites" :key="iLimite">
          <div :class="'pl-1 ' + (limite.compte >= limite.frequenceMin && (!limite.frequenceMax || limite.compte <= limite.frequenceMax) ? 'bg-success' : 'bg-danger')">
            {{ limite.compte }} ({{ limite.texteAAfficher }})
          </div>
        </div>
      </div>
    </div>

    <div v-else class="composante-container">
      <div :style="'color:' + couleurTexte('#000000', '#6F6F6F', '#BFBFBF')">{{ composante.nom }}</div>
      <button type="button" class="btn btn-ajouter" title="Ajouter une catégorie GEMRCN" @click="afficherModalCategories"><i class="fas fa-plus"></i> Ajouter</button>
    </div>
  </div>
</template>

<script>
import { optionsJours } from '@/mixins/menus'
import { couleurTexteSelonFond } from "@/helpers/utils"

export default {
  name: "CasePlanAlimentaire",
  props: ['composante', 'service', 'jour', 'categorie', 'limites'],
  mixins: [optionsJours],
  data() {
    return {
      enChargement: false,
      key: 1
    }
  },
  computed: {
  },
  methods: {
    effacerCategorie() {
      this.$emit('effacer-categorie', {
        service: this.service,
        composante: this.composante,
        jour: this.jour
      });
    },
    afficherModalCategories() {
      this.$root.$emit('afficher-modal-categorie', {
        service: this.service,
        composante: this.composante,
        jour: this.jour
      })
    },
    couleurTexte(couleur, couleurParDefaut, couleurSurFonce) {
      return couleurTexteSelonFond(couleur, couleurParDefaut, couleurSurFonce)
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/couleurs.scss';
@import url(https://fonts.googleapis.com/css?family=Roboto);

td {
  padding: 0 !important;
}
.composante-container {
  border: 1px solid rgb(224, 224, 224);
  font-family: "Roboto";
  cursor: default;
  font-size: 14px;
  font-style: italic;
  height: 120px;
  padding-left: 5px;
  position: relative;
}
.btn-ajouter {
  width: calc(100% - 10px);;
  margin-top: 10px;
  position: absolute;
  bottom: 5px;
  left: 5px;
  color: #007bff;
  border-color: #007bff;
}
.btn-ajouter:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
</style>
